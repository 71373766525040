import React from 'react';
import styled from 'src/theme';

interface LoadingProps {
  size?: number;
  fill?: string;
  display?: string;
  className?: string;
}

const LoadingSpinner: React.FunctionComponent<LoadingProps> = ({
  size = 40,
  fill = 'currentColor',
  display = 'inline-block',
  className = '',
}) => (
  <Root>
    <svg className={className} width={size} height={size} viewBox="0 0 50 50" fill={fill} style={{ display }}>
      <path d="M43.935 25.145c0-10.318-8.364-18.683-18.683-18.683-10.318 0-18.683 8.365-18.683 18.683h4.068c0-8.071 6.543-14.615 14.615-14.615s14.615 6.543 14.615 14.615h4.068z">
        <animateTransform
          attributeType="xml"
          attributeName="transform"
          type="rotate"
          from="0 25 25"
          to="360 25 25"
          dur="0.8s"
          repeatCount="indefinite"
        />
      </path>
    </svg>
  </Root>
);

const Root = styled.div`
  width: 100%;
  text-align: center;
`;

export default LoadingSpinner;
