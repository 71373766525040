import React, { ChangeEventHandler } from 'react';
import styled from '../../theme';
import CheckableInput, { CheckableInputContainer } from '../CheckableInput';

export interface Option {
  value: string;
  label: string;
}
export type Options = Option[];

export interface Props {
  name: string;
  options: Options;
  className?: string;
  onChange: ChangeEventHandler<HTMLInputElement>;
  value?: string | number;
}

const StyledOptionGroup = styled.div`
  margin: 2px;

  ${CheckableInputContainer} + ${CheckableInputContainer} {
    margin-top: 0.25em;
  }
`;

const OptionGroup: React.FunctionComponent<Props> = ({ className = '', options, onChange, value, name }) => {
  return (
    <StyledOptionGroup className={className} role="radiogroup">
      {options.map((option) => (
        <CheckableInput
          key={`${option.value}||${option.label}`}
          type="radio"
          checked={value === option.value}
          value={option.value}
          title={option.label}
          name={name}
          onChange={onChange}
        />
      ))}
    </StyledOptionGroup>
  );
};

export default OptionGroup;
