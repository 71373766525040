/* eslint-disable @typescript-eslint/no-use-before-define */
import { createAsyncThunk } from '@reduxjs/toolkit';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { getIn } from 'formik';
import { normalize } from 'normalizr';
import { stringify as queryStringify } from 'qs';
import { Dispatch } from 'redux';
import { S3UploadStatus } from 'src/constants/S3UploadStatus';
import { CreditClaimStatus, CreditClaimStatusValues } from '../constants/ApplicationStatus';
import { ApplicationTypeFilter } from '../constants/ApplicationTypeFilter';
import { resultsSchema } from '../schemas/claims';
import { claimMetaSelector } from '../selectors/claims';
import { CreditActivity, CreditActivityAttachment, CreditClaim, CreditClaimOrder, User } from '../types';
import { apiRequest } from '../utilities/api';
import { prepareClaim } from '../utilities/prepareClaim';
import { prepareInitialClaimValues } from '../utilities/prepareInitialClaimValues';
import { ClaimStore } from './types';

/* ACTIONS */
export const FETCH_CLAIM_REQUEST = '@CLAIM/REQUEST';
export const FETCH_CLAIM_SUCCESS = '@CLAIM/SUCCESS';
export const FETCH_CLAIM_ERROR = '@CLAIM/ERROR';
export const FETCH_CLAIMS_SUCCESS = '@CLAIMS/SUCCESS';
export const FETCH_CLAIM_ORDER_REQUEST = '@CLAIM/ORDER/REQUEST';
export const FETCH_CLAIM_ORDER_SUCCESS = '@CLAIM/ORDER/SUCCESS';
export const FETCH_CLAIM_ORDER_ERROR = '@CLAIM/ORDER/ERROR';
export const SET_CLAIM_STATUS_FILTERS = '@CLAIMS/SET_CLAIM_STATUS_FILTERS';
export const SET_CLAIM_SORT_ORDER = '@CLAIMS/SET_CLAIM_SORT_ORDER';
export const RESET_CLAIM = '@CLAIM/RESET';

type RemoveCreditActivityAttachmentThunkArg = { attachment: CreditActivityAttachment; onSuccess?: () => void };
export const removeCreditActivityAttachment = createAsyncThunk<void, RemoveCreditActivityAttachmentThunkArg, { rejectValue: string }>(
  '@CLAIMS/REMOVE_CREDIT_ACTIVITY_ATTACHMENT',
  async ({ attachment, onSuccess }, { rejectWithValue }) => {
    try {
      await apiRequest.del(`/v1/credit-activity-attachments/${attachment.id}`);
      if (typeof onSuccess === 'function') onSuccess();
    } catch (error) {
      Rollbar.error(`Failed to delete attachment ${attachment.id}. ${error}`);
      rejectWithValue(error.response.message);
    }
  }
);

type UploadCreditActivityAttachmentThunkArg = {
  claimId: number;
  files: File[];
  startingFieldIndex?: number;
  beforeUpload: (attachment: CreditActivityAttachment, fieldIndex: number) => void;
  onSuccess: (attachment: CreditActivityAttachment, fieldIndex: number) => void;
  onError: (attachment: CreditActivityAttachment, fieldIndex: number) => void;
};
export const uploadCreditActivityAttachments = createAsyncThunk<void, UploadCreditActivityAttachmentThunkArg, { rejectValue: string }>(
  '@CLAIMS/UPLOAD_CREDIT_ACTIVITY_ATTACHMENT',
  async ({ claimId, files, beforeUpload, onSuccess, onError, startingFieldIndex = 0 }, { rejectWithValue }) => {
    const fileUploadRequests = files.map((file: File) => ({
      fileName: file.name,
    }));

    const { data: attachments }: { data: CreditActivityAttachment[] } = await apiRequest.post(
      `/v1/credit-claims/${claimId}/credit-activity-attachments-collection`,
      fileUploadRequests
    );

    attachments.forEach(async (attachment: CreditActivityAttachment, currentIndex: number) => {
      const index = startingFieldIndex + currentIndex;

      beforeUpload(attachment, index);

      const fileToUpload = files.find((file) => file.name === attachment.fileName);
      const uploadResponse = await axios({
        url: attachment.signedUrlForUpload,
        method: 'PUT',
        data: fileToUpload,
        headers: {
          'Content-Type': fileToUpload?.type,
        },
        responseType: 'text', // AWS doesn't return a body which causes an error in FF. This is a workaround.
      })
        .then(function (response) {
          return response;
        })
        .catch(function () {
          onError(attachment, index);
          apiRequest.put(`/v1/credit-activity-attachments/failed/${attachment.id}`);
          return null;
        });

      if (uploadResponse) {
        const { data: updatedAttachment }: { data: CreditActivityAttachment } = await apiRequest.put(
          `/v1/credit-activity-attachments/${attachment.id}`,
          {
            status: S3UploadStatus.COMPLETE,
          }
        );
        onSuccess(updatedAttachment, index);
      } else {
        rejectWithValue(`Credit Activity Attachment Upload failed for claim id ${claimId}, filename: ${attachment.fileName}`);
      }
    });
  }
);

type FetchInProgressPraClaimThunkArg = { userId: User['id']; onSuccess: (claim: CreditClaim | undefined) => void };
export const fetchInProgressPraClaim = createAsyncThunk<void, FetchInProgressPraClaimThunkArg, { rejectValue: string }>(
  '@CLAIMS/FETCH_IN_PROGRESS_PRA',
  async ({ userId, onSuccess }, { rejectWithValue }) => {
    try {
      const query = {
        userId,
        applicationTypeFilter: 'PRA',
        sortOrder: { numberOfCredits: 'DESC', updatedAt: 'DESC' },
        pageSize: 1,
      };
      const { data }: { data: { results: CreditClaim[] } } = await apiRequest.get(`/v1/creditclaims?${queryStringify(query)}`);
      onSuccess(data.results[0]);
    } catch (error) {
      rejectWithValue(error.response.message);
    }
  }
);

type UpdateClaimStatusThunkArg = {
  claimId: number;
  newStatus: CreditClaimStatus;
  onSuccess: () => void;
  onError: () => void;
};
export const updateClaimStatus = createAsyncThunk<void, UpdateClaimStatusThunkArg, { rejectValue: string }>(
  '@CLAIMS/UPDATE_CLAIM_STATUS',
  async ({ claimId, newStatus, onSuccess, onError }, { rejectWithValue }) => {
    try {
      const { data } = await apiRequest.put(`/v1/creditclaims/${claimId}`, { status: newStatus });
      onSuccess();
      fetchClaimSuccess(data);
    } catch (error) {
      onError();
      rejectWithValue(error.response.message);
    }
  }
);

/**
 * This method is designed to help phase out the above `updateClaimStatus`, because it calls more targeted RESTful
 * URIs that are built to handle specific statuses, e.g PATCH /v1/creditclaims/{id}/submitted
 */
export const patchClaimStatus = createAsyncThunk<void, UpdateClaimStatusThunkArg, { rejectValue: string }>(
  '@CLAIMS/PATCH_STATUS',
  async ({ claimId, newStatus, onSuccess, onError }, { rejectWithValue }) => {
    try {
      const { data } = await apiRequest.patch(`/v1/creditclaims/${claimId}/${newStatus}`);
      onSuccess();
      fetchClaimSuccess(data);
    } catch (error) {
      onError();
      rejectWithValue(error.response.message);
    }
  }
);

interface ActionFetchClaimRequest {
  type: typeof FETCH_CLAIM_REQUEST;
  data?: Partial<CreditClaim>;
}

interface ActionFetchClaimSuccess {
  type: typeof FETCH_CLAIM_SUCCESS;
  data: any; // TODO: Claim type
}

interface ActionFetchClaimError {
  type: typeof FETCH_CLAIM_ERROR;
  data: Partial<CreditClaim>;
  error: any; // TODO: Error type
}

interface ActionFetchClaimOrderRequest {
  type: typeof FETCH_CLAIM_ORDER_REQUEST;
}

interface ActionFetchClaimOrderSuccess {
  type: typeof FETCH_CLAIM_ORDER_SUCCESS;
  data: any;
}

interface ActionFetchClaimOrderError {
  type: typeof FETCH_CLAIM_ORDER_ERROR;
  data: Partial<CreditClaimOrder>;
  error: any; // TODO: Error type
}

interface ActionFetchClaimsSuccess {
  type: typeof FETCH_CLAIMS_SUCCESS;
  data: any;
}

interface ActionSetClaimStatusFilters {
  type: typeof SET_CLAIM_STATUS_FILTERS;
  data: CreditClaimStatus[];
}

interface ActionSetClaimSortOrder {
  type: typeof SET_CLAIM_SORT_ORDER;
  data: any;
}

interface ActionResetClaim {
  type: typeof RESET_CLAIM;
}

type ActionClaim =
  | ActionFetchClaimRequest
  | ActionFetchClaimSuccess
  | ActionFetchClaimError
  | ActionFetchClaimsSuccess
  | ActionFetchClaimOrderRequest
  | ActionFetchClaimOrderSuccess
  | ActionFetchClaimOrderError
  | ActionSetClaimStatusFilters
  | ActionSetClaimSortOrder
  | ActionResetClaim;

/* ACTION CREATORS */
export interface ResponseError {
  message: string;
  statusCode?: number;
}

type FetchClaimRequestActionCreator = () => ActionFetchClaimRequest;
export const fetchClaimRequest: FetchClaimRequestActionCreator = () => {
  return { type: FETCH_CLAIM_REQUEST };
};

export type FetchClaimSuccessActionCreator = (data: Partial<CreditClaim>) => ActionFetchClaimSuccess;
export const fetchClaimSuccess: FetchClaimSuccessActionCreator = (data) => {
  return {
    type: FETCH_CLAIM_SUCCESS,
    data,
  };
};

type FetchClaimErrorActionCreator = (error: ResponseError, data: Partial<CreditClaim>) => ActionFetchClaimError;
export const fetchClaimError: FetchClaimErrorActionCreator = (error, data) => {
  return {
    type: FETCH_CLAIM_ERROR,
    data,
    error,
  };
};

export type FetchClaimsSuccessActionCreator = (data: FetchClaimsResponse) => ActionFetchClaimsSuccess;
export const fetchClaimsSuccess: FetchClaimsSuccessActionCreator = (data) => {
  const { results, ...rest } = data;

  results.map((claim: CreditClaim) => {
    const { creditActivities = [] } = claim;
    const failedTransfers = creditActivities.filter((activity: CreditActivity) => {
      return !activity.learnerActivityId;
    });

    claim.hasFailedTransfers = claim.status === CreditClaimStatus.COMPLETED && failedTransfers.length > 0;

    return claim;
  });

  return {
    type: FETCH_CLAIMS_SUCCESS,
    data: {
      meta: { ...rest },
      ...normalize(results, resultsSchema),
    },
  };
};

export type SetClaimStatusFiltersActionCreator = (data: CreditClaimStatus[]) => ActionSetClaimStatusFilters;
export const setClaimStatusFilters: SetClaimStatusFiltersActionCreator = (data) => ({
  type: SET_CLAIM_STATUS_FILTERS,
  data,
});

export type SetClaimSortOrderActionCreator = (data: any) => ActionSetClaimSortOrder;
export const setClaimSortOrder: SetClaimSortOrderActionCreator = (data) => ({
  type: SET_CLAIM_SORT_ORDER,
  data,
});

export type FetchClaimOrderRequestActionCreator = () => ActionFetchClaimOrderRequest;
export const fetchClaimOrderRequest: FetchClaimOrderRequestActionCreator = () => ({ type: FETCH_CLAIM_ORDER_REQUEST });

export type FetchClaimOrderSuccessActionCreator = (data: Partial<CreditClaimOrder>) => ActionFetchClaimOrderSuccess;
export const fetchClaimOrderSuccess: FetchClaimOrderSuccessActionCreator = (data) => ({
  type: FETCH_CLAIM_ORDER_SUCCESS,
  data,
});

export type FetchClaimOrderErrorActionCreator = (error: ResponseError, data: Partial<CreditClaimOrder>) => ActionFetchClaimOrderError;
export const fetchClaimOrderError: FetchClaimOrderErrorActionCreator = (error, data) => ({
  type: FETCH_CLAIM_ORDER_ERROR,
  data,
  error,
});

export type ResetClaimActionCreator = () => ActionResetClaim;
export const resetClaim: ResetClaimActionCreator = () => {
  return { type: RESET_CLAIM };
};

/* THUNKS */
export type OnSuccess = (response: AxiosResponse) => void;
export type OnError = (error: ResponseError) => void;

interface Config {
  onSuccess?: OnSuccess;
  onError?: OnError;
}

export const fetchClaim: (claimId: number | string) => void = (claimId) => {
  return async (dispatch: Dispatch) => {
    dispatch(fetchClaimRequest());

    apiRequest.get(`/v1/creditclaims/${claimId}`).then(handleClaimSuccess(dispatch)).catch(handleClaimError(dispatch));
  };
};

export interface FetchClaimsQueryParams {
  page?: number;
  pageSize?: number;
  status: CreditClaimStatus[];
  applicationTypeFilter?: ApplicationTypeFilter;
  sortOrder: any;
  userId?: number;
}

export interface FetchClaimsResponse {
  page: number;
  pageSize: number;
  results: CreditClaim[];
  sortOrder: { [key: string]: 'ASC' | 'DESC' };
  status: CreditClaimStatus[];
  total: number;
}

export const fetchClaims = (params: Partial<FetchClaimsQueryParams>) => {
  return async (dispatch: Dispatch, getState: any) => {
    const currentParams = claimMetaSelector(getState());
    dispatch(fetchClaimRequest());

    try {
      const { data } = await apiRequest.get<FetchClaimsResponse>('/v1/creditclaims', {
        params: {
          ...currentParams,
          ...params,
        },
      });

      dispatch(fetchClaimsSuccess(data));
    } catch (error) {
      handleClaimError(dispatch)(error);
    }
  };
};

export type CreateClaimAsync = (data: Partial<CreditClaim> & { userId?: number }, config?: Config) => (dispatch: Dispatch) => void;
export const createClaim: CreateClaimAsync = (data, config = {}) => {
  return async (dispatch: Dispatch) => {
    dispatch(fetchClaimRequest());

    apiRequest.post('/v1/creditclaims', data).then(handleClaimSuccess(dispatch, config)).catch(handleClaimError(dispatch, config, data));
  };
};

type ClaimSuccessHandler = (dispatch: Dispatch, config?: Config) => (response: AxiosResponse<Partial<CreditClaim>>) => void;
const handleClaimSuccess: ClaimSuccessHandler =
  (dispatch, config = {}) =>
  (response) => {
    const { data } = response;
    const { onSuccess } = config;

    dispatch(fetchClaimSuccess(prepareInitialClaimValues(data)));

    if (onSuccess && typeof onSuccess === 'function') {
      onSuccess(response);
    }
  };

type ClaimErrorHandler = (dispatch: Dispatch, config?: Config, data?: Partial<CreditClaim>) => (error: AxiosError) => void;
const handleClaimError: ClaimErrorHandler =
  (dispatch, config = {}, data = {}) =>
  (error) => {
    const { onError } = config;
    let errorObj = {
      message: 'Submission failed.',
    };

    if (error.response) {
      errorObj = {
        message: getIn(error, 'response.data.error.message', 'Could not update claim.'),
      };
      dispatch(fetchClaimError(errorObj, data));
    } else if (error.request) {
      errorObj = {
        message: 'Could not make request',
      };
      dispatch(fetchClaimError(errorObj, data));
    } else {
      errorObj = {
        message: 'Submission failed',
      };
      dispatch(fetchClaimError(errorObj, data));
    }

    if (onError && typeof onError === 'function') {
      onError(errorObj);
    }
  };

export type UpdateClaimAsync = (
  claimId: number | string,
  data: Partial<CreditClaim>,
  config?: Config,
  validate?: boolean,
  originalData?: Partial<CreditClaim>
) => (dispatch: Dispatch) => void;

export const updateClaim: UpdateClaimAsync = (claimId, data, config = {}, validate = true, originalData) => {
  return async (dispatch: Dispatch) => {
    dispatch(fetchClaimRequest());

    const apiBase = `/v1/creditclaims/${claimId}`;
    const claimData = prepareClaim(data);
    const url = !validate ? `${apiBase}/?validation=${validate}` : apiBase;

    apiRequest
      .put(url, claimData)
      .then(handleClaimSuccess(dispatch, config))
      .catch(handleClaimError(dispatch, config, { ...originalData, ...claimData } || claimData));
  };
};

type ClaimOrderErrorHandler = (dispatch: Dispatch, config?: Config, data?: Partial<CreditClaimOrder>) => (error: AxiosError) => void;
const handleClaimOrderError: ClaimOrderErrorHandler =
  (dispatch, config = {}, data = {}) =>
  (error) => {
    const { onError } = config;
    let errorObj = {
      message: 'Submission failed.',
    };

    if (error.response) {
      errorObj = {
        message: getIn(error, 'response.data.error.message', 'Could not create order for claim.'),
      };
      dispatch(fetchClaimOrderError(errorObj, data));
    } else if (error.request) {
      errorObj = {
        message: 'Could not make request',
      };
      dispatch(fetchClaimOrderError(errorObj, data));
    } else {
      errorObj = {
        message: 'Submission failed',
      };
      dispatch(fetchClaimOrderError(errorObj, data));
    }

    if (onError && typeof onError === 'function') {
      onError(errorObj);
    }
  };

export type CreateClaimOrderAsync = (claimId: number | string, config?: Config) => (dispatch: Dispatch) => void;

export const createClaimOrder: CreateClaimOrderAsync =
  (claimId, config = {}) =>
  async (dispatch: Dispatch) => {
    dispatch(fetchClaimOrderRequest());

    apiRequest
      .post(`/v1/creditclaims/${claimId}/creditClaimOrder`)
      .then(handleClaimOrderSuccess(dispatch, config))
      .catch(handleClaimOrderError(dispatch, config));
  };

type ClaimOrderSuccessHandler = (dispatch: Dispatch, config?: Config) => (response: AxiosResponse<Partial<CreditClaimOrder>>) => void;
const handleClaimOrderSuccess: ClaimOrderSuccessHandler =
  (dispatch, config = {}) =>
  (response) => {
    const { data } = response;
    const { onSuccess } = config;

    dispatch(fetchClaimOrderSuccess(data));

    if (onSuccess && typeof onSuccess === 'function') {
      onSuccess(response);
    }
  };

/* REDUCER */
export const initialState: ClaimStore = {
  isLoading: false,
  data: {} as CreditClaim,
  error: undefined,
  meta: {},
  entities: { claim: {}, creditEarner: {} },
  result: [],
  statusFilters: CreditClaimStatusValues,
};

const claims = (state = initialState, action: ActionClaim): ClaimStore => {
  switch (action.type) {
    case FETCH_CLAIM_REQUEST:
      return {
        ...state,
        isLoading: true,
        data: initialState.data,
        error: initialState.error,
      };
    case FETCH_CLAIM_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.data,
      };
    case FETCH_CLAIM_ERROR:
      return {
        ...state,
        data: {
          ...state.data,
          ...action.data,
        },
        isLoading: false,
        error: action.error,
      };
    case FETCH_CLAIMS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        ...action.data,
      };
    case FETCH_CLAIM_ORDER_REQUEST:
      return {
        ...state,
        isLoading: true,
        data: {
          ...state.data,
          creditClaimOrder: {} as CreditClaimOrder,
        },
        error: initialState.error,
      };
    case FETCH_CLAIM_ORDER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: {
          ...state.data,
          creditClaimOrder: action.data,
        },
      };
    case FETCH_CLAIM_ORDER_ERROR:
      return {
        ...state,
        data: {
          ...state.data,
          creditClaimOrder: {
            ...state.data.creditClaimOrder,
            ...action.data,
          },
        },
        isLoading: false,
        error: action.error,
      };
    case SET_CLAIM_STATUS_FILTERS:
      return {
        ...state,
        statusFilters: action.data,
      };
    case SET_CLAIM_SORT_ORDER:
      return {
        ...state,
        meta: {
          ...state.meta,
          sortOrder: action.data,
        },
      };
    case RESET_CLAIM:
      return {
        ...state,
        data: initialState.data,
      };

    default:
      return state;
  }
};

export default claims;
