import { ButtonHTMLAttributes } from 'react';
import styled from '../../theme';
export { DropdownButton } from './DropdownButton';
export { default as DropdownPanel } from './DropdownPanel';

export interface DropdownProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  isOpen: boolean;
  width?: number;
  removePadding?: boolean;
  children?: any;
  colorKey?: string;
  arrow?: boolean;
}

export const Dropdown = styled.div<{ styling?: string }>`
  position: relative;
`;
