import { CreditActivity, CreditClaim } from '../types';

export const prepareInitialClaimValues = (claim: Partial<CreditClaim>) => {
  const { creditActivities = [] } = claim;

  claim.creditActivities = creditActivities.map((activity: CreditActivity) => {
    const units = activity.activityUnits;
    return {
      ...activity,
      activityUnits: units ? units.toString() : '',
    };
  });

  return claim;
};
