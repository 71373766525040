import React from 'react';
import UtilityBar from 'src/components/UtilityBar';
import logo from '../../assets/images/EdHub-logo-trademark-gray-white.svg';
import styled from '../../theme';
import { themeBreakpoint, themeColor, themeSize } from '../../theme/helpers';
import { Color } from '../../theme/primaryTheme';

const Header: React.FunctionComponent = () => (
  <StyledHeader>
    <LogoWrapper>
      <Link href="https://edhub.ama-assn.org/">
        <Logo src={logo} alt="American Medical Association Ed Hub" />
      </Link>
    </LogoWrapper>
    <UtilityBar />
  </StyledHeader>
);

const StyledHeader = styled.header`
  position: sticky;
  top: 0;
  z-index: 1;
  height: ${({ theme }) => theme.sizes.desktopHeaderHeight};
  background-color: ${themeColor(Color.BackgroundDark)};

  @media (max-width: ${themeBreakpoint('medium')}) {
    height: ${({ theme }) => theme.sizes.mobileHeaderHeight};
    position: static;
  }
`;

const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 1.8rem ${themeSize('mobileGutter')} 2.1rem;
  margin: auto;
`;

const Link = styled.a`
  width: 15rem;
`;

const Logo = styled.img`
  width: 150px;
  height: 26px;
  object-fit: contain;
`;

export default Header;
