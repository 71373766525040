import React from 'react';
import { FlexTableHeaderRight, FlexTableHeading, FlexTableLeft } from '../../../components/FlexTable';
import Text from '../../../components/Text';
import styled from '../../../theme';
import { themeBreakpoint, themeColor } from '../../../theme/helpers';
import { Color } from '../../../theme/primaryTheme';

interface ISectionHheaderProps {
  title: string;
  description: string;
}

const DescriptionContainer = styled.div`
  margin-bottom: 5rem;

  a {
    text-decoration: underline;

    &:hover {
      color: ${themeColor(Color.Accent)};
    }
  }

  * + * {
    margin-top: 1em;
  }

  @media (max-width: ${themeBreakpoint('medium')}) {
    margin-bottom: 2rem;

    & > * {
      font-size: 1.6rem;
      line-height: 2.1rem;
    }
  }
`;

const FlexTableHeaderLeft = styled(Text)`
  display: none;

  @media (min-width: ${themeBreakpoint('large')}) {
    display: block;
  }
`;

const Title = styled(Text)`
  margin-bottom: 2rem;
`;

const SectionHeader = ({ title, description }: ISectionHheaderProps) => {
  return (
    <FlexTableHeading>
      <FlexTableLeft>
        <DescriptionContainer>
          <Title textStyle="small" color="secondary" tag="h5" bold={true}>
            {title}
          </Title>
          <Text textStyle="small" color="secondary">
            <div dangerouslySetInnerHTML={{ __html: description }} />
          </Text>
        </DescriptionContainer>
        <FlexTableHeaderLeft textStyle="small" color="secondary" tag="h5" bold={true}>
          Board Name
        </FlexTableHeaderLeft>
      </FlexTableLeft>
      <FlexTableHeaderRight>
        <Text textStyle="small" color="secondary" bold={true}>
          Send Credit Data?
        </Text>
      </FlexTableHeaderRight>
    </FlexTableHeading>
  );
};

export default SectionHeader;
