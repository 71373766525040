import React from 'react';
import { connect } from 'react-redux';
import {
  changeBulkLearnerActivityUploadPage,
  ChangeBulkLearnerActivityUploadPageActionCreator,
} from 'src/ducks/bulkLearnerActivityUploads';
import { ReduxStore } from '../../ducks';
import {
  bulkLearnerActivityUploadDataSelector,
  bulkLearnerActivityUploadErrorSelector,
  bulkLearnerActivityUploadPageSelector,
  bulkLearnerActivityUploadPageSizeSelector,
  bulkLearnerActivityUploadTotalSelector,
} from '../../selectors/bulkLearnerActivityUploads';
import styled from '../../theme';
import { BulkLearnerActivityUpload } from '../../types';
import { momentUtc } from '../../utilities/moment';
import Alert, { AlertType } from '../Alert';
import { FetchBulkLearnerActivityUploads } from '../FetchBulkLearnerActivities';
import Pagination from '../Pagination';
import Table, { ColumnConfig } from '../Table';
import Text from '../Text';
import BulkLearnerActivityUploadIngestionReportDownloadButton from './BulkLearnerActivityUploadIngestionReportDownloadButton';
import BulkLearnerActivityUploadOriginalFileDownloadButton from './BulkLearnerActivityUploadOriginalFileDownloadButton';

interface Props {
  uploads: BulkLearnerActivityUpload[];
  page: number;
  pageSize: number;
  total: number;
  error?: any;
  changeBulkLearnerActivityUploadPage?: ChangeBulkLearnerActivityUploadPageActionCreator;
}

const BulkLearnerActivityUploadsTable: React.FunctionComponent<Props> = ({
  uploads,
  error,
  page,
  pageSize,
  total,
  changeBulkLearnerActivityUploadPage,
}) => {
  const columns: ColumnConfig[] = [
    {
      header: 'Date/Time Submitted',
      id: 'createdAt',
      accessor: (upload: BulkLearnerActivityUpload) => <Text>{momentUtc(upload.createdAt).local().format('M/D/YYYY HH:mm')}</Text>,
    },
    {
      header: 'File Name',
      id: 'originalFileName',
      accessor: (upload: BulkLearnerActivityUpload) => `/v1/bulk-learner-activity-uploads/${upload.id}/original-file-data`,
      cellComponent: BulkLearnerActivityUploadOriginalFileDownloadButton,
    },
    {
      header: 'Uploaded By',
      id: 'createdByUserName',
      accessor: (upload: BulkLearnerActivityUpload) => <Text>{upload.createdByUserName}</Text>,
    },
    {
      header: 'Ingestion Report',
      id: 'ingestionReport',
      accessor: (upload: BulkLearnerActivityUpload) => `/v1/bulk-learner-activity-uploads/${upload.id}/ingestion-report-data`,
      cellComponent: BulkLearnerActivityUploadIngestionReportDownloadButton,
    },
  ];

  const totalPages = total && pageSize ? Math.ceil(total / pageSize) : 0;
  const handlePageChange = (newPage: number) => {
    if (newPage >= 1 && newPage <= totalPages && changeBulkLearnerActivityUploadPage) {
      changeBulkLearnerActivityUploadPage(newPage);
    }

    return page;
  };

  return (
    <>
      <FetchBulkLearnerActivityUploads>
        {error && (
          <Alert data-test-id="uploadTableError" type={AlertType.Error}>
            {error.message}
          </Alert>
        )}
        <StyledTable id="uploadTable" columns={columns} data={uploads} />
      </FetchBulkLearnerActivityUploads>
      {total && total > 1 ? <Pagination currentPage={page || 1} pageCount={totalPages} onPageChanged={handlePageChange} /> : null}
    </>
  );
};

const StyledTable = styled(Table)`
  margin-top: 2rem;
`;

const mapStateToProps = (state: ReduxStore) => ({
  uploads: bulkLearnerActivityUploadDataSelector(state),
  pageSize: bulkLearnerActivityUploadPageSizeSelector(state),
  page: bulkLearnerActivityUploadPageSelector(state),
  total: bulkLearnerActivityUploadTotalSelector(state),
  error: bulkLearnerActivityUploadErrorSelector(state),
});

const mapDispatchToProps = {
  changeBulkLearnerActivityUploadPage,
};

export default connect(mapStateToProps, mapDispatchToProps)(BulkLearnerActivityUploadsTable);
