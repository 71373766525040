import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Toggle from 'react-toggled';
import { CreditClaimStatus, creditClaimStatusOptions, StatusCheckbox } from '../../constants/ApplicationStatus';
import { ApplicationTypeFilter } from '../../constants/ApplicationTypeFilter';
import { setClaimStatusFilters } from '../../ducks/claims';
import { showModal } from '../../ducks/modal';
import DefaultPage from '../../layouts/DefaultPage';
import { PageSectionTitle } from '../../layouts/PageStructure';
import { claimLoadingSelector, claimsStatusFiltersSelector, claimsTotalSelector } from '../../selectors/claims';
import { isCreditProcessor } from '../../selectors/user';
import styled from '../../theme';
import primaryTheme, { Color } from '../../theme/primaryTheme';
import ApplicationTable from '../ApplicationTable';
import { DesktopPromptModal, desktopPromptModalId } from '../DesktopPromptModal';
import { Dropdown, DropdownButton, DropdownPanel } from '../Dropdown';
import CheckBox from '../Field/Controls/CheckBox';
import NewItemButton, { ItemType } from '../NewItemButton';
import Text, { TextStyle } from '../Text';

export enum ApplicationSort {
  Name = 'NAME',
  ApplicationType = 'APPLICATION_TYPE',
  ProcessingRate = 'PROCESSING_RATE',
  PaymentType = 'PAYMENT_TYPE',
  DateReceived = 'dateReceived',
  DueDate = 'DUE_DATE',
}

const ApplicationIndex: React.FunctionComponent = () => {
  const { innerWidth } = window;
  const dispatch = useDispatch();
  const isProcessor = useSelector(isCreditProcessor);
  const total = useSelector(claimsTotalSelector);
  const isLoading = useSelector(claimLoadingSelector);
  const statusFilters = useSelector(claimsStatusFiltersSelector);

  React.useEffect(() => {
    // Display modal at widths below the 'medium' breakpoint
    if (innerWidth < parseInt(primaryTheme.breakpoints.medium.slice(0, -2), 10)) {
      dispatch(showModal(desktopPromptModalId));
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const [typeFilter, setTypeFilter] = useState(ApplicationTypeFilter.All);

  const setTypeFilterToAll = () => {
    setTypeFilter(ApplicationTypeFilter.All);
  };

  const setTypeFilterToDirectCredit = () => {
    setTypeFilter(ApplicationTypeFilter.DirectCredit);
  };

  const setTypeFilterToInternationalCredit = () => {
    setTypeFilter(ApplicationTypeFilter.InternationalCredit);
  };

  const setTypeFilterToPRAAward = () => {
    setTypeFilter(ApplicationTypeFilter.PRA);
  };

  const handleStatusFilter = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const { name } = evt.currentTarget;
    const selectedCreditClaimStatus = name as CreditClaimStatus;
    const index = statusFilters.indexOf(selectedCreditClaimStatus);
    const newStatusFilters = [...statusFilters];

    if (index > -1) {
      newStatusFilters.splice(index, 1);
    } else {
      newStatusFilters.push(selectedCreditClaimStatus);
    }

    dispatch(setClaimStatusFilters(newStatusFilters));
  };

  return (
    <DefaultPage
      title={isProcessor ? 'Application Queue' : 'My Applications'}
      action={<NewItemButton itemType={ItemType.Application} />}
      tabs={[
        {
          id: 'all',
          label: 'All',
          selected: typeFilter === ApplicationTypeFilter.All,
          onClick: setTypeFilterToAll,
        },
        {
          id: 'direct',
          label: 'Direct Credit',
          selected: typeFilter === ApplicationTypeFilter.DirectCredit,
          onClick: setTypeFilterToDirectCredit,
        },
        {
          id: 'international',
          label: 'Credit Conversion',
          selected: typeFilter === ApplicationTypeFilter.InternationalCredit,
          onClick: setTypeFilterToInternationalCredit,
        },
        {
          id: 'pra',
          label: 'PRA',
          selected: typeFilter === ApplicationTypeFilter.PRA,
          onClick: setTypeFilterToPRAAward,
        },
      ]}
    >
      <PageSectionTitle>
        <DesktopPromptModal />
        <Text textStyle={TextStyle.SectionTitle} bold={true} tag="h3">
          All Applications {total !== undefined && `(${total})`}
        </Text>
        {isProcessor && (
          <Toggle>
            {({ on, toggle, setOff, getTogglerProps }) => (
              <Dropdown>
                <DropdownButton
                  isOpen={on}
                  onClick={toggle}
                  removePadding={true}
                  colorKey={Color.Label}
                  data-test-id="openStatusFilter"
                  {...getTogglerProps()}
                >
                  Filter By Status
                </DropdownButton>
                {on && (
                  <DropdownPanel arrow={true} onClickOutside={setOff} isOpen={on} width={250}>
                    <ApplicationStatusFilters data-test-id="statusFilterMenu">
                      {creditClaimStatusOptions.map((status: StatusCheckbox) => (
                        <CheckBox
                          key={status.value}
                          name={status.value}
                          title={status.label}
                          checked={statusFilters.includes(status.value)}
                          disabled={isLoading}
                          textProps={{
                            bold: false,
                            textStyle: TextStyle.Small,
                          }}
                          onChange={handleStatusFilter}
                        />
                      ))}
                    </ApplicationStatusFilters>
                  </DropdownPanel>
                )}
              </Dropdown>
            )}
          </Toggle>
        )}
      </PageSectionTitle>
      <ApplicationTable applicationTypeFilter={typeFilter} statusFilters={statusFilters} />
    </DefaultPage>
  );
};

const ApplicationStatusFilters = styled.div`
  padding: 2rem;

  > div + div {
    margin-top: 0.25em;
  }
`;

export default ApplicationIndex;
