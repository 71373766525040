import React from 'react';
import styled from '../../theme';
import CheckableInput from '../CheckableInput';
import { DEFAULT_PANEL_PADDING } from '../Panel';
import Text from '../Text';

interface PraAwardTypeRadioProps {
  field: any;
  id: string;
  label: string;
  locked: boolean;
}

const ItemLabel = styled.label`
  padding: ${DEFAULT_PANEL_PADDING};
  display: flex;
  align-items: start;
`;

const ItemDescription = styled.div``;

const CheckableInputPra: React.FunctionComponent<PraAwardTypeRadioProps> = ({ field, id, label, locked, children }) => (
  <ItemLabel htmlFor={id}>
    <CheckableInput {...field} id={id} value={id} checked={field.value === id} disabled={locked} type="radio" />
    <ItemDescription>
      <Text bold={true} tag="span">
        {label}
      </Text>
      {children}
    </ItemDescription>
  </ItemLabel>
);

export default CheckableInputPra;
