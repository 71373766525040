import React from 'react';
import { LinkStyle } from 'src/constants/LinkStyle';
import { ThemeColors } from 'src/theme/primaryTheme';
import { User } from 'src/types';
import { handleLogout } from 'src/utilities/authentication';
import styled from 'styled-components';
import ExternalLink from '../Link/ExternalLink';
import Text, { TextStyle } from '../Text';
const { REACT_APP_CONTACT_PREFERENCES_CENTER, REACT_APP_NEW_APPLICATION_LINK } = process.env;

const UserInfoTable: React.FunctionComponent<{ user: User }> = ({ user }) => {
  const handleContactPreferencesCenterClick = () => {
    const newTab = window.open();
    newTab.location.href = REACT_APP_CONTACT_PREFERENCES_CENTER;

    handleLogout(false);
    window.location.href = REACT_APP_NEW_APPLICATION_LINK;
  };

  return (
    <StyledTable title="user-info">
      <tbody>
        <tr>
          <td>
            <Text textStyle={TextStyle.SubTitle} bold={true} color={ThemeColors.primary}>
              First Name
            </Text>
          </td>
          <td>
            <Text textStyle={TextStyle.SubTitle} color={ThemeColors.primary}>
              {user.firstName}
            </Text>
          </td>
        </tr>
        <tr>
          <td>
            <Text textStyle={TextStyle.SubTitle} bold={true} color={ThemeColors.primary}>
              Last Name
            </Text>
          </td>
          <td>
            <Text textStyle={TextStyle.SubTitle} color={ThemeColors.primary}>
              {user.lastName}
            </Text>
          </td>
        </tr>
        <tr>
          <td>
            <Text textStyle={TextStyle.SubTitle} bold={true} color={ThemeColors.primary}>
              Email Address
            </Text>
          </td>
          <td>
            <Text textStyle={TextStyle.SubTitle} color={ThemeColors.primary}>
              {user.emails && user.emails[0]}
            </Text>
          </td>
          <td>
            <ExternalLink linkStyle={LinkStyle.ExternalLink} onClick={handleContactPreferencesCenterClick}>
              Update email address
            </ExternalLink>
          </td>
        </tr>
        <tr>
          <td>
            <Text textStyle={TextStyle.SubTitle} bold={true} color={ThemeColors.primary}>
              AMA Membership
            </Text>
          </td>
          <td>
            <Text data-test-id="membershipStatus" textStyle={TextStyle.SubTitle} color={ThemeColors.primary}>
              {user.amaMember ? 'AMA Member' : 'Non-member'}
            </Text>
          </td>
          {!user.amaMember && (
            <td>
              <ExternalLink linkStyle={LinkStyle.ExternalLink} href="https://www.ama-assn.org/amaone/membership">
                Join the AMA
              </ExternalLink>
            </td>
          )}
        </tr>
      </tbody>
    </StyledTable>
  );
};

const StyledTable = styled.table`
  width: 80rem;
  border-spacing: 0;
  border-collapse: collapse;
  margin-bottom: 4.8rem;

  & td {
    padding: 0.5rem 0;
  }
`;

export default UserInfoTable;
