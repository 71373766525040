import { FieldProps, Form, Formik, FormikHelpers, FormikProps, FormikValues } from 'formik';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { AbmsActivitySubType } from 'src/constants/AbmsActivitySubType';
import { LinkStyle } from 'src/constants/LinkStyle';
import { S3UploadStatus } from 'src/constants/S3UploadStatus';
import { pageContent, PageTypes } from 'src/content/pages';
import styled from 'styled-components';
import { ActivityStatus } from '../../constants/ActivityStatus';
import { ActivityType } from '../../constants/ActivityType';
import { CreditClaimStatus } from '../../constants/ApplicationStatus';
import { ApplicationTypeCode } from '../../constants/ApplicationTypeCode';
import { Credits } from '../../constants/Credits';
import { buildRoute, Routes } from '../../constants/Routes';
import { errorSupportMessage } from '../../content/errors';
import { updateClaim } from '../../ducks/claims';
import ApplicationPage from '../../layouts/ApplicationPage';
import { PageFooter } from '../../layouts/PageStructure';
import { abmsBoardsDataSelector } from '../../selectors/boards';
import { claimDataSelector, claimErrorSelector, claimIsCompletedSelector, claimIsEditableSelector } from '../../selectors/claims';
import { isCreditProcessor } from '../../selectors/user';
import { Color } from '../../theme/primaryTheme';
import { CreditActivity, CreditActivityAttachment, CreditClaim } from '../../types';
import {
  canRequestCreditsForTeachingActivity,
  getFollowUpActivities,
  getInvalidActivities,
  getValidActivities,
  transformCreditActivity,
} from '../../utilities/creditActivities';
import Alert, { AlertType } from '../Alert';
import { ApplicationStepLabel } from '../ApplicationProgressBar/ApplicationProgressBar';
import Button from '../Button';
import Debug from '../Debug';
import FetchBoards from '../FetchBoards/FetchBoards';
import FetchClaim from '../FetchClaim';
import Link from '../Link';
import Text from '../Text';
import DirectCreditActivities from './DirectCreditActivities';
import IccActivities from './IccActivities';
import { earnerSchema, processorSchema } from './validationSchema';

const name = 'creditActivities';

export const initialActivityValue = {
  activityTypeCode: '',
  board: '',
  subspeciality: '',
  certificationDate: null,
  doi: '',
  numberOfCredits: '',
  activityUnits: '',
  creditsRequested: '',
  activityStartDate: null,
  activityEndDate: null,
  firstAuthor: false,
  activityTitle: '',
  school: '',
  program: '',
  graduationDate: null,
  conferenceTitle: '',
  city: '',
  country: '',
  status: ActivityStatus.Pending,
  notes: '',
  presentationTitle: '',
  conferenceAccreditor: '',
  creditActivityAttachments: [],
  learnerActivityId: null,
  isContinuousCertificationAssessment: '',
  activitySubTypeCode: '',
};

const initialValues = {
  creditActivities: [initialActivityValue],
};

const ActivityDetails: React.FunctionComponent = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const claim = useSelector(claimDataSelector);
  const claimIsEditable = useSelector(claimIsEditableSelector);
  const claimIsCompleted = useSelector(claimIsCompletedSelector);
  const error = useSelector(claimErrorSelector);
  const abmsBoards = useSelector(abmsBoardsDataSelector);
  const isProcessor = useSelector(isCreditProcessor);

  const content = pageContent[PageTypes.AddActivities];
  const typeContent = content[claim.applicationTypeCode] || {};
  const { title = '', subtitle = '' } = typeContent;

  const handleTypeChange = (event: React.ChangeEvent<any>, formikProps: FieldProps) => {
    const {
      field: { name },
      form: { setFieldValue, setFieldTouched },
    } = formikProps;
    const baseName = name.split('.')[0];
    setFieldValue(baseName, initialActivityValue);

    // Un-touch everything
    Object.keys(initialActivityValue).forEach((key: string) => {
      setFieldTouched(`${baseName}${key}`, false);
    });
  };

  const setInitialClaimData = (creditActivities: CreditActivity[]): Partial<CreditClaim> => {
    const data: Partial<CreditClaim> = {};
    const followUpActivities = getFollowUpActivities(creditActivities);

    if (followUpActivities.length) {
      data.flagged = true;
      return data;
    }

    return data;
  };

  const handleSubmit = (values: Partial<CreditClaim>, { setSubmitting, setStatus }: FormikHelpers<FormikValues>) => {
    setStatus({ success: false, errors: undefined });

    const { creditActivities = [], status } = values;
    const data = setInitialClaimData(creditActivities);

    data.creditActivities = creditActivities
      .map(transformCreditActivity)
      .map((activity: CreditActivity & { creditsRequested: number | string | null }) => {
        // Only include successfully uploaded attachments
        activity.creditActivityAttachments = activity.creditActivityAttachments.filter(
          (attachment: CreditActivityAttachment) => attachment.status === S3UploadStatus.COMPLETE
        );

        /**
         * Credit amounts for an activity are decided as follows:
         * - ABMS Continuous Certification Assessment credits default to 0 unless they have been provided by a CP
         * - ABMS Focused Practice credits default to 0 unless they have been provided by a learner or CP
         * - Teaching in Live CME use credits requested by a learner or CP when the activity occurred before 1 Jan 2023
         * - All the other activity types (including Teaching in Live CME that occurred prior to 1 Jan 2023) use default credit amounts
         */
        if (
          activity.activityTypeCode === ActivityType.Abms &&
          activity.activitySubTypeCode &&
          [AbmsActivitySubType.CONTINUOUS_ASSESSMENT, AbmsActivitySubType.FOCUSED_PRACTICE].includes(
            activity.activitySubTypeCode as AbmsActivitySubType
          )
        ) {
          // Prefer pre-existing or newly entered numberOfCredits (in the case of Focused Practice activities or Continuous Assessment for Credit Processors)
          // or default to 0 credits
          activity.numberOfCredits = Number(activity.numberOfCredits) ?? 0;
        } else if (canRequestCreditsForTeachingActivity(activity) && activity.creditsRequested) {
          activity.numberOfCredits = Number(activity.creditsRequested) ?? 0;
        } else {
          const multiplier = Number(activity.activityUnits ? activity.activityUnits : 1);
          activity.numberOfCredits = Credits[activity.activityTypeCode] * multiplier;
        }

        if (isProcessor || [ActivityStatus.Valid, ActivityStatus.Invalid].includes(activity.status)) {
          return activity;
        }

        if (activity.creditsRequested) {
          delete activity.creditsRequested;
        }

        return {
          ...activity,
          status: ActivityStatus.Pending,
        };
      });

    // When switching from invalid to valid, we check to see if status should be reverted
    if (status === CreditClaimStatus.REJECTED && getValidActivities(data.creditActivities).length > 0) {
      data.status = CreditClaimStatus.SUBMITTED;
    }

    if (getFollowUpActivities(data.creditActivities).length > 0) {
      data.status = CreditClaimStatus.PENDING_CUSTOMER_ACTION;
    }

    if (getInvalidActivities(data.creditActivities).length === data.creditActivities.length) {
      data.status = CreditClaimStatus.REJECTED;
    }

    if (!isProcessor) {
      if (status === CreditClaimStatus.PENDING_CUSTOMER_ACTION) {
        data.status = CreditClaimStatus.UNSUBMITTED;
      }
    }

    dispatch(
      updateClaim(claim.id, data, {
        onSuccess: () => {
          setStatus({ success: true });
          setSubmitting(false);
          history.push(buildRoute(Routes.ApplicationShow, { id: claim.id }));
        },
        onError: () => {
          setSubmitting(false);
          setStatus({
            success: false,
            error: 'Could not submit form. Please try again later.',
          });
        },
      })
    );
  };

  if (claim.creditActivities && claim.creditActivities.length > 0) {
    claim.creditActivities = claim.creditActivities.map((activity: CreditActivity & { creditsRequested: any }) => {
      // Convert boolean to string to play nicely with Formik
      // TODO: Will remove once we delete this column from table. See https://www.pivotaltracker.com/story/show/184890428
      activity.isContinuousCertificationAssessment = activity.activitySubTypeCode
        ? activity.activitySubTypeCode === AbmsActivitySubType.CONTINUOUS_ASSESSMENT
          ? 'true'
          : 'false'
        : false;

      if (canRequestCreditsForTeachingActivity(activity)) {
        activity.creditsRequested = Number(activity.numberOfCredits);
      }
      return activity;
    });
  }

  const initialValuesWithClaim = {
    ...initialValues,
    ...claim,
  };

  // If our response has no activities, add one
  if (initialValuesWithClaim.creditActivities.length === 0) {
    // if we're looking at an ICC application make sure the type is set
    if (claim.applicationTypeCode === ApplicationTypeCode.InternationalCreditConversion) {
      initialActivityValue.activityTypeCode = ActivityType.ICC;
    }

    initialValuesWithClaim.creditActivities = [initialActivityValue];
  }

  const validationSchema = isProcessor ? processorSchema : earnerSchema;

  return (
    <FetchClaim>
      <ApplicationPage
        title={title}
        subtitle={subtitle}
        applicationTypeCode={claim.applicationTypeCode}
        applicationStep={ApplicationStepLabel.AddActivities}
      >
        <FetchBoards>
          <Formik
            initialStatus={{ success: false }}
            initialValues={initialValuesWithClaim}
            enableReinitialize={true}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ values, isSubmitting, errors, status = {} }: FormikProps<Partial<CreditClaim>>) => {
              const allAttachments = values.creditActivities
                .map((activity: CreditActivity) => activity.creditActivityAttachments)
                .reduce(
                  (allCreditActivityAttachments, currentActivitysAttachments) => [
                    ...allCreditActivityAttachments,
                    ...currentActivitysAttachments,
                  ],
                  []
                );
              const attachmentsAreUploading = allAttachments.some(
                (attachment: CreditActivityAttachment) => attachment.status === S3UploadStatus.PENDING
              );

              return (
                <>
                  {status.success && <Alert type={AlertType.Success}>Activities successfully saved.</Alert>}

                  {error && (
                    <Alert type={AlertType.Error}>
                      {error.message}
                      {'\n'}
                      {'\n'}
                      {errorSupportMessage}
                    </Alert>
                  )}
                  <ActivityDetailsForm data-test-id="activityDetailsForm">
                    {values.applicationTypeCode === ApplicationTypeCode.InternationalCreditConversion ? (
                      <IccActivities values={values} name={name} />
                    ) : (
                      <DirectCreditActivities values={values} name={name} onTypeChange={handleTypeChange} abmsBoards={abmsBoards} />
                    )}
                    <ActivityDetailsFooter>
                      {claimIsEditable || (isProcessor && !claimIsCompleted) ? (
                        <>
                          <Button type="submit" loading={status.uploading || isSubmitting || attachmentsAreUploading}>
                            {status.uploading ? 'Uploading ...' : 'Save and Continue'}
                          </Button>
                          {attachmentsAreUploading && (
                            <FormSubmissionNotice tag="p" color={Color.Error}>
                              * Please wait for your documents to finish uploading before proceeding.
                            </FormSubmissionNotice>
                          )}
                        </>
                      ) : (
                        <Link to={buildRoute(Routes.ApplicationShow, { id: claim.id })} linkStyle={LinkStyle.Button}>
                          Continue
                        </Link>
                      )}
                    </ActivityDetailsFooter>
                  </ActivityDetailsForm>
                  <Debug json={errors} />
                  <Debug json={values.creditActivities.map(({ board, subspeciality }) => ({ board, subspeciality }))} />
                </>
              );
            }}
          </Formik>
        </FetchBoards>
      </ApplicationPage>
    </FetchClaim>
  );
};

const ActivityDetailsForm = styled(Form)`
  max-width: 100rem;
`;

const ActivityDetailsFooter = styled(PageFooter)`
  align-items: center;
  justify-content: flex-start;
`;

const FormSubmissionNotice = styled(Text)`
  margin-left: 2rem;
`;

export default ActivityDetails;
