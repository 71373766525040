import React from 'react';
import { buildRoute, Routes } from '../../constants/Routes';
import { PageSection, PageSectionTitle } from '../../layouts/PageStructure';
import styled from '../../theme';
import { ExternalCreditEarner } from '../../types';
import { Columns, ColumnsHeader, ColumnsMain } from '../Columns';
import EditButton from '../EditButton';
import Text from '../Text';
import UserAdditionalSummary from '../UserAdditionalSummary';
import UserContactSummary from '../UserContactSummary';
import UserInfoSummary from '../UserInfoSummary';

const ColumnsHeaderStyled = styled(ColumnsHeader)`
  display: flex;
  justify-content: flex-end;
  padding-right: 0 !important;
  margin-top: -30px !important;
`;

interface Props {
  user: ExternalCreditEarner;
}

const UserProfile: React.FunctionComponent<Props> = ({ user }) => {
  return (
    <Columns>
      <ColumnsHeaderStyled />
      <ColumnsMain>
        <PageSection>
          <PageSectionTitle>
            <Text tag="h2" bold={true} textStyle="medium">
              Personal Information
            </Text>
            <EditButton to={buildRoute(Routes.UserEdit, { id: user.id })} replace={true} label="Edit Profile" />
          </PageSectionTitle>
          <UserInfoSummary user={user} />
        </PageSection>
        <PageSection>
          <PageSectionTitle>
            <Text tag="h2" bold={true} textStyle="medium">
              Contact Information
            </Text>
          </PageSectionTitle>
          <UserContactSummary user={user} />
        </PageSection>
        <PageSection>
          <PageSectionTitle>
            <Text tag="h2" bold={true} textStyle="medium">
              Additional Information
            </Text>
          </PageSectionTitle>
          <UserAdditionalSummary user={user} />
        </PageSection>
      </ColumnsMain>
    </Columns>
  );
};

export default UserProfile;
