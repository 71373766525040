import { Option } from '../components/Field/Controls/Select';
import { ApplicationTypeCode } from './ApplicationTypeCode';

export const ApplicationTypeCodeLabels = {
  [ApplicationTypeCode.DirectCredit]: 'Direct Credit',
  [ApplicationTypeCode.InternationalCreditConversion]: 'Credit Conversion',
  [ApplicationTypeCode.PRA]: 'AMA Physician’s Recognition Award',
};

export const applicationTypeCodeOptions: Option[] = Object.values(ApplicationTypeCode)
  .filter(Boolean)
  .map((value) => {
    return {
      value,
      label: ApplicationTypeCodeLabels[value],
    };
  });
