import React from 'react';
import { useDispatch } from 'react-redux';
import { Redirect } from 'react-router';
import { Routes } from '../../constants/Routes';
import { logout } from '../../ducks/authentication';
import { removeAccessToken } from '../../utilities/authentication';

const SignOut: React.FunctionComponent = () => {
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(logout());
    removeAccessToken();
  }, [dispatch]);

  return <Redirect to={Routes.Home} />;
};

export default SignOut;
