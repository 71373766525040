import qs from 'qs';
import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { Routes } from 'src/constants/Routes';
import { ApplicationTypeCode } from '../../constants/ApplicationTypeCode';
import { emptyCellCharacter } from '../../constants/General';
import { ReduxStore } from '../../ducks';
import { creditEarnerDataSelector } from '../../selectors/creditEarner';
import { prasErrorSelector, prasSelector } from '../../selectors/pras';
import { isCreditProcessor } from '../../selectors/user';
import styled from '../../theme';
import { ExternalCreditEarner, Pra } from '../../types';
import { momentIsBefore, momentUtc } from '../../utilities/moment';
import Alert, { AlertType } from '../Alert';
import Button from '../Button';
import StatusLabel from '../StatusLabel';
import Table, { ColumnConfig } from '../Table';
import Text from '../Text';
import NoTranscriptDataMessage from '../TranscriptLists/NoTranscriptDataMessage';
import PraCertificateDownloadButton from './PraCertificateDownloadButton';
import PraPrintCertificateDownloadButton from './PraPrintCertificateDownloadButton';

const RenewButton = styled(Button)`
  padding: 0.5em 1.25em;
  font-size: 1em;
`;

interface Props extends RouteComponentProps {
  isProcessor: boolean;
  error?: string;
  pras: Pra[];
  showPraHistory: boolean;
  currentEarner: ExternalCreditEarner;
}

const PraTable: React.FunctionComponent<Props> = ({
  pras = [],
  isProcessor,
  error = '',
  showPraHistory,
  currentEarner,
  history, // from withRouter
}) => {
  const isExpired = (pra: Pra) => momentIsBefore(pra.expirationDate, momentUtc());

  const visiblePras = showPraHistory ? pras : pras.filter((pra) => !isExpired(pra));
  if (!visiblePras.length) {
    return <NoTranscriptDataMessage dataType="pra" />;
  }

  const color = (pra: Pra) => (isExpired(pra) ? 'secondary' : undefined);

  const goToRenew = (pra: Pra) => () => {
    const queryData: { applicationTypeCode: ApplicationTypeCode; userId?: number } = {
      applicationTypeCode: ApplicationTypeCode.PRA,
    };
    if (isProcessor) {
      queryData.userId = currentEarner.id;
    }
    return (
      !isExpired(pra) &&
      history.push(
        {
          pathname: Routes.ApplicationNew,
          search: `?${qs.stringify(queryData)}`,
        },
        { claimUser: currentEarner }
      )
    );
  };

  const columns: ColumnConfig[] = [
    {
      header: '',
      id: '',
      accessor: (pra: Pra) => (
        <Text bold={true} color={color(pra)}>
          PRA Award
        </Text>
      ),
      align: 'center',
    },
    {
      header: 'Award Type',
      id: 'isCommendation',
      accessor: (pra: Pra) => <Text color={color(pra)}>{pra.isCommendation ? 'With Commendation' : 'Standard'}</Text>,
      width: '200px',
    },
    {
      header: 'Duration',
      id: 'duration',
      accessor: (pra: Pra) => <Text color={color(pra)}>{pra.duration} Year</Text>,
    },
    {
      header: 'New/Renew',
      id: 'isRenewal',
      accessor: (pra: Pra) => <Text color={color(pra)}>{pra.isRenewal ? 'Renewal' : 'New'}</Text>,
    },
    {
      header: 'Issue Date',
      id: 'effectiveDate',
      accessor: (pra: Pra) => (
        <Text color={color(pra)}>{pra.effectiveDate ? momentUtc(pra.effectiveDate).format('MM/DD/YY') : emptyCellCharacter}</Text>
      ),
    },
    {
      header: 'Exp. Date',
      id: 'expirationDate',
      accessor: (pra: Pra) => (
        <Text color={color(pra)}>{pra.expirationDate ? momentUtc(pra.expirationDate).format('MM/DD/YY') : emptyCellCharacter}</Text>
      ),
    },
    {
      header: '',
      id: 'status',
      accessor: (pra: Pra) =>
        isExpired(pra) ? <StatusLabel status="Expired">Expired</StatusLabel> : <RenewButton onClick={goToRenew(pra)}>Renew</RenewButton>,
    },
    {
      header: '',
      id: 'certificateUrl',
      align: 'center',
      accessor: ({ id }: Pra) => `/v1/pra/${id}/certificate.pdf`,
      cellComponent: PraCertificateDownloadButton,
      width: '42px',
    },
  ];

  let allColumns = columns;
  if (isProcessor) {
    allColumns = [
      ...columns,
      {
        header: '',
        id: 'printCertificateUrl',
        align: 'center',
        accessor: ({ id }: Pra) => `/v1/pra/${id}/certificate.pdf?print=true`,
        cellComponent: PraPrintCertificateDownloadButton,
        width: '42px',
      },
    ];
  }

  return (
    <>
      {error && (
        <Alert data-test-id="transcriptTableError" type={AlertType.Error}>
          {error}
        </Alert>
      )}
      <Table id="praTable" columns={allColumns} data={visiblePras} />
    </>
  );
};

const mapStateToProps = (state: ReduxStore) => ({
  pras: prasSelector(state),
  error: prasErrorSelector(state),
  isProcessor: isCreditProcessor(state),
  currentEarner: creditEarnerDataSelector(state),
});

export default withRouter(connect(mapStateToProps)(PraTable));
