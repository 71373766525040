import React from 'react';
import { connect } from 'react-redux';
import { ReduxStore } from '../../ducks';
import { isCreditProcessor } from '../../selectors/user';
import { ExternalCreditEarner } from '../../types';
import AddressSummary from '../AddressSummary';
import { FieldColumn, FieldRow } from '../FieldRow';
import SummaryValue from '../SummaryValue';

interface Props {
  user: ExternalCreditEarner;
  isProcessor?: boolean;
}

const UserContactSummary: React.FunctionComponent<Props> = ({ user, isProcessor = false }) => {
  const { emails = [], address1, address2, city, stateCode, zip } = user;

  const emailsString = emails.length ? emails : [''];

  return (
    <>
      {emailsString.map((email: string, index: number) => (
        <FieldRow key={`email-${index}`}>
          <FieldColumn>
            <SummaryValue label={`Email Address ${!!index ? ` (${index + 1})` : ''}`} value={email} />
          </FieldColumn>
        </FieldRow>
      ))}
      {isProcessor && (
        <AddressSummary
          label="Mailing Address"
          address={{
            address1,
            address2,
            city,
            stateCode,
            postalCode: zip,
          }}
        />
      )}
    </>
  );
};

const mapStateToProps = (store: ReduxStore) => {
  return {
    isProcessor: isCreditProcessor(store),
  };
};

export default connect(mapStateToProps)(UserContactSummary);
