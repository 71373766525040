import { FormikValues } from 'formik';
import moment from 'moment';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxStore } from 'src/ducks';
import * as Yup from 'yup';
import Field from '../../../components/Field';
import { ControlMeta, FieldWrap, LockedField } from '../../../components/Field/styles';
import LockIcon from '../../../components/Icon/LockIcon';
import Label from '../../../components/Label';
import LoadingSpinner from '../../../components/LoadingSpinner';
import { FormId } from '../../../constants/FormId';
import { formError, formSuccess, submitForm } from '../../../ducks/form';
import { setInitialValues } from '../../../ducks/initialValues';
import { fetchUserSuccess } from '../../../ducks/user';
import { apiRequest } from '../../../utilities/api';
import { removeAccessToken } from '../../../utilities/authentication';
import { getUserInitialValues } from '../../../utilities/initialValues';
import ConfirmationForm from '../ConfirmationForm';
import { FormWrap } from '../ConfirmationForm/styles';
import fields from './fields';
import ModalBody from './ModalBody';

const MODAL_ID = 'userInfoForm';

const UserSchema = () =>
  Yup.object().shape({
    birthMonth: Yup.string().label('Month of birth').required(),
    birthDayOfMonth: Yup.string()
      .label('Day of birth')
      .when('birthMonth', {
        is: (val) => val,
        then: Yup.string()
          .matches(/^[0-9]*$/, {
            message: 'Day of Birth must be a valid day',
            excludeEmptyString: true,
          })
          // eslint-disable-next-line no-template-curly-in-string
          .test('is-valid-day', '${path} is not a valid day', function (value: any): boolean {
            // everything in the backend stores years as this because it was a leap year
            const year = 1904;
            const { parent } = this;
            const monthIndex = parseInt(parent.birthMonth, 10) - 1;

            return moment([year, monthIndex, value]).isValid();
          }),
        otherwise: Yup.string().nullable().default(''),
      })
      .required(),
  });

const UserInfoForm: React.FunctionComponent = () => {
  const dispatch = useDispatch();
  const initialValues = useSelector((state: ReduxStore) => state.initialValues.userInfo);
  const isLoading = useSelector((state: ReduxStore) => state.user.isLoading);
  const user = useSelector((state: ReduxStore) => state.user.data);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  const handleSubmit = async (values: FormikValues) => {
    dispatch(submitForm());

    try {
      const normalizedValues = {};
      Object.keys(values).forEach((key: string) => {
        const value = values[key];
        if (fields[key]) {
          normalizedValues[key] = fields[key].normalizeValue(value);
        }
      });
      const { data } = await apiRequest.post('/v1/user/creditearner', normalizedValues);

      dispatch(fetchUserSuccess(data));
      dispatch(setInitialValues(FormId.UserInfo, getUserInitialValues(data)));
      dispatch(formSuccess());
    } catch (error) {
      // If we get a 401 back from the API, log the user out
      if (error.response && error.response.status === 401) {
        removeAccessToken();
      } else {
        dispatch(formError(error));
      }
    }
  };

  return (
    <ConfirmationForm
      initialValues={initialValues}
      validationSchema={UserSchema()}
      onConfirm={handleSubmit}
      modalTitle="Please review the changes to your User Information"
      modalId={MODAL_ID}
      formId={FormId.UserInfo}
      modalBodyComponent={ModalBody}
      showContact={true}
    >
      <FormWrap>
        <FieldWrap inline={true}>
          <Label>First name</Label>
          <LockedField data-test-id="firstName">
            {user.firstName}
            <ControlMeta>
              <LockIcon size={14} />
            </ControlMeta>
          </LockedField>
        </FieldWrap>

        <FieldWrap inline={true}>
          <Label>Last name</Label>
          <LockedField data-test-id="lastName">
            {user.lastName}
            <ControlMeta>
              <LockIcon size={14} />
            </ControlMeta>
          </LockedField>
        </FieldWrap>

        {Object.keys(fields).map((name, index) => {
          const fieldConfig = fields[name];
          const { label, ...rest } = fieldConfig;

          return <Field key={`${name}_${index}`} name={name} inline={true} label={label} {...rest} />;
        })}
      </FormWrap>
    </ConfirmationForm>
  );
};

export default UserInfoForm;
