import { createSelector } from 'reselect';
import { ReduxStore } from '../ducks';

/**
 * ====
 * AUTH
 * ====
 */
export const auth = (state: ReduxStore) => state.auth;
export const isAuthenticated = createSelector(auth, (data) => data.isAuthenticated);
export const authTokenSelector = createSelector(auth, (data) => data.token);
