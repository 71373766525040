import _ from 'lodash';
import React, { ChangeEventHandler } from 'react';
import styled, { css } from 'styled-components';
import { themeColor } from '../../theme/helpers';
import { Color } from '../../theme/primaryTheme';
import Text, { TextProps } from '../Text';

interface Props {
  id?: string;
  checked: boolean;
  className?: string;
  name: string;
  title?: string;
  description?: string | React.ReactNode;
  type: 'radio' | 'checkbox';
  onChange: ChangeEventHandler<HTMLInputElement>;
  value?: string;
  textProps?: TextProps;
  disabled?: boolean;
  hasError?: boolean;
}

export const CheckableInputContainer = styled.div``;

const checkboxStyles = css<Props>`
  border-radius: 5px;

  :checked::before {
    align-items: center;
    background-color: ${({ disabled }) => (disabled ? themeColor(Color.Secondary) : themeColor(Color.BackgroundDark))};
    color: ${themeColor(Color.Reverse)};
    content: '✓';
    cursor: pointer;
    display: flex;
    font-weight: bold;
    font-size: 1.5em;
    height: 100%;
    justify-content: center;
    position: absolute;
    text-align: center;
    vertical-align: center;
    width: 100%;
  }
`;

export const checkableInputSize = 18;
export const checkableInputMarginRight = 10;
export const radioFillSize = 10;

const radioStyles = css`
  border-radius: 50%;

  &:before {
    content: '';
    display: block;
    width: ${radioFillSize}px;
    height: ${radioFillSize}px;
    top: 3px;
    left: 3px;
    border-radius: 50%;
    background-color: ${themeColor(Color.BackgroundDark)};
    position: absolute;
    transform: scale(0);
    transition: all 0.125s cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  &:checked::before {
    transform: scale(1);
  }
`;

const StyledCheckableInput = styled.input<Props>`
  appearance: none;
  border: 1px solid ${({ hasError }) => (hasError ? themeColor(Color.Error) : themeColor(Color.Border))};
  background-color: ${themeColor(Color.Reverse)};
  color: ${themeColor(Color.BackgroundDark)};
  cursor: pointer;
  display: inline-block;
  height: ${checkableInputSize}px;
  width: ${checkableInputSize}px;
  margin: 0 ${checkableInputMarginRight}px 0 0;
  outline: none;
  padding: 0;
  position: relative;
  top: 3px;

  :checked {
    border-color: ${themeColor(Color.BackgroundDark)};
  }

  ${(props) => (props.type === 'radio' ? radioStyles : checkboxStyles)};
`;
const StyleCheckableInputLabel = styled.label<{
  role: string;
  disabled: boolean;
}>`
  position: relative;
  top: 1px;
  cursor: pointer;
  color: ${themeColor(Color.Primary)};
  ${(props) =>
    props.disabled
      ? css`
          opacity: 0.5;
        `
      : ''}
`;

const CheckableInput: React.FunctionComponent<Props> = ({
  id = '',
  checked,
  className = '',
  name,
  onChange,
  title = '',
  description,
  type,
  value,
  textProps,
  disabled = false,
  hasError = false,
}) => {
  const inputId = id || _.uniqueId();
  return (
    <CheckableInputContainer className={className}>
      <StyleCheckableInputLabel htmlFor={inputId} role={type} aria-checked={checked} tabIndex={checked ? 0 : -1} disabled={disabled}>
        <StyledCheckableInput
          id={inputId}
          name={name}
          type={type}
          checked={checked}
          onChange={onChange}
          hasError={hasError}
          data-test-id={value}
          data-testid={`${name}${disabled ? '-locked' : ''}`}
          value={value}
          disabled={disabled}
        />
        <Text color={hasError ? 'error' : 'label'} tag="span" {...textProps}>
          {description || title}
        </Text>
      </StyleCheckableInputLabel>
    </CheckableInputContainer>
  );
};

export default CheckableInput;
