import { ArrayHelpers, Field as FormikField, FieldProps, FormikHelpers, getIn } from 'formik';
import React from 'react';
import { useDispatch } from 'react-redux';
import { emptyCellCharacter } from 'src/constants/General';
import { removeCreditActivityAttachment } from 'src/ducks/claims';
import { momentUtc } from 'src/utilities/moment';
import { sortedActivities } from '.';
import { ActivityStatusLabel } from '../../constants/ActivityStatus';
import styled from '../../theme';
import { themeColor } from '../../theme/helpers';
import { Color, ThemeColors } from '../../theme/primaryTheme';
import { CreditActivity, CreditActivityAttachment } from '../../types';
import { calculateCredits } from '../../utilities/creditActivities';
import Button from '../Button';
import { Grid, GridItem } from '../Grid';
import Icon from '../Icon';
import { DEFAULT_PANEL_PADDING } from '../Panel';
import ProofDocumentItem from '../ProofDocumentUpload/ProofDocumentItem';
import SortArrows from '../SortArrows';
import Text, { TextStyle } from '../Text';

type Category1ActivityTableProperty =
  | 'provider'
  | 'activityTitle'
  | 'activityEndDate'
  | 'creditActivityAttachments'
  | 'status'
  | 'numberOfCredits';

const category1ColumnLabelsByProperty: { [key in Category1ActivityTableProperty]: string } = {
  provider: 'Provider',
  activityTitle: 'Activity Title',
  activityEndDate: 'Activity Date',
  creditActivityAttachments: 'Proof Document',
  status: 'Status',
  numberOfCredits: 'Credits Earned',
};
const category1GridTemplateColumns = '3fr 2fr 130px 80px 126px 60px 20px';
const category1GridItemsFlexValues = ['19%', '14%', '130px', '80px', '126px', '60px', '20px'];

interface ActivityRowProps {
  activity: CreditActivity;
  index: number;
  activitiesAreEditable: boolean;
  setFieldValue: FormikHelpers<any>['setFieldValue'];
  removeItem: ArrayHelpers['remove'];
}

const ActivityRow: React.FunctionComponent<ActivityRowProps> = ({ activity, index, activitiesAreEditable, removeItem, setFieldValue }) => {
  const dispatch = useDispatch();

  const removeActivity = () => {
    removeItem(index);
  };

  return (
    <StyledActivityRow className="activity-row">
      <Grid key={activity.id} gridTemplateColumns={category1GridTemplateColumns} ieGridItemsFlexValues={category1GridItemsFlexValues}>
        {Object.keys(category1ColumnLabelsByProperty).map((property) => {
          const inputName = `category1CreditActivities[${index}].${property}`;

          switch (property) {
            case 'activityEndDate':
              const date = momentUtc(activity[property]).format('MM/DD/YY');
              return <GridItem key={inputName}>{date}</GridItem>;

            case 'status':
              return <GridItem key={inputName}>{ActivityStatusLabel[activity[property]]}</GridItem>;

            case 'creditActivityAttachments':
              const attachment = activity.creditActivityAttachments[0];

              return (
                <GridItem key={inputName}>
                  {attachment ? (
                    <FormikField name={property}>
                      {({ form }: FieldProps) => {
                        const attachments: CreditActivityAttachment[] = getIn(form.values, inputName);

                        const handleAttachmentRemoval = () => {
                          dispatch(
                            removeCreditActivityAttachment({
                              attachment,
                              onSuccess: () => {
                                setFieldValue(
                                  inputName,
                                  attachments.filter((a) => a && a.id !== attachment.id)
                                );
                              },
                            })
                          );
                        };

                        return (
                          <ProofDocumentItem
                            signedUrlForDownload={attachment.signedUrlForDownload}
                            fileName={attachment.fileName}
                            status={attachment.status}
                            displayIcon={false}
                            truncateLink={true}
                            onRemove={activitiesAreEditable ? handleAttachmentRemoval : undefined}
                          />
                        );
                      }}
                    </FormikField>
                  ) : (
                    emptyCellCharacter
                  )}
                </GridItem>
              );

            default:
              return <GridItem key={inputName}>{activity[property]}</GridItem>;
          }
        })}
        {activitiesAreEditable && (
          <GridItem>
            <Button buttonStyle="icon-toggle" onClick={removeActivity} tabIndex={-1}>
              <Icon name="minusCircle" viewBox="0 0 12 12" fill={ThemeColors.secondary} width={20} height={20} />
            </Button>
          </GridItem>
        )}
      </Grid>
    </StyledActivityRow>
  );
};

const StyledActivityRow = styled.div`
  padding: 12px 16px 11px 20px;
`;

interface ActivityTableProps {
  activities: CreditActivity[];
  activitiesAreEditable: boolean;
  setFieldValue: FormikHelpers<any>['setFieldValue'];
  removeItem: ArrayHelpers['remove'];
}

const PraCategory1ActivityTable: React.FunctionComponent<ActivityTableProps> = ({
  activities,
  activitiesAreEditable,
  setFieldValue,
  removeItem,
}) => {
  const handleDateArrowClick = (chronological: boolean) => () =>
    setFieldValue('transcriptCreditActivities', sortedActivities(activities, chronological));

  return (
    <div data-testid="praCategory1Table">
      <Header>
        <Grid gridTemplateColumns={category1GridTemplateColumns} ieGridItemsFlexValues={category1GridItemsFlexValues}>
          {Object.entries(category1ColumnLabelsByProperty).map(([property, label]) => {
            if (property === 'activityEndDate') {
              return (
                <FlexGridItem key={property}>
                  <Text bold={true} textStyle={TextStyle.Small}>
                    {label}
                  </Text>
                  <SortArrows onUpArrowClick={handleDateArrowClick(false)} onDownArrowClick={handleDateArrowClick(true)} />
                </FlexGridItem>
              );
            }

            return (
              <GridItem key={property}>
                <Text bold={true} textStyle={TextStyle.Small}>
                  {label}
                </Text>
              </GridItem>
            );
          })}
          <GridItem />
        </Grid>
      </Header>
      <Body>
        {activities.map((activity, index: number) => (
          <ActivityRow
            key={index}
            index={index}
            activity={activity}
            removeItem={removeItem}
            activitiesAreEditable={activitiesAreEditable}
            setFieldValue={setFieldValue}
          />
        ))}
      </Body>
      <Footer>
        <CreditTotal bold={true} color={Color.Secondary}>
          Total{' '}
          <Text tag="em" bold={true}>
            AMA PRA Category 1 Credits&trade;
          </Text>
          : {calculateCredits(activities)}
        </CreditTotal>
      </Footer>
    </div>
  );
};

const Header = styled.div`
  padding: ${DEFAULT_PANEL_PADDING};
  padding-bottom: 8px;
`;

const FlexGridItem = styled(GridItem)`
  display: flex;
  align-items: center;
`;

const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: ${DEFAULT_PANEL_PADDING};
`;

const Body = styled.div`
  border-top: 1px solid ${themeColor(Color.Border)};
  border-bottom: 1px solid ${themeColor(Color.Border)};

  ${StyledActivityRow}:nth-child(even) {
    background-color: #f6f8fa;
  }
`;

const CreditTotal = styled(Text)`
  margin-right: 3rem;
`;

export default PraCategory1ActivityTable;
