import { Form, Formik, FormikHelpers, getIn } from 'formik';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { ActivityType } from 'src/constants/ActivityType';
import { S3UploadStatus } from 'src/constants/S3UploadStatus';
import { errorSupportMessage } from 'src/content/errors';
import { ActivityStatus } from '../../constants/ActivityStatus';
import { CreditClaimStatus } from '../../constants/ApplicationStatus';
import { LinkStyle } from '../../constants/LinkStyle';
import { PraClaimMethod } from '../../constants/PraClaimMethod';
import { buildRoute, Routes } from '../../constants/Routes';
import { OnError, updateClaim } from '../../ducks/claims';
import ApplicationPage from '../../layouts/ApplicationPage';
import { PageFooter } from '../../layouts/PageStructure';
import { abmsBoardsDataSelector } from '../../selectors/boards';
import {
  category1ActivitiesSelector,
  category2ActivitiesSelector,
  claimDataSelector,
  claimIsCompletedSelector,
} from '../../selectors/claims';
import { transcriptDataSelector } from '../../selectors/transcripts';
import { isCreditProcessor } from '../../selectors/user';
import styled from '../../theme';
import { Color } from '../../theme/primaryTheme';
import { ClaimFormValues, CreditActivity, CreditActivityAttachment, CreditClaim } from '../../types';
import { generateActivitiesFrom } from '../../utilities/creditActivities';
import { momentIsBefore } from '../../utilities/moment';
import Alert, { AlertType } from '../Alert';
import { ApplicationStepLabel } from '../ApplicationProgressBar/ApplicationProgressBar';
import Button from '../Button';
import Debug from '../Debug';
import FetchBoards from '../FetchBoards/FetchBoards';
import FetchClaim from '../FetchClaim';
import { FetchTranscript } from '../FetchTranscript';
import Link from '../Link';
import ExternalLink from '../Link/ExternalLink';
import { StickyContainer } from '../StickyContainer';
import Text from '../Text';
import ABMSActivitiesSection from './ABMSActivitySection';
import AdditionalActivitiesSection from './AdditionalActivitiesSection';
import { category2DefaultValues } from './category2Columns';
import {
  calculateCreditRequirementsForAwardType,
  calculateTotalCategory1ActivityCredits,
  calculateTotalCategory2ActivityCredits,
  calculateTotalOverallCredits,
  calculateTotalTranscriptActivityCredits,
} from './creditCalculations';
import PraCreditSummary from './PraCreditSummary';
import PraFinalizeApplicationSection from './PraFinalizeApplicationSection';
import ReciprocityActivitySection from './ReciprocityActivitySection';
import ResidencyActivitySection from './ResidencyActivitySection';
import TranscriptActivitiesSection from './TranscriptActivitiesSection';
import { earnerSchema, processorSchema } from './validationSchema';

export interface CreditRequirements {
  requiredOverallCredits: number;
  requiredCategory1Credits: number;
}

const baseCreditActivity = {
  activityTypeCode: ActivityType.SelfReported,
  numberOfCredits: 1,
  status: ActivityStatus.Valid,
} as CreditActivity;

export const sortedActivities = (activities: CreditActivity[] = [], chronological: boolean) => {
  return activities.sort((a: CreditActivity, b: CreditActivity) => {
    // sort activities without dates to the bottom
    if (!a.activityEndDate || !b.activityEndDate) {
      return 1;
    }

    if (a.activityEndDate === b.activityEndDate || chronological === undefined) {
      return 0;
    }

    const chronologicalOrder = momentIsBefore(a.activityEndDate, b.activityEndDate) ? -1 : 1;
    return chronological ? chronologicalOrder : -chronologicalOrder;
  });
};

const claimActivitiesAvailable = (claim: CreditClaim): boolean => {
  return claim && claim.creditActivities !== undefined;
};

const PraActivityDetails: React.FunctionComponent = () => {
  const history = useHistory();

  const claim = useSelector(claimDataSelector);
  const claimIsCompleted = useSelector(claimIsCompletedSelector);
  const isProcessor = useSelector(isCreditProcessor);
  const transcript = useSelector(transcriptDataSelector);
  const category1CreditActivities = useSelector(category1ActivitiesSelector);
  const category2CreditActivities = useSelector(category2ActivitiesSelector);
  const abmsBoards = useSelector(abmsBoardsDataSelector);

  const dispatch = useDispatch();

  const [error, setError] = React.useState('');
  const [claimSaved, setClaimSaved] = React.useState(false);

  const activitiesAreEditable = isProcessor
    ? !claimIsCompleted
    : [CreditClaimStatus.PENDING_CUSTOMER_ACTION, CreditClaimStatus.UNSUBMITTED].includes(claim.status);

  const transcriptCreditActivities = activitiesAreEditable
    ? generateActivitiesFrom(transcript, claim.creditActivities)
    : (claim.creditActivities ?? []).filter((activity) => !!activity.learnerActivityId).map((activity) => ({ ...activity, checked: true }));

  const initialValues: ClaimFormValues = {
    status: claim && claim.status,
    transcriptCreditActivities: sortedActivities(transcriptCreditActivities, false),
    category1CreditActivities,
    category2CreditActivities,
    abmsActivity: undefined,
    residencyActivity: undefined,
    reciprocityActivity: undefined,
    praCertificateEffectiveDate: claim.praCertificateEffectiveDate,
    praCertificateExpirationDate: claim.praCertificateExpirationDate,
    praCertificateClaimMethod: claim.praCertificateClaimMethod,
    praCertificateDuration: `${claim.praCertificateDuration}`,
    additionalPraCat1Credits: claim.additionalPraCat1Credits ?? 0,
    creditActivityAttachments: (claim.creditActivityAttachments ?? []).filter((attachment) => !attachment.creditActivityId),
  };

  switch (claim.praCertificateClaimMethod) {
    case PraClaimMethod.PreviousCME:
      if (initialValues.category2CreditActivities && initialValues.category2CreditActivities.length === 0) {
        initialValues.category2CreditActivities = new Array(3).fill(category2DefaultValues);
      }
      break;

    case PraClaimMethod.ABMS:
      if (claimActivitiesAvailable(claim) && !initialValues.abmsActivity) {
        initialValues.abmsActivity =
          claim.creditActivities.length > 0
            ? {
                ...claim.creditActivities[0],
              }
            : {
                ...baseCreditActivity,
                board: '',
                subspeciality: '',
                certificationDate: '',
                creditActivityAttachments: [],
              };
      }
      break;

    case PraClaimMethod.Residency:
      if (claimActivitiesAvailable(claim) && !initialValues.residencyActivity) {
        initialValues.residencyActivity =
          claim.creditActivities.length > 0
            ? {
                ...claim.creditActivities[0],
              }
            : {
                ...baseCreditActivity,
                activityUnits: '1',
                program: '',
                activityStartDate: '',
                activityEndDate: '',
                creditActivityAttachments: [],
              };
      }
      break;

    case PraClaimMethod.Reciprocity:
      if (claimActivitiesAvailable(claim) && !initialValues.reciprocityActivity) {
        initialValues.reciprocityActivity =
          claim.creditActivities.length > 0
            ? {
                ...claim.creditActivities[0],
              }
            : {
                ...baseCreditActivity,
                provider: '',
                activityEndDate: '',
                creditActivityAttachments: [],
              };
      }
      break;
  }

  const creditRequirements = calculateCreditRequirementsForAwardType(claim);

  const determineIfAttachmentsAreUploading = (praClaimMethod: PraClaimMethod, values: ClaimFormValues): boolean => {
    /**
     * In form values, creditActivityAttachments are a top-level property for Previous CME PRA claims whereas
     * they are nested one-level deeper in the case of nested one level deeper
     * for ABMS, Residency, and Reciprocity
     */
    switch (praClaimMethod) {
      case PraClaimMethod.PreviousCME:
        return getIn(values, 'creditActivityAttachments', []).some(
          (attachment: CreditActivityAttachment) => attachment.status === S3UploadStatus.PENDING
        );

      case PraClaimMethod.ABMS:
        return getIn(values, 'abmsActivity.creditActivityAttachments', []).some(
          (attachment: CreditActivityAttachment) => attachment.status === S3UploadStatus.PENDING
        );

      case PraClaimMethod.Residency:
        return getIn(values, 'residencyActivity.creditActivityAttachments', []).some(
          (attachment: CreditActivityAttachment) => attachment.status === S3UploadStatus.PENDING
        );

      case PraClaimMethod.Reciprocity:
        return getIn(values, 'reciprocityActivity.creditActivityAttachments', []).some(
          (attachment: CreditActivityAttachment) => attachment.status === S3UploadStatus.PENDING
        );

      default:
        return false;
    }
  };

  const handleSubmit = (values: ClaimFormValues, { setSubmitting }: FormikHelpers<ClaimFormValues>) => {
    const {
      transcriptCreditActivities,
      category1CreditActivities,
      category2CreditActivities,
      additionalPraCat1Credits,
      residencyActivity,
      reciprocityActivity,
      abmsActivity,
      praCertificateEffectiveDate,
      praCertificateExpirationDate,
      praCertificateDuration,
    } = values;

    const handleError: OnError = (err) => {
      setError(err.message);
      setSubmitting(false);
    };

    const completedTranscriptCreditActivitiesData =
      transcriptCreditActivities && claim.praCertificateClaimMethod === PraClaimMethod.PreviousCME
        ? transcriptCreditActivities.filter((activity) => activity.checked)
        : [];

    // Filters out any completely "empty" Category 2 credit activities
    const scrubbedCategory2CreditActivities =
      category2CreditActivities && claim.praCertificateClaimMethod === PraClaimMethod.PreviousCME
        ? category2CreditActivities.filter(
            (activity) =>
              !!activity.activityTitle || !!activity.activityDescription || !!activity.activityEndDate || !!activity.numberOfCredits
          )
        : [];

    const creditActivities = [
      ...completedTranscriptCreditActivitiesData,
      ...category1CreditActivities,
      ...scrubbedCategory2CreditActivities.map((activity) => {
        return { ...activity, status: ActivityStatus.Valid };
      }),
      ...(reciprocityActivity ? [reciprocityActivity] : []),
      ...(residencyActivity ? [residencyActivity] : []),
      ...(abmsActivity ? [abmsActivity] : []),
    ].map((activity: CreditActivity) => {
      // Remove attachments that failed to upload
      activity.creditActivityAttachments = activity.creditActivityAttachments?.filter(
        (attachment: CreditActivityAttachment) => attachment.status === S3UploadStatus.COMPLETE
      );
      return activity;
    });

    const status = !isProcessor && claim.status === CreditClaimStatus.PENDING_CUSTOMER_ACTION ? CreditClaimStatus.SUBMITTED : values.status;

    const handleSuccess = () => {
      setSubmitting(false);

      const category1CreditRequirementsAreMet =
        calculateTotalCategory1ActivityCredits(values) >= creditRequirements?.requiredCategory1Credits;
      const overallCreditRequirementsAreMet = calculateTotalOverallCredits(values) >= creditRequirements?.requiredOverallCredits;

      if (!creditRequirements || (category1CreditRequirementsAreMet && overallCreditRequirementsAreMet)) {
        history.push(buildRoute(Routes.ApplicationShow, { id: claim.id }));
      } else {
        setClaimSaved(true);
      }
    };

    dispatch(
      updateClaim(
        claim.id,
        {
          status,
          creditActivities,
          additionalPraCat1Credits: Number(additionalPraCat1Credits),
          praCertificateEffectiveDate,
          praCertificateExpirationDate,
          praCertificateDuration: Number(praCertificateDuration),
        },
        {
          onSuccess: handleSuccess,
          onError: handleError,
        }
      )
    );
  };

  const validationSchema = isProcessor ? processorSchema : earnerSchema;

  const renderSubtitle = () =>
    claim.praCertificateClaimMethod === PraClaimMethod.Reciprocity ? (
      <Text>
        Please ensure you have reviewed all requirements for the award type and include all necessary proof documents. For any questions
        regarding how to apply for the AMA PRA, please contact 800-262-3211 or{' '}
        <ExternalLink href="mailto:mosupport@ama-assn.org">MOSupport@ama-assn.org</ExternalLink>.
      </Text>
    ) : (
      <Text>Enter the activity details to claim your award.</Text>
    );

  return (
    <FetchClaim>
      <ApplicationPage
        title="Add Activity Details"
        subtitle={renderSubtitle}
        applicationTypeCode={claim.applicationTypeCode}
        applicationStep={ApplicationStepLabel.AddActivities}
      >
        <FetchTranscript
          userId={claim.creditEarner && claim.creditEarner.id}
          page={1}
          pageSize={400}
          excludeAwardedLearnerActivities={true}
          praCertificateDuration={claim.praCertificateDuration}
        >
          <FetchBoards>
            {claimSaved && <Alert type={AlertType.Success}>Application saved successfully</Alert>}
            <Formik
              onSubmit={handleSubmit}
              initialValues={initialValues}
              validationSchema={validationSchema}
              validateOnChange={true}
              validateOnBlur={true}
              enableReinitialize={true}
            >
              {({ values, isSubmitting, errors, submitCount, setFieldValue }) => {
                const category1CreditRequirementsAreMet =
                  calculateTotalCategory1ActivityCredits(values) >= creditRequirements?.requiredCategory1Credits;
                const overallCreditRequirementsAreMet = calculateTotalOverallCredits(values) >= creditRequirements?.requiredOverallCredits;

                const attachmentsAreUploading = determineIfAttachmentsAreUploading(claim.praCertificateClaimMethod, values);

                return (
                  <>
                    {claim.praCertificateClaimMethod === PraClaimMethod.PreviousCME && (
                      <StyledStickyContainer>
                        <PraCreditSummary
                          creditRequirements={creditRequirements}
                          totalCategory1ActivityCredits={calculateTotalCategory1ActivityCredits(values)}
                          totalCategory2ActivityCredits={calculateTotalCategory2ActivityCredits(values)}
                        />
                      </StyledStickyContainer>
                    )}
                    <Form data-test-id="praActivityDetailsForm">
                      {error && (
                        <Alert data-test-id="praActivityDetailsError" type={AlertType.Error}>
                          {error}
                          {'\n'}
                          {'\n'}
                          {errorSupportMessage}
                        </Alert>
                      )}
                      {claim.praCertificateClaimMethod === PraClaimMethod.PreviousCME && (
                        <>
                          <TranscriptActivitiesSection
                            activitiesAreEditable={activitiesAreEditable}
                            activities={values.transcriptCreditActivities}
                            totalTranscriptActivityCredits={calculateTotalTranscriptActivityCredits(values)}
                            hasErrors={!!errors.transcriptCreditActivities && submitCount >= 1}
                            isProcessor={isProcessor}
                            setFieldValue={setFieldValue}
                          />
                          <AdditionalActivitiesSection
                            claim={claim}
                            creditRequirements={creditRequirements}
                            totalTranscriptActivityCredits={calculateTotalTranscriptActivityCredits(values)}
                            activitiesAreEditable={activitiesAreEditable}
                            category1Activities={values.category1CreditActivities}
                            category2Activities={values.category2CreditActivities}
                            setFieldValue={setFieldValue}
                            category1HasErrors={!!errors.additionalPraCat1Credits && submitCount >= 1}
                            category2HasErrors={!!errors.category2CreditActivities && submitCount >= 1}
                          />
                        </>
                      )}
                      {claim.praCertificateClaimMethod === PraClaimMethod.ABMS && (
                        <ABMSActivitiesSection
                          activity={values.abmsActivity}
                          claim={claim}
                          values={values}
                          abmsBoards={abmsBoards}
                          activitiesAreEditable={activitiesAreEditable}
                        />
                      )}
                      {claim.praCertificateClaimMethod === PraClaimMethod.Reciprocity && (
                        <ReciprocityActivitySection
                          activity={values.reciprocityActivity}
                          claim={claim}
                          isProcessor={isProcessor}
                          activitiesAreEditable={activitiesAreEditable}
                        />
                      )}
                      {claim.praCertificateClaimMethod === PraClaimMethod.Residency && (
                        <ResidencyActivitySection
                          activity={values.residencyActivity}
                          claim={claim}
                          isProcessor={isProcessor}
                          activitiesAreEditable={activitiesAreEditable}
                        />
                      )}
                      {(isProcessor || claim.praCertificateClaimMethod === PraClaimMethod.PreviousCME) && (
                        <PraFinalizeApplicationSection praApprovalStatus={values.status} />
                      )}
                      <PraActivityDetailsFooter>
                        {activitiesAreEditable ? (
                          <>
                            {creditRequirements && (!category1CreditRequirementsAreMet || !overallCreditRequirementsAreMet) ? (
                              <>
                                <Button type="submit" loading={isSubmitting || attachmentsAreUploading}>
                                  Save
                                </Button>
                                <FormSubmissionNoticeGroup>
                                  {attachmentsAreUploading && (
                                    <FormSubmissionNotice tag="p" color={Color.Error}>
                                      * Please wait for your documents to finish uploading before proceeding.
                                    </FormSubmissionNotice>
                                  )}
                                  <FormSubmissionNotice tag="p" color={Color.Error}>
                                    * You have not met your requirements for a PRA. Add more credits to continue.
                                  </FormSubmissionNotice>
                                </FormSubmissionNoticeGroup>
                              </>
                            ) : (
                              <>
                                <Button type="submit" loading={isSubmitting || attachmentsAreUploading}>
                                  Save and Continue
                                </Button>
                                <FormSubmissionNoticeGroup>
                                  {attachmentsAreUploading && (
                                    <FormSubmissionNotice tag="p" color={Color.Error}>
                                      * Please wait for your documents to finish uploading before proceeding.
                                    </FormSubmissionNotice>
                                  )}
                                </FormSubmissionNoticeGroup>
                              </>
                            )}
                          </>
                        ) : (
                          <Link to={buildRoute(Routes.ApplicationShow, { id: claim.id })} linkStyle={LinkStyle.Button}>
                            Continue
                          </Link>
                        )}
                      </PraActivityDetailsFooter>
                      <Debug json={values} />
                      <Debug json={errors} />
                    </Form>
                  </>
                );
              }}
            </Formik>
          </FetchBoards>
        </FetchTranscript>
      </ApplicationPage>
    </FetchClaim>
  );
};

const StyledStickyContainer = styled(StickyContainer)`
  margin-bottom: 7rem;
`;

const PraActivityDetailsFooter = styled(PageFooter)`
  align-items: center;
  justify-content: flex-start;
`;

const FormSubmissionNoticeGroup = styled.div`
  margin-left: 2rem;
  display: flex;
  flex-direction: column;
`;

const FormSubmissionNotice = styled(Text)`
  &:not(:last-of-type) {
    margin-bottom: 0.5rem;
  }
`;

export default PraActivityDetails;
