import React from 'react';
import Link from '../../components/Link';
import Text from '../../components/Text';
import { LinkStyle } from '../../constants/LinkStyle';
import { Routes } from '../../constants/Routes';
import styled from '../../theme';
import App from '../App';

const NotFound = () => (
  <App>
    <NotFoundContainer data-test-id="notFound">
      <Headline bold={true} textStyle="headline" tag="h2">
        404. Page not found.
      </Headline>
      <Body>
        <Text textStyle="medium">
          The page you're looking for cannot be found. Please check the address, or try visiting the home page.
        </Text>
      </Body>
      <Link to={Routes.Home} linkStyle={LinkStyle.Button}>
        Return home
      </Link>
    </NotFoundContainer>
  </App>
);

const Headline = styled(Text)`
  margin-bottom: 2rem;
`;

const Body = styled.div`
  margin-bottom: 2rem;
`;

const NotFoundContainer = styled.div`
  padding: 2rem 0 4rem 7rem;
`;

export default NotFound;
