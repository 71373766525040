import { FormId } from '../constants/FormId';

export const SET_INITIAL_VALUES = '@initialValues/SET';
export const UPDATE_INITIAL_VALUES = '@initialValues/UPDATE';

interface ActionSetInitialValues {
  type: typeof SET_INITIAL_VALUES;
  formId: string;
  initialValues: any;
}

interface ActionUpdateInitialValues {
  type: typeof UPDATE_INITIAL_VALUES;
  formId: string;
  newValues: any;
}
export type InitialValuesAction = ActionSetInitialValues | ActionUpdateInitialValues;

export type InitialValuesStore = { [key in FormId]?: any };

export const setInitialValues = (formId: FormId, initialValues: any): ActionSetInitialValues => {
  return {
    type: SET_INITIAL_VALUES,
    formId,
    initialValues,
  };
};

export const updateInitialValues = (formId: FormId, newValues: any): ActionUpdateInitialValues => {
  return {
    type: UPDATE_INITIAL_VALUES,
    formId,
    newValues,
  };
};

export const initialState: InitialValuesStore = {
  userInfo: {},
  optIn: {},
};

const initialValues = (state = initialState, action: InitialValuesAction) => {
  switch (action.type) {
    case SET_INITIAL_VALUES:
      return {
        ...state,
        [action.formId]: action.initialValues,
      };
    case UPDATE_INITIAL_VALUES:
      return {
        ...state,
        [action.formId]: {
          ...state[action.formId],
          ...action.newValues,
        },
      };
    default:
      return state;
  }
};

export default initialValues;
