import styled from '../../../theme';
import { themeColor, themeBreakpoint } from '../../../theme/helpers';
import { PageTitleIcon } from '../../PageStructure';
import Button from '../../../components/Button';

import { FieldRow, FieldColumn } from '../../../components/FieldRow';
import { Color } from '../../../theme/primaryTheme';
import { IconWrap } from '../../../components/Icon';

export const Section = styled.div`
  margin: 2rem 0 0 0;
  display: block;
`;

export const SearchFieldRow = styled(FieldRow)`
  &&& {
    align-items: flex-start;
  }
`;

export const SearchButton = styled(Button)`
  @media (max-width: ${themeBreakpoint('medium')}) {
    width: 100%;
  }
  &&& {
    display: block;
    margin-top: 32px;
  }
`;

export const SearchButtonColumn = styled(FieldColumn)`
  height: 100%;
`;

export const SectionTitle = styled.div`
  line-height: 1;
  padding-bottom: 0.75em;
  margin: 2rem 0 0 0;
  display: inline-flex;
  align-items: center;
  color: ${themeColor('accent')};

  em {
    font-weight: 400;
    font-style: normal;
  }

  ${PageTitleIcon} {
    padding: 0.25em 0.5em;
    margin: 0 0 0 auto;
  }
`;

// TODO: very similar to PageTitleIcon will probably want to abstract later
export const SectionTitleIcon = styled(Button)`
  padding: 0;
  color: ${themeColor(Color.Accent)};
  display: inline-flex;
  align-items: center;

  ${IconWrap} {
    margin-left: 0.5em;
    position: relative;
  }

  &:hover {
    background-color: transparent;
  }
`;
