import React from 'react';
import Icon from './index';

const LockIcon: React.FunctionComponent<{
  size?: number;
  marginLeft?: boolean;
}> = ({ size = 16, marginLeft = false }) => {
  const iconWidth = size;
  const iconHeight = Math.ceil(size * (21 / 16));
  const styles = marginLeft ? { marginLeft: '0.2em' } : {};

  return <Icon fill="#8DA3B5" name="padlock" width={iconWidth} height={iconHeight} viewBox="0 0 16 21" style={styles} />;
};

export default LockIcon;
