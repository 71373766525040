import styled, { css } from '../../theme';
import { themeColor } from '../../theme/helpers';
import { Color } from '../../theme/primaryTheme';
import { IconWrap } from '../Icon';
import { DropdownProps } from './index';

export const StyledButton = styled.button<DropdownProps>`
  appearance: none;
  background-color: transparent;
  border: none;
  box-shadow: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  text-align: left;
  font-size: 14px;
  padding: ${(props) => (props.removePadding ? '0' : '2.4rem')};
  border-radius: 0.5rem;
  transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
  color: ${(props) => (props.colorKey ? themeColor(props.colorKey) : 'inherit')};
  ${(props) =>
    props.isOpen
      ? css`
          pointer-events: none;
        `
      : ''};

  // no hover state when padding is removed
  ${(props) =>
    !props.removePadding
      ? css`
          &:hover {
            background-color: ${themeColor('backgroundLight')};
          }
        `
      : ''}

  ${IconWrap} {
    margin-left: 0.75rem;
  }

  :focus {
    outline: none;
  }

  ${(props) =>
    props.isOpen && !props.removePadding
      ? css`
          &,
          &:hover {
            background-color: ${themeColor('backgroundDark')};
            border-radius: 0.5rem 0.5rem 0 0;
          }
        `
      : ''}
`;

const openStyles = css`
  opacity: 1;
  transform: translateY(0);
  visibility: visible;
  pointer-events: auto;
`;

export const StyledDropdownPanel = styled.div<DropdownProps>`
  border-radius: 0 0 3px 3px;
  background-color: #fff;
  position: absolute;
  top: 100%;
  width: 100%;
  opacity: 0;
  transform: translateY(-1rem);
  visibility: hidden;
  pointer-events: none;
  transition: all 0.125s cubic-bezier(0.455, 0.03, 0.515, 0.955);
  will-change: transform, opacity;
  z-index: 2;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.15);
  overflow: hidden;

  ${(props) =>
    props.arrow
      ? css`
          margin-top: 1.5rem;

          &:after {
            content: '';
            position: absolute;
            width: 1.25rem;
            display: block;
            height: 1.25rem;
            top: -0.5rem;
            left: 50%;
            margin-left: -0.625rem;
            transform: rotate(45deg);
            background-color: ${themeColor(Color.Reverse)};
          }
        `
      : ''}

  ${(props) =>
    props.width
      ? css`
          width: ${props.width}px;
          margin-left: ${props.width / -2}px;
          left: 50%;
        `
      : ''};
  ${(props) => (props.isOpen ? openStyles : '')};
`;
