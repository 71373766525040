import { Option } from '../components/Field/Controls/Select';

export interface StatusCheckbox extends Option {
  value: CreditClaimStatus;
}

export enum CreditClaimStatus {
  UNSUBMITTED = 'unsubmitted',
  PENDING_CUSTOMER_ACTION = 'pending_customer_action',
  SUBMITTED = 'submitted',
  PROCESSING = 'processing',
  APPROVED = 'approved',
  REJECTED = 'rejected',
  PAYMENT_RECEIVED = 'payment_received',
  PAYMENT_PROCESSED = 'payment_processed',
  PAYMENT_FAILED = 'payment_failed',
  COMPLETED = 'completed',
}

export const CreditClaimStatusLabels = {
  [CreditClaimStatus.UNSUBMITTED]: 'Unsubmitted',
  [CreditClaimStatus.SUBMITTED]: 'Submitted',
  [CreditClaimStatus.PROCESSING]: 'Processing',
  [CreditClaimStatus.PENDING_CUSTOMER_ACTION]: 'Pending Action',
  [CreditClaimStatus.APPROVED]: 'Approved',
  [CreditClaimStatus.REJECTED]: 'Rejected',
  [CreditClaimStatus.COMPLETED]: 'Completed',
  [CreditClaimStatus.PAYMENT_RECEIVED]: 'Payment Received',
  [CreditClaimStatus.PAYMENT_FAILED]: 'Failed Payment',
  [CreditClaimStatus.PAYMENT_PROCESSED]: 'Payment Processed',
};

export const CreditClaimStatusValues = Object.values(CreditClaimStatus);

export const creditClaimStatusOptions = CreditClaimStatusValues.map((value: CreditClaimStatus) => ({
  label: CreditClaimStatusLabels[value],
  value,
}));
