import styled, { css } from 'styled-components';

interface GridProps {
  gridColumn?: string;
  gridRow?: string;
  gridArea?: string;
}

export const GridItem = styled.div<GridProps>`
  ${({ gridColumn }) =>
    gridColumn &&
    css`
      grid-column: ${gridColumn};
    `};

  ${({ gridArea }) =>
    gridArea &&
    css`
      grid-column: ${gridArea};
    `};

  ${({ gridRow }) =>
    gridRow &&
    css`
      grid-row: ${gridRow};
    `};
`;

interface GridProps {
  gridTemplateColumns?: string;
  gridTemplateRows?: string;
  gridGap?: string | number;
  alignItems?: string;

  /* props for IE 11 support */
  ieGridItemsMargin?: string | number;
  ieGridItemsFlexValues?: Array<number | string>;
}

export const Grid = styled.div<GridProps>`
  width: 100%;
  display: grid;
  grid-gap: ${({ gridGap = '4rem 2rem' }) => gridGap};
  grid-template-columns: ${({ gridTemplateColumns = 'repeat(2, 1fr)' }) => gridTemplateColumns};
  grid-template-rows: ${({ gridTemplateRows = 'auto' }) => gridTemplateRows};
  align-items: ${({ alignItems = 'center' }) => alignItems};

  @supports not (display: grid) {
    display: flex;
    flex-flow: row wrap;
  }

  /* Targets IE 11 */
  @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
    display: flex;

    & > ${GridItem} {
      margin: ${({ ieGridItemsMargin = '.5rem 1rem' }) => ieGridItemsMargin};

      ${({ ieGridItemsFlexValues = [] }) =>
        ieGridItemsFlexValues.map(
          (flexValue, idx) =>
            css`
              &:nth-of-type(${idx + 1}) {
                display: flex;
                flex: 1 0 auto;
                width: ${flexValue};

                & > * {
                  width: 100%;
                }
              }
            `
        )}
    }
  }
`;
