import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Icon from 'src/components/Icon';
import { ActivityStatus, ActivityStatusLabel } from 'src/constants/ActivityStatus';
import { calculatePriceForCollectionOfDcIccActivities } from 'src/constants/ProccesingRates';
import styled, { css } from 'styled-components';
import { ActivityType, renderActivityTypeLabel } from '../../../../constants/ActivityType';
import { claimDataSelector, claimUserMembershipSelector } from '../../../../selectors/claims';
import { themeColor } from '../../../../theme/helpers';
import { Color, ThemeColors } from '../../../../theme/primaryTheme';
import { CreditActivity } from '../../../../types';
import { calculateValidAndPendingCredits, renderActivityTitle } from '../../../../utilities/creditActivities';
import Button from '../../../Button';
import Text from '../../../Text';

const ActivityTypeGroup: React.FC<{ activityType: ActivityType; activities: CreditActivity[] }> = ({ activityType, activities }) => {
  const claimUserIsMember = useSelector(claimUserMembershipSelector);
  const { rateCode } = useSelector(claimDataSelector);
  const [groupIsExpanded, setGroupIsExpanded] = useState(false);

  return (
    <>
      <ActivityGroupSummary onClick={() => setGroupIsExpanded(!groupIsExpanded)} data-testid={`${activityType}-group-summary`}>
        <tr>
          <ToggleButtonCell>
            <Button buttonStyle="toggle">
              <IconOrientation groupIsExpanded={groupIsExpanded}>
                <Icon name="angleArrowDown" width={16} height={10} viewBox="0 0 16 10" fill={ThemeColors.primary} />
              </IconOrientation>
            </Button>
          </ToggleButtonCell>
          <td colSpan={2}>
            <Text bold={true}>{renderActivityTypeLabel(activityType)}</Text>
          </td>
          <td>
            <Text bold={true}>{calculateValidAndPendingCredits(activities)}</Text>
          </td>
          <td>
            <Text bold={true}>{`$${calculatePriceForCollectionOfDcIccActivities(rateCode, activities, claimUserIsMember)}`}</Text>
          </td>
        </tr>
      </ActivityGroupSummary>
      <Divider groupIsExpanded={groupIsExpanded}>
        <tr>
          <td colSpan={5} />
        </tr>
      </Divider>
      <ActivityGroupDetails groupIsExpanded={groupIsExpanded} data-testid={`${activityType}-group-details`}>
        {activities.map((activity) => {
          const { id, status } = activity;
          return (
            <tr key={id} data-testid={`activity-${id}`}>
              <td>{/* empty cell for alignment */}</td>
              <td>{renderActivityTitle(activity)}</td>
              <td>
                <StatusLabel status={status}>
                  <Text textStyle="small">{ActivityStatusLabel[status]}</Text>
                </StatusLabel>
              </td>
              <td>{calculateValidAndPendingCredits([activity])}</td>
              <td>{/* empty cell for alignment */}</td>
            </tr>
          );
        })}
      </ActivityGroupDetails>
    </>
  );
};

const ToggleButtonCell = styled.td`
  width: 3rem;
`;

const IconOrientation = styled.div<{ groupIsExpanded: boolean }>`
  ${({ groupIsExpanded }) =>
    !groupIsExpanded &&
    css`
      transform: rotate(-0.25turn);
    `}
`;

const ActivityGroupSummary = styled.tbody`
  background-color: ${themeColor(Color.CardHeader)};

  &:hover {
    cursor: pointer;
  }

  &:last-of-type {
    background-color: blue;
  }
`;

const Divider = styled.tbody<{ groupIsExpanded: boolean }>`
  border-bottom: 1px solid ${themeColor(Color.Border)};

  & > tr > td {
    padding: 0;
  }

  ${({ groupIsExpanded }) =>
    !groupIsExpanded &&
    css`
      &:nth-last-of-type(2) {
        border-bottom: none;
      }
    `}
`;

const ActivityGroupDetails = styled.tbody<{ groupIsExpanded: boolean }>`
  background-color: ${themeColor(Color.Reverse)};

  ${({ groupIsExpanded }) =>
    !groupIsExpanded &&
    css`
      display: none;
    `}
`;

const statusStyles = {
  [ActivityStatus.FollowUp]: css`
    color: ${themeColor(Color.Error)};
    border: 1px solid ${themeColor(Color.Error)};
  `,
  [ActivityStatus.Pending]: css`
    color: ${themeColor(Color.Gray)};
    border: 1px solid ${themeColor(Color.Gray)};
  `,
  [ActivityStatus.Valid]: css`
    background-color: ${themeColor(Color.Confirmation)};
    color: ${themeColor(Color.Reverse)};
  `,
  [ActivityStatus.Invalid]: css`
    background-color: ${themeColor(Color.Error)};
    color: ${themeColor(Color.Reverse)};
  `,
};

const StatusLabel = styled.div<{ status: ActivityStatus }>`
  padding: 0.3rem 0.8rem;
  text-align: center;
  max-width: 10rem; /* Magic number to fit all activity status labels */
  ${({ status }) => statusStyles[status]}
`;

export default ActivityTypeGroup;
