import { FormikHelpers } from 'formik';
import React from 'react';
import styled from '../../theme';
import { themeColor } from '../../theme/helpers';
import { Color, ThemeColors } from '../../theme/primaryTheme';
import { CreditActivity } from '../../types';
import { calculateCredits } from '../../utilities/creditActivities';
import Button from '../Button';
import Field from '../Field';
import { Grid, GridItem } from '../Grid';
import Icon from '../Icon';
import { DEFAULT_PANEL_PADDING } from '../Panel';
import SortArrows from '../SortArrows';
import Text, { TextStyle } from '../Text';
import { category2Columns, category2DefaultValues, category2GridItemsFlexValues, category2GridTemplateColumns } from './category2Columns';
import { sortedActivities } from './index';

interface ActivityRowProps {
  activitiesAreEditable: boolean;
  activity: CreditActivity;
  index: number;
  removeItem: (index: number) => any;
}

interface ActivityTableProps {
  activitiesAreEditable: boolean;
  activities: CreditActivity[];
  addItem: (obj: any) => void;
  removeItem: (index: number) => any;
  setFieldValue: FormikHelpers<any>['setFieldValue'];
}

const StyledActivityRow = styled.div`
  padding: 12px 16px 11px 20px;
`;

const renderField = (name: string, column: any, fieldIsEditable: boolean) => {
  if (column.render && typeof column.render === 'function') {
    return column.render(name, column, fieldIsEditable);
  }

  return <Field name={name} showErrorMessage={false} locked={!fieldIsEditable} {...column} />;
};

const ActivityRow: React.FunctionComponent<ActivityRowProps> = ({ activitiesAreEditable, activity, index, removeItem }) => {
  const handleRemoveClick = () => {
    removeItem(index);
  };

  const activityIsEditable = activitiesAreEditable;

  return (
    <StyledActivityRow className="activity-row">
      <Grid key={activity.id} gridTemplateColumns={category2GridTemplateColumns} ieGridItemsFlexValues={category2GridItemsFlexValues}>
        {Object.keys(category2Columns).map((name) => {
          const column = category2Columns[name];
          const inputName = `category2CreditActivities[${index}].${name}`;

          return <GridItem key={inputName}>{renderField(inputName, column, activityIsEditable)}</GridItem>;
        })}
        {activityIsEditable && (
          <GridItem>
            <Button buttonStyle="icon-toggle" onClick={handleRemoveClick} tabIndex={-1}>
              <Icon name="minusCircle" viewBox="0 0 12 12" fill={ThemeColors.secondary} width={20} height={20} />
            </Button>
          </GridItem>
        )}
      </Grid>
    </StyledActivityRow>
  );
};

const Header = styled.div`
  padding: ${DEFAULT_PANEL_PADDING};
  padding-bottom: 8px;
`;

const FlexGridItem = styled(GridItem)`
  display: flex;
  align-items: center;
`;

const Footer = styled.div<{ activitiesAreEditable: boolean }>`
  display: flex;
  justify-content: ${({ activitiesAreEditable }) => (activitiesAreEditable ? 'space-between' : 'flex-end')};
  align-items: center;
  padding: ${DEFAULT_PANEL_PADDING};
`;

const Body = styled.div`
  border-top: 1px solid ${themeColor(Color.Border)};
  border-bottom: 1px solid ${themeColor(Color.Border)};

  ${StyledActivityRow}:nth-child(even) {
    background-color: #f6f8fa;
  }
`;

const StyledButton = styled(Button)`
  display: inline-flex;

  & > span:first-of-type {
    margin-right: 1rem;
  }
`;

const CreditTotal = styled(Text)`
  margin-right: 3rem;
`;

const PraCategory2ActivityTable: React.FunctionComponent<ActivityTableProps> = ({
  activitiesAreEditable,
  activities,
  addItem,
  removeItem,
  setFieldValue,
}) => {
  const handleAddClick = () => {
    addItem(category2DefaultValues);
  };

  const handleDateArrowClick = (chronological: boolean) => () =>
    setFieldValue('category2CreditActivities', sortedActivities(activities, chronological));

  const isActivityComplete = (activity: CreditActivity) =>
    !!(activity.activityTitle && activity.activityDescription && activity.activityEndDate && activity.numberOfCredits);

  // if all rows are filled in completely, add another row
  if (activities.length > 0 && activities.every((activity) => isActivityComplete(activity))) {
    addItem(category2DefaultValues);
  }

  return (
    <>
      <Header>
        <Grid gridTemplateColumns={category2GridTemplateColumns} ieGridItemsFlexValues={category2GridItemsFlexValues}>
          {Object.keys(category2Columns).map((name) => {
            const column = category2Columns[name];

            if (name === 'activityEndDate') {
              return (
                <FlexGridItem key={name}>
                  <Text bold={true} textStyle={TextStyle.Small}>
                    {column.title}
                  </Text>
                  <SortArrows onUpArrowClick={handleDateArrowClick(false)} onDownArrowClick={handleDateArrowClick(true)} />
                </FlexGridItem>
              );
            }

            return (
              <GridItem key={name}>
                <Text bold={true} textStyle={TextStyle.Small}>
                  {column.title}
                </Text>
              </GridItem>
            );
          })}
          <GridItem />
        </Grid>
      </Header>
      <Body>
        {activities.map((activity, index: number) => (
          <ActivityRow
            activitiesAreEditable={activitiesAreEditable}
            removeItem={removeItem}
            key={index}
            index={index}
            activity={activity}
          />
        ))}
      </Body>
      <Footer activitiesAreEditable={activitiesAreEditable}>
        {activitiesAreEditable && (
          <StyledButton data-test-id="add-item-category-2" buttonStyle="plain-text" type="button" onClick={handleAddClick}>
            <Icon name={'plusCircle'} width={20} height={20} viewBox="0 0 12 12" />
            <Text bold={true} tag="span" color={Color.Secondary}>
              Add another row
            </Text>
          </StyledButton>
        )}
        <CreditTotal bold={true} color={Color.Secondary}>
          Total{' '}
          <Text tag="em" bold={true}>
            AMA PRA Category 2 Credits&trade;
          </Text>
          : {calculateCredits(activities)}
        </CreditTotal>
      </Footer>
    </>
  );
};

export default PraCategory2ActivityTable;
