import moment from 'moment';

const noEmptyValues = (value: any) => {
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  return value instanceof Object ? !!Object.keys(deleteEmptyKeys(value)).length : Boolean(value);
};

export const deleteEmptyKeys = (obj: Record<string, any>): Record<string, any> => {
  const o = { ...obj };
  Object.keys(o).forEach((k) => {
    // Moment breaks everything, so skip it.
    if (moment.isMoment(o[k])) {
      return;
    }

    // Skip sources
    if (k === 'sources') {
      return;
    }

    if (o[k] instanceof Object) {
      // If the object is empty, remove it.
      if (!Object.keys(o[k]).length) {
        delete o[k];
      } else {
        deleteEmptyKeys(o[k]);
      }
    }

    // If key is an array, filter out empty values.
    if (Array.isArray(o[k])) {
      o[k] = o[k].filter(noEmptyValues).map(deleteEmptyKeys);

      // Then delete the key if the remaining array is empty.
      if (!o[k].length) {
        delete o[k];
      }
    }

    // Otherwise, delete the key if it's falsy.
    else if (o[k] === '' || o[k] === undefined || o[k] === null) {
      delete o[k];
    }
  });

  return o;
};

export const nullifyEmptyValues = (obj: Record<string, any>): Record<string, any> => {
  const o = { ...obj };
  Object.keys(o).forEach((k) => {
    // Moment breaks everything, so skip it.
    if (moment.isMoment(o[k])) {
      return;
    }

    // Skip sources
    if (k === 'sources') {
      return;
    }

    if (o[k] instanceof Object) {
      // If the object at this key is not empty,
      // recursively loop through it to process its keys.
      if (Object.keys(o[k]).length) {
        nullifyEmptyValues(o[k]);
      }
    }

    if (Array.isArray(o[k])) {
      // Filter out empty values, and recursively
      // call this function on each remaining key.
      o[k] = o[k].filter(noEmptyValues);
    }

    // Otherwise, set the key to `null` if it's falsy.
    else if (o[k] === '' || o[k] === undefined) {
      o[k] = null;
    }
  });

  return o;
};
