import styled from '../../theme';

export const TextStack = styled.div`
  > * + * {
    margin-top: 2rem;
  }

  > * + button {
    margin-top: 4rem;
  }
`;
