import React from 'react';
import { RouteComponentProps } from 'react-router';
import { Columns, ColumnsMain } from '../../components/Columns';
import CreditEarnerForm from '../../components/CreditEarnerForm';
import { Routes } from '../../constants/Routes';
import { ExternalCreditEarner } from '../../types';
import DefaultPage from '../DefaultPage';

const NewUser: React.FunctionComponent<RouteComponentProps> = ({ history }) => {
  const getRedirect = (data: ExternalCreditEarner) => ({
    pathname: Routes.ApplicationNew,
    state: { user: data },
    search: `?userId=${data.id}`,
  });
  return (
    <DefaultPage title="Create New User" subtitle="Create a new user, or search for an existing user in the database.">
      <Columns>
        <ColumnsMain>
          <CreditEarnerForm history={history} submitText="Start Application for User" redirectTo={getRedirect} />
        </ColumnsMain>
      </Columns>
    </DefaultPage>
  );
};

export default NewUser;
