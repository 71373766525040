import React from 'react';
import { themeColor } from 'src/theme/helpers';
import styled from '../../theme';
import { Color } from '../../theme/primaryTheme';
import Text, { TextStyle } from '../Text';
import { TextStack } from '../TextStack';

const NoTranscriptDataMessage: React.FunctionComponent<{ dataType: 'learnerActivity' | 'pra' }> = ({ dataType }) => (
  <Root data-test-id={dataType === 'learnerActivity' ? 'noLearnerActivities' : 'noPras'}>
    {dataType === 'learnerActivity' ? (
      <TextStack>
        <Text textStyle={TextStyle.SubHeadline} color={Color.Primary} bold={true}>
          No activities found.
        </Text>
        <Text textStyle={TextStyle.Medium}>When activities have been accepted, they will be shown here.</Text>
      </TextStack>
    ) : (
      <Text textStyle={TextStyle.SubHeadline} color={Color.Primary} bold={true}>
        No PRAs found.
      </Text>
    )}
  </Root>
);

const Root = styled.div`
  text-align: center;
  background-color: ${themeColor(Color.Reverse)};
  padding: 4rem;
  border-radius: 0.4rem;
`;

export default NoTranscriptDataMessage;
