import { createAction, createReducer } from '@reduxjs/toolkit';
import { getAccessToken } from '../utilities/authentication';

export const login = createAction('@AUTH/LOGIN_SUCCESS');
export const logout = createAction('@AUTH/LOGOUT_SUCCESS');

export const initialState: {
  isAuthenticated: boolean;
  token: string | null;
} = {
  isAuthenticated: Boolean(getAccessToken()),
  token: getAccessToken(),
};

export default createReducer(initialState, (builder) =>
  builder
    .addCase(login, (state) => ({
      ...state,
      isAuthenticated: true,
      token: getAccessToken(),
    }))
    .addCase(logout, (state) => ({
      ...state,
      isAuthenticated: false,
      token: null,
    }))
);
