import React from 'react';
import { useState } from 'react';
import { ApplicationTypeCodeLabels } from '../../constants/ApplicationTypeCodeLabels';
import { ModalType } from '../../constants/ModalType';
import styled from '../../theme';
import { CreditClaim } from '../../types';
import ActionModal from '../ActionModal';
import CheckBox from '../Field/Controls/CheckBox';
import Text, { TextStyle } from '../Text';
import { getEarnerName } from './index';

const CheckBoxWrap = styled.div`
  margin: 3rem 0;
`;

interface Props {
  claim: CreditClaim;
  handleConfirm: (checked: boolean) => void;
}

const PaymentFailedModal: React.FunctionComponent<Props> = ({ claim, handleConfirm }) => {
  const [checked, setChecked] = useState(true);

  const handleChange = () => {
    setChecked(!checked);
  };

  const updateClaimIfChecked = () => handleConfirm(checked);

  return (
    <ActionModal
      modalId={`${ModalType.PaymentFailed}-${claim.id}`}
      title="Mark as Payment Failed"
      onConfirm={updateClaimIfChecked}
      confirmLabel="Mark and Retry Payment"
      disableConfirm={!checked}
    >
      <Text textStyle={TextStyle.Label}>Application</Text>
      <div style={{ marginBottom: '1rem' }} />
      <Text textStyle={TextStyle.Default} bold={true}>
        {getEarnerName(claim)}
      </Text>
      <Text textStyle={TextStyle.Default}>{ApplicationTypeCodeLabels[claim.applicationTypeCode || '']}</Text>
      <div style={{ marginBottom: '3rem' }} />
      <Text textStyle={TextStyle.Default}>
        You can change the status of a user’s previously completed or approved application if their payment on Advantage has failed. This
        action can’t be undone. Once payment has been resubmitted on Advantage, the application status will move back to processing.
      </Text>
      <CheckBoxWrap>
        <CheckBox name="status" title="Payment Failed" checked={checked} onChange={handleChange} />
      </CheckBoxWrap>
    </ActionModal>
  );
};

export default PaymentFailedModal;
