import { ArrayHelpers, Field as FormikField, FieldArray, FieldProps, getIn } from 'formik';
import React from 'react';
import styled from '../../theme';
import { Color, ThemeColors } from '../../theme/primaryTheme';
import Button from '../Button';
import ErrorMessage from '../ErrorMessage';
import { FieldColumn, FieldRow } from '../FieldRow';
import Icon, { IconWrap } from '../Icon';
import { FieldLabel } from '../Label';
import Text from '../Text';

// A bunch of ugly code for pretty alignments
export const ArrayFields = styled.div`
  ${FieldRow} {
    position: relative;
  }

  ${FieldRow} + ${FieldRow} {
    margin-top: 1rem;
  }

  ${FieldColumn} + ${FieldColumn} {
    margin-left: 1rem;
  }

  ${IconWrap} {
    position: relative;
    top: 1px;
  }
`;

export const ArrayFieldFooter = styled.div`
  margin-top: 2rem;

  button {
    display: flex;
    align-items: center;
  }

  ${IconWrap} {
    margin-right: 0.5em;
    position: relative;
    top: -1px;
  }
`;

export const RemoveButton = styled.span`
  position: absolute;
  right: -30px;
  top: 16px;
`;

interface Props {
  name: string;
  label: string;
  children: any;
  addAnotherLabel: string;
  locked?: boolean;
  required?: boolean;
  errors?: any;
}

const ArrayField: React.FunctionComponent<Props> = ({ name, label, children, addAnotherLabel, required = false, locked = false }) => {
  return (
    <FormikField name={name}>
      {({ field, form }: FieldProps) => {
        const { errors, values, touched } = form;
        const { name } = field;

        return (
          <>
            <FieldArray name={field.name}>
              {(arrayHelpers: ArrayHelpers) => {
                const addItem = () => {
                  arrayHelpers.push('');
                };

                const hasError = getIn(errors, name) && getIn(touched, name);
                const fieldValues = getIn(values, name) || [];

                return (
                  <ArrayFields data-test-id={`arrayField-${name}`}>
                    <FieldLabel required={required} hasError={Boolean(hasError)} text={label} />
                    {fieldValues.map((value: string, index: number) => {
                      const fieldName = `${name}[${index}]`;
                      const removeItem = () => {
                        form.setFieldValue(`${name}[${index}]`, null);
                        form.setFieldValue(`sources.${name}[${index}]`, null);
                        arrayHelpers.remove(index);
                      };

                      return (
                        <FieldRow key={fieldName}>
                          <FieldColumn grow={1}>{children(fieldName)}</FieldColumn>
                          {!locked && fieldValues.length > 1 && getIn(values, `sources.${fieldName}`) !== 'AIMS' && (
                            <RemoveButton data-test-id={`remove-item-${fieldName}`}>
                              <Button buttonStyle="icon-toggle" onClick={removeItem}>
                                <Icon name="minusCircle" viewBox="0 0 12 12" fill={ThemeColors.secondary} width={20} height={20} />
                              </Button>
                            </RemoveButton>
                          )}
                        </FieldRow>
                      );
                    })}
                    {!locked && (
                      <ArrayFieldFooter>
                        <Button data-test-id={`add-item-${name}`} buttonStyle="plain-text" type="button" onClick={addItem}>
                          <Icon name={'plusCircle'} width={20} height={20} viewBox="0 0 12 12" />
                          <Text bold={true} tag="span" color={Color.Secondary}>
                            {addAnotherLabel}
                          </Text>
                        </Button>
                      </ArrayFieldFooter>
                    )}
                    <ErrorMessage name={name} />
                  </ArrayFields>
                );
              }}
            </FieldArray>
          </>
        );
      }}
    </FormikField>
  );
};

export default ArrayField;
