import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { ReduxStore } from '../../ducks';
import { fetchClaim } from '../../ducks/claims';
import { claimLoadingSelector } from '../../selectors/claims';
import LoadingSpinner from '../LoadingSpinner';

interface Props extends RouteComponentProps<{ id: string }> {
  loading: boolean;
  fetchClaim: typeof fetchClaim;
}

const FetchClaim: React.FunctionComponent<Props> = ({ match, loading, children, fetchClaim }) => {
  const { id } = match.params;

  useEffect(
    () => {
      if (id) {
        fetchClaim(id);
      }
    },
    [id] // eslint-disable-line react-hooks/exhaustive-deps
  );

  if (loading) {
    return <LoadingSpinner />;
  }

  return <>{children}</>;
};

const mapStateToProps = (state: ReduxStore) => ({
  loading: claimLoadingSelector(state),
});

const mapDispatchToProps = {
  fetchClaim,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FetchClaim));
