import FormikWithRef from '../FormikWithRef';
import { Form } from 'formik';
import _ from 'lodash';
import React, { useRef, useState } from 'react';
import Toggle from 'react-toggled';
import Field from '../../../components/Field';
import Select, { Option } from '../../../components/Field/Controls/Select';
import { FieldColumn } from '../../../components/FieldRow';
import Icon from '../../../components/Icon';
import Text from '../../../components/Text';
import { UserSearchCriteria } from '../../../types';
import { deleteEmptyKeys } from '../../../utilities/cleanData';
import { momentUtc } from '../../../utilities/moment';
import { stateOptions } from '../stateOptions';
import { SearchButton, SearchButtonColumn, SearchFieldRow, Section, SectionTitle, SectionTitleIcon } from './styles';

interface AdvancedSearchOption extends Option {
  inputLabel: string;
  stateInputLabel?: string;
  placeholder?: string;
  showState?: boolean;
  showDOB?: boolean;
  maxLength: number;
}

export const advancedSearchOptions: AdvancedSearchOption[] = [
  {
    label: 'ME Number',
    value: 'meNumber',
    inputLabel: 'ME Number',
    placeholder: '12345678901',
    showState: false,
    maxLength: 11,
  },
  {
    label: 'NPI Number',
    value: 'npiNumber',
    inputLabel: 'NPI Number',
    placeholder: '1234567890',
    showState: false,
    maxLength: 10,
  },
  {
    label: 'License Number / State',
    value: 'licenseNumber',
    inputLabel: 'License Number',
    stateInputLabel: 'License State',
    showState: true,
    maxLength: 50,
  },
  {
    label: 'Date of Birth / State',
    value: 'dateOfBirth',
    inputLabel: 'Date of Birth',
    placeholder: 'YYYY-MM-DD',
    stateInputLabel: 'State of Practice',
    showState: true,
    maxLength: 10,
  },
];

const BasicSearchFields = () => (
  <Section>
    <SearchFieldRow even={true}>
      <FieldColumn>
        <Field name="firstName" placeholder="John" label="First Name" />
      </FieldColumn>
      <FieldColumn>
        <Field name="lastName" placeholder="Smith" label="Last Name" />
      </FieldColumn>
      <SearchButtonColumn>
        <SearchButton name="submitSearchButton" type="submit">
          Search
        </SearchButton>
      </SearchButtonColumn>
    </SearchFieldRow>
  </Section>
);

interface AdvancedSearchProps {
  onOptionChanged: (event: React.ChangeEvent<HTMLInputElement>) => void;
  selectedAdvancedSearchOption: AdvancedSearchOption;
  toggleRef: any;
}

const AdvancedSearch: React.FunctionComponent<AdvancedSearchProps> = ({ onOptionChanged, selectedAdvancedSearchOption, toggleRef }) => {
  return (
    <Toggle ref={toggleRef}>
      {({ on, toggle }) => (
        <>
          <Section>
            <SectionTitle>
              <SectionTitleIcon type="button" buttonStyle="toggle" name="advancedSearchToggle" onClick={toggle}>
                <Text tag="div" bold={true} color="accent">
                  Advanced Search (AIMS)
                </Text>
                <Icon name={on ? 'caretUp' : 'caretDown'} width={10} height={5} viewBox="0 0 10 5" />
              </SectionTitleIcon>
            </SectionTitle>
          </Section>
          {on && (
            <>
              <Text tag="p">Select an option below to refine your search. You can only select one at a time.</Text>
              <Section>
                <SearchFieldRow>
                  <FieldColumn>
                    <Field
                      component={Select}
                      name="advancedSearchOption"
                      label="Select Search Criteria"
                      onChange={onOptionChanged}
                      controlProps={{ options: advancedSearchOptions }}
                    />
                  </FieldColumn>
                  <FieldColumn>
                    <Field
                      name="advancedSearchOptionValue"
                      label={selectedAdvancedSearchOption.inputLabel}
                      placeholder={selectedAdvancedSearchOption.placeholder}
                      controlProps={{
                        maxLength: selectedAdvancedSearchOption.maxLength,
                      }}
                    />
                  </FieldColumn>
                  {selectedAdvancedSearchOption.showState && (
                    <FieldColumn>
                      <Field
                        name="state"
                        label={selectedAdvancedSearchOption.stateInputLabel}
                        component={Select}
                        controlProps={{
                          options: stateOptions,
                          defaultLabel: 'Select State',
                        }}
                      />
                    </FieldColumn>
                  )}
                </SearchFieldRow>
              </Section>
            </>
          )}
        </>
      )}
    </Toggle>
  );
};

interface UserSearchFormProps {
  criteria: UserSearchCriteria;
  onSubmit: (criteria: UserSearchCriteria) => void;
  advancedSearchToggleRef: React.RefObject<any>;
}

const UserSearchForm = (props: UserSearchFormProps) => {
  const [state, setState] = useState({
    selectedAdvancedSearchOption: advancedSearchOptions[0],
    initialFormValues: {
      ...props.criteria,
      advancedSearchOption: 'meNumber',
      advancedSearchOptionValue: '',
      state: '',
    },
  });
  const formik = useRef(null);

  const advancedSearchParamChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const form = formik.current;
    const option = advancedSearchOptions.find((o) => o.value === event.target.value);
    if (option) {
      if ([state.selectedAdvancedSearchOption.value.toString(), option.value.toString()].includes('dateOfBirth')) {
        // Clear input when switching from date to identifier or vice versa.
        form.setFieldValue('advancedSearchOptionValue', '');
      }

      setState({
        ...state,
        selectedAdvancedSearchOption: option,
      });

      form.setFieldValue('advancedSearchOption', option.value);
    }

    if (!option || !option.showState) {
      form.setFieldValue('state', '');
    }
  };

  const validateForm = (values: any) => {
    const errors: any = {};

    if (_.isEmpty(values.firstName)) {
      errors.firstName = 'First Name is required';
    }
    if (_.isEmpty(values.lastName)) {
      errors.lastName = 'Last Name is required';
    }

    if (props.advancedSearchToggleRef.current.getOn()) {
      const option = advancedSearchOptions.find((o) => o.value === values.advancedSearchOption);

      if (option) {
        if (option.showState && _.isEmpty(values.state)) {
          errors.state = 'State is required';
        }

        if (option.value === 'dateOfBirth') {
          const date = momentUtc(values.advancedSearchOptionValue);

          if (!date.isValid()) {
            errors.advancedSearchOptionValue = 'Please enter a valid date';
          }
        }
        if (values.advancedSearchOptionValue === '') {
          errors.advancedSearchOptionValue = `${option.inputLabel} is required`;
        }
      }
    }
    return errors;
  };

  const handleSubmit = (values: any) => {
    const criteria = { ...values };
    const option = advancedSearchOptions.find((o) => o.value === criteria.advancedSearchOption);

    if (option) {
      criteria[option.value.toString()] = criteria.advancedSearchOptionValue || '';

      if (option.value === 'licenseNumber') {
        criteria.licenseState = criteria.state;
      }

      if (option.value === 'dateOfBirth') {
        criteria.practiceState = criteria.state;
      }

      delete criteria.state;
    }

    formik.current.setFieldValue('advancedSearchOptionValue', criteria.advancedSearchOptionValue);

    criteria.searchAims = props.advancedSearchToggleRef.current.getOn();
    delete criteria.advancedSearchOption;
    delete criteria.advancedSearchOptionValue;

    const cleanCriteria = deleteEmptyKeys(criteria);
    props.onSubmit(cleanCriteria);
  };

  return (
    <FormikWithRef
      enableReinitialize={true}
      initialValues={state.initialFormValues}
      onSubmit={handleSubmit}
      ref={formik}
      validate={validateForm}
    >
      <Form>
        <BasicSearchFields />
        <AdvancedSearch toggleRef={props.advancedSearchToggleRef} onOptionChanged={advancedSearchParamChange} {...state} />
      </Form>
    </FormikWithRef>
  );
};

export default UserSearchForm;
