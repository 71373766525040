import React from 'react';
import { connect } from 'react-redux';
import LoadingSpinner from '../../components/LoadingSpinner';
import NewItemButton, { ItemType } from '../../components/NewItemButton';
import { Pagination } from '../../components/Pagination';
import Text from '../../components/Text';
import { ReduxStore } from '../../ducks';
import { fetchUserSearchResults, userSearchReset } from '../../ducks/userSearch';
import { ExternalCreditEarner, UserSearchCriteria } from '../../types';
import { momentUtc } from '../../utilities/moment';
import DefaultPage from '../DefaultPage';
import UserSearchForm from '../Forms/UserSearchForm';
import { PageSection } from '../PageStructure';
import Results from './Results';
import { ClearSearchButton, ResultCount, SearchResultsTitleBar } from './styles';

interface Props {
  currentPage: number;
  criteria: UserSearchCriteria;
  dispatch: any;
  isLoading: boolean;
  pageSize: number;
  totalPages: number;
  totalResults: number;
  users: ExternalCreditEarner[];
}

interface State {
  showResultsSection: boolean;
}

class UserSearch extends React.Component<Props, State> {
  public advancedSearchToggleRef: React.RefObject<any> = React.createRef();

  public state: State = {
    showResultsSection: false,
  };

  public render() {
    const { criteria, isLoading, totalResults, users, totalPages, currentPage } = this.props;

    return (
      <DefaultPage
        title="User Search"
        subtitle="Search to add a physician from the database."
        action={<NewItemButton itemType={ItemType.User} />}
      >
        <>
          <Text textStyle="sectionTitle" tag="h2">
            Search Database
          </Text>
          <UserSearchForm criteria={criteria} onSubmit={this.submitSearch} advancedSearchToggleRef={this.advancedSearchToggleRef} />
          {isLoading ? (
            <PageSection>
              <LoadingSpinner size={60} />
            </PageSection>
          ) : (
            this.state.showResultsSection && (
              <>
                <SearchResultsTitleBar>
                  <Text textStyle="sectionTitle" tag="h2">
                    Search Results <ResultCount>({totalResults})</ResultCount>
                  </Text>
                  <ClearSearchButton name="clearSearchButton" buttonStyle="text" onClick={this.resetSearch}>
                    Clear Search
                  </ClearSearchButton>
                </SearchResultsTitleBar>
                <Results users={users} onResetSearch={this.resetSearch} />
              </>
            )
          )}
          {totalResults > 0 && totalPages > 1 && (
            <Pagination currentPage={currentPage} pageCount={totalPages} onPageChanged={this.pageChanged} />
          )}
        </>
      </DefaultPage>
    );
  }

  private submitSearch = (criteria: UserSearchCriteria) => {
    if (criteria.dateOfBirth) {
      const date = momentUtc(criteria.dateOfBirth);
      // Date submitted to AIMS must have format YYYY-MM-DD
      criteria.dateOfBirth = date.format('YYYY-MM-DD');
    }
    this.props.dispatch(fetchUserSearchResults(criteria, 1, 10));
    this.setState({ showResultsSection: true });
  };

  private pageChanged = (pageNumber: number) => {
    this.props.dispatch(fetchUserSearchResults(this.props.criteria, pageNumber));
  };

  private resetSearch = () => {
    this.props.dispatch(userSearchReset());
    this.advancedSearchToggleRef.current.setOff();
    this.setState({ showResultsSection: false });
  };
}

const mapStateToProps = (state: ReduxStore) => {
  const { criteria, currentPage, isLoading, pageSize = 10, totalResults, users } = state.userSearch;
  return {
    criteria,
    currentPage,
    isLoading,
    pageSize,
    totalPages: Math.ceil(totalResults / pageSize),
    totalResults,
    users,
  };
};

export default connect(mapStateToProps)(UserSearch);
