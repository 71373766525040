import React from 'react';
import { S3UploadStatus } from 'src/constants/S3UploadStatus';
import styled from 'src/theme';
import { Color, ThemeColors } from '../../theme/primaryTheme';
import { ColorKeys } from '../../theme/theme';
import { CreditActivityAttachment } from '../../types';
import Button from '../Button';
import Icon from '../Icon';
import ExternalLink from '../Link/ExternalLink';
import LoadingSpinner from '../LoadingSpinner';
import Text from '../Text';

interface Props {
  status: CreditActivityAttachment['status'];
  fileName: CreditActivityAttachment['fileName'];
  signedUrlForDownload?: CreditActivityAttachment['signedUrlForDownload'];
  loading?: boolean;
  onRemove?: () => void;
  displayIcon?: boolean;
  as?: keyof JSX.IntrinsicElements;
  truncateLink?: boolean;
}

const FileIcon = ({ status = S3UploadStatus.PENDING, loading = false }) => {
  const renderIcon = () => {
    if (status === S3UploadStatus.FAILED) {
      return <WarningIcon name="warning" fill={ThemeColors[Color.Error]} width={16} height={16} viewBox="0 0 86 75" />;
    }

    if (loading) {
      return <LoadingSpinner size={16} />;
    }

    return <Icon name="file" width={14} height={16} viewBox="0 0 12.6 15" />;
  };

  return <ItemIcon>{renderIcon()}</ItemIcon>;
};

const WarningIcon = styled(Icon)`
  display: flex;
  align-items: center;
`;

const ProofDocumentItem: React.FunctionComponent<Props> = ({
  signedUrlForDownload = null,
  loading = false,
  status,
  fileName,
  onRemove,
  displayIcon = true,
  as = 'li',
  truncateLink = false,
}) => {
  const removeAttachment = () => {
    if (typeof onRemove === 'function') onRemove();
  };

  return (
    <StyledItem as={as}>
      {displayIcon && <FileIcon status={status} loading={loading} />}
      {status === S3UploadStatus.FAILED || signedUrlForDownload === null ? (
        <Text tag="span" color={status === S3UploadStatus.FAILED ? Color.Error : Color.Secondary} truncate={truncateLink}>
          {status === S3UploadStatus.FAILED
            ? `There was an error uploading this document, please try again later: "${fileName}"`
            : fileName}
        </Text>
      ) : (
        <ExternalLink href={signedUrlForDownload} target="_blank" rel="noopener nofollow" truncate={truncateLink}>
          {fileName}
        </ExternalLink>
      )}

      {onRemove && status === S3UploadStatus.COMPLETE && (
        <RemoveButton type="button" buttonStyle="toggle" color={Color.Border as ColorKeys} onClick={removeAttachment} title="remove file">
          <Icon name={'minusCircle'} width={12} height={12} viewBox="0 0 12 12" />
        </RemoveButton>
      )}
    </StyledItem>
  );
};

const StyledItem = styled.li`
  display: flex;
  align-items: center;

  & + & {
    margin-top: 0.8rem;
  }

  & > a {
    padding-bottom: 0; // remove from ExternalLink
  }
`;

const RemoveButton = styled(Button)`
  margin-left: 0.5rem;
`;

const ItemIcon = styled.span`
  margin-right: 0.6em;
`;

export default ProofDocumentItem;
