import React from 'react';
import { CreditClaimStatusValues } from 'src/constants/ApplicationStatus';
import { PageSection, PageSectionTitle } from '../../layouts/PageStructure';
import { ExternalCreditEarner } from '../../types';
import ApplicationTable from '../ApplicationTable';
import Text from '../Text';

interface Props {
  user: ExternalCreditEarner;
}

const UserApplications: React.FunctionComponent<Props> = ({ user }) => {
  return (
    <PageSection>
      <PageSectionTitle>
        <Text tag="h3" bold={true} textStyle="sectionTitle">
          Applications
        </Text>
      </PageSectionTitle>
      <ApplicationTable userId={user.id} statusFilters={CreditClaimStatusValues} />
    </PageSection>
  );
};

export default UserApplications;
