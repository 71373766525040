import React from 'react';
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';
import styled from '../../theme';
import { LinkProps, linkStyles, rootStyles } from './styles';

type InternalLinkProps = LinkProps & RouterLinkProps;

// Ignores the linkStyle prop
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Link = styled(({ linkStyle, ...rest }) => <RouterLink {...rest} />)<InternalLinkProps>`
  ${rootStyles};
  border-bottom: ${({ border }) => (border ? '1px solid #8DA3B5' : 'none')};
  font-weight: ${({ bold }) => (bold ? '700' : '400')};

  ${({ linkStyle }) => (linkStyle ? linkStyles[linkStyle] : '')}
`;

export default Link;
