import React from 'react';
import checkmarkIcon from '../../assets/images/checkmark.svg';
import styled from '../../theme';
import { themeColor } from '../../theme/helpers';
import Button from '../Button';
import Text from '../Text';

const SuccessView = styled.div`
  text-align: center;
`;

const SuccessIcon = styled.span`
  display: inline-block;
  width: 6.4rem;
  height: 6.4rem;
  border: 2px solid ${themeColor('accent')};
  border-radius: 50%;
  margin-bottom: 2.4rem;
  background: url(${checkmarkIcon}) no-repeat center center;
`;

const SuccessHeadline = styled.div`
  margin-bottom: 1.6rem;
`;

const SuccessFooter = styled.div`
  margin-top: 3.6rem;
`;

const Success: React.FunctionComponent<any> = ({ onButtonClick }) => {
  return (
    <SuccessView>
      <SuccessIcon />
      <SuccessHeadline>
        <Text textStyle="headline">Thank you!</Text>
      </SuccessHeadline>
      <Text>Your changes have been saved.</Text>
      <SuccessFooter>
        <Button onClick={onButtonClick} buttonStyle="text" data-type-id="backToSite">
          Back to site
        </Button>
      </SuccessFooter>
    </SuccessView>
  );
};

export default Success;
