import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'src/theme';
import { themeBreakpoint } from 'src/theme/helpers';
import { LinkStyle } from '../../constants/LinkStyle';
import { isCreditProcessor } from '../../selectors/user';
import Icon from '../Icon';
import Link from '../Link';
import ExternalLink from '../Link/ExternalLink';
import MobileDisclaimer from './MobileDisclaimer';

interface Props {
  to: string;
}

const NewApplicationLink: React.FunctionComponent<Props> = ({ to }) => {
  const isProcessor = useSelector(isCreditProcessor);

  const svgStyles = {
    marginRight: '0.75em',
    position: 'relative',
    top: '0.125em',
  };

  const renderBody = () => {
    return (
      <>
        <DesktopOnlyIcon name={'plusCircle'} width={20} height={20} viewBox="0 0 12 12" style={svgStyles} />
        New Application
        <MobileDisclaimer />
      </>
    );
  };

  if (['staging', 'production'].includes(process.env.REACT_APP_ENV) && !isProcessor) {
    return (
      <ExternalLink href={process.env.REACT_APP_NEW_APPLICATION_LINK} data-test-id="NewApplicationLink" linkStyle={LinkStyle.NavLink}>
        {renderBody()}
      </ExternalLink>
    );
  }

  return (
    <Link to={to} data-test-id="NewApplicationLink" linkStyle={LinkStyle.NavLink}>
      {renderBody()}
    </Link>
  );
};

const DesktopOnlyIcon = styled(Icon)`
  @media (max-width: ${themeBreakpoint('medium')}) {
    display: none;
  }
`;

export default NewApplicationLink;
