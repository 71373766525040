import _ from 'lodash';
import moment from 'moment';
import { AbmsActivitySubType } from 'src/constants/AbmsActivitySubType';
import { ActivityType } from 'src/constants/ActivityType';
import { emptyCellCharacter, LEGACY_TEACHING_YEAR_LIMIT } from 'src/constants/General';
import { initialActivityValue } from '../components/ActivityDetails';
import { ActivityStatus } from '../constants/ActivityStatus';
import { LearnerActivitiesById } from '../ducks/types';
import { CreditActivity } from '../types';
import { nullifyEmptyValues } from './cleanData';
import { getActivityDoi } from './getActivityDoi';

type ActivitiesByType = { [activityType in ActivityType]: CreditActivity[] };

export const groupActivitiesByType = (activities: CreditActivity[]): Partial<ActivitiesByType> =>
  activities.reduce((activitiesByType: Partial<ActivitiesByType>, activity: CreditActivity): Partial<ActivitiesByType> => {
    const activityType = activity.activityTypeCode;
    if (activitiesByType[activityType] === undefined) {
      activitiesByType[activityType] = [activity];
    } else {
      activitiesByType[activityType].push(activity);
    }
    return activitiesByType;
  }, {});

export const removeInvalidActivities = (activities: CreditActivity[]): CreditActivity[] => {
  return activities.filter((activity: CreditActivity) => activity.status !== ActivityStatus.Invalid);
};

export const getActivityTypeCreditTotal = (activities: CreditActivity[]): number => {
  const noInvalidActivities = removeInvalidActivities(activities);
  return noInvalidActivities.reduce((tally: number, activity: CreditActivity): number => {
    const credits = Number(activity.numberOfCredits);
    return tally + (credits || 0);
  }, 0);
};

const getActivitiesByStatus = (statuses: ActivityStatus[], activities: CreditActivity[]): CreditActivity[] => {
  return activities.filter((activity: CreditActivity) => statuses.includes(activity.status));
};

export const getValidActivities = (activities: CreditActivity[]): CreditActivity[] => {
  return getActivitiesByStatus([ActivityStatus.Valid], activities);
};

export const getFollowUpActivities = (activities: CreditActivity[]): CreditActivity[] => {
  return getActivitiesByStatus([ActivityStatus.FollowUp], activities);
};

export const getPendingActivities = (activities: CreditActivity[]): CreditActivity[] => {
  return getActivitiesByStatus([ActivityStatus.Pending], activities);
};

export const getInvalidActivities = (activities: CreditActivity[]): CreditActivity[] => {
  return getActivitiesByStatus([ActivityStatus.Invalid], activities);
};

const getValidAndPendingActivities = (activities: CreditActivity[]): CreditActivity[] => {
  return getActivitiesByStatus([ActivityStatus.Valid, ActivityStatus.Pending], activities);
};

const getCheckedActivities = (activities: CreditActivity[]): CreditActivity[] => {
  return activities.filter((activity: CreditActivity) => activity.checked);
};

export const calculateCredits = (activities: CreditActivity[]): number => {
  return activities.reduce((count: number, activity: CreditActivity) => count + Number(activity.numberOfCredits), 0);
};

export const calculateCheckedCredits = (activities: CreditActivity[]): number => {
  return calculateCredits(getCheckedActivities(activities));
};

export const calculateValidAndPendingCredits = (activities: CreditActivity[]): number => {
  return calculateCredits(getValidAndPendingActivities(activities));
};

export const transformCreditActivity = (activity: CreditActivity): CreditActivity => {
  const { activityTypeCode, doi, activitySubTypeCode } = activity;

  const transformedCreditActivity = nullifyEmptyValues({
    ..._.pick(activity, Object.keys(initialActivityValue)),
    id: activity.id,
  }) as CreditActivity;

  if (!doi) {
    transformedCreditActivity.doi = getActivityDoi(activity);
  }

  // Convert "string-ified" booleans to actual booleans
  // TODO: Will remove once we delete this column from table. See https://www.pivotaltracker.com/story/show/184890428
  transformedCreditActivity.isContinuousCertificationAssessment =
    activityTypeCode === ActivityType.Abms && activitySubTypeCode === AbmsActivitySubType.CONTINUOUS_ASSESSMENT;

  return transformedCreditActivity;
};

export const generateActivitiesFrom = (
  transcript: LearnerActivitiesById,
  claimActivities?: CreditActivity[]
): CreditActivity[] | undefined => {
  return (
    transcript &&
    Object.values(transcript).reduce((activities, learnerActivity) => {
      return [
        ...activities,
        {
          learnerActivityId: learnerActivity.id,
          doi: learnerActivity.doi,
          accreditor: learnerActivity.accreditor,
          activityTitle: learnerActivity.courseTitle,
          activityEndDate: learnerActivity.activityEndDate,
          numberOfCredits: learnerActivity.praCreditsClaimed,
          activityTypeCode: ActivityType.SelfReported,
          status: ActivityStatus.Valid,
          checked:
            claimActivities?.length > 0 ? !!claimActivities.find((activity) => activity.learnerActivityId === learnerActivity.id) : true,
        } as CreditActivity,
      ];
    }, [])
  );
};

export const renderActivityTitle = (activity: CreditActivity) => {
  const activityTitlesByType = {
    [ActivityType.Abms]: activity.board,
    [ActivityType.Article]: activity.activityTitle,
    [ActivityType.Poster]: activity.presentationTitle,
    [ActivityType.Teaching]: activity.activityTitle,
    [ActivityType.Medical]: `${activity.school} - ${activity.program}`,
    [ActivityType.Acgme]: activity.program,
    [ActivityType.ICC]: activity.conferenceTitle,
  };

  return activityTitlesByType[activity.activityTypeCode] ?? emptyCellCharacter;
};

export const canRequestCreditsForTeachingActivity = (activity: Partial<CreditActivity>): boolean => {
  if (activity.activityTypeCode !== ActivityType.Teaching) {
    return false;
  }
  const activityEndDate = moment(activity.activityEndDate);
  return activityEndDate.isValid() && activityEndDate.year() > LEGACY_TEACHING_YEAR_LIMIT;
};

interface ActivityIsEditableArgs {
  activity: Partial<CreditActivity>;
  claimIsCompleted: boolean;
  claimIsEditable: boolean;
  isProcessor: boolean;
}

export const creditActivityIsEditable = (args: ActivityIsEditableArgs): boolean => {
  const { isProcessor, claimIsCompleted, claimIsEditable, activity } = args;
  const editableActivityStatuses = isProcessor ? Object.values(ActivityStatus) : [ActivityStatus.FollowUp, ActivityStatus.Pending];

  // CPs can still edit activities for rejected claims
  return (
    ((!claimIsCompleted && isProcessor) || claimIsEditable) &&
    !activity.learnerActivityId &&
    editableActivityStatuses.includes(activity?.status)
  );
};
