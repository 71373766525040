import React from 'react';
import ExternalLink from '../../components/Link/ExternalLink';
import styled from '../../theme';
import { ExternalCreditEarner } from '../../types';

const Root = styled.span`
  display: flex;

  span + span {
    &::before {
      content: '·';
      margin: 0 0.5em;
      pointer-events: none;
    }
  }
`;

interface Props {
  user: ExternalCreditEarner;
}

const UserSubtitle: React.FunctionComponent<Props> = ({ user }) => {
  const { city = '', stateCode = '', phone = '', emails = [] } = user;
  const place = city ? `${city}${stateCode ? `, ${stateCode}` : ''}` : '';
  const email = emails[0] || '';

  if (!place && !phone && !email) {
    return null;
  }

  return (
    <Root>
      {place && <span>{place}</span>}
      {phone && (
        <span>
          <ExternalLink href={`tel:${phone}`}>{phone}</ExternalLink>
        </span>
      )}
      {email && (
        <span>
          <ExternalLink href={`mailto:${email}`}>{email}</ExternalLink>
        </span>
      )}
    </Root>
  );
};

export default UserSubtitle;
