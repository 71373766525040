import React from 'react';
import { Color, ThemeColors } from '../../theme/primaryTheme';

const getPath = (name: string, fill: string) => {
  switch (name) {
    case 'angleArrowUp':
      return <path fill={fill} d="M13.7375 9.49207L8 3.99606L2.2625 9.49206L0.5 7.80006L8 0.600065L15.5 7.80007L13.7375 9.49207Z" />;
    case 'angleArrowDown':
      return <path fill={fill} d="M2.2625 0.607788L8 6.10379L13.7375 0.607788L15.5 2.29979L8 9.49979L0.5 2.29979L2.2625 0.607788Z" />;
    case 'plusCircle':
      return (
        <path
          fill={fill}
          d="M6,0a6,6,0,1,0,6,6A6,6,0,0,0,6,0ZM8.69,6.48a.06.06,0,0,1-.06.06H6.39V8.78a0,0,0,0,1,0,.05H5.66a0,0,0,0,1,0-.05V6.54H3.37a.06.06,0,0,1-.06-.06V5.81a.06.06,0,0,1,.06-.06H5.61V3.51a0,0,0,0,1,0,0h.68a0,0,0,0,1,0,0V5.75H8.63a.06.06,0,0,1,.06.06Z"
        />
      );
    case 'minusCircle':
      return (
        <path
          fill={fill}
          d="M6,0a6,6,0,1,0,6,6A6,6,0,0,0,6,0ZM8.69,6.48a.06.06,0,0,1-.06.06H3.37a.06.06,0,0,1-.06-.06V5.81a.06.06,0,0,1,.06-.06H8.63a.06.06,0,0,1,.06.06Z"
        />
      );
    case 'file':
      return <path fill={fill} d="M8.1,0H0v15h12.6V4.7L8.1,0z M8.2,2.3l2.3,2.4H8.2V2.3z M1.5,13.5v-12h5.2v4.8h4.3v7.2H1.5z" />;
    case 'print':
      return (
        <g>
          <path d="M42,43H18c-0.553,0-1,0.447-1,1s0.447,1,1,1h24c0.553,0,1-0.447,1-1S42.553,43,42,43z" />
          <path d="M42,48H18c-0.553,0-1,0.447-1,1s0.447,1,1,1h24c0.553,0,1-0.447,1-1S42.553,48,42,48z" />
          <g>
            <path d="M51,17V0H9v17H0v34h6v3h3v6h42v-6h3v-3h6V17H51z M11,2h38v15H11V2z M49,54v4H11v-4V37h38V54z M50,32c-2.757,0-5-2.243-5-5s2.243-5,5-5s5,2.243,5,5S52.757,32,50,32z" />
            <circle cx="50" cy="27" r="3" />
          </g>
        </g>
      );

    case 'trashcan':
      return (
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.07143 0.666667H9.42857V2H0V0.666667H2.35714L3.03061 0H6.39796L7.07143 0.666667ZM2.01993 12C1.27911 12 0.672991 11.4 0.672991 10.6666V2.66664H8.75462V10.6666C8.75462 11.4 8.1485 12 7.40768 12H2.01993Z"
          fill={fill}
        />
      );
    case 'arrowLeft':
      return <path fillRule="evenodd" clipRule="evenodd" d="M0 4l5.317-4v3.095H16.8v1.81H5.317V8L0 4z" fill={fill} />;
    case 'arrowRightCircle':
      return (
        <path
          id="Combined-Shape"
          className="cls-1"
          fill={fill}
          d="M11,21A10,10,0,1,1,21,11,10,10,0,0,1,11,21Zm4.84-10L12.78,8.74v1.75H6.16v1h6.62v1.75Z"
        />
      );
    case 'arrowsCircle':
      return (
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill={fill}
          d="M6.644 2.534v.977l2.822-1.63L6.644.252v1.26A7.557 7.557 0 0 0 4.53 15.62l.467-.884A6.558 6.558 0 0 1 6.644 2.534zM9.466 16.487v1.26L6.644 16.12l2.822-1.63v.977a6.558 6.558 0 0 0 1.648-12.202l.467-.884a7.558 7.558 0 0 1-2.115 14.107z"
        />
      );
    case 'checkmark':
      return (
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.6388 0.209326C13.0597 0.533147 13.1218 1.1165 12.7774 1.51228L5.68646 9.6604C5.50509 9.86882 5.2362 9.99267 4.94988 9.99968C4.66356 10.0067 4.38817 9.89617 4.19551 9.69692L0.256126 5.62286C-0.109751 5.24447 -0.080091 4.65887 0.322374 4.31489C0.724838 3.9709 1.3477 3.99878 1.71358 4.37717L4.88497 7.65699L11.2529 0.339622C11.5973 -0.0561595 12.2178 -0.114495 12.6388 0.209326Z"
          fill={fill}
        />
      );
    case 'flagOutline':
      return (
        <path
          fill={fill}
          d="M9.7,1L10,2.3l0.2,0.8h0.8H16v9.6H9.4l-0.3-1.3L9,10.6H8.2H3.1h-1v1V18H1V1H9.7 M10.5,0h-0.4H0.5H0v0.5v18V19
	h0.5h2.1h0.5v-0.5v-6.9h5.1l0.3,1.7l0.1,0.4H9h7.5H17v-0.5V2.6V2.1h-0.5h-5.6l-0.3-1.7L10.5,0L10.5,0z"
        />
      );
    case 'flagFill':
      return (
        <polygon
          fill={fill}
          points="3.1,18.5 3.1,11.6 8.2,11.6 8.5,13.3 8.6,13.7 9,13.7 16.5,13.7 17,13.7 17,13.2 17,2.6 17,2.1
	16.5,2.1 10.9,2.1 10.6,0.4 10.5,0 10.1,0 0.5,0 0,0 0,0.5 0,18.5 0,19 0.5,19 2.6,19 3.1,19 "
        />
      );
    case 'dotsCircle':
      return (
        <>
          <path
            fill={fill}
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 24c6.627 0 12-5.373 12-12S18.627 0 12 0 0 5.373 0 12s5.373 12 12 12z"
          />
          <path fill="#fff" d="M6 14a2 2 0 1 0 0-4 2 2 0 0 0 0 4zM12 14a2 2 0 1 0 0-4 2 2 0 0 0 0 4zM18 14a2 2 0 1 0 0-4 2 2 0 0 0 0 4z" />
        </>
      );
    case 'pencil':
      return (
        <path
          fill={fill}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.838 1.462a.553.553 0 0 1 0 .784L8.822 3.262 6.739 1.179 7.755.162a.553.553 0 0 1 .784 0l1.3 1.3zM0 10V7.917l6.144-6.144 2.083 2.083L2.083 10H0z"
        />
      );
    case 'clock':
      return (
        <>
          <path
            d="M8.99169 0.666687C4.39169 0.666687 0.666687 4.40002 0.666687 9.00002C0.666687 13.6 4.39169 17.3334 8.99169 17.3334C13.6 17.3334 17.3334 13.6 17.3334 9.00002C17.3334 4.40002 13.6 0.666687 8.99169 0.666687ZM9.00002 15.6667C5.31669 15.6667 2.33335 12.6834 2.33335 9.00002C2.33335 5.31669 5.31669 2.33335 9.00002 2.33335C12.6834 2.33335 15.6667 5.31669 15.6667 9.00002C15.6667 12.6834 12.6834 15.6667 9.00002 15.6667Z"
            fill={fill}
          />
          <path d="M9.41669 4.83331H8.16669V9.83331L12.5417 12.4583L13.1667 11.4333L9.41669 9.20831V4.83331Z" fill={fill} />
        </>
      );
    case 'caretDown':
      return <path fill={fill} d="M0 0L5 5L10 0H0Z" />;
    case 'caretUp':
      return <path fill={fill} d="M10 5L5 -4.37114e-07L0 5L10 5Z" />;
    case 'sort':
      return (
        <>
          <path fill={fill} d="M2.05 7.45l4 3.94L10 7.45z" />
          <path fill={fill} d="M2.05 4.55L6 .61l4 3.94z" />
        </>
      );
    case 'fillCaretDown':
      return (
        <>
          <path fill={ThemeColors[Color.Secondary]} d="M2.05 7.45l4 3.94L10 7.45z" />
          <path fill={fill} d="M2.05 4.55L6 .61l4 3.94z" />
        </>
      );
    case 'fillCaretUp':
      return (
        <>
          <path fill={fill} d="M2.05 7.45l4 3.94L10 7.45z" />
          <path fill={ThemeColors[Color.Secondary]} d="M2.05 4.55L6 .61l4 3.94z" />
        </>
      );
    case 'download':
      return <path d="M10,6h4l-7,7L0,6h4V0h6V6z M0,17v-2h14v2H0z" fill={fill} />;
    case 'upload':
      return <path d="M39.3,61.1h17.5V43.6h11.7L48,23.2L27.6,43.6h11.7V61.1z M27.6,67h40.8v5.8H27.6V67z" fill={fill} />;
    case 'padlock':
      return (
        <path
          d="M11.1 7H4.9V5c0-1.7 1.4-3.1 3.1-3.1s3.1 1.4 3.1 3.1v2zM8 16c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm6-9h-1V5c0-2.8-2.2-5-5-5S3 2.2 3 5v2H2C.9 7 0 7.9 0 9v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V9c0-1.1-.9-2-2-2z"
          fillRule="evenodd"
          clipRule="evenodd"
          fill={fill}
        />
      );
    case 'warning':
      return (
        <>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M49.1941 3.51171L85.0311 64.4649C87.784 69.1472 84.3429 75 78.8371 75H7.16295C1.65712 75 -1.78402 69.1472 0.968895 64.4649L36.8059 3.51171C39.5589 -1.17057 46.4411 -1.17057 49.1941 3.51171ZM46.5395 5.01672C44.9664 2.34114 41.0336 2.34114 39.4605 5.01672L3.62349 65.9699C2.0504 68.6455 4.01676 71.99 7.16295 71.99H78.8371C81.9832 71.99 83.9496 68.6455 82.3765 65.9699L46.5395 5.01672Z"
            fill={fill}
          />
          <path d="M46.9394 35.464V22H39V35.464L40.697 52.1425H45.2424L46.9394 35.464ZM47 65V56.9337H39V65H47Z" fill={fill} />
        </>
      );
    case 'hamburger':
      return (
        <>
          <rect width="22" height="2.72727" rx="0.1" fill={fill} />
          <rect y="7" width="22" height="2.72727" rx="0.1" fill={fill} />
          <rect y="14" width="22" height="2.72727" rx="0.1" fill={fill} />
        </>
      );
    case 'close':
      return (
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.03028 8.44245L12.6484 14.0605L14.0605 12.6484L8.44245 7.03028L14.0605 1.41218L12.6484 1.28988e-05L7.03028 5.61811L1.41217 0L0 1.41217L5.61811 7.03028L0 12.6484L1.41217 14.0606L7.03028 8.44245Z"
          fill={fill}
        />
      );
    case 'successIndicator':
      return (
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.5 9C16.5 13.1421 13.1421 16.5 9 16.5C4.85786 16.5 1.5 13.1421 1.5 9C1.5 4.85786 4.85786 1.5 9 1.5C13.1421 1.5 16.5 4.85786 16.5 9ZM18 9C18 13.9706 13.9706 18 9 18C4.02944 18 0 13.9706 0 9C0 4.02944 4.02944 0 9 0C13.9706 0 18 4.02944 18 9ZM8.68579 12.2922L13.0058 6.29215L12.1943 5.70785L8.225 11.2207L5.76072 8.65373L5.03933 9.34627L7.91933 12.3463L8.33504 12.7793L8.68579 12.2922Z"
          fill={fill}
        />
      );
    case 'failureIndicator':
      return (
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.2 9C16.2 12.9765 12.9765 16.2 9 16.2C5.02355 16.2 1.8 12.9765 1.8 9C1.8 5.02355 5.02355 1.8 9 1.8C12.9765 1.8 16.2 5.02355 16.2 9ZM18 9C18 13.9706 13.9706 18 9 18C4.02944 18 0 13.9706 0 9C0 4.02944 4.02944 0 9 0C13.9706 0 18 4.02944 18 9ZM6.38152 5.49561L9.03611 8.1502L11.6902 5.49607L12.575 6.38086L9.9209 9.03498L12.575 11.6891L11.6903 12.5739L9.03611 9.91977L6.38151 12.5744L5.49672 11.6896L8.15132 9.03498L5.49674 6.3804L6.38152 5.49561Z"
          fill={fill}
        />
      );
    default:
      return <path />;
  }
};

export default getPath;
