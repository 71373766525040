export enum ActivityType {
  Abms = 'abms',
  Article = 'articles',
  Poster = 'posters',
  Teaching = 'teaching',
  Medical = 'medical',
  Acgme = 'acgme',
  ICC = 'ICC',
  PRA = 'pra',
  SelfReported = 'self_reported',
}

export enum ActivityAccredidationType {
  Category1 = 'AMA PRA Category 1 Credits (TM)',
  Category2 = 'AMA PRA Category 2 Credits (TM)',
}

const ActivityTypeLabels = {
  [ActivityType.Abms]: 'ABMS member board certification and MOC®',
  [ActivityType.Article]: 'Published Article',
  [ActivityType.Poster]: 'Poster Presentation',
  [ActivityType.Teaching]: 'Teaching in Live CME',
  [ActivityType.Medical]: 'Medically Related Advanced Degree',
  [ActivityType.ICC]: 'Credit Conversion',
  [ActivityType.Acgme]: 'ACGME Accredited Residency and Fellowship Program',
};

export const directCreditActivityTypeOptions = [
  ActivityType.Abms,
  ActivityType.Article,
  ActivityType.Poster,
  ActivityType.Teaching,
  ActivityType.Medical,
  ActivityType.Acgme,
].map((type: ActivityType) => {
  return {
    label: ActivityTypeLabels[type],
    value: type,
  };
});

export const renderActivityTypeLabel = (activityType: ActivityType) => {
  return ActivityTypeLabels[activityType];
};
