export enum IdentifierSource {
  Advantage = 'ADVANTAGE',
  IngestedActivity = 'INGESTED_ACTIVITY',
  SSO = 'SSO',
  ManualEntry = 'MANUAL_ENTRY',
  AIMS = 'AIMS',
  Unknown = 'UNKNOWN',
  Test = 'TEST',
  DataConversion = 'DATA_CONVERSION',
}
