export enum ModalType {
  SaveApplication = 'saveApplication',
  DeleteApplication = 'deleteApplication',
  RejectApplication = 'rejectApplication',
  ApplicationNotes = 'applicationNotes',
  PaymentFailed = 'paymentFailed',
  DeleteActivity = 'deleteActivity',
  ResumePraApplication = 'resumePraApplication',
  ClaimCredits = 'claimCredits',
}
