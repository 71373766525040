import { FormikValues } from 'formik';
import React from 'react';
import { connect } from 'react-redux';
import { ActivityType } from '../../constants/ActivityType';
import { CreditClaimStatus } from '../../constants/ApplicationStatus';
import { ReduxStore } from '../../ducks';
import { claimDataSelector } from '../../selectors/claims';
import { isCreditProcessor } from '../../selectors/user';
import { Board, CreditClaim } from '../../types';
import AbmsFields from './AbmsFields';
import AcgmeFields from './AcgmeFields';
import ArticleFields from './ArticleFields';
import IccFields from './IccFields';
import MedDegreeFields from './MedDegreeFields';
import PosterFields from './PosterFields';
import TeachingFields from './TeachingFields';

export interface GenericActivityProps {
  baseName: string;
  isProcessor: boolean;
  values: FormikValues;
  claimId?: number;
  claimStatus?: CreditClaimStatus;
}

interface Props extends GenericActivityProps {
  claim: Partial<CreditClaim>;
  selectedType: ActivityType;
  abmsBoards?: Board[];
}

const ActivityFields: React.FunctionComponent<Props> = ({ selectedType, claim, ...rest }) => {
  const Component = {
    [ActivityType.Teaching]: TeachingFields,
    [ActivityType.Medical]: MedDegreeFields,
    [ActivityType.Poster]: PosterFields,
    [ActivityType.Article]: ArticleFields,
    [ActivityType.Abms]: AbmsFields,
    [ActivityType.Acgme]: AcgmeFields,
    [ActivityType.ICC]: IccFields,
  }[selectedType];

  if (!Component || !claim) {
    return null;
  }

  return <Component claimId={claim.id} claimStatus={claim.status} {...rest} />;
};

const mapStateToProps = (state: ReduxStore) => ({
  isProcessor: isCreditProcessor(state),
  claim: claimDataSelector(state),
});

export default connect(mapStateToProps)(ActivityFields);
