import { emptyCellCharacter } from '../constants/General';
import { PraClaimMethod } from '../constants/PraClaimMethod';

/**
 * Gets a user friendly award type string based on commendation,
 * duration and claim method
 *
 * @param {boolean} withCommendation
 * @param {number} certificateDuration
 * @param {PraClaimMethod} claimMethod
 * @return {string}
 */
export const getAwardTypeString: (withCommendation: boolean, certificateDuration: number, claimMethod: PraClaimMethod) => string = (
  withCommendation,
  certificateDuration,
  claimMethod
) => {
  switch (claimMethod) {
    case PraClaimMethod.ABMS:
      return '3 Year Standard Certificate, ABMS Certification';
    case PraClaimMethod.Reciprocity:
      return '3 Year Standard Certificate, Reciprocity Agreement';
    case PraClaimMethod.Residency:
      return `${certificateDuration} Year Standard Certificate, Completing Residency or Fellowship`;
    case PraClaimMethod.PreviousCME:
      if (withCommendation) {
        return `${certificateDuration} Year Certificate with Commendation`;
      }

      return `${certificateDuration} Year Standard Certificate`;
    default:
      return emptyCellCharacter;
  }
};
