import { Field as FormikField, FieldProps as FormikFieldProps, getIn } from 'formik';
import React, { useState } from 'react';
import { IdentifierSource } from '../../constants/IdentifierSource';
import ErrorMessage from '../ErrorMessage';
import { FieldLabel } from '../Label';
import Input from './Controls/Input';
import LockedInput from './Controls/LockedInput';
import Select, { Option } from './Controls/Select';
import { FieldWrap, StyledField } from './styles';

interface ControlProps extends React.InputHTMLAttributes<HTMLInputElement> {
  options?: Option[];
  defaultLabel?: string;
  type?: string;
  disabled?: boolean;
  title?: string;
  description?: string | React.ReactNode;
  variant?: 'small' | 'default';
}

export type FieldOnChange = (evt: React.ChangeEvent<any>, formikProps?: FormikFieldProps) => void;

interface LockedProps {
  value: any;
}

export interface FieldProps extends React.InputHTMLAttributes<HTMLInputElement> {
  name: string;
  component?: React.ComponentType;
  lockedComponent?: React.ComponentType<LockedProps>;
  options?: Option[];
  label?: string;
  type?: string;
  hasFocus?: boolean;
  hasError?: boolean;
  controlProps?: ControlProps;
  defaultLabel?: string;
  fieldBorder?: boolean;
  inline?: boolean;
  locked?: boolean;
  onChange?: FieldOnChange;
  showErrorMessage?: boolean;
}

export const Field: React.FunctionComponent<FieldProps> = ({
  name,
  value,
  onChange,
  onFocus,
  onBlur,
  required = false,
  disabled = false,
  label,
  type = 'text',
  placeholder,
  maxLength,
  hasError = false,
  controlProps,
  component,
  lockedComponent,
  fieldBorder = true,
  inline = false,
  locked = false,
  className,
}) => {
  const Component = component || Input;
  const LockedComponent = locked && lockedComponent ? lockedComponent : LockedInput;
  const [hasFocus, setHasFocus] = useState(false);

  const handleFocus = (event: any) => {
    setHasFocus(true);
    if (onFocus) {
      onFocus(event);
    }
  };

  const handleBlur = (event: any) => {
    setHasFocus(false);
    if (onBlur) {
      onBlur(event);
    }
  };

  const labelOrValue = (value: any) => {
    const selectOptions = controlProps && controlProps.options;

    if (component === Select && selectOptions) {
      const selectedOption = selectOptions.find((option) => option && option.value === value);
      return (selectedOption && selectedOption.label) || '';
    }

    return value;
  };

  if (type === 'hidden') {
    return <input type="hidden" value={value} name={name} />;
  }

  return (
    <FieldWrap className={className} inline={inline}>
      {label && <FieldLabel hasError={hasError} required={required} locked={locked} text={label} htmlFor={name} />}
      <StyledField type={type} border={fieldBorder} hasError={hasError} hasFocus={hasFocus}>
        {locked ? (
          <LockedComponent data-test-id={`${name}-locked`} data-testid={`${name}-locked`} value={labelOrValue(value)} {...controlProps} />
        ) : (
          <Component
            id={name}
            name={name}
            value={value}
            onChange={onChange}
            onBlur={handleBlur}
            onFocus={handleFocus}
            placeholder={placeholder}
            maxLength={maxLength}
            hasError={hasError}
            disabled={disabled}
            {...controlProps}
          />
        )}
      </StyledField>
    </FieldWrap>
  );
};

export default function ({ name, onChange, locked = false, showErrorMessage = true, ...rest }: FieldProps) {
  return (
    <FormikField name={name}>
      {({ field, form, meta }: FormikFieldProps) => {
        const hasError = getIn(form.touched, name) && getIn(form.errors, name);
        const handleChange = (evt: React.ChangeEvent<any>) => {
          if (onChange) {
            onChange(evt, { field, form, meta });
          }
          return field.onChange(evt);
        };

        return (
          <>
            <Field
              {...field}
              onChange={handleChange}
              {...rest}
              locked={locked || getIn(form.values, `sources.${name}`) === IdentifierSource.AIMS}
              hasError={hasError}
            />

            {showErrorMessage && <ErrorMessage name={name} />}
          </>
        );
      }}
    </FormikField>
  );
}
