import { getIn } from 'formik';
import React from 'react';
import { activityContent } from 'src/content/activities';
import { ActivityType } from '../../constants/ActivityType';
import { Credits } from '../../constants/Credits';
import { Color } from '../../theme/primaryTheme';
import Field from '../Field';
import DatePickerField from '../Field/DatePickerField';
import { FieldColumn, FieldRow } from '../FieldRow';
import OptionGroup from '../OptionGroup';
import ProofDocumentUpload from '../ProofDocumentUpload';
import Text, { TextStyle } from '../Text';
import { GenericActivityProps } from './index';
import ProcessorFields from './ProcessorFields';
import Requirements from './Requirements';
import { TotalCreditsRow } from './styles';

const numOfYearsOptions = [
  {
    label: '1 year',
    value: '1',
  },
  {
    label: '2 years',
    value: '2',
  },
  {
    label: '3 years',
    value: '3',
  },
];

const AcgmeFields: React.FunctionComponent<GenericActivityProps> = ({ baseName, values, isProcessor, claimId }) => {
  const { description, footer, fields } = activityContent[ActivityType.Acgme];
  const years = getIn(values, `${baseName}.activityUnits`);
  return (
    <>
      <Requirements content={description} />
      <FieldRow>
        <FieldColumn grow={1}>
          <Field name={`${baseName}.program`} required={true} {...fields.program} />
        </FieldColumn>
      </FieldRow>
      <FieldRow>
        <FieldColumn grow={1}>
          <Field
            type="radio"
            name={`${baseName}.activityUnits`}
            component={OptionGroup}
            fieldBorder={false}
            controlProps={{ options: numOfYearsOptions }}
            required={true}
            {...fields.activityUnits}
          />
        </FieldColumn>
      </FieldRow>
      <FieldRow even={true}>
        <FieldColumn>
          <DatePickerField name={`${baseName}.activityStartDate`} required={true} {...fields.activityStartDate} />
        </FieldColumn>
        <FieldColumn>
          <DatePickerField name={`${baseName}.activityEndDate`} required={true} {...fields.activityEndDate} />
        </FieldColumn>
      </FieldRow>
      <FieldRow>
        <FieldColumn grow={1}>
          <ProofDocumentUpload
            name={`${baseName}.creditActivityAttachments`}
            required={!isProcessor}
            claimId={claimId}
            {...fields.creditActivityAttachments}
          />
        </FieldColumn>
      </FieldRow>
      <TotalCreditsRow>
        <FieldColumn>
          <Text tag="span" textStyle={TextStyle.Medium} bold={true} color={Color.Label}>
            {footer.total}
          </Text>
          &ensp;
          <Text tag="span" textStyle={TextStyle.Medium} color={Color.Label} data-test-id="totalCredits">
            {years ? Number(years) * Credits[ActivityType.Acgme] : '—'}
          </Text>
          <Text tag="div" textStyle={TextStyle.Small}>
            <p dangerouslySetInnerHTML={{ __html: footer.disclaimer }} />
          </Text>
        </FieldColumn>
      </TotalCreditsRow>
      {isProcessor && <ProcessorFields baseName={baseName} values={values} />}
    </>
  );
};

export default AcgmeFields;
