import React from 'react';
import Toggle from 'react-toggled';
import styled from 'src/theme';
import { ThemeColors } from 'src/theme/primaryTheme';
import { PageSection, PageSectionDescription, PageSectionTitle, PageTitleIcon } from '../../layouts/PageStructure';
import Icon from '../Icon';
import Text from '../Text';

interface Props {
  title?: string | React.ReactNode;
  description?: string | React.ReactNode;
  id?: string;
  defaultOn?: boolean;
  forceOn?: boolean;
}

const ToggleablePageSection: React.FunctionComponent<Props> = ({ title, children, description, id, defaultOn = false, forceOn }) => {
  return (
    <Toggle defaultOn={defaultOn}>
      {({ on, toggle }) => (
        <PageSection data-testid={id}>
          {title && (
            <ClickablePageSectionTitle onClick={toggle}>
              <Text tag="h2" bold={true} textStyle="medium">
                {title}
              </Text>
              <PageTitleIcon buttonStyle="toggle" data-testid="page-section-toggle">
                <Icon
                  name={on || forceOn ? 'angleArrowUp' : 'angleArrowDown'}
                  width={16}
                  height={10}
                  viewBox="0 0 16 10"
                  fill={forceOn ? ThemeColors.grayLight : undefined}
                />
              </PageTitleIcon>
            </ClickablePageSectionTitle>
          )}
          <ToggleablePageSectionContent>
            {description && <PageSectionDescription>{description}</PageSectionDescription>}
            {(on || forceOn) && children}
          </ToggleablePageSectionContent>
        </PageSection>
      )}
    </Toggle>
  );
};

const ClickablePageSectionTitle = styled(PageSectionTitle)`
  cursor: pointer;
`;

const ToggleablePageSectionContent = styled.div`
  max-width: 100rem;
`;

export default ToggleablePageSection;
