import axios, { AxiosInstance } from 'axios';
import { getAccessToken } from './authentication';

export const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    Pragma: 'no-cache',
    'Cache-Control': 'no-store, no-cache, must-revalidate',
  },
});

// TODO: logging via Winston transport (adapt logging from backend)
instance.interceptors.response.use(
  // Allow any 2xx responses to pass through as-is
  (response) => response,

  // Intercept any 5xx responses and log them to Rollbar
  (error) => {
    const { response } = error;
    if (process.env.REACT_APP_ROLLBAR_ENABLED === 'true' && response && response.status >= 500) {
      Rollbar.error(`${response.status} received from ${response.config.url}`, error);
    }
    return Promise.reject(error);
  }
);

const getClient = (): AxiosInstance => {
  instance.defaults.headers.common['Authorization'] = `Bearer ${getAccessToken()}`;
  return instance;
};

export const apiRequest = {
  get: <T>(path: string, config = {}) =>
    getClient().request<T>({
      url: path,
      method: 'get',
      ...config,
    }),
  patch: (path: string, data = {}, config = {}) =>
    getClient().request({
      url: path,
      method: 'patch',
      data,
      ...config,
    }),
  post: (path: string, data = {}, config = {}) =>
    getClient().request({
      url: path,
      method: 'post',
      data,
      ...config,
    }),
  put: (path: string, data = {}, config = {}) =>
    getClient().request({
      url: path,
      method: 'put',
      data,
      ...config,
    }),
  del: (path: string, config = {}) =>
    getClient().request({
      url: path,
      method: 'delete',
      ...config,
    }),
};
