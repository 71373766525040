import { getIn } from 'formik';
import { emptyCellCharacter } from '../constants/General';
import { momentUtc } from './moment';

export const dateAccessor = (keyPath: string) => (data: any) => {
  const value = getIn(data, keyPath) || '';

  if (!value) {
    return emptyCellCharacter;
  }

  const moment = momentUtc(value);
  return moment.isValid() ? moment.format('MM/DD/YY') : emptyCellCharacter;
};
