import { AxiosResponse } from 'axios';
import { Form, Formik, FormikBag, FormikValues, getIn } from 'formik';
import qs from 'qs';
import React from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { PraClaimMethod } from 'src/constants/PraClaimMethod';
import { getClaimSubtotal } from 'src/constants/ProccesingRates';
import { errorSupportMessage } from 'src/content/errors';
import { PageTypes, pageContent } from 'src/content/pages';
import { momentUtc } from 'src/utilities/moment';
import styled from 'styled-components';
import { ActivityStatus } from '../../constants/ActivityStatus';
import { CreditClaimStatus } from '../../constants/ApplicationStatus';
import { ApplicationTypeCode } from '../../constants/ApplicationTypeCode';
import { RateCode } from '../../constants/CreditClaimRateCode';
import { PaymentCode } from '../../constants/PaymentCode';
import { Routes, buildRoute } from '../../constants/Routes';
import { ReduxStore } from '../../ducks';
import {
  CreateClaimOrderAsync,
  FetchClaimSuccessActionCreator,
  OnError,
  OnSuccess,
  UpdateClaimAsync,
  createClaimOrder,
  fetchClaimSuccess,
  updateClaim,
  updateClaimStatus,
} from '../../ducks/claims';
import ApplicationPage from '../../layouts/ApplicationPage';
import { PageFooter } from '../../layouts/PageStructure';
import {
  claimDataSelector,
  claimUserSelector,
  totalCategory1ActivityCreditsSelector,
  totalCategory2ActivityCreditsSelector,
} from '../../selectors/claims';
import { isCreditProcessor } from '../../selectors/user';
import { CreditActivity, CreditClaim, CreditClaimOrder, ExternalCreditEarner } from '../../types';
import { getFollowUpActivities, getInvalidActivities } from '../../utilities/creditActivities';
import Alert, { AlertType } from '../Alert';
import { ApplicationStepLabel } from '../ApplicationProgressBar/ApplicationProgressBar';
import Button from '../Button';
import Debug from '../Debug';
import EditButton from '../EditButton';
import FetchClaim from '../FetchClaim';
import FormSection from '../FormSection';
import ExternalLink from '../Link/ExternalLink';
import UserInfoTable from '../NewApplication/UserInfoTable';
import PraCreditSummary from '../PraActivityDetails/PraCreditSummary';
import { calculateCreditRequirementsForAwardType } from '../PraActivityDetails/creditCalculations';
import { StickyContainer } from '../StickyContainer';
import Text, { TextStyle } from '../Text';
import { CreditActivitiesSummary } from './Sections/CreditActivitiesSummary';
import PaymentInfoSection from './Sections/PaymentInfoSection';
import PraNumCopiesSection from './Sections/PraNumCopiesSection';
import { validationSchema } from './validationSchema';

const { REACT_APP_PUBLIC_URL, REACT_APP_ADVANTAGE_URL } = process.env;

interface Props extends RouteComponentProps<{ id: string; orderToken?: string }> {
  claim: CreditClaim;
  user: ExternalCreditEarner;
  updateClaim: UpdateClaimAsync;
  createClaimOrder: CreateClaimOrderAsync;
  fetchClaimSuccess: FetchClaimSuccessActionCreator;
  isProcessor: boolean;
}

const OrderDetails: React.FunctionComponent<Props> = ({
  location,
  claim = {} as CreditClaim,
  user = {} as ExternalCreditEarner,
  updateClaim,
  createClaimOrder,
  history,
  match,
  isProcessor = false,
}) => {
  const { orderToken } = match.params;
  const content = pageContent[PageTypes.OrderDetails];
  const dispatch = useDispatch();
  const totalCategory1ActivityCredits = useSelector(totalCategory1ActivityCreditsSelector);
  const totalCategory2ActivityCredits = useSelector(totalCategory2ActivityCreditsSelector);

  const [error, setError] = React.useState('');
  const [loading, setLoading] = React.useState(false);

  const billingAddress = getIn(claim, 'creditEarner.billingAddress');
  const certificatesEmail = getIn(claim, 'certificatesEmail') || getIn(user, 'emails[0]') || '';
  const methodOfPayment = getIn(claim, 'creditClaimOrder.methodOfPayment', '');
  const initialValues: Partial<CreditClaim> = {
    applicationTypeCode: claim.applicationTypeCode,
    id: claim.id,
    notes: claim.notes || '',
    rateCode: claim.rateCode || ('' as RateCode),
    paymentType: claim.paymentType || PaymentCode.CreditCard,
    paymentNote: claim.paymentNote || '',
    creditActivities: claim.creditActivities || [],
    billingAddress: claim.billingAddress || {
      Line1: user.address1 || '',
      Line2: user.address2 || '',
      City: user.city || '',
      StateCode: user.stateCode || '',
      PostalCode: user.zip || '',
    },
    praNumCopies: 0,
    certificatesEmail,
    mailingAddressIsSameAsBilling: false, // this gets overriden in PaymentInfoSection
  };

  if (claim.applicationTypeCode === ApplicationTypeCode.PRA) {
    initialValues.praMailingAddress = {
      Line1: user.address1 || '',
      Line2: user.address2 || '',
      City: user.city || '',
      StateCode: user.stateCode || '',
      PostalCode: user.zip || '',
      ...claim.praMailingAddress,
      CountryCode: claim.praMailingAddress?.CountryCode || user.countryCode || 'USA',
    };

    initialValues.praNumCopies = claim.praNumCopies || 1;
  }

  if (!claim.billingAddress && billingAddress) {
    initialValues.billingAddress = {
      Line1: billingAddress.line1 || '',
      Line2: billingAddress.line2 || '',
      City: billingAddress.city || '',
      StateCode: billingAddress.stateCode || '',
      PostalCode: billingAddress.zip || '',
    };
  }

  /**
   * Force Credit Card payment for Credit Earners
   */
  if (!isProcessor) {
    initialValues.paymentType = PaymentCode.CreditCard;
  }

  const newFinishedStatus = (status: CreditClaimStatus, orderToken: string | undefined) => {
    if (
      [CreditClaimStatus.APPROVED, CreditClaimStatus.UNSUBMITTED, CreditClaimStatus.PAYMENT_FAILED].includes(status) &&
      (orderToken || methodOfPayment)
    ) {
      return isProcessor ? CreditClaimStatus.COMPLETED : CreditClaimStatus.SUBMITTED;
    }
    return undefined;
  };
  const newProcessingStatus = (status: CreditClaimStatus) =>
    isProcessor && status === CreditClaimStatus.SUBMITTED ? CreditClaimStatus.PROCESSING : undefined;

  /**
   * When loading the claim:
   *  If there is an OrderToken parameter in the URL or the claim has a non-empty methodOfPayment
   *    AND the claim has Approved, Unsubmitted, or Payment Failed status
   *  Then
   *    If a Credit Processor is loading the claim
   *      Then the claim is set to COMPLETED
   *    Else (the Credit Earner is loading the claim)
   *      Then the claim status is set to SUBMITTED
   *  Else
   *    If a Credit Processor is loading the claim and the status is SUBMITTED
   *      Then the claim status is set to PROCESSING
   *
   *  If there was any change to the status based on the above logic
   *  Then
   *    The updated claim status is sent to the API
   *    The page redirects back to itself and strips away the OrderToken from the URL
   */
  React.useEffect(
    () => {
      const { id, status } = claim;

      if (id) {
        const newStatus = newFinishedStatus(status, orderToken) || newProcessingStatus(status);
        if (newStatus) {
          // Remove Advantage orderToken from URL on success
          const onSuccess = () => {
            setLoading(false);
            orderToken && history.replace(buildRoute(Routes.ApplicationComplete, { id }));
          };
          const onError = () => {
            setLoading(false);
            setError(`There was an issue updating your claim to be ${newStatus}.`);
          };
          setLoading(true);
          dispatch(updateClaimStatus({ claimId: id, newStatus, onSuccess, onError }));
        }
      }
    },
    [claim.id] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const applicationCost = getClaimSubtotal(claim.rateCode, user.amaMember, claim);

  const areActivitiesValidOrInvalid = (activities: CreditActivity[]) =>
    activities.every(({ status }) => [ActivityStatus.Valid, ActivityStatus.Invalid].includes(status));

  const saveApplication = (values: Partial<CreditClaim>) => () => {
    const { creditActivities = [] } = values;
    const isPra = values.applicationTypeCode === ApplicationTypeCode.PRA;

    const handleError: OnError = (err) => {
      setError(err.message);
    };

    // A CP can complete an application if all activities are marked valid or invalid
    if (isPra || areActivitiesValidOrInvalid(creditActivities)) {
      values.status = CreditClaimStatus.COMPLETED;
    } else {
      setError('Cannot complete application with activities of status "Follow Up" or "Pending"');
      window.scrollTo(0, 0);
      return;
    }

    if (isPra && claim.status !== CreditClaimStatus.APPROVED) {
      setError('Cannot complete PRA application without first marking the application as "Approved"');
      window.scrollTo(0, 0);
      return;
    }

    if (isPra && !(claim.praCertificateEffectiveDate && claim.praCertificateExpirationDate)) {
      setError('Cannot complete PRA application without entering both an effective date and an expiration date');
      window.scrollTo(0, 0);
      return;
    }

    updateClaim(
      claim.id,
      {
        ...claim,
        ...values,
      },
      {
        onSuccess: () => {
          history.push(buildRoute(Routes.ApplicationComplete, { id: claim.id }));
        },
        onError: handleError,
      }
    );
  };

  const submitApplication = () => {
    const newStatus = CreditClaimStatus.SUBMITTED;

    const onSuccess = () => {
      setLoading(false);
      history.push(Routes.ApplicationIndex);
    };
    const onError = () => {
      setLoading(false);
      setError(`There was an issue updating your claim to be ${newStatus}.`);
    };

    setLoading(true);
    dispatch(updateClaimStatus({ claimId: claim.id, newStatus, onSuccess, onError }));
  };

  const redirectToAdvantage = (orderNumberToken: string) => {
    const queryString = qs.stringify({
      ordNumber: orderNumberToken,
      paramRtnUrl: `${REACT_APP_PUBLIC_URL}${buildRoute(Routes.ApplicationComplete, { id: claim.id })}/`,
      applnm: 'edhub',
    });
    const advantageUrl = `${REACT_APP_ADVANTAGE_URL}?${queryString}`;

    window.open(advantageUrl, '_self');
    return;
  };

  const handleSubmit = (values: Partial<CreditClaim>, formikBag: FormikBag<Props, FormikValues>) => {
    const { creditActivities = [] } = values;
    const { setSubmitting } = formikBag;
    const followUpItems = getFollowUpActivities(creditActivities);
    const invalidActivities = getInvalidActivities(creditActivities);
    setError('');

    const handleOrderSuccess: OnSuccess = ({ data: orderData }: AxiosResponse<Partial<CreditClaimOrder>>) => {
      const { methodOfPayment, orderNumberToken } = orderData;

      if (methodOfPayment) {
        history.push(buildRoute(Routes.ApplicationComplete, { id: claim.id }));
        return;
      }

      if (orderNumberToken) {
        redirectToAdvantage(orderNumberToken);
        setSubmitting(false);
        return;
      }
    };

    const handleOrderError: OnError = (err) => {
      Rollbar.error('Could not create order in Advantage.', err);
      setError(err.message);
      setSubmitting(false);
    };

    const handlePrepareForPayment = ({ data: claimData }: AxiosResponse<Partial<CreditClaim>>): void => {
      if (claimData.paymentType === PaymentCode.CreditCard) {
        createClaimOrder(claim.id, {
          onSuccess: handleOrderSuccess,
          onError: handleOrderError,
        });
      } else {
        history.push(buildRoute(Routes.ApplicationComplete, { id: claim.id }));
      }
    };

    const handleClaimError: OnError = (err) => {
      setError(err.message);
      setSubmitting(false);
    };

    if (claim.applicationTypeCode !== ApplicationTypeCode.PRA) {
      /**
       * Quick validation to make there there are actually activities.
       * If not, throw an error
       */
      if (!creditActivities.length) {
        setError(content.mustAddActivities);
        setSubmitting(false);
        return;
      }

      if (followUpItems.length) {
        const status = isProcessor ? CreditClaimStatus.PENDING_CUSTOMER_ACTION : CreditClaimStatus.UNSUBMITTED;
        updateClaim(claim.id, { status });
        setError(content.noFollowUp);
        setSubmitting(false);
        window.scrollTo(0, 0); // go to the top of the page
        return;
      }

      if (invalidActivities.length === creditActivities.length) {
        updateClaim(claim.id, { status: CreditClaimStatus.REJECTED });
        setError(content.allInvalid);
        setSubmitting(false);
        window.scrollTo(0, 0); // go to the top of the page
        return;
      }

      if (isProcessor && !areActivitiesValidOrInvalid(creditActivities)) {
        setError('Cannot approve application and proceed to payment with activities of status "Follow Up" or "Pending"');
        setSubmitting(false);
        window.scrollTo(0, 0);
        return;
      }
    }

    if (claim.status === CreditClaimStatus.PAYMENT_FAILED) {
      updateClaim(
        claim.id,
        {
          billingAddress: values.billingAddress,
        },
        {
          onSuccess: ({ data }) => {
            const orderNumberToken = getIn(data, 'creditClaimOrder.orderNumberToken');
            if (orderNumberToken) {
              redirectToAdvantage(orderNumberToken);
            }
            setSubmitting(false);
            window.scrollTo(0, 0);
          },
          onError: handleClaimError,
        }
      );
      return;
    }

    const updateData = {
      rateCode: values.rateCode,
      paymentType: values.paymentType,
      paymentNote: values.paymentNote,
      creditActivities: values.creditActivities,
      billingAddress: values.billingAddress,
      praMailingAddress: values.mailingAddressIsSameAsBilling ? values.billingAddress : values.praMailingAddress,
      certificatesEmail: values.certificatesEmail,
      praNumCopies: values.praNumCopies,
    };

    if (isProcessor) {
      updateClaim(
        claim.id,
        {
          ...updateData,
          status: CreditClaimStatus.APPROVED,
        },
        {
          onSuccess: handlePrepareForPayment,
          onError: handleClaimError,
        },
        true,
        claim
      );
    } else {
      updateClaim(
        claim.id,
        updateData,
        {
          onSuccess: () => {
            updateClaim(
              claim.id,
              {
                dateReceived: momentUtc().toISOString(),
              },
              {
                onSuccess: handlePrepareForPayment,
                onError: handleClaimError,
              }
            );
          },
          onError: handleClaimError,
        },
        true,
        claim
      );
    }
  };

  const renderPageTitle = (claim: CreditClaim) => {
    return claim.applicationTypeCode === ApplicationTypeCode.PRA &&
      location.pathname.includes('order-details') &&
      [CreditClaimStatus.APPROVED, CreditClaimStatus.PENDING_CUSTOMER_ACTION, CreditClaimStatus.REJECTED].includes(claim.status)
      ? content.title.default
      : content.title[claim.status] || content.title.default;
  };

  const renderPageSubtitle = (status: CreditClaimStatus) => {
    return content.description[status] || content.description.default;
  };

  const renderFooter = ({ isSubmitting = false, values }: { isSubmitting?: boolean; values: Partial<CreditClaim> }) => {
    const { paymentType } = values;

    if (claim.status === CreditClaimStatus.COMPLETED) {
      return null;
    }

    // Offline payments
    if (isProcessor && paymentType === PaymentCode.Offline) {
      return (
        <PageFooter>
          <Button
            loading={loading}
            onClick={saveApplication({ ...values, status: CreditClaimStatus.COMPLETED })}
            data-test-id="completeApplication"
          >
            {content.completeButton}
          </Button>
        </PageFooter>
      );
    }

    // Rejected or Follow-Up Required, Do Not Allow Payment
    if (isProcessor && [CreditClaimStatus.PENDING_CUSTOMER_ACTION, CreditClaimStatus.REJECTED].includes(claim.status)) {
      return !location.pathname.includes('order-details') ? null : (
        <PageFooter>
          <Button loading={loading} onClick={saveApplication(values)} data-test-id="submitApplication">
            {content.completeButton}
          </Button>
        </PageFooter>
      );
    }

    // Ready for Completion
    if (
      isProcessor &&
      methodOfPayment &&
      [
        CreditClaimStatus.PROCESSING,
        CreditClaimStatus.APPROVED,
        CreditClaimStatus.PAYMENT_RECEIVED,
        CreditClaimStatus.PENDING_CUSTOMER_ACTION,
      ].includes(claim.status)
    ) {
      return (
        <PageFooter>
          <Button
            loading={loading}
            onClick={saveApplication({ ...values, status: CreditClaimStatus.COMPLETED })}
            data-test-id="completeApplication"
          >
            {content.completeButton}
          </Button>
        </PageFooter>
      );
    }

    // Ready for Credit Earner Submission
    if (methodOfPayment && !isProcessor && [CreditClaimStatus.UNSUBMITTED, CreditClaimStatus.PAYMENT_RECEIVED].includes(claim.status)) {
      return (
        <PageFooter>
          <Button loading={loading} onClick={submitApplication} data-test-id="submitApplication">
            {content.submitButton}
          </Button>
        </PageFooter>
      );
    }

    // Ready for Payment
    return !methodOfPayment || claim.status === CreditClaimStatus.PAYMENT_FAILED ? (
      <PageFooter>
        <Button type="submit" loading={loading || isSubmitting} data-test-id="payment">
          {content.nextButton}
        </Button>
      </PageFooter>
    ) : null;
  };

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} enableReinitialize={true} onSubmit={handleSubmit}>
      {({ isSubmitting, values, setFieldValue, setFieldTouched }) => {
        const resetPaymentFields = () => {
          /**
           * Reset Billing address and paymentNote to initial values when
           * switching between Offline and CC payments
           */
          setFieldValue('billingAddress', initialValues.billingAddress);
          setFieldValue('paymentNote', initialValues.paymentNote);
          setFieldTouched('billingAddress', false);
          setFieldTouched('paymentNote', false);
        };

        const renderEditUser = () => {
          if (!isProcessor) {
            return null;
          }
          return <EditButton id="editUserButton" to={buildRoute(Routes.UserEdit, { id: user.id })} />;
        };

        const hasFailedLearnerActivityTransfers =
          claim.applicationTypeCode !== ApplicationTypeCode.PRA &&
          values.creditActivities.filter((activity: CreditActivity) => !activity.learnerActivityId).length > 0;

        return (
          <FetchClaim>
            <ApplicationPage
              title={renderPageTitle(claim)}
              subtitle={renderPageSubtitle(claim.status)}
              applicationTypeCode={claim.applicationTypeCode}
              applicationStep={ApplicationStepLabel.OrderDetails}
            >
              {claim.applicationTypeCode === ApplicationTypeCode.PRA && claim.praCertificateClaimMethod === PraClaimMethod.PreviousCME && (
                <StyledStickyContainer>
                  <PraCreditSummary
                    creditRequirements={calculateCreditRequirementsForAwardType(claim)}
                    totalCategory1ActivityCredits={totalCategory1ActivityCredits}
                    totalCategory2ActivityCredits={totalCategory2ActivityCredits}
                  />
                </StyledStickyContainer>
              )}
              <Form data-test-id="orderDetailsForm">
                {error && (
                  <Alert data-test-id="orderDetailsError" type={AlertType.Error}>
                    {error}
                    {'\n'}
                    {'\n'}
                    {errorSupportMessage}
                  </Alert>
                )}
                {claim.status === CreditClaimStatus.COMPLETED && hasFailedLearnerActivityTransfers && (
                  <Alert data-test-id="failedTransferWarning" type={AlertType.Warning}>
                    {content.transcriptFailure}
                  </Alert>
                )}
                {claim.status === CreditClaimStatus.REJECTED && claim.applicationTypeCode !== ApplicationTypeCode.PRA && (
                  <Alert data-test-id="claimRejectedError" type={AlertType.Error}>
                    {content.claimRejected}
                  </Alert>
                )}

                {[ApplicationTypeCode.DirectCredit, ApplicationTypeCode.InternationalCreditConversion].includes(
                  claim.applicationTypeCode
                ) && <CreditActivitiesSummary />}

                <FormSection title="Name and Contact" renderAction={renderEditUser}>
                  <NameAndContactText tag="p" textStyle={TextStyle.SubTitle}>
                    Review and confirm your contact information below. The name displayed below will appear on all your CME certificates and
                    awards. If you need to make changes to your name, please contact us at:{' '}
                    <ExternalLink data-test-id="contactLink" href="mailto:mosupport@ama-assn.org">
                      MOSupport@ama-assn.org
                    </ExternalLink>
                    .
                  </NameAndContactText>
                  <UserInfoTable user={user} />
                  <Text>
                    If your AMA membership status is incorrect, please contact us at:{' '}
                    <ExternalLink data-test-id="contactLink" href="mailto:mosupport@ama-assn.org">
                      MOSupport@ama-assn.org
                    </ExternalLink>
                  </Text>
                </FormSection>

                {claim.applicationTypeCode === ApplicationTypeCode.PRA && (
                  <>
                    {applicationCost && (
                      <FormSection title="Application Cost">
                        <Text data-test-id="applicationCost">${Number(applicationCost).toFixed(2)}</Text>
                      </FormSection>
                    )}
                    <PraNumCopiesSection value={values.praNumCopies} />
                  </>
                )}

                <PaymentInfoSection
                  applicationTypeCode={claim.applicationTypeCode}
                  paymentType={values.paymentType}
                  onPaymentTypeChange={resetPaymentFields}
                  values={values}
                />

                {renderFooter({ isSubmitting, values })}
              </Form>
              <Debug json={{ status: claim.status }} />
            </ApplicationPage>
          </FetchClaim>
        );
      }}
    </Formik>
  );
};

const StyledStickyContainer = styled(StickyContainer)`
  margin-bottom: 7rem;
`;

const NameAndContactText = styled(Text)`
  margin-bottom: 1em;
`;

const mapStateToProps = (state: ReduxStore) => ({
  claim: claimDataSelector(state),
  user: claimUserSelector(state),
  isProcessor: isCreditProcessor(state),
});

const mapDispatchToProps = {
  updateClaim,
  createClaimOrder,
  fetchClaimSuccess,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OrderDetails));
