import React from 'react';
import styled from '../../theme';
import { themeColor } from '../../theme/helpers';
import Button from '../Button';
import Text from '../Text';

const ErrorView = styled.div`
  text-align: center;
`;

const ErrorIcon = styled.span`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  margin: 0 auto 2.4rem;
  width: 6.4rem;
  height: 6.4rem;
  border: 1px solid currentColor;
  border-radius: 50%;
  color: ${themeColor('error')};
`;

export const IconText = styled.span`
  font-size: 3.6rem;
  position: relative;
  top: 0.08em;
`;

const Headline = styled.div`
  margin-bottom: 1.6rem;
`;

const Footer = styled.div`
  margin-top: 3.6rem;
`;

const Error: React.FunctionComponent<{ onButtonClick: () => void }> = ({ onButtonClick }) => {
  return (
    <ErrorView>
      <ErrorIcon>
        <IconText>!</IconText>
      </ErrorIcon>
      <Headline>
        <Text textStyle="headline">Error</Text>
      </Headline>
      <Text>Unable to save at this time. Please try again later.</Text>
      <Footer>
        <Button onClick={onButtonClick} buttonStyle="text">
          Back To Site
        </Button>
      </Footer>
    </ErrorView>
  );
};

export default Error;
