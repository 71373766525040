import React from 'react';
import LoadingSpinner from '../../components/LoadingSpinner';
import App from '../App';

const LoadingPage: React.FunctionComponent = ({ children }) => (
  <App>
    <div style={{ margin: '5rem auto', textAlign: 'center' }}>
      <LoadingSpinner size={60} /> {children}
    </div>
  </App>
);

export default LoadingPage;
