import { HTMLAttributes } from 'react';
import styled, { css } from '../../theme';
import { themeBreakpoint, themeColor } from '../../theme/helpers';
import { Color } from '../../theme/primaryTheme';
import ThemeInterface from '../../theme/theme';

interface StyledFieldProps extends HTMLAttributes<HTMLDivElement> {
  hasError?: boolean;
  hasFocus?: boolean;
  border?: boolean;
  theme: ThemeInterface;
  type?: string;
}

const getBorderColor = (props: StyledFieldProps) => {
  if (props.hasError) {
    return themeColor('error')(props);
  }

  if (props.hasFocus) {
    return themeColor('primary')(props);
  }

  return themeColor('border')(props);
};

export const StyledField = styled.div<StyledFieldProps>`
  ${({ border }) =>
    border
      ? css`
          border: 1px solid ${getBorderColor};
          border-radius: 0.5rem;
        `
      : ''};
  ${({ type }) => {
    if (type === 'radio' || type === 'checkbox') {
      return css`
        background-color: transparent;
      `;
    } else {
      return css`
        background-color: ${themeColor(Color.Reverse)};
      `;
    }
  }};
  position: relative;

  &:focus {
    outline: none;
  }

  select {
    appearance: none;

    ::-ms-expand {
      display: none;
    }
  }

  input,
  select,
  textarea {
    box-sizing: border-box;
    color: ${themeColor('primary')};

    :disabled {
      color: ${themeColor('secondary')};
    }

    ::-webkit-input-placeholder {
      color: ${themeColor('secondary')};
    }

    ::-moz-placeholder {
      color: ${themeColor('secondary')};
    }

    :-ms-input-placeholder {
      color: ${themeColor('secondary')};
    }

    :-moz-placeholder {
      color: ${themeColor('secondary')};
    }
  }
`;

const inlineStyles = css`
  & + & {
    margin-top: 1.2rem;
  }

  label {
    margin-bottom: 0.5em;
  }

  @media (min-width: ${themeBreakpoint('medium')}) {
    display: flex;
    align-items: baseline;
    width: 100%;

    > *:not(label) {
      flex-grow: 1;
    }

    label {
      width: 200px;
      margin-bottom: 0;
    }
  }
`;

export const FieldWrap = styled.div<{ inline?: boolean }>`
  & + & {
    margin-top: 10px;
  }

  ${(props) => (props.inline ? inlineStyles : '')};
`;

const baseLockedFieldStyles = css`
  height: 54px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  padding: 1.5rem;
  font-size: 1.6rem;
  border-radius: 0.5rem;
  color: ${themeColor('secondary')};
  background-color: #f8f8f8;
`;

const smallLockedFieldStyles = css`
  height: 36px;
  padding: 0.5em;
`;

export const LockedField = styled.div<{ variant?: 'small' | 'default' }>`
  ${baseLockedFieldStyles}
  ${({ variant }) => variant === 'small' && smallLockedFieldStyles}
`;

export const ControlMeta = styled.div`
  position: absolute;
  right: 1.5rem;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const ErrorText = styled.p<{ inline?: boolean }>`
  margin-top: 0.5rem;
  color: ${themeColor('error')};
  ${({ inline }) =>
    inline
      ? css`
          margin-left: 200px;
          margin-bottom: 1.5rem;
        `
      : ''};
`;
