import React from 'react';
import { connect } from 'react-redux';
import { CreditClaimStatus } from '../../constants/ApplicationStatus';
import { ApplicationTypeCodeLabels } from '../../constants/ApplicationTypeCodeLabels';
import { ApplicationTypeFilter } from '../../constants/ApplicationTypeFilter';
import { emptyCellCharacter } from '../../constants/General';
import { buildRoute, Routes } from '../../constants/Routes';
import { ReduxStore } from '../../ducks';
import { fetchClaims, FetchClaimsQueryParams } from '../../ducks/claims';
import {
  claimLoadingSelector,
  claimResultsSelector,
  claimSelector,
  claimsPageSelector,
  claimsTotalPagesSelector,
  claimsTotalSelector,
} from '../../selectors/claims';
import { earnerSelector } from '../../selectors/creditEarner';
import { isCreditProcessor } from '../../selectors/user';
import { Color, ThemeColors } from '../../theme/primaryTheme';
import { CreditClaim } from '../../types';
import { dateAccessor } from '../../utilities/displayDate';
import { ApplicationSort } from '../ApplicationIndex';
import FlagApplicationButton from '../FlagApplicationButton';
import Icon from '../Icon';
import Link from '../Link';
import LoadingSpinner from '../LoadingSpinner';
import Pagination from '../Pagination';
import { ProcessingRateLabel } from '../ProcessingRateLabel';
import StatusLabel from '../StatusLabel';
import Table, { ColumnConfig } from '../Table';
import SortableTableHeaderCell from '../Table/SortableTableHeaderCell';
import Text from '../Text';
import ClaimActionMenu from './ClaimActionMenu';

export const getEarnerName = ({ creditEarner }: CreditClaim): string => `${creditEarner?.firstName ?? '—'} ${creditEarner?.lastName ?? ''}`;

const goToClaim = ({ data, history }: any) =>
  history.push(
    buildRoute(
      [CreditClaimStatus.SUBMITTED, CreditClaimStatus.COMPLETED].includes(data.status)
        ? Routes.ApplicationComplete
        : Routes.ApplicationShow,
      { id: data.id }
    )
  );

const DateCellComponent = ({ data, value, isProcessor }: any) =>
  !isProcessor && data.status === CreditClaimStatus.UNSUBMITTED ? <>{emptyCellCharacter}</> : <>{value}</>;

interface Props {
  applications: any;
  isLoading: boolean;
  totalPages: number;
  fetchClaims: any;
  page: number;
  userId?: number;
  applicationTypeFilter?: ApplicationTypeFilter;
  statusFilters: CreditClaimStatus[];
  isProcessor: boolean;
}

interface State {
  total: number;
  page: number;
  sortField?: ApplicationSort;
  showRejected: boolean;
  loading: boolean;
}

class ApplicationTable extends React.Component<Props, State> {
  public state: State = {
    total: 0,
    page: this.props.page,
    sortField: undefined,
    showRejected: false,
    loading: false,
  };

  private readonly pageSize = 20;

  public goToPage = (page: number) => {
    const { totalPages } = this.props;
    const { page: currentPage } = this.state;

    if (page >= 1 && page <= totalPages) {
      this.setState({ page });
      return page;
    }

    return currentPage;
  };

  public componentDidMount(): void {
    this.fetchClaims();
  }

  public componentDidUpdate(prevProps: Props): void {
    if (!prevProps.isLoading) {
      this.fetchClaims();
    }
  }

  public fetchClaims() {
    const { page } = this.state;
    const { isLoading, userId, fetchClaims, applicationTypeFilter = ApplicationTypeFilter.All, statusFilters } = this.props;

    const params: Partial<FetchClaimsQueryParams> = {
      page,
      pageSize: this.pageSize,
      applicationTypeFilter,
      status: statusFilters,
    };

    if (userId) {
      params.userId = userId;
    }

    if (!isLoading) {
      fetchClaims(params);
    }
  }

  public render() {
    const { applications, totalPages, isLoading, page } = this.props;

    return (
      <>
        {isLoading ? <LoadingSpinner /> : <Table columns={this.displayedColumns()} data={applications} />}
        {totalPages > 1 && <Pagination currentPage={page} pageCount={totalPages} onPageChanged={this.goToPage} />}
      </>
    );
  }

  private displayedColumns: () => ColumnConfig[] = () => {
    const columns = [
      {
        header: 'Flag',
        headerCellComponent: SortableTableHeaderCell,
        id: 'flagged',
        accessor: 'flagged',
        cellComponent: ({ data }: any) => {
          return (
            <>
              <FlagApplicationButton size={14} claimId={data.id} flagged={data.flagged} />
              {data.hasFailedTransfers && (
                <div style={{ marginTop: 10 }}>
                  <Icon name="warning" fill={ThemeColors[Color.Warning]} width={16} height={16} viewBox="0 0 86 75" />
                </div>
              )}
            </>
          );
        },
      },
      {
        header: 'ID',
        id: 'id',
        headerCellComponent: SortableTableHeaderCell,
        accessor: 'id',
        onCellClick: goToClaim,
      },
      {
        header: 'Name',
        id: 'name',
        accessor: (data: any) => getEarnerName(data),
        cellComponent: ({ data, value, isProcessor }: any) => {
          if (isProcessor) {
            return (
              <Link to={buildRoute(Routes.UserShow, { id: data.creditEarner?.id })}>
                <Text bold={true} tag="span">
                  {value}
                </Text>
              </Link>
            );
          }

          return (
            <Text bold={true} tag="span">
              {value}
            </Text>
          );
        },
      },
      {
        header: 'Type',
        id: 'applicationTypeCode',
        headerCellComponent: SortableTableHeaderCell,
        accessor: (data: any) => ApplicationTypeCodeLabels[data.applicationTypeCode] || data.applicationTypeCode,
        onCellClick: goToClaim,
      },
      {
        header: 'Status',
        id: 'status',
        headerCellComponent: SortableTableHeaderCell,
        accessor: 'status',
        cellComponent: ({ value }: any) => <StatusLabel status={value} />,
        onCellClick: goToClaim,
      },
      {
        header: 'Rate',
        id: 'rateCode',
        headerCellComponent: SortableTableHeaderCell,
        accessor: 'rateCode',
        cellComponent: ({ value, status }: any) => <ProcessingRateLabel rate={value} rejected={status === 'rejected'} />,
        onCellClick: goToClaim,
      },
      {
        header: 'Payment',
        id: 'paymentType',
        headerCellComponent: SortableTableHeaderCell,
        accessor: (data: any) => (data.paymentType === 'CC' ? 'Credit Card' : 'Offline'),
        onCellClick: goToClaim,
      },
      {
        header: 'Received',
        id: 'dateReceived',
        headerCellComponent: SortableTableHeaderCell,
        accessor: dateAccessor('dateReceived'),
        cellComponent: DateCellComponent,
        onCellClick: goToClaim,
      },
      {
        header: 'Due',
        id: 'dueDate',
        headerCellComponent: SortableTableHeaderCell,
        accessor: dateAccessor('dueDate'),
        cellComponent: DateCellComponent,
        onCellClick: goToClaim,
      },
      {
        header: '',
        id: 'actionMenu',
        accessor: '',
        align: 'right',
        cellComponent: ({ data, history }: any) => <ClaimActionMenu data-test-id="claimActionMenu" claim={data} history={history} />,
      },
    ] as ColumnConfig[];
    return columns.filter(
      ({ id }) =>
        this.props.isProcessor ||
        ['name', 'applicationTypeCode', 'status', 'rateCode', 'paymentType', 'dateReceived', 'dueDate'].includes(id)
    );
  };
}

const mapStateToProps = (state: ReduxStore) => {
  const results = claimResultsSelector(state);
  const applications = results.map((id: number) => {
    const normalizedClaim = claimSelector(id)(state);
    const creditEarnerId = normalizedClaim.creditEarner;
    const creditEarner = earnerSelector(creditEarnerId)(state);

    return {
      ...normalizedClaim,
      creditEarner,
    };
  });

  return {
    applications,
    total: claimsTotalSelector(state),
    page: claimsPageSelector(state),
    totalPages: claimsTotalPagesSelector(state),
    isLoading: claimLoadingSelector(state),
    isProcessor: isCreditProcessor(state),
  };
};

const mapDispatchToProps = {
  fetchClaims,
};

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationTable);
