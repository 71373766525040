import React from 'react';
import { BulkLearnerActivityUpload } from '../../types';
import { FileDownload } from '../FileDownload';

const rollbarErrorText = (data: BulkLearnerActivityUpload) => `Error fetching original file for Bulk Learner Activity Upload ${data.id}`;

const BulkLearnerActivityUploadOriginalFileDownloadButton: React.FunctionComponent<{
  value?: string;
  data: BulkLearnerActivityUpload;
}> = (props) => (
  <FileDownload
    {...props}
    buttonProps={{ buttonText: props.data.originalFileName }}
    downloadedFileName={props.data.originalFileName}
    rollbarErrorText={rollbarErrorText}
  />
);

export default BulkLearnerActivityUploadOriginalFileDownloadButton;
