import React from 'react';
import styled from 'src/theme';
import { themeBreakpoint } from 'src/theme/helpers';
import { LinkStyle } from '../../constants/LinkStyle';
import { Routes } from '../../constants/Routes';
import Link from '../Link';
import MobileDisclaimer from './MobileDisclaimer';
import NewApplicationLink from './NewApplicationLink';

const EarnerNavLinks: React.FunctionComponent = () => {
  return (
    <>
      <NavLink mobileOrder={4}>
        <NewApplicationLink to={Routes.ApplicationNew} />
      </NavLink>
      <NavLink mobileOrder={3}>
        <Link linkStyle={LinkStyle.NavLink} to={Routes.ApplicationIndex}>
          My Applications
          <MobileDisclaimer />
        </Link>
      </NavLink>
      <NavLink mobileOrder={1}>
        <Link linkStyle={LinkStyle.NavLink} to={Routes.Transcript}>
          My Transcript
        </Link>
      </NavLink>
      <NavLink mobileOrder={2}>
        <Link linkStyle={LinkStyle.NavLink} to={Routes.Preferences}>
          My Preferences
        </Link>
      </NavLink>
    </>
  );
};

const NavLink = styled.div<{ mobileOrder: number }>`
  @media (max-width: ${themeBreakpoint('medium')}) {
    order: ${(props) => props.mobileOrder};
  }
`;

export default EarnerNavLinks;
