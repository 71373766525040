import React from 'react';
import { FlexTableGroup, FlexTableLeft, FlexTableRight, FlexTableRows } from '../../../components/FlexTable';
import Label from '../../../components/Label';
import ToggleTextInput from '../../../components/ToggleTextInput';
import { Board } from '../../../types';

interface IBoardListProps {
  boards: Board[];
}

const BoardFields = ({ boards = [] }: IBoardListProps) => {
  return (
    <>
      {boards.map((board: Board) => {
        return (
          board.userDefinableAttributes.length > 0 && (
            <FlexTableRows key={board.id}>
              <FlexTableLeft>
                <Label bold={true}>{board.name}</Label>
              </FlexTableLeft>
              <FlexTableRight>
                <FlexTableGroup>
                  <ToggleTextInput board={board} />
                </FlexTableGroup>
              </FlexTableRight>
            </FlexTableRows>
          )
        );
      })}
    </>
  );
};

export default BoardFields;
