import styled, { css } from '../../theme';
import { LinkProps, linkStyles, rootStyles } from './styles';

const ExternalLink = styled.a<LinkProps>`
  ${rootStyles};
  border-bottom: ${({ border }) => (border ? '1px solid #8DA3B5' : 'none')};
  font-weight: ${({ bold }) => (bold ? '700' : '400')};
  ${({ truncate = false }) =>
    truncate &&
    css`
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    `}

  ${({ linkStyle }) => (linkStyle ? linkStyles[linkStyle] : '')}
`;

export default ExternalLink;
