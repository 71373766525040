import React from 'react';
import { ExternalCreditEarner } from '../../types';
import Debug from '../Debug';
import { FieldColumn, FieldRow } from '../FieldRow';
import SummaryValue from '../SummaryValue';

interface Props {
  user: ExternalCreditEarner;
}

const UserAdditionalSummary: React.FunctionComponent<Props> = ({ user }) => {
  const { phone, fax, medSchool, gradYear } = user;

  /***
   * gradYear can potentially have values stored as MM/YYYY or YYYY-MM.
   * After split, we locate the element with 4 digits. If found, we use that as the formattedGradYear.
   * Otherwise, we use the original value. See: https://www.pivotaltracker.com/story/show/184833271
   */
  const yearParts = (gradYear ?? '').split(/[-/]/);
  const formattedGradYear = yearParts.find((y) => y.length === 4) ?? gradYear;

  return (
    <>
      <FieldRow even={true}>
        <FieldColumn>
          <SummaryValue label="Phone" value={phone} />
        </FieldColumn>
        <FieldColumn>
          <SummaryValue label="Fax" value={fax} />
        </FieldColumn>
      </FieldRow>
      <FieldRow even={true}>
        <FieldColumn>
          <SummaryValue label="Medical School" value={medSchool} />
        </FieldColumn>
        <FieldColumn>
          <SummaryValue label="Graduation Year" value={formattedGradYear} />
        </FieldColumn>
      </FieldRow>
      <Debug json={user} />
    </>
  );
};

export default UserAdditionalSummary;
