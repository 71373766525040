import { ArrayHelpers, FieldArray, FormikValues, getIn } from 'formik';
import React from 'react';
import { useSelector } from 'react-redux';
import { ActivityStatus } from '../../constants/ActivityStatus';
import { directCreditActivityTypeOptions, renderActivityTypeLabel } from '../../constants/ActivityType';
import {
  claimDataSelector,
  claimHasOrder as claimHasOrderSelector,
  claimIsCompletedSelector,
  claimIsEditableSelector,
} from '../../selectors/claims';
import { isCreditProcessor } from '../../selectors/user';
import { Board, CreditActivity } from '../../types';
import ActivityCard from '../ActivityCard';
import ActivityFields from '../ActivityFields';
import ActivityInfoSummary from '../ActivityInfoSummary';
import Button from '../Button';
import Field, { FieldOnChange } from '../Field';
import Select from '../Field/Controls/Select';
import { FieldColumn, FieldRow } from '../FieldRow';
import Label from '../Label';
import StatusLabel from '../StatusLabel';
import SummaryValue from '../SummaryValue';
import { initialActivityValue } from './index';
import { AddItemFooter } from './styles';

interface Props {
  values: FormikValues;
  name: string;
  onTypeChange: FieldOnChange;
  abmsBoards: Board[];
}

const DirectCreditActivities: React.FunctionComponent<Props> = ({ values, name, onTypeChange, abmsBoards }) => {
  const claim = useSelector(claimDataSelector);
  const isProcessor = useSelector(isCreditProcessor);
  const claimHasOrder = useSelector(claimHasOrderSelector);
  const claimIsCompleted = useSelector(claimIsCompletedSelector);
  const claimIsEditable = useSelector(claimIsEditableSelector);

  if (!claim) {
    return null;
  }

  return (
    <FieldArray name={name}>
      {(arrayHelpers: ArrayHelpers) => {
        const addItem = () => {
          arrayHelpers.push(initialActivityValue);
        };
        const { creditActivities = [] } = values;

        return (
          <div data-test-id="activities">
            {creditActivities.map((activity: CreditActivity, index: number) => {
              const removeItem = () => {
                arrayHelpers.remove(index);
              };
              const editableActivityStatuses = isProcessor
                ? Object.values(ActivityStatus)
                : [ActivityStatus.FollowUp, ActivityStatus.Pending];

              // CPs can edit activities for rejected claims
              const activityIsEditable =
                ((!claimIsCompleted && isProcessor) || claimIsEditable) &&
                !activity.learnerActivityId &&
                editableActivityStatuses.includes(activity.status);

              const selectedType = getIn(values, `${name}.[${index}].activityTypeCode`);

              return (
                <ActivityCard
                  locked={!activityIsEditable}
                  title={`Activity ${index + 1}`}
                  handleConfirm={removeItem}
                  key={`activity-${index}`}
                >
                  {activityIsEditable ? (
                    <>
                      <FieldRow>
                        <FieldColumn grow={1}>
                          {claimHasOrder ? (
                            <div data-test-id="activityTypeSummary">
                              <SummaryValue label="Activity Type" value={renderActivityTypeLabel(activity.activityTypeCode)} />
                            </div>
                          ) : (
                            <Field
                              name={`${name}[${index}].activityTypeCode`}
                              label="Activity Type"
                              component={Select}
                              onChange={onTypeChange}
                              controlProps={{
                                options: directCreditActivityTypeOptions,
                                defaultLabel: 'Select Activity Type',
                              }}
                            />
                          )}
                        </FieldColumn>
                      </FieldRow>
                      <ActivityFields baseName={`${name}[${index}]`} values={values} selectedType={selectedType} abmsBoards={abmsBoards} />
                      {!isProcessor && activity.status === ActivityStatus.FollowUp && (
                        <>
                          <FieldRow>
                            <FieldColumn>
                              <Label tag="p">Activity Status</Label>
                              <div>
                                <StatusLabel status={activity.status} />
                              </div>
                            </FieldColumn>
                          </FieldRow>
                          {activity.notes && (
                            <FieldRow>
                              <FieldColumn>
                                <SummaryValue label="Notes" value={activity.notes} />
                              </FieldColumn>
                            </FieldRow>
                          )}
                        </>
                      )}
                    </>
                  ) : (
                    <ActivityInfoSummary baseName={`${name}[${index}]`} values={values} selectedType={selectedType} />
                  )}
                </ActivityCard>
              );
            })}
            {!claimIsCompleted && !claimHasOrder && (
              <AddItemFooter>
                <Button data-test-id="addActivity" buttonStyle="outlined" type="button" onClick={addItem} full={true}>
                  Add Another Activity
                </Button>
              </AddItemFooter>
            )}
          </div>
        );
      }}
    </FieldArray>
  );
};

export default DirectCreditActivities;
