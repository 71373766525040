import { createSelector } from 'reselect';
import { ReduxStore } from '../ducks';

/**
 * =========
 * USER DATA
 * =========
 */
export const user = (state: ReduxStore) => state.user;
export const userLoadingSelector = createSelector(user, (user) => user.isLoading);
export const userInitializedSelector = createSelector(user, (user) => user.initialized);
export const userDataSelector = createSelector(user, (user) => user.data);
export const selectUserId = createSelector(userDataSelector, (user) => user.id);
export const isCreditProcessor = createSelector(userDataSelector, (userData) => userData.isCreditProcessor);
export const isAdmin = createSelector(userDataSelector, (userData) => userData.isAdmin);
