import { countryOptions } from 'src/layouts/Forms/countryOptions';
import * as yup from 'yup';
import { ApplicationTypeCode } from '../../constants/ApplicationTypeCode';
import { RateCode } from '../../constants/CreditClaimRateCode';
import { PaymentCode } from '../../constants/PaymentCode';
import { stateOptions } from '../../layouts/Forms/stateOptions';
import { zipRegex } from '../../utilities/regex';

const stateValues = stateOptions.map((state) => state.value);
const countryCodeValues = countryOptions.map((country) => country.code);

export const validationSchema = yup.object().shape({
  rateCode: yup.string().oneOf(Object.values(RateCode)).required().label('Processing rate'),
  paymentType: yup.string().oneOf([PaymentCode.CreditCard, PaymentCode.Offline]).required().label('Payment type'),
  praNumCopies: yup.number().when('applicationTypeCode', {
    is: (val) => val === ApplicationTypeCode.PRA,
    then: yup.number().min(1, 'Please enter a number between 1 and 10.').max(10, 'Please enter a number between 1 and 10.'),
    otherwise: yup.number().notRequired(),
  }),
  billingAddress: yup.object().when('paymentType', {
    is: (val) => val === PaymentCode.CreditCard,
    then: yup.object().shape({
      Line1: yup.string().label('Address').nullable(true).required(),
      Line2: yup.string().nullable(true),
      City: yup.string().required().nullable(true).label('City'),
      StateCode: yup.string().label('State').nullable(true).oneOf(stateValues, 'Please select a state').required(),
      PostalCode: yup
        .string()
        .nullable(true)
        .label('ZIP')
        .matches(zipRegex, {
          message: 'Must be valid ZIP code',
          excludeEmptyString: true,
        })
        .required(),
    }),
    otherwise: yup.object().notRequired(),
  }),
  praMailingAddress: yup.object().when(['applicationTypeCode', 'mailingAddressIsSameAsBilling'], {
    is: (applicationTypeCode, mailingAddressIsSameAsBilling) =>
      applicationTypeCode === ApplicationTypeCode.PRA && !mailingAddressIsSameAsBilling,
    then: yup.object().shape({
      Line1: yup.string().label('Address').nullable(true).required(),
      Line2: yup.string().nullable(true),
      City: yup.string().required().nullable(true).label('City'),
      CountryCode: yup.string().label('Country').nullable(true).oneOf(countryCodeValues, 'Please select a country').required(),
      StateCode: yup.string().when('CountryCode', {
        is: (countryCode) => countryCode !== 'USA',
        then: yup.string().notRequired().nullable(),
        otherwise: yup.string().label('State').nullable(true).oneOf(stateValues, 'Please select a state').required(),
      }),
      PostalCode: yup.string().when('CountryCode', {
        is: (countryCode) => countryCode !== 'USA',
        then: yup.string().notRequired().nullable(),
        otherwise: yup
          .string()
          .nullable(true)
          .label('ZIP')
          .matches(zipRegex, {
            message: 'Must be valid ZIP code',
            excludeEmptyString: true,
          })
          .required(),
      }),
    }),
    otherwise: yup.object().notRequired(),
  }),
  certificatesEmail: yup.string().label('Email').email(),
});
