import { Field as FormikField, FieldProps, getIn } from 'formik';
import React from 'react';
import { ErrorText } from '../Field/styles';

interface Props {
  name: string;
}

const ErrorMessage: React.FunctionComponent<Props> = ({ name, ...rest }) => {
  return (
    <FormikField name={name}>
      {({ form }: FieldProps) => {
        const error = getIn(form.errors, name);
        const touch = getIn(form.touched, name);

        return touch && error && typeof error === 'string' && error.length > 1 ? ( // [1]
          <ErrorText data-test-id={`${name}-error`} {...rest}>
            {error}
          </ErrorText>
        ) : null;
      }}
    </FormikField>
  );
};

export default ErrorMessage;

/**
 * [1] This is a bit hacky, but it's related to how Formik and yup surface errors
 * on array fields. Essentially the error can appear in two forms:
 *
 * key: "this is the error"
 * key: [ "this is the error for the first field" ]
 *
 * Whether it's the first or second depends on where the error is surfaced. The
 * issue is that when the error takes the form of the first, we get one letter
 * errors when the name of the field is something like emails[0]
 *
 * I'm sure there's a much better way to do this, but I spent wayy to much time
 * trying to figure it out and ended up here.
 */
