import { createSelector } from 'reselect';
import { ReduxStore } from '../ducks';
import { Board } from '../types';

/**
 * ==========
 * BOARD DATA
 * ==========
 */
export const boards = (state: ReduxStore) => state.boardData;
export const boardsLoadingSelector = createSelector(boards, (board) => board.isLoading);
export const boardsDataSelector = createSelector(boards, (board) => board.data);
export const boardsErrorSelector = createSelector(boards, (board) => board.error);

export const abmsBoardsDataSelector = createSelector(boardsDataSelector, (boards: Board[]) =>
  boards.filter((board) => !board.isStateBoard)
);
