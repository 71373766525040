import React from 'react';
import { MenuPanelStyles } from './styles';

interface Props {
  onClickOutside: () => void;
  onClick: () => void;
  isOpen: boolean;
}

class ActionMenuPanel extends React.Component<Props> {
  private menuRef: React.RefObject<HTMLDivElement> = React.createRef();

  public componentDidMount() {
    document.addEventListener('mousedown', this.handleOutsideClick, false);
  }

  public componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleOutsideClick, false);
  }

  public render() {
    const { isOpen, children, onClick } = this.props;
    return (
      <MenuPanelStyles onClick={onClick} isOpen={isOpen} ref={this.menuRef}>
        {children}
      </MenuPanelStyles>
    );
  }

  private handleOutsideClick: React.EventHandler<any> = (event) => {
    const { onClickOutside } = this.props;
    const { current } = this.menuRef;

    if (current && !current.contains(event.target)) {
      onClickOutside();
    }
  };
}

export default ActionMenuPanel;
