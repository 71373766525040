import React from 'react';
import ActionModal from '../ActionModal';
import Text, { TextStyle } from '../Text';

interface Props {
  handleConfirm: () => void;
  modalId: string;
}

const DeleteActivityModal: React.FunctionComponent<Props> = ({ handleConfirm, modalId }) => {
  return (
    <ActionModal id={modalId} modalId={modalId} title="Delete Activity" onConfirm={handleConfirm} confirmLabel="Delete Activity">
      <Text textStyle={TextStyle.Default}>Are you sure you want to delete this activity?</Text>
    </ActionModal>
  );
};

export default DeleteActivityModal;
