import { ActivityType } from '../constants/ActivityType';
import { fileUpload } from './fields';
import { AbmsActivitySubType } from 'src/constants/AbmsActivitySubType';

const program = {
  label: 'Program',
  placeholder: 'Program Name',
};

const footer = {
  total: 'Total Credits:',
  disclaimer: '',
};

export const activityContent = {
  [ActivityType.Acgme]: {
    description:
      'This activity earns 20 <em>AMA PRA Category 1 Credits™</em> for each year of participation in a residency or fellowship program completed within the last six years. Residents and fellows who are AMA members can receive a credit certificate at no charge as a benefit of membership.',
    footer: {
      ...footer,
      disclaimer: '20 credits per year of residency/fellowship, up to 3 years.',
    },
    fields: {
      program,
      activityUnits: {
        label: 'Years in Program',
      },
      activityStartDate: {
        label: 'Start of Program',
      },
      activityEndDate: {
        label: 'End of Program',
      },
      creditActivityAttachments: {
        ...fileUpload,
        helpText:
          'Attach letter from the Program Director or the Certificate of Completion of the Residency Program verifying the dates of participation and completion of the program.',
      },
    },
  },
  [ActivityType.Medical]: {
    description:
      'Obtaining a medically related advanced degree is eligible for 25 <em>AMA PRA Category 1 Credits™</em>. This activity cannot be claimed if individual courses within the academic program were already certified for <em>AMA PRA Category 1 Credit™</em>.',
    footer,
    fields: {
      school: {
        label: 'School',
        placeholder: 'School',
      },
      program,
      graduationDate: {
        label: 'Graduation Date',
        placeholder: 'MM/DD/YYYY',
      },
      creditActivityAttachments: {
        ...fileUpload,
        helpText:
          'Attach a copy of the diploma or final transcript indicating the degree and date of completion. The date noted in your application above must match the date of graduation or completion shown on the diploma or transcript you submit.',
      },
    },
  },
  [ActivityType.Poster]: {
    description:
      'Poster presentations are eligible for 5 <em>AMA PRA Category 1 Credits™</em> per poster. Applicant must be listed as the first author that is included in the published abstracts for a conference that is designated for <em>AMA PRA Category 1 Credit™</em>.',
    footer,
    fields: {
      presentationTitle: {
        label: 'Presentation Title',
        placeholder: 'Presentation Title',
      },
      firstAuthor: {
        label: 'Applicant is first author',
      },
      activityEndDate: {
        label: 'End Date',
      },
      creditActivityAttachments: {
        ...fileUpload,
        helpText:
          'Attach a copy of the page(s) in the published activity documents that lists the author and poster abstract, accredited CME provider, AMA credit designation statement, title, and date of the activity.',
      },
    },
  },
  [ActivityType.Teaching]: {
    description: `<p>Teaching a live activity may only be redeemed for credit if that activity is designated for <em>AMA PRA Category 1 Credits™</em>. This credit is for preparing and presenting an original presentation and may only be claimed once for a repeated presentation. The amount of redeemable credit is as follows:</p><ul><li> If the activity occurred in 2022 or earlier, it's eligible for up to two (2) <em>AMA PRA Category 1 Credits™</em> per hour of presentation time.</li><li> If the activity occurred on January 1, 2023 or later, it's eligible for up to four (4) <em>AMA PRA Category 1 Credits™</em> per hour of presentation time.</li></ul>`,
    footer: {
      ...footer,
    },
    fields: {
      activityTitle: {
        label: 'Title of Activity',
        placeholder: 'Activity Title',
      },
      activityEndDate: {
        label: 'Date',
      },
      activityUnits: {
        label: 'Hours Presented',
        placeholder: '0',
        helpText: 'Increments of .25',
        maxLength: 6,
      },
      creditsRequested: {
        label: 'Credits Requested',
        placeholder: '0',
        helpText: {
          increment: 'Increments of .25',
          max: 'Must not exceed 4x the hours presented',
        },
        maxLength: 6,
      },
      creditActivityAttachments: {
        ...fileUpload,
        helpText:
          'Attach a copy of the page(s) used by the provider to announce or describe the activity, which includes the name of the applicant speaker, the accredited CME provider, AMA Credit Designation Statement, date, and location of the activity.',
      },
    },
  },
  [ActivityType.Article]: {
    description:
      'Publishing an article is eligible for 10 <em>AMA PRA Category 1 Credits™</em> per article. The applicant must appear as the first listed or last listed author, and the article must be a peer-reviewed article in a journal included in the MEDLINE bibliographic database.',
    footer,
    fields: {
      doi: {
        label: 'DOI',
        placeholder: '10.1001/art.0130.a4380b4b',
        maxLength: 255,
      },
      activityTitle: {
        label: 'Article Title',
        placeholder: 'Article Title',
        maxLength: 500,
      },
      firstAuthor: {
        label: 'Applicant is first listed or last listed author',
      },
      activityEndDate: {
        label: 'Date',
      },
      creditActivityAttachments: {
        ...fileUpload,
        helpText: '',
      },
    },
  },
  [AbmsActivitySubType.SECURE_EXAM]: {
    fields: {
      subspeciality: {
        label: 'Subspecialty (optional)',
        defaultLabel: 'Select subspecialty',
        lockedInput: 'No available subspecialties for the selected board',
      },
    },
  },
  [AbmsActivitySubType.CONTINUOUS_ASSESSMENT]: {
    description:
      '<p>Continuous certification assessment is eligible for direct credits for the following boards:</p><ul><li>American Board of Dermatology</li><li>American Board of Psychiatry and Neurology</li><li>American Board of Plastic Surgery</li><li>American Board of Surgery</li></ul>CME total credit awarded will be based on the guidelines of your individual medical board.',
    previewText: '<p>Continuous certification assessment is eligible for direct credits for the following boards:</p>',
    fields: {
      subspeciality: {
        label: 'Subspecialty (optional)',
        defaultLabel: 'Select subspecialty',
        lockedInput: 'No available subspecialties for the selected board',
      },
    },
  },
  [AbmsActivitySubType.FOCUSED_PRACTICE]: {
    description: '<p>Focused Practice Designation is eligible for direct credit for the following boards: </p>',
    previewText: '<p>Focused Practice Designation is eligible for direct credit for the following boards: </p>',
    fields: {
      subspeciality: {
        label: 'Area of Focused Practice',
        defaultLabel: 'Select area of focused practice',
        lockedInput: 'No available focused practices for the selected board',
      },
    },
  },
};
