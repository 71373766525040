import { getIn } from 'formik';
import React from 'react';
import { connect } from 'react-redux';
import { ReduxStore } from '../../ducks';
import { setClaimSortOrder, SetClaimSortOrderActionCreator } from '../../ducks/claims';
import { claimsSortOrderSelector } from '../../selectors/claims';
import styled from '../../theme';
import { Color, ThemeColors } from '../../theme/primaryTheme';
import Icon from '../Icon';
import { ColumnConfig, SortOrderValues } from './index';
import { TableCell } from './styles';

export enum SortOrders {
  ASC = 'ASC',
  DESC = 'DESC',
}

export interface SortOrder {
  [key: string]: any;
}

interface Props {
  sortOrder: SortOrder;
  column: ColumnConfig;
  setClaimSortOrder: SetClaimSortOrderActionCreator;
}

const Root = styled(TableCell).attrs({
  as: 'th',
})`
  cursor: pointer;
`;

const CellWrap = styled.div`
  display: inline-flex;
  align-items: center;

  span {
    margin-left: 4px;
  }
`;

export const getOpposingSortOrder = (value?: SortOrderValues) => {
  return value === SortOrders.ASC ? SortOrders.DESC : SortOrders.ASC;
};

const SortableTableHeaderCell: React.FunctionComponent<Props> = ({ column, sortOrder, setClaimSortOrder }) => {
  let getSortOrder = (sortOrder: SortOrder) => {
    const { id } = column;
    return sortOrder && getIn(sortOrder, id);
  };

  if (typeof column.getSortOrder === 'function') {
    getSortOrder = column.getSortOrder;
  }

  const handleClick = () => {
    const { id } = column;

    const newSortOrder =
      typeof column.setSortOrder === 'function' ? column.setSortOrder(sortOrder) : { [id]: getOpposingSortOrder(getSortOrder(sortOrder)) };
    setClaimSortOrder(newSortOrder);
  };

  const getSortIconName = () => {
    if (getSortOrder(sortOrder) === SortOrders.DESC) {
      return 'fillCaretDown';
    }

    if (getSortOrder(sortOrder) === SortOrders.ASC) {
      return 'fillCaretUp';
    }

    return 'sort';
  };

  const getSortIconFill = () => {
    if (getSortOrder(sortOrder)) {
      return ThemeColors[Color.Primary];
    }

    return ThemeColors[Color.Secondary];
  };

  return (
    <Root onClick={handleClick} data-test-id={`sort-${column.id}`}>
      <CellWrap>
        {column.header}
        {column.header && <Icon viewBox="0 0 12 12" name={getSortIconName()} fill={getSortIconFill()} width={12} height={12} />}
      </CellWrap>
    </Root>
  );
};

const mapStateToProps = (state: ReduxStore) => ({
  sortOrder: claimsSortOrderSelector(state),
});

const mapDispatchToProps = {
  setClaimSortOrder,
};

export default connect(mapStateToProps, mapDispatchToProps)(SortableTableHeaderCell);
