import React from 'react';
import { LinkStyle } from '../../constants/LinkStyle';
import Icon from '../Icon';
import Link from '../Link';

interface Props {
  to: any;
  label?: string;
  replace?: boolean;
  id?: string;
}

const EditButton: React.FunctionComponent<Props> = ({ to, id, replace = false, label }) => (
  <Link data-test-id={id} to={to} replace={replace} linkStyle={LinkStyle.AccentText}>
    <Icon viewBox="0 0 10 10" width={10} height={10} name="pencil" style={{ marginRight: '0.325em' }} />
    {label ? label : 'Edit'}
  </Link>
);

export default EditButton;
