import React from 'react';
import ReactModal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import { Transition } from 'react-spring';
import { ReduxStore } from 'src/ducks';
import styled from 'src/theme';
import { hideModal } from '../../ducks/modal';
import { Color, ThemeColors } from '../../theme/primaryTheme';
import Button from '../Button';
import Icon from '../Icon';
import Text, { TextStyle } from '../Text';

export const modalId = 'desktopPrompt';

const DesktopPromptModal: React.FunctionComponent = () => {
  const dispatch = useDispatch();
  const isOpen = useSelector((state: ReduxStore) => state.modal[modalId] || false);

  const closeModal = () => {
    dispatch(hideModal(modalId));
  };

  const styleOverrides = {
    overlay: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    content: {
      width: '90vw',
      maxWidth: '80rem',
      maxHeight: '90vh',
      border: 'none',
      borderRadius: 'none',
      top: 'auto',
      left: 'auto',
      right: 'auto',
      bottom: 'auto',
      padding: '2rem',
      boxShadow: '0 2px 10px 0 rgba(0,0,0,0.25)',
    },
  };

  return (
    <Transition
      items={isOpen}
      from={{ opacity: 0, transform: 'translate3d(0, 30px, 0)' }}
      enter={{ opacity: 1, transform: 'translate3d(0, 0, 0)' }}
      leave={{ opacity: 0, transform: 'translate3d(0, 30px, 0)' }}
    >
      {(show) =>
        show &&
        ((style) => (
          <ReactModal
            isOpen={isOpen}
            htmlOpenClassName="is-scroll-locked"
            bodyOpenClassName="is-scroll-locked"
            style={{
              content: { ...styleOverrides.content, ...style },
              overlay: styleOverrides.overlay,
            }}
            onRequestClose={closeModal}
          >
            <ModalTop>
              <Text textStyle={TextStyle.ModalTitle}>Desktop recommended</Text>
              <Button buttonStyle="toggle" onClick={closeModal}>
                <Icon name="close" fill={ThemeColors[Color.Accent]} width={14} height={14} viewBox="0 0 15 15" />
              </Button>
            </ModalTop>
            <Text textStyle={TextStyle.ModalBody}>
              This page is not yet optimized for mobile. We recommend using a desktop computer to properly visualize all content.
            </Text>
            <ModalActionButton onClick={closeModal}>Got It</ModalActionButton>
          </ReactModal>
        ))
      }
    </Transition>
  );
};

const ModalTop = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2.5rem;
`;

const ModalActionButton = styled(Button)`
  margin-top: 3.5rem;
`;

export default DesktopPromptModal;
