import React from 'react';
import { BulkLearnerActivityUpload } from '../../types';
import { FileDownload } from '../FileDownload';

const rollbarErrorText = (data: BulkLearnerActivityUpload) => `Error fetching ingestion report for Bulk Learner Activity Upload ${data.id}`;

const BulkLearnerActivityUploadIngestionReportDownloadButton: React.FunctionComponent<{
  value?: string;
  data: BulkLearnerActivityUpload;
}> = (props) => (
  <FileDownload
    {...props}
    iconName="download"
    downloadedFileName={`IR-${props.data.originalFileName}`}
    rollbarErrorText={rollbarErrorText}
    hidden={!props.data.hasIngestionReportData}
  />
);

export default BulkLearnerActivityUploadIngestionReportDownloadButton;
