export enum LinkStyle {
  Button = 'button',
  DualLinks = 'dualLinks',
  Default = 'default',
  TextButton = 'textButton',
  ButtonOutline = 'buttonOutline',
  AccentText = 'accentText',
  NavLink = 'navLink',
  ExternalLink = 'externalLink',
}
