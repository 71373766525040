import React from 'react';
import { connect } from 'react-redux';
import { LinkStyle } from '../../constants/LinkStyle';
import { buildRoute, Routes } from '../../constants/Routes';
import { ReduxStore } from '../../ducks';
import { isCreditProcessor } from '../../selectors/user';
import { ExternalCreditEarner } from '../../types';
import Icon from '../Icon';
import Link from '../Link';
import ExternalLink from '../Link/ExternalLink';

export enum ItemType {
  User = 'User',
  Application = 'Application',
}

interface Props {
  itemType: ItemType;
  state?: {
    selectedUser: ExternalCreditEarner;
  };
  isProcessor: boolean;
}

const NewItemButton: React.FunctionComponent<Props> = ({ itemType, state, isProcessor }) => {
  const getPath: () => string = () => {
    if (itemType === ItemType.User) {
      return Routes.UserNew;
    }

    if (itemType === ItemType.Application) {
      if (isProcessor) {
        return state?.selectedUser ? buildRoute(Routes.ApplicantInformationWithId, { id: state.selectedUser.id }) : Routes.UserIndex;
      }

      return ['staging', 'production'].includes(process.env.REACT_APP_ENV)
        ? process.env.REACT_APP_NEW_APPLICATION_LINK
        : Routes.ApplicationNew;
    }

    // The `itemType` prop must be an `ItemType` option,
    // but setting a fallback _just in case_ we screw up.
    return Routes.Home;
  };

  const svgStyles = {
    marginRight: '0.75em',
    position: 'relative',
    top: '0.125em',
  };

  const renderBody = () => {
    return (
      <>
        <Icon name={'plusCircle'} width={20} height={20} viewBox="0 0 12 12" style={svgStyles} />
        New {itemType}
      </>
    );
  };

  const isExternal = getPath().startsWith('http');

  return isExternal ? (
    <ExternalLink linkStyle={LinkStyle.Button} href={getPath()}>
      {renderBody()}
    </ExternalLink>
  ) : (
    <Link to={{ pathname: getPath(), state }} linkStyle={LinkStyle.Button} data-test-id={`new${itemType}Button`}>
      {renderBody()}
    </Link>
  );
};

const mapStateToProps = (state: ReduxStore) => ({
  isProcessor: isCreditProcessor(state),
});

export default connect(mapStateToProps)(NewItemButton);
