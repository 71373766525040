import { AxiosError, AxiosResponse } from 'axios';
import { getIn } from 'formik';
import { Dispatch } from 'redux';
import { BulkLearnerActivityUpload, PagedResponse } from '../types';
import { apiRequest } from '../utilities/api';
import { BulkLearnerActivityUploadStore } from './types';

/* ACTIONS */
const FETCH_BULK_LEARNER_ACTIVITY_UPLOAD_REQUEST = '@BULK_LEARNER_ACTIVITY_UPLOAD/REQUEST';
const FETCH_BULK_LEARNER_ACTIVITY_UPLOAD_SUCCESS = '@BULK_LEARNER_ACTIVITY_UPLOAD/SUCCESS';
const FETCH_BULK_LEARNER_ACTIVITY_UPLOAD_ERROR = '@BULK_LEARNER_ACTIVITY_UPLOAD/ERROR';
const FETCH_BULK_LEARNER_ACTIVITY_UPLOADS_SUCCESS = '@BULK_LEARNER_ACTIVITY_UPLOADS/SUCCESS';
const RESET_BULK_LEARNER_ACTIVITY_UPLOAD = '@BULK_LEARNER_ACTIVITY_UPLOAD/RESET';
const CHANGE_BULK_LEARNER_ACTIVITY_UPLOAD_PAGE = '@BULK_LEARNER_ACTIVITY_UPLOAD/PAGE_CHANGE';

interface ActionFetchBulkLearnerActivityUploadRequest {
  type: typeof FETCH_BULK_LEARNER_ACTIVITY_UPLOAD_REQUEST;
}

interface ActionFetchBulkLearnerActivityUploadSuccess {
  type: typeof FETCH_BULK_LEARNER_ACTIVITY_UPLOAD_SUCCESS;
  data: BulkLearnerActivityUpload;
}

interface ActionFetchBulkLearnerActivityUploadError {
  type: typeof FETCH_BULK_LEARNER_ACTIVITY_UPLOAD_ERROR;
  data?: File;
  error: any; // TODO: Error type
}

interface ActionFetchBulkLearnerActivityUploadsSuccess {
  type: typeof FETCH_BULK_LEARNER_ACTIVITY_UPLOADS_SUCCESS;
  data: BulkLearnerActivityUpload[];
}

interface ActionResetBulkLearnerActivityUpload {
  type: typeof RESET_BULK_LEARNER_ACTIVITY_UPLOAD;
}

interface ChangeBulkLearnerActivityUploadPageAction {
  type: typeof CHANGE_BULK_LEARNER_ACTIVITY_UPLOAD_PAGE;
  page: number;
}

type ActionBulkLearnerActivityUpload =
  | ActionFetchBulkLearnerActivityUploadRequest
  | ActionFetchBulkLearnerActivityUploadSuccess
  | ActionFetchBulkLearnerActivityUploadError
  | ActionFetchBulkLearnerActivityUploadsSuccess
  | ActionResetBulkLearnerActivityUpload
  | ChangeBulkLearnerActivityUploadPageAction;

/* ACTION CREATORS */
export interface ResponseError {
  message: string;
  statusCode?: number;
}

type FetchBulkLearnerActivityUploadRequestActionCreator = () => ActionFetchBulkLearnerActivityUploadRequest;
const fetchBulkLearnerActivityUploadRequest: FetchBulkLearnerActivityUploadRequestActionCreator = () => {
  return { type: FETCH_BULK_LEARNER_ACTIVITY_UPLOAD_REQUEST };
};

export type FetchBulkLearnerActivityUploadSuccessActionCreator = (data: any) => ActionFetchBulkLearnerActivityUploadSuccess;
export const fetchBulkLearnerActivityUploadSuccess: FetchBulkLearnerActivityUploadSuccessActionCreator = (data) => {
  return {
    type: FETCH_BULK_LEARNER_ACTIVITY_UPLOAD_SUCCESS,
    data,
  };
};

type FetchBulkLearnerActivityUploadErrorActionCreator = (error: ResponseError, data?: File) => ActionFetchBulkLearnerActivityUploadError;
const fetchBulkLearnerActivityUploadError: FetchBulkLearnerActivityUploadErrorActionCreator = (error, data) => {
  return {
    type: FETCH_BULK_LEARNER_ACTIVITY_UPLOAD_ERROR,
    data,
    error,
  };
};

export type FetchBulkLearnerActivityUploadsSuccessActionCreator = (data: any) => ActionFetchBulkLearnerActivityUploadsSuccess;
export const fetchBulkLearnerActivityUploadsSuccess: FetchBulkLearnerActivityUploadsSuccessActionCreator = (data) => {
  return {
    type: FETCH_BULK_LEARNER_ACTIVITY_UPLOADS_SUCCESS,
    data,
  };
};

export type ResetBulkLearnerActivityUploadActionCreator = () => ActionResetBulkLearnerActivityUpload;
export const resetBulkLearnerActivityUpload: ResetBulkLearnerActivityUploadActionCreator = () => {
  return { type: RESET_BULK_LEARNER_ACTIVITY_UPLOAD };
};

/* THUNKS */
export type OnSuccess = (response?: AxiosResponse) => void;
export type OnError = (error: ResponseError) => void;

interface Config {
  onSuccess?: OnSuccess;
  onError?: OnError;
}

export type CreateBulkLearnerActivityUploadAsync = (file: File, config?: Config) => (dispatch: Dispatch) => void;
export const createBulkLearnerActivityUpload: CreateBulkLearnerActivityUploadAsync = (file, config = {}) => {
  return (dispatch: Dispatch) => {
    dispatch(fetchBulkLearnerActivityUploadRequest());

    const formData = new FormData();
    // 'originalFile' must be the FormData key here
    formData.append('originalFile', file);

    apiRequest
      .post('/v1/bulk-learner-activity-uploads', formData)
      .then((response: AxiosResponse<BulkLearnerActivityUpload>) => {
        const { data } = response;
        const { onSuccess } = config;

        dispatch(fetchBulkLearnerActivityUploadSuccess(data));

        if (onSuccess && typeof onSuccess === 'function') {
          onSuccess(response);
        }
      })
      .catch((error: AxiosError) => {
        const { onError } = config;
        let errorObj = {
          message: 'Upload failed.',
        };

        if (error.response) {
          errorObj = {
            message: getIn(error, 'response.data.error.message', 'Could not upload data.'),
          };
          dispatch(fetchBulkLearnerActivityUploadError(errorObj, file));
        } else if (error.request) {
          errorObj = {
            message: 'Could not make request',
          };
          dispatch(fetchBulkLearnerActivityUploadError(errorObj, file));
        } else {
          errorObj = {
            message: 'Upload failed',
          };
          dispatch(fetchBulkLearnerActivityUploadError(errorObj, file));
        }

        if (onError && typeof onError === 'function') {
          onError(errorObj);
        }
      });
  };
};

export type FetchBulkLearnerActivityUploadsAsync = (page?: number, pageSize?: number, config?: Config) => (dispatch: Dispatch) => void;
export const fetchBulkLearnerActivityUploads: FetchBulkLearnerActivityUploadsAsync =
  (page?: number, pageSize?: number, config = {}) =>
  async (dispatch: Dispatch) => {
    dispatch(fetchBulkLearnerActivityUploadRequest());

    apiRequest
      .get('/v1/bulk-learner-activity-uploads', {
        params: {
          page,
          pageSize,
        },
      })
      .then(({ data }: AxiosResponse<PagedResponse<BulkLearnerActivityUpload>>) => {
        dispatch(fetchBulkLearnerActivityUploadsSuccess(data));
      })
      .catch((error: AxiosError) => {
        const { onError } = config;
        let errorObj = {
          message: 'Upload failed.',
        };

        if (error.response) {
          errorObj = {
            message: getIn(error, 'response.data.error.message', 'Could not upload data.'),
          };
          dispatch(fetchBulkLearnerActivityUploadError(errorObj));
        } else if (error.request) {
          errorObj = {
            message: 'Could not make request',
          };
          dispatch(fetchBulkLearnerActivityUploadError(errorObj));
        } else {
          errorObj = {
            message: 'Upload failed',
          };
          dispatch(fetchBulkLearnerActivityUploadError(errorObj));
        }

        if (onError && typeof onError === 'function') {
          onError(errorObj);
        }
      });
  };

export type ChangeBulkLearnerActivityUploadPageActionCreator = (page: number) => ChangeBulkLearnerActivityUploadPageAction;
export const changeBulkLearnerActivityUploadPage: ChangeBulkLearnerActivityUploadPageActionCreator = (page) => {
  return {
    type: CHANGE_BULK_LEARNER_ACTIVITY_UPLOAD_PAGE,
    page,
  };
};

/* REDUCER */
export const initialState = {
  isLoading: false,
  results: [],
  page: 1,
  pageSize: 25,
  total: 0,
  error: undefined,
};

const bulkLearnerActivityUploads = (
  state: BulkLearnerActivityUploadStore = initialState,
  action: ActionBulkLearnerActivityUpload
): BulkLearnerActivityUploadStore => {
  switch (action.type) {
    case FETCH_BULK_LEARNER_ACTIVITY_UPLOAD_REQUEST:
      return {
        ...state,
        isLoading: true,
        results: initialState.results,
        error: initialState.error,
      };
    case FETCH_BULK_LEARNER_ACTIVITY_UPLOAD_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case FETCH_BULK_LEARNER_ACTIVITY_UPLOAD_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    case FETCH_BULK_LEARNER_ACTIVITY_UPLOADS_SUCCESS:
      return {
        ...state,
        ...action.data,
        isLoading: false,
      };
    case RESET_BULK_LEARNER_ACTIVITY_UPLOAD:
      return {
        ...state,
        results: initialState.results,
      };
    case CHANGE_BULK_LEARNER_ACTIVITY_UPLOAD_PAGE:
      return {
        ...state,
        page: action.page,
        error: '',
      };

    default:
      return state;
  }
};

export default bulkLearnerActivityUploads;
