import { ArrayHelpers, FieldArray, FormikHelpers } from 'formik';
import React from 'react';
import styled from '../../theme';
import { CreditActivity } from '../../types';
import Alert, { AlertType } from '../Alert';
import Panel from '../Panel';
import Text from '../Text';
import ToggleablePageSection from '../ToggleablePageSection';
import PraTranscriptActivityTable from './PraTranscriptActivityTable';

interface Props {
  activitiesAreEditable: boolean;
  activities?: CreditActivity[];
  hasErrors?: boolean;
  isProcessor: boolean;
  setFieldValue: FormikHelpers<any>['setFieldValue'];
  totalTranscriptActivityCredits: number;
}

const TranscriptActivitiesSection: React.FunctionComponent<Props> = ({
  activitiesAreEditable,
  activities = [],
  hasErrors = false,
  isProcessor = false,
  setFieldValue,
  totalTranscriptActivityCredits,
}) => {
  const sectionTitle =
    totalTranscriptActivityCredits > 0
      ? `Congratulations, ${totalTranscriptActivityCredits} credits have already been applied to this PRA application.`
      : '0 credits have been applied to this PRA application.';

  return (
    <ToggleablePageSection title={sectionTitle} id="transcriptActivitiesToggle">
      <TranscriptActivitiesDescription tag="p">
        Below are the CME credits automatically pulled from the AMA Ed Hub transcript. You can proceed and finish your application if you
        have enough credits. If you need additional credits, you can add them in the next section.
      </TranscriptActivitiesDescription>
      <Panel>
        {hasErrors && (
          <Alert data-test-id="praActivityDetailsError" type={AlertType.TableError}>
            Please correct the fields highlighted below
          </Alert>
        )}
        <FieldArray name="creditActivities">
          {({ push, remove }: ArrayHelpers) => (
            <PraTranscriptActivityTable
              activitiesAreEditable={activitiesAreEditable}
              addItem={push}
              removeItem={remove}
              activities={activities}
              isProcessor={isProcessor}
              setFieldValue={setFieldValue}
            />
          )}
        </FieldArray>
      </Panel>
    </ToggleablePageSection>
  );
};

const TranscriptActivitiesDescription = styled(Text)`
  margin-bottom: 1rem;
`;

export default TranscriptActivitiesSection;
