/**
 * The front-end doesn't have to worry about all possible values.
 * Commented values may be available but are not user selectable
 */
export enum PraCertificateCode {
  PRA_STANDARD_1Y = 'STD_1Y',
  PRA_STANDARD_2Y = 'STD_2Y',
  PRA_STANDARD_3Y = 'STD_3Y',
  // PRA_STANDARD_1Y_RESIDENCY = 'STD_1Y_RES',
  // PRA_STANDARD_2Y_RESIDENCY = 'STD_2Y_RES',
  PRA_STANDARD_3Y_RESIDENCY = 'STD_3Y_RES',
  // PRA_STANDARD_1Y_RECIPROCITY = 'STD_1Y_REC',
  // PRA_STANDARD_2Y_RECIPROCITY = 'STD_2Y_REC',
  PRA_STANDARD_3Y_RECIPROCITY = 'STD_3Y_REC',
  PRA_STANDARD_3Y_ABMS = 'STD_3Y_ABMS',
  PRA_COMMENDATION_1Y = 'CMD_1Y',
  PRA_COMMENDATION_2Y = 'CMD_2Y',
  PRA_COMMENDATION_3Y = 'CMD_3Y',
}
