export const ThemeColors = {
  accent: '#7770FC',
  background: '#EEF2F4',
  backgroundDark: '#16202D',
  backgroundLight: '#F4F6F7',
  border: '#D0DCE6',
  borderLight: '#ECF2F7',
  confirmation: '#81CB3F',
  confirmationDark: '#448D03',
  confirmationLight: '#EDF8E4',
  darkNavy: '#5D6D7B',
  success: '#5DA53E',
  error: '#FC5E47',
  primary: '#243647',
  secondary: '#8DA3B5',
  label: '#62707C',
  cardHeader: '#E7EDF3',
  reverse: '#FFFFFF',
  gray: '#758898',
  grayLight: '#d3d7da',
  grayBlue: '#6A879F',
  warning: '#F2BC35',
};

export const Color = {
  Accent: 'accent',
  Background: 'background',
  BackgroundDark: 'backgroundDark',
  BackgroundLight: 'backgroundLight',
  Border: 'border',
  BorderLight: 'borderLight',
  Confirmation: 'confirmation',
  ConfirmationDark: 'confirmationDark',
  ConfirmationLight: 'confirmationLight',
  DarkNavy: 'darkNavy',
  Error: 'error',
  Primary: 'primary',
  Secondary: 'secondary',
  Label: 'label',
  CardHeader: 'cardHeader',
  Reverse: 'reverse',
  Gray: 'gray',
  GrayLight: 'grayLight',
  GrayBlue: 'grayBlue',
  Warning: 'warning',
};

const primaryTheme = {
  colors: ThemeColors,
  breakpoints: {
    small: '480px',
    medium: '900px',
    large: '1024px',
  },
  sizes: {
    maxSiteWidth: '144rem;',
    desktopGutter: '16.5rem',
    mobileGutter: '1.6rem',
    desktopHeaderHeight: '15.2rem',
    mobileHeaderHeight: '11.5rem',
  },
};

export default primaryTheme;
