import adler32 from 'adler-32';
import moment from 'moment';
import { AbmsActivitySubType } from 'src/constants/AbmsActivitySubType';
import { Accreditor } from '../constants/Accreditor';
import { ActivityType } from '../constants/ActivityType';
import { CreditActivity } from '../types';

const AMA_BASE = '10.1001';

const doiTypes = {
  [ActivityType.Teaching]: 'tch',
  [ActivityType.Poster]: 'postr',
  [ActivityType.Medical]: 'degr',
  [ActivityType.Abms]: 'abms',
  [ActivityType.Acgme]: 'res',
  [ActivityType.ICC]: 'icc',
};

const iccDoiTypes = {
  [Accreditor.EACCME]: 'eaccme',
  [Accreditor.RCPSC]: 'rcpsc',
  [Accreditor.QCHPAD]: 'qchp',
  [Accreditor.EBAC]: 'ebac',
};

const dateFormat = 'MMDD';

export const getActivityDoi = (activity: CreditActivity): string => {
  let activityDate;
  let doiHashString;
  let doiIdentifier = doiTypes[activity.activityTypeCode];

  const formatDate = (date: any) => moment(date).format(dateFormat);

  switch (activity.activityTypeCode) {
    case ActivityType.Poster:
      activityDate = formatDate(activity.activityEndDate);
      doiHashString = activity.presentationTitle;
      break;
    case ActivityType.ICC:
      activityDate = formatDate(activity.activityStartDate);
      doiHashString = activity.conferenceTitle;
      doiIdentifier = `${doiTypes[activity.activityTypeCode]}${iccDoiTypes[activity.conferenceAccreditor]}`;
      break;
    case ActivityType.Acgme:
      activityDate = `${formatDate(activity.activityEndDate)}${activity.activityUnits}`;
      doiHashString = activity.program;
      break;
    case ActivityType.Medical:
      activityDate = formatDate(activity.graduationDate);
      doiHashString = activity.program;
      break;
    case ActivityType.Abms:
      activityDate = formatDate(activity.certificationDate);
      doiHashString = `${activity.board}, ${activity.subspeciality}${
        activity.activitySubTypeCode === AbmsActivitySubType.FOCUSED_PRACTICE ? ' Focused Practice' : ' Certification'
      }`;
      break;
    default:
      activityDate = formatDate(activity.activityEndDate);
      doiHashString = activity.activityTitle;
      break;
  }

  return `${AMA_BASE}/${doiIdentifier}.${activityDate}.${adler32.str(doiHashString) >>> 0}`;
};
