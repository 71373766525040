import { connect as formikConnect, FormikProps, FormikValues /* ErrorMessage */ } from 'formik';
import _ from 'lodash';
import React from 'react';
import styled from '../../theme';
import { themeBreakpoint } from '../../theme/helpers';
import { OrganizationFieldData } from '../../types';
import Field from '../Field';
import Text from '../Text';
import ToggleInput from '../ToggleInput';

const CreditQuestion = styled(Text)`
  flex: 1 3;
  margin-left: 0.2rem;

  @media (min-width: ${themeBreakpoint('large')}) {
    display: none;
    margin-left: 0;
  }
`;

const ToggleColumn = styled.div`
  margin-right: 4rem;

  @media (max-width: ${themeBreakpoint('large')}) {
    display: flex;
    align-items: center;
    margin-top: 2rem;
    width: 100%;
  }
`;

const ToggleTextInputGroup = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: ${themeBreakpoint('medium')}) {
    flex-direction: column-reverse;
    align-items: flex-start;
  }
`;

const StyledField = styled(Field)`
  width: 100%;
`;

const ToggleTextInput: React.SFC<any & FormikProps<FormikValues>> = ({
  formik: { setFieldValue, handleChange, values, initialValues },
  board,
}) => {
  const initialValue: OrganizationFieldData = initialValues[board.id] || {};
  const preferredUniqueId = initialValue.preferredUniqueId;
  const toggleName = `${board.id}.optInToSendToOrganization`;
  const inputName = `${board.id}.userSuppliedId`;
  const preferredValue = _.get(values, `${board.id}.${preferredUniqueId}`) || '';

  const handleInputChange = (event: any) => {
    const { currentTarget } = event;
    const { value } = currentTarget;

    if (_.isEmpty(initialValue.aimsSuppliedId) && _.isEmpty(value)) {
      setFieldValue(toggleName, false);
    } else {
      setFieldValue(toggleName, true);
    }

    if (handleChange) {
      handleChange(event);
    }
  };

  const boardPlaceholder = _.get(values, `${board.id}.aimsSuppliedId`) || board.placeholder;

  const inputValue = _.isEmpty(preferredValue) ? _.get(values, inputName) : preferredValue;

  // If the preferred unique ID is aimsSuppliedId, and aimsSuppliedId has a value, disable the input
  const fieldDisabled = preferredUniqueId === 'aimsSuppliedId' && !_.isEmpty(initialValue.aimsSuppliedId);

  // If there is any id (userSuppliedId or aimsSuppliedId) then allow toggling
  const toggleDisabled = _.isEmpty(initialValue.aimsSuppliedId) && _.isEmpty(initialValue.userSuppliedId) && _.isEmpty(inputValue);

  return (
    <ToggleTextInputGroup>
      <ToggleColumn>
        <CreditQuestion color="secondary" tag="div" bold={true}>
          Send Credit Data?
        </CreditQuestion>
        <ToggleInput name={toggleName} showLabel={true} disabled={toggleDisabled} />
      </ToggleColumn>
      <StyledField
        placeholder={boardPlaceholder}
        disabled={fieldDisabled}
        name={inputName}
        maxLength={20}
        type="text"
        onChange={handleInputChange}
        value={inputValue}
      />
    </ToggleTextInputGroup>
  );
};

export default formikConnect(ToggleTextInput);
