import React from 'react';
import { Pra } from '../../types';
import { FileDownload } from '../FileDownload';
import { DownloadButtonProps } from '../FileDownload/FileDownload';

const rollbarErrorText = (data: Pra) => `Error response generating PDF for PRA ID ${data.id}`;

const PraPrintCertificateDownloadButton: React.FunctionComponent<{
  value?: string;
  data: Pra;
  buttonProps?: DownloadButtonProps;
}> = (props) => <FileDownload {...props} iconName="file" downloadedFileName="PRA_Certificate.pdf" rollbarErrorText={rollbarErrorText} />;

export default PraPrintCertificateDownloadButton;
