import _ from 'lodash';
import React from 'react';
import Button from '../../components/Button';
import NewItemButton, { ItemType } from '../../components/NewItemButton';
import Text from '../../components/Text';
import { TextStack } from '../../components/TextStack';
import UsersTable from '../../components/UsersTable';
import { Color } from '../../theme/primaryTheme';
import { ExternalCreditEarner } from '../../types';
import { NoMatchesButtons, NoMatchesContainer } from './styles';

interface ResultsProps {
  users?: ExternalCreditEarner[];
  onResetSearch: () => void;
}

const NoResults: React.FunctionComponent<ResultsProps> = ({ onResetSearch }) => {
  return (
    <NoMatchesContainer>
      <TextStack>
        <Text textStyle="secondaryHeadline" color={Color.Secondary} bold={true}>
          No matches found.
        </Text>
        <Text textStyle="medium">Please modify your search term and try again, or create a new user.</Text>
      </TextStack>
      <NoMatchesButtons>
        <Button buttonStyle="outlined" onClick={onResetSearch} style={{ marginRight: '1.4rem' }}>
          Try a New Search
        </Button>{' '}
        <NewItemButton itemType={ItemType.User} />
      </NoMatchesButtons>
    </NoMatchesContainer>
  );
};

const Results: React.FunctionComponent<ResultsProps> = ({ users = [], onResetSearch }) => {
  if (_.isEmpty(users)) {
    return <NoResults onResetSearch={onResetSearch} />;
  } else {
    return <UsersTable users={users} />;
  }
};

export default Results;
