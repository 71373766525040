export enum Routes {
  Home = '/',
  Unauthenticate = '/unauthenticate',
  Preferences = '/preferences',
  Transcript = '/transcript',

  ApplicantInformation = '/applicant-information',
  ApplicantInformationWithId = '/applicant-information/:id',

  ApplicationActivities = '/applications/:id/activity-details',
  ApplicationShow = '/applications/:id/order-details',
  ApplicationComplete = '/applications/:id/complete',
  ApplicationCompleteToken = '/applications/:id/complete/:orderToken',
  ApplicationNew = '/new-application', // CANNOT CHANGE - AMA has an external page pointing here
  ApplicationIndex = '/applications',

  PraAwardType = '/applications/:id/award-type',
  PraActivities = '/applications/:id/credit-activities/:claimMethod',

  UserIndex = '/users',
  UserNew = '/users/new',
  UserShow = '/users/:id',
  UserEdit = '/users/:id/edit',
  UserApplications = '/users/:id/applications',
  UserTranscript = '/users/:id/transcript',

  BulkLearnerActivityUpload = '/admin/bulk-learner-activity-upload',
}

export const processorApplicationRoutes = [
  Routes.ApplicantInformation,
  Routes.ApplicantInformationWithId,
  Routes.ApplicationNew,
  Routes.ApplicationActivities,
  Routes.ApplicationShow,
  Routes.ApplicationComplete,
];

export const processorPraApplicationRoutes = [
  Routes.ApplicantInformation,
  Routes.ApplicantInformationWithId,
  Routes.ApplicationNew,
  Routes.PraAwardType,
  Routes.PraActivities,
  Routes.ApplicationShow,
  Routes.ApplicationComplete,
];

export const earnerApplicationRoutes = [
  Routes.ApplicationNew,
  Routes.ApplicationActivities,
  Routes.ApplicationShow,
  Routes.ApplicationComplete,
];

export const earnerPraApplicationRoutes = [
  Routes.ApplicationNew,
  Routes.PraAwardType,
  Routes.PraActivities,
  Routes.ApplicationShow,
  Routes.ApplicationComplete,
];

export const buildRoute = (routeKey: Routes, params: { [key: string]: string | number } = {}): string => {
  let target = routeKey as string;

  for (const [key, val] of Object.entries(params)) {
    target = target.replace(`:${key}`, typeof val === 'number' ? val.toString() : val);
  }
  return target;
};
