import { ArrayHelpers, FieldArray, FormikHelpers } from 'formik';
import React from 'react';
import styled from 'styled-components';
import { CreditActivity } from '../../types';
import Panel from '../Panel';
import Text from '../Text';
import PraCategory1ActivityTable from './PraCategory1ActivityTable';

interface Props {
  activities: CreditActivity[];
  activitiesAreEditable: boolean;
  setFieldValue: FormikHelpers<any>['setFieldValue'];
}

const Category1ActivitiesSection: React.FunctionComponent<Props> = ({ activities, activitiesAreEditable, setFieldValue }) => {
  const panelHeading = (
    <Text bold={true}>
      Previously Added{' '}
      <Text tag="em" bold={true}>
        AMA PRA Category 1 Credit&trade;
      </Text>{' '}
      Activities
    </Text>
  );

  return (
    <Root data-testid="praCategory1Section">
      <Panel heading={panelHeading}>
        <FieldArray name="category1CreditActivities">
          {({ remove }: ArrayHelpers) => (
            <PraCategory1ActivityTable
              activities={activities}
              activitiesAreEditable={activitiesAreEditable}
              setFieldValue={setFieldValue}
              removeItem={remove}
            />
          )}
        </FieldArray>
      </Panel>
    </Root>
  );
};

const Root = styled.div`
  margin-top: 3.2rem;
`;

export default Category1ActivitiesSection;
