import React from 'react';
import styled from '../../theme';
import { themeColor } from '../../theme/helpers';
import { Color } from '../../theme/primaryTheme';

export const DEFAULT_PANEL_PADDING = '18px 20px 16px';

interface Props {
  heading?: string | JSX.Element;
  headingPadding?: string | number;
}

const Panel: React.FunctionComponent<Props> = ({ children, heading, headingPadding, ...rest }) => {
  return (
    <PanelRoot {...rest}>
      {heading && <PanelHeading padding={headingPadding}>{heading}</PanelHeading>}
      {children}
    </PanelRoot>
  );
};

const PanelRoot = styled.div`
  border: 1px solid ${themeColor(Color.Border)};
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  background-color: ${themeColor(Color.Reverse)};
`;

const PanelHeading = styled.div<{ padding?: string | number }>`
  border-radius: 5px 5px 0 0;
  background-color: ${themeColor(Color.CardHeader)};
  padding: ${({ padding = DEFAULT_PANEL_PADDING }) => padding};
  font-weight: bold;
  display: flex;
`;

export const PanelBody = styled.div<{ padding?: string | number }>`
  padding: ${({ padding = DEFAULT_PANEL_PADDING }) => padding};
`;

export default Panel;
