import React from 'react';
import { PraClaimType } from '../../../constants/PraClaimType';
import Field, { FieldOnChange } from '../../Field';
import { FieldColumn, FieldRow } from '../../FieldRow';
import FormSection from '../../FormSection';
import OptionGroup, { Option } from '../../OptionGroup';

const praClaimTypeOptions: Option[] = [
  { value: PraClaimType.CME, label: 'I have completed enough CME credit for an AMA PRA' },
  { value: PraClaimType.ABMS, label: 'I would like to use my recent ABMS Board Certification' },
  { value: PraClaimType.Residency, label: 'I would like to use my recent ACGME-accredited Residency or Fellowship' },
  { value: PraClaimType.Reciprocity, label: 'I have received a reciprocity letter from a qualified medical society' },
];

const ClaimTypeSection: React.FunctionComponent<{ isEditable: boolean; onChange?: FieldOnChange }> = ({ isEditable, onChange }) => {
  return (
    <FormSection description="Please select the category that best matches your PRA application needs from the list below. Click on each button to find out more.">
      <FieldRow>
        <FieldColumn grow={1}>
          <Field
            type="radio"
            name="praClaimType"
            component={OptionGroup}
            onChange={onChange}
            fieldBorder={false}
            controlProps={{ options: praClaimTypeOptions }}
            locked={!isEditable}
          />
        </FieldColumn>
      </FieldRow>
    </FormSection>
  );
};

export default ClaimTypeSection;
