import { createAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { AnyAction, Dispatch } from 'redux';
import { ExternalCreditEarner, PagedResponse, UserSearchCriteria, UserSearchResponse } from '../types';
import { apiRequest } from '../utilities/api';
import { UserSearchStore } from './types';

export const USER_SEARCH_REQUEST = 'userSearch/USER_SEARCH_REQUEST';
export const USER_SEARCH_SUCCESS = 'userSearch/USER_SEARCH_SUCCESS';
export const USER_SEARCH_ERROR = 'userSearch/USER_SEARCH_ERROR';
export const USER_SEARCH_RESET = 'userSearch/USER_SEARCH_RESET';
export const USER_SEARCH_SET_SELECTED_RESULT = 'userSearch/SET_SELECTED_RESULT';

interface ActionUserSearchRequest extends AnyAction {
  type: typeof USER_SEARCH_REQUEST;
  criteria: UserSearchCriteria;
}

interface ActionUserSearchSuccess extends AnyAction {
  type: typeof USER_SEARCH_SUCCESS;
  data: UserSearchResponse;
}

interface ActionUserSearchError extends AnyAction {
  type: typeof USER_SEARCH_ERROR;
  error: any;
}

interface ActionUserSearchReset extends AnyAction {
  type: typeof USER_SEARCH_RESET;
}

interface ActionUserSearchSetSelectedResult extends AnyAction {
  type: typeof USER_SEARCH_SET_SELECTED_RESULT;
}

export type UserSearchAction =
  | ActionUserSearchRequest
  | ActionUserSearchSuccess
  | ActionUserSearchError
  | ActionUserSearchReset
  | ActionUserSearchSetSelectedResult;

export const userSearchSuccess = (data: UserSearchResponse): ActionUserSearchSuccess => {
  return {
    type: USER_SEARCH_SUCCESS,
    data,
  };
};

export const userSearchRequest = (criteria: UserSearchCriteria): ActionUserSearchRequest => {
  return {
    type: USER_SEARCH_REQUEST,
    criteria,
  };
};

export const userSearchError = (error: any): ActionUserSearchError => {
  return {
    type: USER_SEARCH_ERROR,
    error,
  };
};

export const userSearchReset = (): ActionUserSearchReset => {
  return {
    type: USER_SEARCH_RESET,
  };
};

export const fetchUserSearchResults = (query: UserSearchCriteria, page: number, pageSize = 10) => {
  return async (dispatch: Dispatch) => {
    dispatch(userSearchRequest(query));

    try {
      const { data } = (await apiRequest.get('/v1/user/search', {
        params: {
          q: query,
          page,
          pageSize,
          view: 'external',
        },
      })) as AxiosResponse<PagedResponse<ExternalCreditEarner>>;
      dispatch(userSearchSuccess(data));
    } catch (error) {
      if (error.response) {
        dispatch(
          userSearchError({
            message: `${error.response.status} status returned`,
          })
        );
      } else if (error.request) {
        dispatch(
          userSearchError({
            message: 'No response returned',
          })
        );
      } else {
        dispatch(userSearchError(error));
      }
    }
  };
};

export const setSelectedResult = createAction<ExternalCreditEarner>(USER_SEARCH_SET_SELECTED_RESULT);

export const initialState: UserSearchStore = {
  criteria: {
    firstName: '',
    lastName: '',
  },
  users: [],
  currentPage: 1,
  totalResults: 0,
  pageSize: 10,
  isLoading: false,
  hasError: false,
  error: {},
  selectedResult: undefined,
};

const userSearch = (state = initialState, action: UserSearchAction): UserSearchStore => {
  switch (action.type) {
    case USER_SEARCH_SUCCESS:
      return {
        ...state,
        users: action.data.results,
        currentPage: action.data.page,
        totalResults: action.data.total,
        pageSize: action.data.pageSize,
        isLoading: false,
      };

    case USER_SEARCH_REQUEST:
      return {
        ...state,
        criteria: action.criteria,
        users: [],
        isLoading: true,
        hasError: false,
        error: {},
      };
    case USER_SEARCH_ERROR:
      return {
        ...state,
        isLoading: false,
        hasError: true,
        error: action.error,
      };
    case USER_SEARCH_RESET:
      return { ...initialState };

    case USER_SEARCH_SET_SELECTED_RESULT:
      return {
        ...state,
        selectedResult: action.payload,
      };

    default:
      return state;
  }
};

export default userSearch;
