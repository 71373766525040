import React from 'react';
import styled from 'src/theme';
import { themeBreakpoint } from 'src/theme/helpers';
import Icon from '../Icon';
import { DropdownProps } from './index';
import { StyledButton } from './styles';

export const DropdownButton: React.FunctionComponent<DropdownProps> = ({ children, ...rest }) => {
  return (
    <StyledButton {...rest}>
      {children}
      <DesktopOnlyIcon viewBox="0 0 10 5" width={10} height={5} name={rest.isOpen ? 'caretUp' : 'caretDown'} />
    </StyledButton>
  );
};

const DesktopOnlyIcon = styled(Icon)`
  @media (max-width: ${themeBreakpoint('medium')}) {
    display: none;
  }
`;
