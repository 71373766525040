import React from 'react';
import styled from '../../theme';
import getPath from './paths';

// TODO: Break this into individual files and import those files
interface IconProps {
  name?: string;
  className?: string;
  style?: Record<string, string | number>;
  fill?: string;
  width?: string | number;
  height?: string | number;
  viewBox?: string;
  transform?: string;
}

export const IconWrap = styled.span`
  display: inline-block;
  width: ${(props: IconProps) => props.width || 'auto'};
  height: ${(props: IconProps) => props.height || 'auto'};
`;

const Icon: React.FunctionComponent<IconProps> = ({
  name = 'default',
  className = '',
  style = {},
  fill = 'currentColor',
  width = '100%',
  height = '100%',
  viewBox = '0 0 32 32',
  transform = 'translate(0, 0)',
}) => {
  return (
    <IconWrap data-test-id={`icon-${name}`} className={className} style={{ width, height, ...style }}>
      <svg
        fill={fill}
        name={name}
        style={style}
        height={height}
        width={width}
        viewBox={viewBox}
        transform={transform}
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        {getPath(name, fill)}
      </svg>
    </IconWrap>
  );
};

export default Icon;
