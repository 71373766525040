import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxStore } from '../../ducks';
import { fetchPras } from '../../ducks/pras';
import LoadingSpinner from '../LoadingSpinner';

interface Props {
  userId?: number;
}

const FetchPras: React.FunctionComponent<Props> = ({ userId, children }) => {
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state: ReduxStore) => state.pras);

  React.useEffect(
    () => {
      if (userId) {
        dispatch(fetchPras(userId));
      }
    },
    [userId] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return isLoading ? <LoadingSpinner /> : <>{children}</>;
};

export default FetchPras;
