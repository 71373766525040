import styled, { css } from '../../theme';
import { themeColor } from '../../theme/helpers';
import { Color } from '../../theme/primaryTheme';
import Button from '../Button';
import TextArea from '../Field/Controls/TextArea';
import { StyledField } from '../Field/styles';

interface NotesProps {
  isEditMode: boolean;
  isDirty: boolean;
  notesStyle?: string;
}

/**
 * NOTES HEADER
 */
const headerStyles = {
  modal: css`
    margin-bottom: 1rem;
  `,
  sidebar: css`
    padding: 1rem 2rem;
    background-color: ${themeColor(Color.CardHeader)};
    border: 1px solid ${themeColor(Color.Border)};
    border-bottom: 0;
    border-radius: 3px 3px 0 0;
  `,
  default: css``,
};

export const NotesHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

/**
 * NOTES LABEL
 */
const labelStyles = {
  modal: css``,
  sidebar: css`
    font-size: 1.8rem;
  `,
  default: css``,
};
export const NotesLabel = styled.label`
  font-weight: 700;
`;

/**
 * NOTES ACTIONS
 */
export const NotesActions = styled.div`
  display: flex;
  font-size: 14px;
  line-height: 1.2;
`;

/**
 * NOTES BUTTON
 */
interface NotesButtonProps {
  color?: string;
}
export const NotesButton = styled(Button)<NotesButtonProps>`
  color: ${({ color }) => color || 'inherit'};

  & + & {
    margin-left: 2rem;
  }

  &:disabled {
    background-color: transparent !important;
  }
`;

/**
 * NOTES BODY
 */
const bodyStyles = {
  modal: css`
    border-radius: 3px;
  `,
  sidebar: css`
    border-radius: 0 0 3px 3px;
  `,
  default: css``,
};

export const NotesBody = styled.div`
  background-color: ${themeColor(Color.Reverse)};
  border: 1px solid ${themeColor(Color.Border)};

  ${StyledField} {
    border: 0;
    border-radius: 0;
    background-color: transparent;
  }
`;

/**
 * NOTES TEXTAREA + READONLY TEXT DIV
 */
const baseTextStyles = css`
  min-height: 15rem;
  padding: 2rem;
  font-size: 1.4rem;
  color: ${themeColor(Color.Primary)};
  background-color: transparent;
`;

export const NotesTextArea = styled(TextArea)`
  ${baseTextStyles};

  :-webkit-input-placeholder {
    color: ${themeColor(Color.Secondary)};
  }
`;

export const NotesText = styled.div`
  ${baseTextStyles};
  white-space: pre-wrap;
`;

/**
 * ROOT NOTES COMPONENT
 */
const rootStyles = {
  modal: css`
    margin-top: 3rem;
  `,
  sidebar: css`
    margin: 3.5rem 45px;
  `,
  default: css``,
};

export const Root = styled.div<NotesProps>`
  display: flex;
  flex-direction: column;
  ${({ notesStyle = 'default' }) => rootStyles[notesStyle]};

  ${NotesHeader} {
    ${({ notesStyle = 'default' }) => headerStyles[notesStyle]};
  }

  ${NotesLabel} {
    ${({ notesStyle = 'default' }) => labelStyles[notesStyle]};

    ${({ isEditMode }) => {
      if (!isEditMode) {
        return css`
          color: ${themeColor(Color.Label)};
        `;
      } else {
        return css`
          color: ${themeColor(Color.BackgroundDark)};
        `;
      }
    }}
  }

  ${NotesBody} {
    ${({ notesStyle = 'default' }) => bodyStyles[notesStyle]};
  }

  ${NotesText} {
    ${({ isDirty }) => {
      if (!isDirty) {
        return css`
          color: ${themeColor(Color.Secondary)};
        `;
      } else {
        return css`
          color: ${themeColor(Color.Primary)};
        `;
      }
    }}
  }
`;
