import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, RouteProps, BrowserRouter as Router, Switch } from 'react-router-dom';
import { Dispatch } from 'redux';
import { Routes } from '../../constants/Routes';
import { UserTypes } from '../../constants/UserTypes';
import { ReduxStore } from '../../ducks';
import BulkLearnerActivityUpload from '../../layouts/BulkLearnerActivityUpload/BulkLearnerActivityUpload';
import NewUser from '../../layouts/NewUser';
import NotFound from '../../layouts/NotFound';
import Preferences from '../../layouts/Preferences';
import SignOut from '../../layouts/SignOut';
import SingleUser from '../../layouts/SingleUser';
import Transcript from '../../layouts/Transcript';
import UserSearch from '../../layouts/UserSearch';
import { isAuthenticated } from '../../selectors/auth';
import { isAdmin, isCreditProcessor, userInitializedSelector } from '../../selectors/user';
import ActivityDetails from '../ActivityDetails';
import ApplicantInformation from '../ApplicantInformation';
import { ApplicationFinished } from '../ApplicationFinished';
import ApplicationIndex from '../ApplicationIndex';
import { NewApplication } from '../NewApplication';
import PraActivityDetails from '../PraActivityDetails';
import { PraAwardType } from '../PraAwardType';
import OrderDetails from '../SingleClaim/OrderDetails';
import AmaRoute from './AmaRoute';
import ScrollToTop from './ScrollToTop';

interface Props {
  isCreditProcessor: boolean;
  isInitialized: boolean;
  isAuthenticated: boolean;
  isAdmin: boolean;
  dispatch: Dispatch;
}

interface RouteConfigItem extends RouteProps {
  title?: string;
  subtitle?: string;
  restrictedTo?: UserTypes[];
}

export const routeConfig: RouteConfigItem[] = [
  {
    title: 'MOC and CME automated reporting',
    path: Routes.Home,
    render: () => {
      return <Redirect to={Routes.Preferences} />;
    },
    exact: true,
  },
  {
    title: 'Sign Out',
    path: Routes.Unauthenticate,
    component: SignOut,
  },
  {
    title: 'MOC Reporting Preferences',
    path: Routes.Preferences,
    component: Preferences,
    restrictedTo: [UserTypes.Earner],
  },
  {
    title: 'Loading',
    path: Routes.Preferences,
    render: () => {
      return <Redirect to={Routes.ApplicationIndex} />;
    },
    restrictedTo: [UserTypes.Processor],
  },
  {
    title: 'Transcript',
    path: Routes.Transcript,
    component: Transcript,
    restrictedTo: [UserTypes.Earner],
  },
  {
    title: 'Confirm User Info',
    path: Routes.ApplicationNew,
    component: NewApplication,
    restrictedTo: [UserTypes.Earner],
  },
  {
    title: 'Profile Info',
    path: Routes.ApplicantInformationWithId,
    component: ApplicantInformation,
    restrictedTo: [UserTypes.Processor],
  },
  {
    title: 'Profile Info',
    path: Routes.ApplicantInformation,
    component: ApplicantInformation,
    restrictedTo: [UserTypes.Processor],
  },
  {
    title: 'Processing Information',
    path: Routes.ApplicationNew,
    component: NewApplication,
    restrictedTo: [UserTypes.Processor],
  },
  {
    title: 'Award Type',
    path: Routes.PraAwardType,
    component: PraAwardType,
    restrictedTo: [UserTypes.Earner, UserTypes.Processor],
  },
  {
    title: 'Add Activities',
    path: Routes.PraActivities,
    component: PraActivityDetails,
    restrictedTo: [UserTypes.Earner, UserTypes.Processor],
  },
  {
    title: 'Activity Details',
    path: Routes.ApplicationActivities,
    component: ActivityDetails,
    restrictedTo: [UserTypes.Earner, UserTypes.Processor],
  },
  {
    title: 'Application Summary',
    path: Routes.ApplicationShow,
    component: OrderDetails,
    restrictedTo: [UserTypes.Earner, UserTypes.Processor],
  },
  {
    title: 'Application Complete',
    path: Routes.ApplicationCompleteToken,
    component: ApplicationFinished,
    restrictedTo: [UserTypes.Earner, UserTypes.Processor],
  },
  {
    title: 'Application Complete',
    path: Routes.ApplicationComplete,
    component: ApplicationFinished,
    restrictedTo: [UserTypes.Earner, UserTypes.Processor],
  },
  {
    title: 'Applications',
    path: Routes.ApplicationIndex,
    component: ApplicationIndex,
    restrictedTo: [UserTypes.Processor, UserTypes.Earner],
    exact: true,
  },
  {
    title: 'New User',
    path: Routes.UserNew,
    component: NewUser,
    restrictedTo: [UserTypes.Processor],
  },
  {
    title: 'User',
    path: Routes.UserShow,
    component: SingleUser,
    restrictedTo: [UserTypes.Processor],
  },
  {
    title: 'User Search',
    path: Routes.UserIndex,
    component: UserSearch,
    restrictedTo: [UserTypes.Processor],
  },
  {
    title: 'Bulk Learner Activity Upload',
    path: Routes.BulkLearnerActivityUpload,
    component: BulkLearnerActivityUpload,
    restrictedTo: [UserTypes.Admin],
  },
];

const getUserRoutes = (userTypes: UserTypes[]) =>
  routeConfig.filter(({ restrictedTo = [] }) => restrictedTo.length && restrictedTo.some((type) => userTypes.includes(type)));

const AppRoutes: React.FunctionComponent<Props> = (props) => {
  const { isAdmin = false, isCreditProcessor = false, isAuthenticated, isInitialized = false } = props;
  const userType = isCreditProcessor ? UserTypes.Processor : UserTypes.Earner;
  const userTypes: UserTypes[] = [...(isAdmin ? [UserTypes.Admin] : []), userType];

  const availableRoutes = getUserRoutes(userTypes);
  const publicRoutes = routeConfig.filter(({ restrictedTo = [] }) => !restrictedTo.length);

  return (
    <Router>
      <ScrollToTop>
        <Switch>
          {publicRoutes.map((route, idx) => (
            <Route key={`${idx}-${route.path}`} {...route} />
          ))}

          {availableRoutes.map((route) => (
            <AmaRoute isAuthenticated={isAuthenticated} userType={userType} isInitialized={isInitialized} key={route.title} {...route} />
          ))}

          <Route component={NotFound} />
        </Switch>
      </ScrollToTop>
    </Router>
  );
};

const mapStateToProps = (state: ReduxStore) => {
  return {
    isCreditProcessor: isCreditProcessor(state),
    isAuthenticated: isAuthenticated(state),
    isInitialized: userInitializedSelector(state),
    isAdmin: isAdmin(state),
  };
};

export default connect(mapStateToProps)(AppRoutes);
