import { FormikBag } from 'formik';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import LoadingSpinner from '../../../components/LoadingSpinner';
import { FormId } from '../../../constants/FormId';
import { fetchBoardData } from '../../../ducks/boardData';
import { formError, formSuccess, submitForm } from '../../../ducks/form';
import { updateInitialValues } from '../../../ducks/initialValues';
import { Board } from '../../../types';
import { apiRequest } from '../../../utilities/api';
import ConfirmationForm from '../ConfirmationForm';
import { FormGroup } from '../ConfirmationForm/styles';
import BoardFields from './BoardFields';
import { labels } from './labels';
import ModalBody from './ModalBody';
import SectionHeader from './SectionHeader';

const MODAL_ID = 'optInForm';

interface IFormSection {
  id: string;
  title: string;
  description: string;
  placeholder: string;
  boards: Board[];
}

interface IFormStateProps {
  sections: IFormSection[];
  initialValues: any;
  isLoading: any;
}

interface IFormDispatchProps {
  fetchBoardData: () => any;
  submitForm: () => any;
  formError: (errors: any) => any;
  updateInitialValues: (s: string, value: any) => any;
  formSuccess: () => any;
}

const valueChanged = (val1: any, val2: any) => {
  // _.isEmpty(true) resolves to true. Don't use it here.
  return !(((_.isUndefined(val1) || _.isNull(val1)) && (_.isUndefined(val2) || _.isNull(val2))) || val1 === val2);
};

const mapDispatchToProps: IFormDispatchProps = {
  fetchBoardData,
  submitForm,
  formError,
  formSuccess,
  updateInitialValues,
};

type FormProps = IFormStateProps & IFormDispatchProps;

const OptInBoard = Yup.object().shape({
  optInToSendToOrganization: Yup.bool().nullable(true),
  userSuppliedId: Yup.string().label('ID'),
});

const OptInSchema = (boardIds: string[]) =>
  Yup.object().shape(
    boardIds.reduce((accumulator: any, id: string) => {
      accumulator[id] = OptInBoard;
      return accumulator;
    }, {})
  );

interface FormValues {
  [id: number]: Board;
}

class OptInForm extends React.Component<FormProps> {
  public componentDidMount(): void {
    this.props.fetchBoardData();
  }

  public render() {
    const { sections, initialValues, isLoading } = this.props;
    const boardIds = Object.keys(initialValues);

    if (isLoading) {
      return <LoadingSpinner />;
    }

    return (
      <ConfirmationForm
        initialValues={initialValues}
        validationSchema={OptInSchema(boardIds)}
        onConfirm={this.handleSubmit}
        modalTitle="Please review changes to your opt-out preferences"
        modalId={MODAL_ID}
        formId={FormId.OptIn}
        modalBodyComponent={ModalBody}
      >
        <>
          {sections.map((section: IFormSection) => {
            const { boards = [] } = section;
            return (
              <div key={section.id}>
                {boards.length > 0 && (
                  <FormGroup key="state-boards">
                    <SectionHeader title={section.title} description={section.description} />
                    <BoardFields boards={boards} />
                  </FormGroup>
                )}
              </div>
            );
          })}
        </>
      </ConfirmationForm>
    );
  }

  private handleSubmit = async (values: FormValues, formikBag: FormikBag<any, FormValues>) => {
    this.props.submitForm();

    const body = Object.keys(values).reduce((accumulator, boardId: string) => {
      const board: Board = values[boardId];
      const initValues = this.props.initialValues[boardId];

      // If there's no change to optIn or userSuppliedId, don't send it
      if (
        (_.isUndefined(board.optInToSendToOrganization) && _.isUndefined(board.userSuppliedId)) ||
        (!valueChanged(board.userSuppliedId, initValues.userSuppliedId) &&
          !valueChanged(board.optInToSendToOrganization, initValues.optInToSendToOrganization))
      ) {
        return accumulator;
      }

      // If the board has no userDefinableAttributes then we should not be submitting it
      if (!board.userDefinableAttributes.length) {
        return accumulator;
      }

      accumulator.push(board);

      return accumulator;
    }, [] as Board[]);

    try {
      const errors: any = {};
      const { data } = await apiRequest.post('/v1/user/creditearner/organizations', body);

      if (data && _.isArray(data)) {
        data.forEach((response: { id: number; status: number; message?: string }) => {
          if (response.status < 200 || response.status >= 300) {
            errors[response.id] = response.message;
          }
        });

        if (Object.keys(errors).length > 0) {
          const { setFieldError } = formikBag;

          Object.keys(errors).forEach((boardId: string) => {
            const preferredUniqueId = values[boardId].preferredUniqueId;
            setFieldError(`[${boardId}][${preferredUniqueId}]`, 'Could not save ID.');
          });

          return this.props.formError(errors);
        } else {
          const newValues = data
            .filter((response) => response.status === 200)
            .map((response) => {
              return values[response.id];
            })
            .reduce((accumulator, board) => {
              accumulator[board.id] = board;
              return accumulator;
            }, {});

          this.props.updateInitialValues('optIn', newValues);
          return this.props.formSuccess();
        }
      }
    } catch (error) {
      return this.props.formError({ submit: error.message });
    }
  };
}

interface IBoardTypes {
  defaultBoards: { [id: string]: Board };
  stateBoards: Board[];
  specialtyBoards: Board[];
}
const mapStateToProps = (state: any): IFormStateProps => {
  const { boardData, initialValues } = state;
  const { data: boards = [], isLoading } = boardData;

  const { defaultBoards, stateBoards, specialtyBoards }: IBoardTypes = boards.reduce(
    (boardTypes: IBoardTypes, board: Board) => {
      board.userSuppliedId = board.userSuppliedId || '';

      boardTypes.defaultBoards[board.id] = board;

      if (board.isStateBoard) {
        boardTypes.stateBoards.push(board);
      } else {
        boardTypes.specialtyBoards.push(board);
      }

      return boardTypes;
    },
    {
      defaultBoards: {} as { [id: string]: Board },
      stateBoards: [] as Board[],
      specialtyBoards: [] as Board[],
    } as IBoardTypes
  );

  const sections = [
    {
      id: 'state',
      ...labels.state,
      boards: _.sortBy(stateBoards, labels.state.sortKeys),
    },
    {
      id: 'specialty',
      ...labels.specialty,
      boards: _.sortBy(specialtyBoards, labels.specialty.sortKeys),
    },
  ];

  /*
   * We need to do this because Formik gets mad if we don't initialize the form
   * with a value for each field a user can edit. This causes some problems for
   * us since we get the available fields from a different endpoint than
   * we get the data to populate it.
   */
  const adjustedInitialValues = {
    ...defaultBoards,
    ...initialValues.optIn,
  };

  return {
    sections,
    isLoading,
    initialValues: adjustedInitialValues,
  };
};

export default connect<IFormStateProps, IFormDispatchProps>(mapStateToProps, mapDispatchToProps)(OptInForm);
