import styled from '../../theme';
import { themeColor } from '../../theme/helpers';
import { Color } from '../../theme/primaryTheme';

export const ActivityHeader = styled.div`
  display: flex;
  background-color: ${themeColor(Color.CardHeader)};
  padding: 1.5rem 2rem;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px 4px 0 0;
`;

export const ActivityBody = styled.div`
  padding: 3rem 2rem;
`;

export const AddItemFooter = styled.div`
  margin-top: 2rem;
`;

export const ActivitySection = styled.section`
  border: 1px solid ${themeColor(Color.Border)};
  background-color: ${themeColor(Color.Reverse)};
  border-radius: 5px;

  & + & {
    margin-top: 2rem;
  }
`;
