import styled from '../../theme';
import { themeBreakpoint } from '../../theme/helpers';

export const Columns = styled.div`
  @media (min-width: ${themeBreakpoint('large')}) {
    display: flex;
    flex-wrap: wrap;
  }
`;

export const ColumnsHeader = styled.div`
  width: 100%;
  margin-bottom: 2rem;

  @media (min-width: ${themeBreakpoint('large')}) {
    padding-right: 35%;
  }
`;

export const ColumnsMain = styled.article`
  @media (min-width: ${themeBreakpoint('large')}) {
    width: 65%;
  }
`;

export const ColumnsSide = styled.aside`
  @media (max-width: ${themeBreakpoint('large')}) {
    margin-top: 9rem;
  }

  @media (min-width: ${themeBreakpoint('large')}) {
    width: 35%;
    padding-left: 5%;
  }
`;
