import React from 'react';
import { connect } from 'react-redux';
import styled from 'src/theme';
import { ReduxStore } from '../../ducks';
import { fetchBulkLearnerActivityUploads, FetchBulkLearnerActivityUploadsAsync } from '../../ducks/bulkLearnerActivityUploads';
import {
  bulkLearnerActivityUploadLoadingSelector,
  bulkLearnerActivityUploadPageSelector,
  bulkLearnerActivityUploadPageSizeSelector,
} from '../../selectors/bulkLearnerActivityUploads';
import LoadingSpinner from '../LoadingSpinner';

interface Props {
  isLoading: boolean;
  fetchBulkLearnerActivityUploads: FetchBulkLearnerActivityUploadsAsync;
  page: number;
  pageSize: number;
}

const FetchBulkLearnerActivityUploads: React.FunctionComponent<Props> = ({
  isLoading,
  fetchBulkLearnerActivityUploads,
  page,
  pageSize,
  children,
}) => {
  React.useEffect(() => {
    fetchBulkLearnerActivityUploads(page, pageSize);
  }, [page]); // eslint-disable-line react-hooks/exhaustive-deps

  return isLoading ? <StyledLoadingSpinner /> : <>{children}</>;
};

const StyledLoadingSpinner = styled(LoadingSpinner)`
  margin-top: 2rem;
`;

const mapStateToProps = (state: ReduxStore, { page, pageSize }: { page?: number; pageSize?: number }) => ({
  isLoading: bulkLearnerActivityUploadLoadingSelector(state),
  page: page ?? bulkLearnerActivityUploadPageSelector(state),
  pageSize: pageSize ?? bulkLearnerActivityUploadPageSizeSelector(state),
});

const mapDispatchToProps = {
  fetchBulkLearnerActivityUploads,
};

export default connect(mapStateToProps, mapDispatchToProps)(FetchBulkLearnerActivityUploads);
