import { FieldProps, getIn } from 'formik';
import React from 'react';
import { AbmsActivitySubType, directCreditAbmsActivitySubTypeOptions } from 'src/constants/AbmsActivitySubType';
import styled from 'styled-components';
import { ActivityType } from '../../constants/ActivityType';
import { CreditClaimStatus } from '../../constants/ApplicationStatus';
import { Credits } from '../../constants/Credits';
import { emptyCellCharacter } from '../../constants/General';
import { Color } from '../../theme/primaryTheme';
import { Board } from '../../types';
import Field from '../Field';
import LockedInput from '../Field/Controls/LockedInput';
import Select from '../Field/Controls/Select';
import { Option } from '../Field/Controls/Select/index';
import DatePickerField from '../Field/DatePickerField';
import { FieldColumn, FieldRow } from '../FieldRow';
import Label from '../Label';
import OptionGroup from '../OptionGroup';
import ProofDocumentUpload from '../ProofDocumentUpload';
import Text, { TextStyle } from '../Text';
import { GenericActivityProps } from './index';
import ProcessorFields from './ProcessorFields';
import Requirements from './Requirements';
import { TotalCreditsRow } from './styles';
import { useSelector } from 'react-redux';
import { claimIsCompletedSelector, claimIsEditableSelector } from 'src/selectors/claims';
import { creditActivityIsEditable } from 'src/utilities/creditActivities';
import { activityContent } from 'src/content/activities';

interface AbmsActivityProps extends GenericActivityProps {
  abmsBoards: Board[];
}

interface RequirementType {
  content: string;
  previewText?: string;
}

const FOCUSED_PRACTICE_DESIGNATION = 'focused practice designation';

const AbmsFields: React.FunctionComponent<AbmsActivityProps> = ({ baseName, values, isProcessor, claimId, claimStatus, abmsBoards }) => {
  const currentCreditActivity = getIn(values, baseName);
  const isContinuousCertificationAssessmentSelected =
    currentCreditActivity.activitySubTypeCode === AbmsActivitySubType.CONTINUOUS_ASSESSMENT;
  const isSecureExamSelected = currentCreditActivity.activitySubTypeCode === AbmsActivitySubType.SECURE_EXAM;
  const isFocusedPracticeSelected = currentCreditActivity.activitySubTypeCode === AbmsActivitySubType.FOCUSED_PRACTICE;
  const shouldDisplayCreditField = isFocusedPracticeSelected || (isProcessor && isContinuousCertificationAssessmentSelected);

  const previouslySelectedBoard = abmsBoards.find((board) => board.name === currentCreditActivity.board);
  const [subspecialties, setSubspecialties] = React.useState<string[]>(
    previouslySelectedBoard && previouslySelectedBoard.subspecialties ? previouslySelectedBoard.subspecialties : []
  );

  const claimIsCompleted = useSelector(claimIsCompletedSelector);
  const claimIsEditable = useSelector(claimIsEditableSelector);

  const activitySubTypeCode = currentCreditActivity.activitySubTypeCode
    ? currentCreditActivity.activitySubTypeCode
    : AbmsActivitySubType.SECURE_EXAM;
  const { description = '', previewText = '', fields } = activityContent[activitySubTypeCode];
  const [requirements, setRequirements] = React.useState<RequirementType>({ content: description, previewText });
  const [filteredBoards, setFilteredBoards] = React.useState<Array<Board>>(abmsBoards);

  const abmsBoardOptions: Option[] = filteredBoards
    .sort((a, b) => (a.name > b.name ? 1 : -1))
    .map(({ name }) => ({
      value: name,
      label: name,
    }));

  const subspecialtyOptions: Option[] = subspecialties
    .filter((subspecialty: string) => {
      const isFocusedPracticeSubspecialty = subspecialty?.toLowerCase()?.endsWith(FOCUSED_PRACTICE_DESIGNATION);
      return (isFocusedPracticeSelected && isFocusedPracticeSubspecialty) || (!isFocusedPracticeSelected && !isFocusedPracticeSubspecialty);
    })
    .sort()
    .map((subspecialty: string) => ({
      value: subspecialty,
      label: subspecialty,
    }));

  const handleBoardChange = (event: React.ChangeEvent<any>, formikProps: FieldProps) => {
    const {
      target: { value },
    } = event;
    const {
      form: { setFieldValue, setFieldTouched },
    } = formikProps;
    setFieldValue(`${baseName}.board`, value);

    const newlySelectedBoard = abmsBoards.find((board) => board.name === value);

    setSubspecialties(newlySelectedBoard && newlySelectedBoard.subspecialties ? newlySelectedBoard.subspecialties : []);
    setFieldValue(`${baseName}.subspeciality`, '');
    setFieldTouched(`${baseName}.subspeciality`, false);
  };

  const handleActivitySubTypeCodeChange = (event: React.ChangeEvent<any>, formikProps: FieldProps) => {
    const {
      target: { value },
    } = event;

    const {
      form: { setFieldValue, setFieldTouched },
    } = formikProps;

    let content = '';

    if (value === AbmsActivitySubType.FOCUSED_PRACTICE) {
      const fpdBoards = abmsBoards.filter((board) =>
        Boolean(board.subspecialties?.find((s) => s.toLowerCase().includes(FOCUSED_PRACTICE_DESIGNATION)))
      );
      content = fpdBoards.length
        ? `${activityContent[value].description}<ul>${fpdBoards.map((board) => `<li>${board.name}</li>`).join('')}</ul>`
        : 'No FPD boards currently available.';
      setFilteredBoards(fpdBoards);
    } else {
      content = activityContent[value]?.description ?? '';
      setFilteredBoards(abmsBoards);
    }
    setRequirements({ content, previewText: activityContent[value]?.previewText ?? '' });
    setFieldTouched(`${baseName}.board`, false);

    const activityIsEditable = creditActivityIsEditable({
      activity: currentCreditActivity,
      claimIsCompleted,
      claimIsEditable,
      isProcessor,
    });

    if (activityIsEditable) {
      const numberOfCredits = value === AbmsActivitySubType.SECURE_EXAM ? Credits[ActivityType.Abms] : '';
      setFieldValue(`${baseName}.numberOfCredits`, numberOfCredits);
    }
  };

  const renderTotalCredits = () => {
    // Display default credit amount for Secure Exam Certification activities
    if (isSecureExamSelected) {
      return Credits[ActivityType.Abms];
    }

    // Display saved credit amount for Continuous Certification and Focused Practice activities
    if (currentCreditActivity.id && currentCreditActivity.numberOfCredits > 0) {
      return currentCreditActivity.numberOfCredits;
    }

    return emptyCellCharacter;
  };

  return (
    <>
      <FieldRow>
        <FieldColumn grow={1}>
          <Field
            type="radio"
            name={`${baseName}.activitySubTypeCode`}
            component={OptionGroup}
            fieldBorder={false}
            controlProps={{ options: directCreditAbmsActivitySubTypeOptions }}
            onChange={handleActivitySubTypeCodeChange}
          />
        </FieldColumn>
      </FieldRow>
      <Requirements content={requirements.content} cutoff={500} previewText={requirements.previewText} />
      <FieldRow>
        <FieldColumn grow={1}>
          {abmsBoardOptions.length > 0 && (
            <Field
              name={`${baseName}.board`}
              required={true}
              component={Select}
              onChange={handleBoardChange}
              controlProps={{
                options: abmsBoardOptions,
                defaultLabel: 'Select board',
              }}
              label="Board"
            />
          )}
        </FieldColumn>
      </FieldRow>
      {subspecialtyOptions.length > 0 ? (
        <FieldRow>
          <FieldColumn grow={1}>
            <Field
              name={`${baseName}.subspeciality`}
              component={Select}
              controlProps={{
                options: subspecialtyOptions,
                defaultLabel: fields.subspeciality.defaultLabel,
              }}
              required={isFocusedPracticeSelected}
              label={fields.subspeciality.label}
            />
          </FieldColumn>
        </FieldRow>
      ) : currentCreditActivity.board !== '' ? (
        <FieldRow>
          <FieldColumn grow={1}>
            <Label>{fields.subspeciality.label}</Label>
            <LockedInput value={fields.subspeciality.lockedInput} data-test-id="subspecialtyLockedInput" />
          </FieldColumn>
        </FieldRow>
      ) : null}
      <FieldRow>
        <FieldColumn>
          <DatePickerField name={`${baseName}.certificationDate`} required={true} label="Date" />
        </FieldColumn>
      </FieldRow>
      <FieldRow>
        <FieldColumn grow={1}>
          <ProofDocumentUpload
            name={`${baseName}.creditActivityAttachments`}
            required={!isProcessor}
            claimId={claimId}
            label="Proof Document(s)"
            helpText="Attach a copy of the board certificate, continuous certification assessment certificate, specialty board notification
             letter or focus practice designation certificate. The certificate date will match the date of completion of your submitted certificate or notification letter."
          />
        </FieldColumn>
      </FieldRow>
      <AbmsTotalCreditsRow>
        <FieldColumn grow={1}>
          <Text tag="span" textStyle={TextStyle.Medium} bold={true} color={Color.Label}>
            {`Total Credits${shouldDisplayCreditField ? '*' : ''}:`}
          </Text>
          &ensp;
          {shouldDisplayCreditField ? (
            <AbmsEditCreditsField
              name={`${baseName}.numberOfCredits`}
              type="number"
              controlProps={{ variant: 'small' }}
              required={shouldDisplayCreditField}
              locked={claimStatus === CreditClaimStatus.COMPLETED}
              maxLength={6}
            />
          ) : (
            <Text tag="span" textStyle={TextStyle.Medium} color={Color.Label} data-test-id="abmsNumberOfCreditsText">
              {renderTotalCredits()}
            </Text>
          )}
        </FieldColumn>
      </AbmsTotalCreditsRow>
      {isProcessor && <ProcessorFields baseName={baseName} values={values} />}
    </>
  );
};

const AbmsTotalCreditsRow = styled(TotalCreditsRow)`
  flex-direction: column;
`;

const AbmsEditCreditsField = styled(Field)`
  display: inline-block;
  width: 10rem;
`;

export default AbmsFields;
