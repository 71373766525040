import Button from '../../components/Button';
import styled from '../../theme';
import { PageHead, PageSectionTitle } from '../PageStructure';

export const ApplicantIndexPageHead = styled(PageHead)`
  padding: 60px 0;
`;

export const NoMatchesContainer = styled.div`
  padding: 2.4em;
  text-align: center;
`;

export const NoMatchesButtons = styled.div`
  padding: 2.2em;
  text-align: center;
`;

export const PageActions = styled.div`
  margin-left: auto;
`;

export const ResultCount = styled.span`
  font-weight: normal;
`;

export const SearchResultsTitleBar = styled(PageSectionTitle)`
  margin-top: 5rem;
`;

export const ClearSearchButton = styled(Button)`
  font-size: 14px;
  font-weight: 500;

  &::after {
    border-bottom: 0;
  }
`;
