import React from 'react';
import { activityContent } from 'src/content/activities';
import { ActivityType } from '../../constants/ActivityType';
import { Credits } from '../../constants/Credits';
import { Color } from '../../theme/primaryTheme';
import Field from '../Field';
import CheckBox from '../Field/Controls/CheckBox';
import DatePickerField from '../Field/DatePickerField';
import { FieldColumn, FieldRow } from '../FieldRow';
import ProofDocumentUpload from '../ProofDocumentUpload';
import Text, { TextStyle } from '../Text';
import { GenericActivityProps } from './index';
import ProcessorFields from './ProcessorFields';
import Requirements from './Requirements';
import { TotalCreditsRow } from './styles';

const ArticleFields: React.FunctionComponent<GenericActivityProps> = ({ baseName, values, isProcessor, claimId }) => {
  const { description, fields, footer } = activityContent[ActivityType.Article];
  return (
    <>
      <Requirements content={description} />
      <FieldRow>
        <FieldColumn grow={1}>
          <Field name={`${baseName}.doi`} required={true} {...fields.doi} />
        </FieldColumn>
      </FieldRow>
      <FieldRow>
        <FieldColumn grow={1}>
          <Field name={`${baseName}.activityTitle`} required={true} {...fields.activityTitle} />
        </FieldColumn>
      </FieldRow>
      <FieldRow>
        <FieldColumn grow={1}>
          <Field
            component={CheckBox}
            fieldBorder={false}
            name={`${baseName}.firstAuthor`}
            required={true}
            controlProps={{
              title: fields.firstAuthor.label,
            }}
          />
        </FieldColumn>
      </FieldRow>
      <FieldRow>
        <FieldColumn>
          <DatePickerField name={`${baseName}.activityEndDate`} required={true} {...fields.activityEndDate} />
        </FieldColumn>
      </FieldRow>
      <FieldRow>
        <FieldColumn grow={1}>
          <ProofDocumentUpload name={`${baseName}.creditActivityAttachments`} claimId={claimId} {...fields.creditActivityAttachments} />
        </FieldColumn>
      </FieldRow>
      <TotalCreditsRow>
        <FieldColumn>
          <Text tag="span" textStyle={TextStyle.Medium} bold={true} color={Color.Label}>
            {footer.total}
          </Text>
          &ensp;
          <Text tag="span" textStyle={TextStyle.Medium} color={Color.Label}>
            {Credits[ActivityType.Article]}
          </Text>
        </FieldColumn>
      </TotalCreditsRow>
      {isProcessor && <ProcessorFields baseName={baseName} values={values} />}
    </>
  );
};

export default ArticleFields;
