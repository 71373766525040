import { combineReducers } from 'redux';
import { hideModal } from './modal';

export const FORM_SUBMIT = '@form/SUBMIT';
export const FORM_SUCCESS = '@form/SUCCESS';
export const FORM_ERROR = '@form/ERROR';

export type FormErrors = any[];
interface ActionFormSubmit {
  type: typeof FORM_SUBMIT;
}

interface ActionFormError {
  type: typeof FORM_ERROR;
  errors: FormErrors;
}

interface ActionFormSuccess {
  type: typeof FORM_SUCCESS;
}

type FormAction = ActionFormSubmit | ActionFormError | ActionFormSuccess;

export interface FormStore {
  success: boolean;
  view: string;
  errors: FormErrors;
}

export const submitForm = (): ActionFormSubmit => {
  return {
    type: FORM_SUBMIT,
  };
};

export const formSuccess = (): ActionFormSuccess => {
  return {
    type: FORM_SUCCESS,
  };
};

export const formError = (errors: FormErrors): ActionFormError => {
  return {
    type: FORM_ERROR,
    errors,
  };
};

export const initialState = {
  success: false,
  view: 'default',
  errors: [],
};

export const success = (state = initialState.success, action: FormAction): boolean => {
  switch (action.type) {
    case FORM_SUBMIT:
    case FORM_ERROR:
      return false;
    case FORM_SUCCESS:
      return true;
    default:
      return state;
  }
};

export const view = (state = initialState.view, action: FormAction | typeof hideModal): string => {
  switch (action.type) {
    case FORM_SUBMIT:
      return 'loading';
    case FORM_SUCCESS:
      return 'success';
    case FORM_ERROR:
      return 'error';
    case hideModal.type:
      return 'default';
    default:
      return state;
  }
};

export const errors = (state = initialState.errors, action: FormAction): FormErrors => {
  switch (action.type) {
    case FORM_ERROR:
      return [...state, ...action.errors];
    case FORM_SUBMIT:
    case FORM_SUCCESS:
      return [];
    default:
      return state;
  }
};

export default combineReducers<FormStore>({
  success,
  view,
  errors,
});
