import React from 'react';
import { LinkStyle } from '../../constants/LinkStyle';
import { Routes } from '../../constants/Routes';
import Link from '../Link';
import NewApplicationLink from './NewApplicationLink';

const ProcessorNavLinks = () => (
  <>
    <NewApplicationLink to={Routes.UserIndex} />
    <Link linkStyle={LinkStyle.NavLink} to={Routes.ApplicationIndex}>
      Application Queue
    </Link>
    <Link linkStyle={LinkStyle.NavLink} to={Routes.UserIndex}>
      User Search
    </Link>
  </>
);

export default ProcessorNavLinks;
