import React from 'react';
import ConfirmButton from '../Button/ConfirmButton';
import LockIcon from '../Icon/LockIcon';
import Text, { TextStyle } from '../Text';
import { Body, Header, Root } from './styles';

interface Props {
  locked?: boolean;
  title: string;
  handleConfirm?: () => void;
  children: React.ReactNode;
  allowRemoval?: boolean;
}

const ActivityCard: React.FunctionComponent<Props> = ({ locked = false, title, handleConfirm, children, allowRemoval = true }) => {
  const renderIcon = () => {
    if (locked) {
      return <LockIcon size={12} />;
    }

    if (allowRemoval && handleConfirm) {
      return <ConfirmButton label="Remove Activity" onConfirm={handleConfirm} />;
    }

    return null;
  };

  return (
    <Root data-test-id="activity" locked={locked}>
      <Header>
        <Text tag="h2" bold={true} textStyle={TextStyle.SubTitle}>
          {title}
        </Text>
        {renderIcon()}
      </Header>
      <Body>{children}</Body>
    </Root>
  );
};

export default ActivityCard;
