import { createSelector } from 'reselect';
import { ReduxStore } from '../ducks';
import { claimEntitiesSelector } from './claims';

export const earnersSelector = createSelector(claimEntitiesSelector, (entities) => entities.creditEarner);
export const earnerSelector = (id: number) => createSelector(earnersSelector, (earners) => earners[id]);
/**
 * ==============
 * CREDIT EARNERS
 * ==============
 */
export const creditEarners = (state: ReduxStore) => state.creditEarners;
export const creditEarnerLoadingSelector = createSelector(creditEarners, (creditEarner) => creditEarner.isLoading);
export const creditEarnerDataSelector = createSelector(creditEarners, (creditEarner) => creditEarner.data);
export const creditEarnerErrorSelector = createSelector(creditEarners, (creditEarner) => creditEarner.error);
