export const addSources = (values: any, value = 'MANUAL_ENTRY') => {
  const { sources: initialSources = {} } = values;
  const sources = Object.assign({}, initialSources);

  Object.keys(values).forEach((field: string) => {
    if (field === 'sources') {
      return;
    }

    if (Array.isArray(values[field])) {
      sources[field] = values[field].map((item: string, index: number) => {
        if (initialSources[field] && initialSources[field][index]) {
          return initialSources[field][index];
        }

        return value;
      });
    } else {
      if (!initialSources[field]) {
        sources[field] = value;
      }
    }
  });

  return { ...values, sources };
};
