import { ArrayHelpers, FieldArray, FormikHelpers } from 'formik';
import React from 'react';
import styled from '../../theme';
import { CreditActivity } from '../../types';
import Alert, { AlertType } from '../Alert';
import Panel from '../Panel';
import Text from '../Text';
import { TextStack } from '../TextStack';
import PraCategory2ActivityTable from './PraCategory2ActivityTable';

interface Props {
  activitiesAreEditable: boolean;
  activities?: CreditActivity[];
  hasErrors?: boolean;
  setFieldValue: FormikHelpers<any>['setFieldValue'];
}

const Category2ActivitiesSection: React.FunctionComponent<Props> = ({
  activitiesAreEditable,
  activities = [],
  hasErrors = false,
  setFieldValue,
}) => {
  const renderPanelHeading = () => (
    <Text bold={true}>
      Add{' '}
      <Text tag="em" bold={true}>
        AMA PRA Category 2 Credit&trade;
      </Text>{' '}
      Activities{' '}
      <Text tag="span" bold={false}>
        (Optional)
      </Text>
    </Text>
  );

  return (
    <div data-testid="praCategory2Section">
      <Category2ActivitiesDescription>
        <Text tag="p">
          If you don't have enough <Text tag="em">AMA PRA Category 1 Credits&trade;</Text> to fill your total credit requirement, then add{' '}
          <Text tag="em">AMA PRA Category 2 Credits&trade;</Text>.
        </Text>
        <Text tag="p">
          <Text tag="em">AMA PRA Category 2 Credit&trade;</Text> may be self-claimed for activities which are not certified for{' '}
          <Text tag="em">AMA PRA Category 1&trade;</Text>. Examples include: Teaching healthcare professionals, online study, consulting
          with peers, reading authoritative medical literature, and preceptorship.
        </Text>
      </Category2ActivitiesDescription>
      <Panel heading={renderPanelHeading()}>
        {hasErrors && (
          <Alert data-test-id="praActivityDetailsError" type={AlertType.TableError}>
            Please fill in the remaining fields highlighted below
          </Alert>
        )}
        <FieldArray name="category2CreditActivities">
          {({ push, remove }: ArrayHelpers) => (
            <PraCategory2ActivityTable
              activitiesAreEditable={activitiesAreEditable}
              addItem={push}
              removeItem={remove}
              activities={activities}
              setFieldValue={setFieldValue}
            />
          )}
        </FieldArray>
      </Panel>
    </div>
  );
};

const Category2ActivitiesDescription = styled(TextStack)`
  margin: 3.2rem 0;
`;

export default Category2ActivitiesSection;
