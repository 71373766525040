import React from 'react';
import { StyledInput } from '../styles';

export interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  name: string;
  hasError?: boolean;
}

const Input = ({ name, ...rest }: Props) => {
  return <StyledInput name={name} {...rest} />;
};

export default Input;
