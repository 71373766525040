import styled from '../../theme';
import { themeBreakpoint, themeColor } from '../../theme/helpers';

export const HeaderToggle = styled.div`
  display: flex;
  align-items: center;
  border-top: 1px solid ${themeColor('borderLight')};
  padding: 2rem 0;
`;

export const FormPanel = styled.div`
  background-color: ${themeColor('background')};
  padding: 5rem 6rem;
  border-radius: 0.5rem;

  @media (max-width: ${themeBreakpoint('medium')}) {
    padding: 3rem 2rem;
  }
`;
