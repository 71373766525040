import React from 'react';
import styled from 'styled-components';
import { PageSection, PageSectionDescription, PageSectionTitle } from '../../layouts/PageStructure';
import Text from '../Text';

interface Props {
  title?: string | React.ReactNode;
  description?: string | React.ReactNode;
  renderAction?: () => React.ReactNode;
  id?: string;
}

const FormSection: React.FunctionComponent<Props> = ({ title, children, renderAction, description, id }) => (
  <PageSection data-test-id={id}>
    {title && (
      <PageSectionTitle>
        <Text tag="h2" bold={true} textStyle="medium">
          {title}
        </Text>
        {typeof renderAction === 'function' && renderAction()}
      </PageSectionTitle>
    )}
    {description && <PageSectionDescription>{description}</PageSectionDescription>}
    <FormSectionContent>{children}</FormSectionContent>
  </PageSection>
);

const FormSectionContent = styled.div`
  max-width: 100rem;
`;

export default FormSection;
