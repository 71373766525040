import { groupActivitiesByType, removeInvalidActivities } from 'src/utilities/creditActivities';
import { Option } from '../components/Field/Controls/Select';
import { CreditActivity } from '../types';
import { ActivityType } from './ActivityType';
import { ApplicationTypeCode } from './ApplicationTypeCode';
import { RateCode } from './CreditClaimRateCode';
import { PraClaimMethod } from './PraClaimMethod';

export interface RateCodeOption extends Option {
  value: RateCode;
}

export const processingRateOptions: RateCodeOption[] = [
  {
    label: 'Standard (within 4 weeks)',
    value: RateCode.Standard,
  },
  /**
   * TODO: Confirm that we can remove this permanently as AMA may not support the Express rate any longer
   */
  /* Removed for COVID-19 (https://www.pivotaltracker.com/story/show/171864817)
  {
    label: 'Express (2 business days)',
    value: RateCode.Express,
  },
  */
];

export const getProcessingRateLabel = (rateCode?: RateCode) => {
  if (!rateCode) {
    return '';
  }

  const processingRate = processingRateOptions.find((option) => option.value === rateCode);
  return processingRate ? processingRate.label : '';
};

export const calculatePriceForCollectionOfDcIccActivities = (
  rateCode?: RateCode,
  activities?: CreditActivity[],
  isMember?: boolean
): number => {
  if (!rateCode) {
    return 0;
  }

  const nonInvalidActivitiesGroupedByType = groupActivitiesByType(removeInvalidActivities(activities || []));

  return Object.keys(nonInvalidActivitiesGroupedByType).reduce((acc: number, activityType: string) => {
    let fee = 0;

    /**
     * Subtotal is based on activity group, not individual activity, so we pull off the first
     * activity in each group to get the type of the group
     */
    if (nonInvalidActivitiesGroupedByType[activityType][0].activityTypeCode === ActivityType.Acgme) {
      fee += isMember ? 0 : 75;
    } else {
      fee += isMember ? 30 : 75;
    }

    /**
     * TODO: Confirm that we can remove this permanently as AMA may not support the Express rate any longer
     */
    if (rateCode === RateCode.Express) {
      fee += isMember ? 20 : 25;
    }

    return acc + fee;
  }, 0);
};

const getPraSubtotal = (rateCode: RateCode, claimMethod?: PraClaimMethod, withCommendation?: boolean, isMember?: boolean): number => {
  let fee = 0;

  switch (claimMethod) {
    case PraClaimMethod.PreviousCME:
    case PraClaimMethod.ABMS:
      if (withCommendation) {
        fee = isMember ? 70 : 140;
      } else {
        fee = isMember ? 50 : 100;
      }
      break;
    case PraClaimMethod.Reciprocity:
      fee = isMember ? 40 : 90;
      break;
    case PraClaimMethod.Residency:
      fee = isMember ? 0 : 100;
      break;
  }

  /**
   * TODO: Confirm that we can remove this permanently as AMA may not support the Express rate any longer
   */
  if (rateCode === RateCode.Express) {
    fee += 30;
  }

  return fee;
};

export const getClaimSubtotal = (
  rateCode: RateCode,
  isMember: boolean,
  claim: {
    applicationTypeCode?: ApplicationTypeCode;
    praCertificateClaimMethod?: PraClaimMethod;
    praCertificateIsCommendation?: boolean;
    creditActivities?: CreditActivity[];
  }
) => {
  const { applicationTypeCode, praCertificateClaimMethod, praCertificateIsCommendation, creditActivities } = claim;

  if (applicationTypeCode === ApplicationTypeCode.PRA) {
    return getPraSubtotal(rateCode, praCertificateClaimMethod, praCertificateIsCommendation, isMember);
  }

  return calculatePriceForCollectionOfDcIccActivities(rateCode, creditActivities, isMember);
};
