import { FormikValues, getIn } from 'formik';
import { pick } from 'lodash';
import React, { ChangeEventHandler, useState } from 'react';
import { useSelector } from 'react-redux';
import CheckBox from 'src/components/Field/Controls/CheckBox';
import styled from 'src/theme';
import { CreditClaimStatus } from '../../../constants/ApplicationStatus';
import { ApplicationTypeCode } from '../../../constants/ApplicationTypeCode';
import { PaymentCode } from '../../../constants/PaymentCode';
import { claimDataSelector } from '../../../selectors/claims';
import { isCreditProcessor } from '../../../selectors/user';
import { AdvantagePostalAddress, AdvantagePostalAddressSummary } from '../../../types';
import AddressSummary from '../../AddressSummary';
import Field from '../../Field';
import AddressField from '../../Field/AddressField';
import TextArea from '../../Field/Controls/TextArea';
import { FieldColumn, FieldRow } from '../../FieldRow';
import FormSection from '../../FormSection';
import OptionGroup from '../../OptionGroup';
import SummaryValue from '../../SummaryValue';

interface Props {
  applicationTypeCode?: ApplicationTypeCode;
  paymentType: PaymentCode;
  onPaymentTypeChange: ChangeEventHandler;
  values: FormikValues;
}

const addressesAreNotEmptyAndEquivalent = (billingAddress: AdvantagePostalAddressSummary, praMailingAddress: AdvantagePostalAddress) => {
  const flatBillingAddress = Object.values(pick(billingAddress, ['line1', 'line2', 'stateCode', 'city', 'zip', 'countryCode'])).join('');
  const flatMailingAddress = Object.values(
    pick(praMailingAddress, ['Line1', 'Line2', 'StateCode', 'City', 'PostalCode', 'CountryCode'])
  ).join('');
  return flatBillingAddress !== '' && flatBillingAddress === flatMailingAddress;
};

const PaymentInfoSection: React.FunctionComponent<Props> = ({ applicationTypeCode, paymentType, onPaymentTypeChange, values }) => {
  const claim = useSelector(claimDataSelector);
  const isProcessor = useSelector(isCreditProcessor);

  const editableStatuses = isProcessor
    ? Object.values(CreditClaimStatus).filter((status) => status !== CreditClaimStatus.COMPLETED)
    : [CreditClaimStatus.UNSUBMITTED, CreditClaimStatus.PENDING_CUSTOMER_ACTION];
  const isEditable = editableStatuses.includes(claim.status);

  // TODO: use same interface for billingAddress and praMailingAddress (https://www.pivotaltracker.com/story/show/174976871)
  const billingAddress: AdvantagePostalAddressSummary = getIn(claim, 'creditEarner.billingAddress', {});
  const praMailingAddress: AdvantagePostalAddress = getIn(claim, 'praMailingAddress', {});

  // This only keeps track of whether the checkbox should-be checked. The praMailingAddress is only updated upon form submission.
  const billingAndMailingAreSame = addressesAreNotEmptyAndEquivalent(billingAddress, praMailingAddress);
  const [mailingAddressIsSameAsBilling, setMailingAddressIsSameAsBilling] = useState(billingAndMailingAreSame);
  const useBillingAddressForMailing = () => {
    setMailingAddressIsSameAsBilling(!mailingAddressIsSameAsBilling);
  };

  const paymentMethods = [
    {
      label: 'Credit Card',
      value: PaymentCode.CreditCard,
    },
    {
      label: 'Offline Payment',
      value: PaymentCode.Offline,
    },
  ];

  if (isEditable) {
    return (
      <>
        {isProcessor && (
          <FormSection title="Payment Method">
            <FieldRow>
              <FieldColumn>
                <Field
                  type="radio"
                  name="paymentType"
                  component={OptionGroup}
                  onChange={onPaymentTypeChange}
                  fieldBorder={false}
                  controlProps={{ options: paymentMethods }}
                />
              </FieldColumn>
            </FieldRow>
            {paymentType === PaymentCode.Offline && (
              <FieldRow>
                <FieldColumn grow={1}>
                  <Field name="paymentNote" placeholder="Notes ..." label="Notes (Optional)" component={TextArea} />
                </FieldColumn>
              </FieldRow>
            )}
          </FormSection>
        )}

        {paymentType === PaymentCode.CreditCard && (
          <FormSection
            title="Billing Address"
            description="Enter the billing address for the credit card you will use to pay for the award"
          >
            <AddressField
              fieldNames={{
                address1: 'billingAddress.Line1',
                address2: 'billingAddress.Line2',
                city: 'billingAddress.City',
                stateCode: 'billingAddress.StateCode',
                postalCode: 'billingAddress.PostalCode',
              }}
            />
          </FormSection>
        )}

        {applicationTypeCode === ApplicationTypeCode.PRA && (
          <FormSection title="Award Mailing Address">
            <FieldRow>
              <FieldColumn grow={1}>
                <Field
                  name="mailingAddressIsSameAsBilling"
                  onChange={useBillingAddressForMailing}
                  value={Number(mailingAddressIsSameAsBilling)}
                  component={CheckBox}
                  type="checkbox"
                  fieldBorder={false}
                  controlProps={{
                    title: 'Mailing address is same as billing',
                  }}
                />
              </FieldColumn>
            </FieldRow>
            <MailingDescription>Mail my PRA certifcate to the following address:</MailingDescription>
            <AddressField
              disabled={mailingAddressIsSameAsBilling}
              showCountry={!mailingAddressIsSameAsBilling}
              values={values}
              fieldNames={
                /**
                 * When the checkbox is checked, we're showing the billing address fields and locking them.
                 * praMailingAddress remains unchanged until the form is submitted.
                 */
                mailingAddressIsSameAsBilling
                  ? {
                      address1: 'billingAddress.Line1',
                      address2: 'billingAddress.Line2',
                      city: 'billingAddress.City',
                      stateCode: 'billingAddress.StateCode',
                      postalCode: 'billingAddress.PostalCode',
                    }
                  : {
                      address1: 'praMailingAddress.Line1',
                      address2: 'praMailingAddress.Line2',
                      city: 'praMailingAddress.City',
                      stateCode: 'praMailingAddress.StateCode',
                      postalCode: 'praMailingAddress.PostalCode',
                      countryCode: 'praMailingAddress.CountryCode',
                    }
              }
            />
          </FormSection>
        )}
      </>
    );
  }

  // Not editable. Summary only.
  return (
    <>
      {claim.paymentType === PaymentCode.Offline && (
        <FormSection title="Offline Payment">
          <FieldRow>
            <FieldColumn>
              <SummaryValue label="Notes" value={claim.paymentNote} />
            </FieldColumn>
          </FieldRow>
        </FormSection>
      )}

      {claim.paymentType === PaymentCode.CreditCard && (
        <FormSection title="Billing Address">
          {[CreditClaimStatus.PAYMENT_FAILED, CreditClaimStatus.PENDING_CUSTOMER_ACTION].includes(claim.status) ? (
            <AddressField
              fieldNames={{
                address1: 'billingAddress.Line1',
                address2: 'billingAddress.Line2',
                city: 'billingAddress.City',
                stateCode: 'billingAddress.StateCode',
                postalCode: 'billingAddress.PostalCode',
              }}
            />
          ) : (
            <AddressSummary advantageAddressSummary={billingAddress} />
          )}
        </FormSection>
      )}

      {applicationTypeCode === ApplicationTypeCode.PRA && (
        <FormSection
          id="praMailingAddress"
          title="Award Mailing Address"
          description="Enter the address to which you would like your awards mailed"
        >
          <AddressSummary advantageAddress={praMailingAddress} />
        </FormSection>
      )}
    </>
  );
};

export const MailingDescription = styled.div`
  margin: 3rem 0;
`;

export default PaymentInfoSection;
