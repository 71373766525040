import styled from '../../theme';
import { themeBreakpoint, themeColor } from '../../theme/helpers';

type FlexAlignments = 'baseline' | 'center';

interface IFlexProps {
  alignItems?: FlexAlignments;
}

export const FlexTableHeading = styled.div`
  display: flex;
  align-items: flex-end;
  margin-bottom: 2rem;

  @media (max-width: ${themeBreakpoint('medium')}) {
    margin-bottom: 0;
  }
`;

export const FlexTableRows = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 1.5rem 0;
  border-top: 1px solid ${themeColor('border')};

  &:last-of-type {
    border-bottom: 1px solid ${themeColor('border')};
  }

  @media (max-width: ${themeBreakpoint('medium')}) {
    flex-direction: column;
    align-items: flex-start;
    padding: 3rem 0;

    &:first-of-type {
      border-top: none;
    }
  }
`;

export const FlexTableLeft = styled.div`
  margin-left: 0.2rem;

  @media (min-width: ${themeBreakpoint('large')}) {
    margin-bottom: 0;
    margin-left: 0;
    flex-grow: 1;
    max-width: 60%;
    padding-right: 2rem;
  }
`;

export const FlexTableRight = styled.div`
  width: 100%;

  @media (min-width: ${themeBreakpoint('large')}) {
    width: 40%;
  }
`;

export const FlexTableHeaderRight = styled.div`
  display: none;

  @media (min-width: ${themeBreakpoint('large')}) {
    display: block;
  }
`;

export const FlexTableGroup = styled.div<IFlexProps>`
  display: flex;
  align-items: ${(props) => (props.alignItems ? props.alignItems : 'baseline')};

  @media (max-width: ${themeBreakpoint('large')}) {
    flex-wrap: wrap;
    display: block;
  }
`;
