import React from 'react';
import UserTranscript from '../../components/UserTranscript';
import DefaultPage from '../DefaultPage';

const Transcript = () => (
  <DefaultPage title={'My Transcript'}>
    <UserTranscript />
  </DefaultPage>
);

export default Transcript;
