import React from 'react';
import { themeBreakpoint } from 'src/theme/helpers';
import { emptyCellCharacter } from '../../constants/General';
import styled from '../../theme';
import { Color } from '../../theme/primaryTheme';
import Text, { TextProps, TextStyle } from '../Text';

interface Props extends TextProps {
  value: any;
  label?: string | React.ReactNode;
  displayType?: SummaryDisplayType;
}

export enum SummaryDisplayType {
  Default = 'default',
  Small = 'small',
  Mobile = 'mobile',
}

const Root = styled.div`
  @media (max-width: ${themeBreakpoint('medium')}) {
    margin-bottom: 0.8rem;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
`;

const SummaryValueLabel = styled(Text)`
  margin-bottom: 0.5rem;

  @media (max-width: ${themeBreakpoint('medium')}) {
    margin-bottom: 0;
  }
`;

const SummaryValue: React.FunctionComponent<Props> = ({ value, label, displayType = SummaryDisplayType.Default, ...rest }) => {
  const textStyles = {
    [SummaryDisplayType.Default]: {
      value: TextStyle.Medium,
      label: TextStyle.Label,
    },
    [SummaryDisplayType.Small]: {
      value: TextStyle.Default,
      label: TextStyle.Small,
    },
    [SummaryDisplayType.Mobile]: {
      value: TextStyle.Value,
      label: TextStyle.Label,
    },
  };
  return (
    <Root>
      {label && (
        <SummaryValueLabel bold={true} color={Color.Label} textStyle={textStyles[displayType].label}>
          {label}
        </SummaryValueLabel>
      )}
      <Text color={value ? Color.Primary : Color.Secondary} textStyle={textStyles[displayType].value} {...rest}>
        {value ? value : emptyCellCharacter}
      </Text>
    </Root>
  );
};

export const SmallSummaryValue: React.FunctionComponent<Props> = (props) => {
  return <SummaryValue displayType={SummaryDisplayType.Small} {...props} />;
};

export default SummaryValue;
