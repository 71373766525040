import React from 'react';
import { connect } from 'react-redux';
import { ReduxStore } from '../../ducks';
import { nonDeletedLearnerActivitySelector, transcriptErrorSelector } from '../../selectors/transcripts';
import { LearnerActivity } from '../../types';
import Alert, { AlertType } from '../Alert';
import { ToggleableLearnerActivityCard } from '../ToggleableTranscriptCard';
import NoTranscriptDataMessage from './NoTranscriptDataMessage';

interface Props {
  error?: string;
  learnerActivities: LearnerActivity[];
}

const LearnerActivityList: React.FunctionComponent<Props> = ({ learnerActivities = [], error = '' }) => {
  if (!learnerActivities.length) {
    return <NoTranscriptDataMessage dataType="learnerActivity" />;
  }

  return (
    <>
      {error && (
        <Alert data-test-id="transcriptTableError" type={AlertType.Error}>
          {error}
        </Alert>
      )}
      {learnerActivities.map((learnerActivity) => (
        <ToggleableLearnerActivityCard learnerActivity={learnerActivity} key={learnerActivity.id} />
      ))}
    </>
  );
};

const mapStateToProps = (state: ReduxStore) => ({
  learnerActivities: nonDeletedLearnerActivitySelector(state),
  error: transcriptErrorSelector(state),
});

export default connect(mapStateToProps)(LearnerActivityList);
