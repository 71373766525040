import React from 'react';
import ReactModal from 'react-modal';
import { connect } from 'react-redux';
import { Transition } from 'react-spring';
import { Dispatch } from 'redux';
import closeIcon from '../../assets/images/close.svg';
import { hideModal } from '../../ducks/modal';
import Button from '../Button';
import { ModalBody, ModalButtonGroup, ModalClose, ModalHeader } from '../Modal/styles';
import Text from '../Text';

/*
TODO: I don't think this is the best way to handle this, but I couldn't get
the DOM to have this element in a testing environment. Rather than spend
a ton of time trying to make a test work, I added this escape hatch
 */
const rootNode = document.getElementById('root');
if (rootNode) {
  // We need setAppElement for accessibility:
  // http://reactcommunity.org/react-modal/accessibility/#app-element
  ReactModal.setAppElement(rootNode);
}

const styleOverrides = {
  overlay: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  content: {
    width: '90vw',
    maxWidth: '65rem',
    maxHeight: '90vh',
    border: 'none',
    borderRadius: 'none',
    top: 'auto',
    left: 'auto',
    right: 'auto',
    bottom: 'auto',
    padding: '5rem',
    boxShadow: '0 2px 10px 0 rgba(0,0,0,0.25)',
  },
};

interface ModalProps {
  isOpen: boolean;
  onConfirm: () => void;
  onCancel?: () => void;
  onCloseModal?: () => void;
  title?: string;
  view?: string;
  errors?: string;
  readonly modalId?: string;
  dispatch?: Dispatch;
  confirmLabel?: string;
  cancelLabel?: string;
  showButtons?: boolean;
  disableConfirm?: boolean;
}

export class ActionModal extends React.Component<ModalProps, any> {
  public render() {
    const {
      modalId,
      isOpen,
      title,
      children,
      showButtons = true,
      onConfirm,
      onCancel,
      onCloseModal,
      confirmLabel,
      cancelLabel,
      disableConfirm = false,
    } = this.props;

    const handleCloseModal = () => {
      const { dispatch, modalId } = this.props;

      if (dispatch !== undefined && modalId !== undefined) {
        onCloseModal && onCloseModal();
        dispatch(hideModal(modalId));
      }
    };

    return (
      <Transition
        items={isOpen}
        from={{ opacity: 0, transform: 'translate3d(0, 30px, 0)' }}
        enter={{ opacity: 1, transform: 'translate3d(0, 0, 0)' }}
        leave={{ opacity: 0, transform: 'translate3d(0, 30px, 0)' }}
      >
        {(show) =>
          show &&
          ((style) => (
            <ReactModal
              isOpen={isOpen}
              htmlOpenClassName="is-scroll-locked"
              bodyOpenClassName="is-scroll-locked"
              style={{
                content: { ...styleOverrides.content, ...style },
                overlay: styleOverrides.overlay,
              }}
              onRequestClose={handleCloseModal}
              data={{ 'test-id': modalId }}
            >
              {title && (
                <ModalHeader>
                  <Text textStyle="modalTitle">{title}</Text>
                </ModalHeader>
              )}
              <ModalBody>{children}</ModalBody>
              {showButtons && (
                <ModalButtonGroup>
                  <Button onClick={onCancel ?? handleCloseModal} buttonStyle="plain-text" data-type="cancel">
                    {cancelLabel || 'Cancel'}
                  </Button>
                  <Button disabled={disableConfirm} onClick={onConfirm ?? handleCloseModal} data-type="submit">
                    {confirmLabel || 'Confirm'}
                  </Button>
                </ModalButtonGroup>
              )}
              <ModalClose type="button" onClick={handleCloseModal}>
                <img src={closeIcon} alt="dismiss modal" />
              </ModalClose>
            </ReactModal>
          ))
        }
      </Transition>
    );
  }
}

const mapStateToProps = (state: any, ownProps: any) => {
  const { modalId } = ownProps;
  const { modal, form } = state;
  const { view, errors } = form;

  return {
    view,
    isOpen: modal[modalId] || false,
    errors,
  };
};

export default connect(mapStateToProps)(ActionModal);
