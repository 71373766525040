import { createSelector } from 'reselect';
import { ReduxStore } from '../ducks';

export const bulkLearnerActivityUploads = (state: ReduxStore) => state.bulkLearnerActivityUploads;

export const bulkLearnerActivityUploadLoadingSelector = createSelector(
  bulkLearnerActivityUploads,
  (bulkLearnerActivityUploads) => bulkLearnerActivityUploads.isLoading
);

export const bulkLearnerActivityUploadPageSelector = createSelector(
  bulkLearnerActivityUploads,
  (bulkLearnerActivityUpload) => bulkLearnerActivityUpload.page
);

export const bulkLearnerActivityUploadPageSizeSelector = createSelector(
  bulkLearnerActivityUploads,
  (bulkLearnerActivityUpload) => bulkLearnerActivityUpload.pageSize
);

export const bulkLearnerActivityUploadTotalSelector = createSelector(
  bulkLearnerActivityUploads,
  (bulkLearnerActivityUpload) => bulkLearnerActivityUpload.total
);

export const bulkLearnerActivityUploadDataSelector = createSelector(
  bulkLearnerActivityUploads,
  (bulkLearnerActivityUploads) => bulkLearnerActivityUploads.results
);

export const bulkLearnerActivityUploadErrorSelector = createSelector(
  bulkLearnerActivityUploads,
  (bulkLearnerActivityUploads) => bulkLearnerActivityUploads.error
);
