import React from 'react';
import { LabelHTMLAttributes } from 'react';
import { ColorKeys } from '../../theme/theme';
import { renderLabel } from '../../utilities/renderLabel';
import LockIcon from '../Icon/LockIcon';
import Text, { TextProps } from '../Text';

interface Props extends TextProps, LabelHTMLAttributes<HTMLLabelElement> {
  color?: ColorKeys;
}

const Label = ({ children, ...rest }: Props) => {
  return (
    <Text tag="label" textStyle="label" {...rest}>
      {children}
    </Text>
  );
};

interface FieldLabelProps extends Props {
  hasError: boolean;
  required?: boolean;
  locked?: boolean;
  text: string;
}

export const FieldLabel: React.FunctionComponent<FieldLabelProps> = ({ hasError, text, required = false, locked = false, ...rest }) => {
  return (
    <Label color={hasError ? 'error' : undefined} {...rest}>
      {renderLabel(text, { required })}
      {locked && <LockIcon size={10} marginLeft={true} />}
    </Label>
  );
};

export default Label;
