import React from 'react';
import styled from '../../theme';
import ThemeInterface from '../../theme/theme';

export interface ToggleSwitchProps {
  on: boolean;
  hasFocus?: boolean;
}

const Switch = styled.span`
  position: relative;
  display: inline-block;
  width: 50px;
  height: 30px;
  padding: 5px;
  align-items: center;
  border-radius: 20px;
  background-color: ${(props: { on: boolean; theme: ThemeInterface }) =>
    props.on ? props.theme.colors.accent : props.theme.colors.secondary};

  &:hover {
    cursor: pointer;
  }
`;

const StyledSlider = styled.span<{ on: boolean; hasFocus?: boolean }>`
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  ${(props) => (props.hasFocus ? 'box-shadow: 0 0 8px #16202D;' : '')};
  background: #fff;
  transition: transform 0.3s;
  left: 5px;
  transform: ${(props) => (props.on ? 'translateX(20px)' : 'translateX(0)')};
`;

const ToggleSwitch: React.FunctionComponent<ToggleSwitchProps> = ({ on = false, hasFocus }) => {
  return (
    <Switch on={on}>
      <StyledSlider on={on} hasFocus={hasFocus} />
    </Switch>
  );
};

export default ToggleSwitch;
