import React from 'react';
import { DropdownProps } from './index';
import { StyledDropdownPanel } from './styles';

interface Props extends DropdownProps {
  onClickOutside: () => void;
  isOpen: boolean;
}

class DropdownPanel extends React.Component<Props> {
  public static defaultProps = {
    arrow: false,
    width: null,
  };

  private menuRef: React.RefObject<HTMLDivElement> = React.createRef();

  public componentDidMount() {
    document.addEventListener('mousedown', this.handleOutsideClick, false);
  }

  public componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleOutsideClick, false);
  }

  public render() {
    const { isOpen, children } = this.props;
    return (
      <StyledDropdownPanel arrow={this.props.arrow} isOpen={isOpen} width={this.props.width} ref={this.menuRef}>
        {children}
      </StyledDropdownPanel>
    );
  }

  private handleOutsideClick: React.EventHandler<any> = (event) => {
    const { onClickOutside } = this.props;
    const { current } = this.menuRef;

    if (current && !current.contains(event.target)) {
      onClickOutside();
    }
  };
}

export default DropdownPanel;
