import { Field, FieldProps } from 'formik';
import React from 'react';
import Input from 'src/components/Field/Controls/Input';
import { PraCertificateCode } from '../../../constants/PraCertificateCode';
import { Routes } from '../../../constants/Routes';
import { PageSection } from '../../../layouts/PageStructure';
import styled from '../../../theme';
import { themeColor } from '../../../theme/helpers';
import { Color } from '../../../theme/primaryTheme';
import Link from '../../Link';
import Panel, { DEFAULT_PANEL_PADDING } from '../../Panel';
import Text from '../../Text';

const StyledItem = styled.div`
  padding: 0.5em 0;

  & + & {
    border-top: 1px solid ${themeColor(Color.Border)};
  }
`;

const StyledItemFooter = styled.div`
  margin: 1em 0 0.5em;
`;

const StyledPageSection = styled(PageSection)`
  margin-top: 2rem !important;
`;

const ItemLabel = styled.label`
  padding: ${DEFAULT_PANEL_PADDING};
  display: flex;
  align-items: start;
`;

const DescriptionText = styled(Text)`
  margin-top: 1em;
  color: ${Color.Gray};
`;

const ItemDescription = styled.div``;

const praClaimMethods = {
  [PraCertificateCode.PRA_STANDARD_3Y_ABMS]: {
    label: 'ABMS Board Certification for 3 Year Standard PRA',
    description: 'Your most recent ABMS member board certification acquired through initial certification or by taking the secure exam.',
  },
  [PraCertificateCode.PRA_STANDARD_3Y_RECIPROCITY]: {
    label: 'Reciprocity Letter for 3 Year Standard PRA',
    description:
      'A letter or notice from a qualified medical society that has a reciprocal agreement with the AMA stating that you are eligible to apply for the AMA PRA.',
  },
  [PraCertificateCode.PRA_STANDARD_3Y_RESIDENCY]: {
    label: 'ACGME-accredited residency or fellowship for 1-, 2-, 3-Year Standard PRA',
    description:
      'Your Certificate of Completion of your Residency Program or a letter from the Program Director verifying the dates of participate and completion from the program.',
  },
};

const ClaimMethodSection: React.FunctionComponent<{ method: string; isEditable: boolean }> = ({ method, isEditable }) => {
  const { label, description } = praClaimMethods[method];

  return (
    <StyledPageSection>
      <Panel>
        <StyledItem key={`option-${method}`}>
          <Field key={method} name="praCertificateCode">
            {({ field }: FieldProps) => (
              <ItemLabel htmlFor={method}>
                <Input
                  data-testid={`${field.name}${isEditable ? '' : '-locked'}`}
                  type="hidden"
                  {...field}
                  value={method}
                  disabled={!isEditable}
                />
                <ItemDescription>
                  <Text bold={true} tag="span">
                    {label}
                  </Text>
                  <DescriptionText>{description}</DescriptionText>
                  {[PraCertificateCode.PRA_STANDARD_3Y_ABMS, PraCertificateCode.PRA_STANDARD_3Y_RESIDENCY].includes(
                    method as PraCertificateCode
                  ) && (
                    <StyledItemFooter>
                      {method === PraCertificateCode.PRA_STANDARD_3Y_ABMS ? (
                        <Text textStyle="small" bold={true}>
                          You can also claim 60{' '}
                          <Text tag="em" bold={true}>
                            AMA PRA Category 1 Credits&trade;
                          </Text>{' '}
                          for completing ABMS member board certification and MOC.
                        </Text>
                      ) : (
                        <Text textStyle="small" bold={true}>
                          You can also claim 20{' '}
                          <Text tag="em" bold={true}>
                            AMA PRA Category 1 Credits&trade;
                          </Text>{' '}
                          for each year of participation in an ACGME accredited residency or fellowship program.
                        </Text>
                      )}
                      <Text textStyle="small" bold={true} color={Color.Accent}>
                        <Link to={`${Routes.ApplicationNew}?applicationTypeCode=dc`}>Start a Direct Credit application</Link>
                      </Text>
                    </StyledItemFooter>
                  )}
                </ItemDescription>
              </ItemLabel>
            )}
          </Field>
        </StyledItem>
      </Panel>
    </StyledPageSection>
  );
};

export default ClaimMethodSection;
