import _ from 'lodash';
import React from 'react';
import Toggle from 'react-toggled';
import Icon from '../Icon';
import Text, { TextStyle } from '../Text';
import { AppTypeInfo, DescriptionBlock, Toggler } from './styles';

interface Props {
  content: string;
  showAll?: boolean;
  previewText?: string;
  cutoff?: number;
}

const Requirements: React.FunctionComponent<Props> = ({ content, showAll, previewText, cutoff }) => {
  const defaultCutoff = cutoff ?? 350;
  const showToggle = !showAll && content.length > defaultCutoff;

  if (!showToggle) {
    return (
      <AppTypeInfo>
        <Text tag="div" textStyle={TextStyle.Small}>
          <DescriptionBlock dangerouslySetInnerHTML={{ __html: content }} />
        </Text>
      </AppTypeInfo>
    );
  }

  const preview = _.truncate(content, {
    length: defaultCutoff,
    separator: '. ',
    omission: '. [...]',
  });

  return (
    <Toggle>
      {({ on, getTogglerProps, toggle }) => {
        return (
          <AppTypeInfo>
            {Boolean(previewText) ? (
              <Text textStyle={TextStyle.Small}>
                <DescriptionBlock dangerouslySetInnerHTML={{ __html: on ? content : previewText }} />
              </Text>
            ) : (
              <Text textStyle={TextStyle.Small}>{on ? content : preview}</Text>
            )}
            <Toggler onClick={toggle} {...getTogglerProps()} type="button" buttonStyle="plain-text" data-test-id="requirementsToggler">
              {on ? 'Hide' : 'View'} all requirements
              <Icon
                name={on ? 'angleArrowUp' : 'angleArrowDown'}
                width={12}
                height={7.5}
                viewBox="0 0 16 10"
                style={{ marginLeft: '0.2em' }}
              />
            </Toggler>
          </AppTypeInfo>
        );
      }}
    </Toggle>
  );
};

export default Requirements;
