import { PraClaimMethod } from 'src/constants/PraClaimMethod';
import { calculateCheckedCredits, calculateCredits } from 'src/utilities/creditActivities';
import { CreditRequirements } from '.';
import { ClaimFormValues, CreditClaim } from '../../types';

export const calculateCreditRequirementsForAwardType = (claim: CreditClaim): CreditRequirements | undefined => {
  const { praCertificateIsCommendation, praCertificateDuration } = claim;
  const base = praCertificateIsCommendation ? 90 : 50;

  return claim.praCertificateClaimMethod === PraClaimMethod.PreviousCME
    ? {
        requiredOverallCredits: base * Number(praCertificateDuration),
        requiredCategory1Credits: (base - 30) * Number(praCertificateDuration),
      }
    : undefined;
};

export const calculateTotalTranscriptActivityCredits = (values: ClaimFormValues) =>
  values.transcriptCreditActivities ? calculateCheckedCredits(values.transcriptCreditActivities) : 0;

export const calculateTotalSelfReportedCategory1ActivityCredits = (values: ClaimFormValues) =>
  values.category1CreditActivities ? calculateCredits(values.category1CreditActivities) : 0;

export const calculateTotalCategory1ActivityCredits = (values: ClaimFormValues) =>
  // Transcript activities are a subset of Category 1 credit activities
  calculateTotalTranscriptActivityCredits(values) +
  calculateTotalSelfReportedCategory1ActivityCredits(values) +
  Number(values.additionalPraCat1Credits);

export const calculateTotalCategory2ActivityCredits = (values: ClaimFormValues) =>
  values.category2CreditActivities ? calculateCredits(values.category2CreditActivities) : 0;

export const calculateTotalOverallCredits = (values: ClaimFormValues) =>
  calculateTotalCategory1ActivityCredits(values) + calculateTotalCategory2ActivityCredits(values);
