import React from 'react';
import { ActivityStatus, ActivityStatusLabel } from '../../constants/ActivityStatus';
import { CreditClaimStatus, CreditClaimStatusLabels } from '../../constants/ApplicationStatus';
import styled from '../../theme';
import { ThemeColors } from '../../theme/primaryTheme';
import { lightenOrDarken } from '../../utilities/colors';
import Text from '../Text';

interface Props {
  status: CreditClaimStatus | ActivityStatus | string;
}

const upperFirst = (s: string) => s.charAt(0).toUpperCase() + s.slice(1);

const theme = {
  [CreditClaimStatus.UNSUBMITTED]: {
    text: ThemeColors.gray,
    bg: 'transparent',
    borderColor: ThemeColors.gray,
  },
  [CreditClaimStatus.SUBMITTED]: {
    text: ThemeColors.confirmationDark,
    bg: 'transparent',
    borderColor: ThemeColors.confirmation,
  },
  [CreditClaimStatus.PENDING_CUSTOMER_ACTION]: {
    text: ThemeColors.error,
    bg: 'transparent',
    borderColor: ThemeColors.error,
  },
  [CreditClaimStatus.PROCESSING]: {
    text: ThemeColors.accent,
    bg: 'transparent',
    borderColor: ThemeColors.accent,
  },
  [CreditClaimStatus.APPROVED]: {
    text: ThemeColors.reverse,
    bg: ThemeColors.confirmation,
    borderColor: ThemeColors.confirmation,
  },
  [CreditClaimStatus.REJECTED]: {
    text: ThemeColors.reverse,
    bg: ThemeColors.error,
    borderColor: ThemeColors.error,
  },
  [CreditClaimStatus.COMPLETED]: {
    text: ThemeColors.reverse,
    bg: ThemeColors.backgroundDark,
    borderColor: ThemeColors.backgroundDark,
  },
  [CreditClaimStatus.PAYMENT_FAILED]: {
    text: ThemeColors.error,
    bg: 'transparent',
    borderColor: ThemeColors.error,
  },

  [ActivityStatus.Valid]: {
    text: ThemeColors.confirmationDark,
    bg: ThemeColors.confirmationLight,
    borderColor: ThemeColors.confirmationLight,
  },
  [ActivityStatus.Invalid]: {
    text: ThemeColors.primary,
    bg: ThemeColors.grayLight,
    borderColor: ThemeColors.grayLight,
  },
  [ActivityStatus.FollowUp]: {
    text: ThemeColors.error,
    bg: lightenOrDarken(ThemeColors.error, +30),
    borderColor: lightenOrDarken(ThemeColors.error, +30),
  },
  certificate_unavailable: {
    text: ThemeColors.secondary,
    bg: '#E7EDF0',
    borderColor: 'transparent',
  },
};

const getThemeTextColor = (status: CreditClaimStatus | ActivityStatus | string): string => {
  if (status in theme) {
    return theme[status].text;
  }

  return ThemeColors.primary;
};

const getThemeBGColor = (status: CreditClaimStatus | ActivityStatus | string): string => {
  if (status in theme) {
    return theme[status].bg;
  }

  return ThemeColors.border;
};

const getThemeBorderColor = (status: CreditClaimStatus | ActivityStatus | string): string => {
  if (status in theme) {
    return theme[status].borderColor ? theme[status].borderColor : theme[status].bg;
  }

  return ThemeColors.border;
};

const StyledStatusLabel = styled.span<{ status: CreditClaimStatus | ActivityStatus | string }>`
  display: inline-block;
  padding: 3px 8px;
  color: ${({ status }) => getThemeTextColor(status)};
  background-color: ${({ status }) => getThemeBGColor(status)};
  border: 1px solid ${({ status }) => getThemeBorderColor(status)};
  border-radius: 2px;
  white-space: nowrap;
  text-align: center;
  min-width: 80px;
`;

const getStatusLabelText = (status: CreditClaimStatus | ActivityStatus | string) => {
  if (CreditClaimStatusLabels[status]) {
    return CreditClaimStatusLabels[status];
  }

  switch (status) {
    case ActivityStatus.Valid:
    case ActivityStatus.Invalid:
    case ActivityStatus.FollowUp:
      return ActivityStatusLabel[status];
    case 'certificate_unavailable':
      return 'PDF Unavailable';
    default:
      return upperFirst(status as string);
  }
};

const StatusLabel: React.FunctionComponent<Props> = ({ status = '' }) => {
  return (
    <StyledStatusLabel status={status} data-test-id="statusLabel">
      <Text textStyle="small" tag="span">
        {getStatusLabelText(status)}
      </Text>
    </StyledStatusLabel>
  );
};

export default StatusLabel;
