export enum ActivityStatus {
  Valid = 'valid',
  Invalid = 'invalid',
  FollowUp = 'follow_up',
  Pending = 'pending',
}

export const ActivityStatusLabel = {
  [ActivityStatus.Valid]: 'Valid',
  [ActivityStatus.Invalid]: 'Invalid',
  [ActivityStatus.FollowUp]: 'Follow-up',
  [ActivityStatus.Pending]: 'Pending',
};
