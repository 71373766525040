import React from 'react';
import { connect } from 'react-redux';
import Toggle from 'react-toggled';
import styled, { css } from 'src/theme';
import { themeBreakpoint, themeColor } from 'src/theme/helpers';
import { Color } from 'src/theme/primaryTheme';
import { ReduxStore } from '../../ducks';
import { isAuthenticated } from '../../selectors/auth';
import { isCreditProcessor, userLoadingSelector } from '../../selectors/user';
import { ThemeColors } from '../../theme/primaryTheme';
import Button from '../Button';
import Icon from '../Icon';
import EarnerNavLinks from './EarnerNavLinks';
import ProcessorNavLinks from './ProcessorNavLinks';

interface Props {
  isAuthenticated: boolean;
  isLoading: boolean;
  isProcessor: boolean;
}

const NavMenu: React.FunctionComponent<Props> = ({ isAuthenticated, isLoading = true, isProcessor }) => {
  const renderLinks = () => {
    if (isLoading) {
      return null;
    }

    if (isProcessor) {
      return <ProcessorNavLinks />;
    }

    if (isAuthenticated) {
      return <EarnerNavLinks />;
    }

    return null;
  };

  return (
    <>
      <Toggle>
        {({ on: isOpen, toggle }) =>
          isOpen ? (
            <>
              <MobileMenuButton isAuthenticated={isAuthenticated} onClick={toggle} buttonStyle="icon-toggle">
                <MobileOnlyIcon name="close" fill={ThemeColors[Color.Accent]} width={20} height={20} viewBox="0 0 15 15" />
              </MobileMenuButton>
              <MobileMenu>
                <MobileNavLinks>{renderLinks()}</MobileNavLinks>
              </MobileMenu>
            </>
          ) : (
            <MobileMenuButton isAuthenticated={isAuthenticated} onClick={toggle} buttonStyle="icon-toggle">
              <MobileOnlyIcon name="hamburger" fill={ThemeColors[Color.Accent]} width={20} height={20} viewBox="0 0 22 18" />
            </MobileMenuButton>
          )
        }
      </Toggle>
      <DesktopNavLinks>{renderLinks()}</DesktopNavLinks>
    </>
  );
};

const MobileMenuButton = styled(Button)<{ isAuthenticated: boolean }>`
  display: none;
  ${({ isAuthenticated }) =>
    isAuthenticated
      ? ''
      : css`
          visibility: hidden;
        `}

  @media (max-width: ${themeBreakpoint('medium')}) {
    display: block;
    width: 2rem;
  }
`;

const DesktopNavLinks = styled.div`
  flex: 1;
  display: flex;
  align-items: center;

  > * + * {
    margin-left: 4rem;
  }

  @media (max-width: ${themeBreakpoint('medium')}) {
    display: none;
  }
`;

const MobileNavLinks = styled.div`
  display: none;

  @media (max-width: ${themeBreakpoint('medium')}) {
    display: flex;
    flex-direction: column;
  }
`;

const MobileMenu = styled.div`
  display: none;

  @media (max-width: ${themeBreakpoint('medium')}) {
    position: absolute;
    top: 11.5rem;
    left: 0rem;
    z-index: 2;
    display: block;
    background-color: ${themeColor(Color.Reverse)};
    width: 100%;
    height: 100%;
    padding: 0 1rem;
    border-bottom: 1px solid ${themeColor(Color.BorderLight)};
  }
`;

const MobileOnlyIcon = styled(Icon)`
  display: none;

  @media (max-width: ${themeBreakpoint('medium')}) {
    display: block;
  }
`;

const mapStateToProps = (state: ReduxStore) => ({
  isAuthenticated: isAuthenticated(state),
  isLoading: userLoadingSelector(state),
  isProcessor: isCreditProcessor(state),
});

export default connect(mapStateToProps)(NavMenu);
