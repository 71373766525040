import React from 'react';
import { BulkLearnerActivityUploadForm } from '../../components/BulkLearnerActivityUploadForm';
import { BulkLearnerActivityUploadsTable } from '../../components/BulkLearnerActivityUploadsTable';
import Text, { TextStyle } from '../../components/Text';
import DefaultPage from '../DefaultPage';
import { PageSection, PageSectionTitle } from '../PageStructure';

const BulkLearnerActivityUpload: React.FunctionComponent = () => {
  return (
    <DefaultPage title="Bulk Learner Activity Upload">
      <PageSection>
        <PageSectionTitle>
          <Text tag="h2" bold={true} textStyle={TextStyle.SectionTitle}>
            Upload CSV
          </Text>
        </PageSectionTitle>
        <Text textStyle={TextStyle.SubTitle}>Here you can upload a CSV</Text>
        <BulkLearnerActivityUploadForm />
      </PageSection>
      <PageSection>
        <PageSectionTitle>
          <Text tag="h2" bold={true} textStyle={TextStyle.SectionTitle}>
            Ingested CSVs
          </Text>
        </PageSectionTitle>
        <Text textStyle={TextStyle.SubTitle}>Here you can download Successes and Errors and see the stats for an ingestion</Text>
        <BulkLearnerActivityUploadsTable />
      </PageSection>
    </DefaultPage>
  );
};

export default BulkLearnerActivityUpload;
