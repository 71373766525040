import React from 'react';
import { connect } from 'react-redux';
import { ReduxStore } from '../../ducks';
import { isCreditProcessor } from '../../selectors/user';
import { ExternalCreditEarner } from '../../types';
import { formattedDate, momentUtc } from '../../utilities/moment';
import { FieldColumn, FieldRow } from '../FieldRow';
import SummaryValue from '../SummaryValue';

interface Props {
  user: ExternalCreditEarner;
  isProcessor?: boolean;
}

const UserInfoSummary: React.FunctionComponent<Props> = ({ user = {} as ExternalCreditEarner, isProcessor = false }) => {
  const { firstName, lastName, degreeType, meNumber, dob, amaMember } = user;
  const membershipStatusString = amaMember ? 'Yes' : 'No';
  const dobString = dob && momentUtc(dob).isValid() && formattedDate(dob);

  return (
    <>
      <FieldRow even={true}>
        <FieldColumn>
          <SummaryValue label="First Name" value={firstName} />
        </FieldColumn>
        <FieldColumn>
          <SummaryValue label="Last Name" value={lastName} />
        </FieldColumn>
      </FieldRow>
      {isProcessor && (
        <FieldRow>
          <FieldColumn>
            <SummaryValue label="Degree" value={degreeType} />
          </FieldColumn>
        </FieldRow>
      )}
      {isProcessor && (
        <FieldRow>
          <FieldColumn>
            <SummaryValue label="ME #" value={meNumber} />
          </FieldColumn>
        </FieldRow>
      )}
      {isProcessor && (
        <FieldRow>
          <FieldColumn>
            <SummaryValue label="Birthdate" value={dobString} />
          </FieldColumn>
        </FieldRow>
      )}
      <FieldRow>
        <FieldColumn data-test-id="membershipStatus">
          <SummaryValue label="AMA Member" value={membershipStatusString} />
        </FieldColumn>
      </FieldRow>
    </>
  );
};

const mapStateToProps = (store: ReduxStore) => {
  return {
    isProcessor: isCreditProcessor(store),
  };
};

export default connect(mapStateToProps)(UserInfoSummary);
