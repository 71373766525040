import { Field as FormikField, FieldProps } from 'formik';
import React, { ChangeEvent } from 'react';
import styled from '../../theme';
import { themeBreakpoint, themeColor } from '../../theme/helpers';
import Text from '../Text';
import ToggleSwitch from '../ToggleSwitch';

const FlexLabel = styled.label<{ disabled: boolean }>`
  width: 110px;
  @media (max-width: ${themeBreakpoint('large')}) {
    width: 100%;
    flex: 0 9;
  }
  display: flex;
  align-items: center;
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
`;

const ToggleControl = styled.input`
  padding: 0;
  margin: 0;
  width: 0;
  height: 0;
  opacity: 0;

  &:focus {
    outline: 1px solid ${themeColor('primary')};
  }
`;

const ToggleLabel = styled(Text)`
  position: relative;
  top: 0.125em;
  margin-left: 0.65em;
  line-height: 1;
  font-size: 1.6rem;
`;

export interface ToggleInputProps {
  showLabel: boolean;
  disabled: boolean;
  name: string;
}

const ToggleInput: React.FunctionComponent<ToggleInputProps> = ({ showLabel, disabled, name }) => {
  const [hasFocus, setHasFocus] = React.useState(false);

  return (
    <FormikField name={name}>
      {({ field }: FieldProps) => {
        const { value, onBlur } = field;

        const handleBlur = (event: ChangeEvent<HTMLInputElement>) => {
          setHasFocus(false);

          if (onBlur) {
            onBlur(event);
          }
        };

        return (
          <FlexLabel htmlFor={name} disabled={disabled}>
            <ToggleControl
              {...field}
              name={name}
              id={name}
              type="checkbox"
              disabled={disabled}
              checked={value}
              value={value}
              onFocus={() => setHasFocus(true)}
              onBlur={handleBlur}
            />
            <ToggleSwitch on={value} hasFocus={hasFocus} />
            {showLabel && (
              <ToggleLabel color={value ? 'accent' : 'secondary'} bold={true} tag="span">
                {value ? 'Yes' : 'No'}
              </ToggleLabel>
            )}
          </FlexLabel>
        );
      }}
    </FormikField>
  );
};

export default ToggleInput;
