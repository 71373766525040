import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { LinkStyle } from '../../constants/LinkStyle';
import { API_APP_ID, loginUrl } from '../../utilities/authentication';
import ExternalLink from '../Link/ExternalLink';

const { REACT_APP_PUBLIC_URL } = process.env;

interface LoginButtonProps extends RouteComponentProps {
  linkStyle?: LinkStyle;
}

const LoginButton: React.FunctionComponent<LoginButtonProps> = ({ linkStyle, children, location, ...rest }) => {
  const path = location ? location.pathname : '/';
  const redirectTo = path !== '/' ? `${REACT_APP_PUBLIC_URL}${path}` : `${REACT_APP_PUBLIC_URL}/preferences`;

  // Workaround to direct user to SSO with appropriate branding
  const setAppIdInUrl = () => {
    if (window.history && !window.location.href.includes(`appId=${API_APP_ID}`)) {
      const updatedHistoryUrl = window.location.href + (window.location.href.includes('?') ? '&' : '?') + `appId=${API_APP_ID}`;
      window.history.replaceState(null, null, updatedHistoryUrl);
    }
  };

  return (
    <ExternalLink linkStyle={linkStyle} href={loginUrl(redirectTo)} onClick={setAppIdInUrl} {...rest}>
      {children}
    </ExternalLink>
  );
};

export default withRouter(LoginButton);
