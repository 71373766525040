import { CreditClaimStatus } from 'src/constants/ApplicationStatus';
import { S3UploadStatus } from 'src/constants/S3UploadStatus';
import { momentIsBefore, momentUtc } from 'src/utilities/moment';
import * as Yup from 'yup';
import { PraClaimMethod } from '../../constants/PraClaimMethod';
import { category2Columns } from './category2Columns';

const getOtherFieldNames = (allFieldNames: string[], fieldName: string): string[] => allFieldNames.filter((name) => name !== fieldName);

const abmsActivitySchema = {
  board: Yup.string().required('Board is required'),
  certificationDate: Yup.date()
    .typeError('Valid certification date is required')
    .required()
    .test({
      name: 'isValid',
      message: 'Valid certification date is required',
      test: (value) => {
        return value === null || momentUtc(value).isValid();
      },
    }),
};

const reciprocityActivitySchema = {
  provider: Yup.string().required('Organization name is required'),
  activityEndDate: Yup.date()
    .typeError('Valid end date is required')
    .required()
    .test({
      name: 'isValid',
      message: 'Valid end date is required',
      test: (value) => {
        return value === null || momentUtc(value).isValid();
      },
    }),
};

const residencyActivitySchema = {
  program: Yup.string().required('Program name is required'),
  activityUnits: Yup.number().required('Number of years is required'),
  activityStartDate: Yup.date()
    .typeError('Valid start date is required')
    .required()
    .test({
      name: 'isValid',
      message: 'Valid start date is required',
      test: (value) => {
        return value === null || momentUtc(value).isValid();
      },
    }),
  activityEndDate: Yup.date()
    .typeError('Valid end date is required')
    .required()
    .test({
      name: 'isValid',
      message: 'Valid end date is required',
      test: (value) => {
        return value === null || momentUtc(value).isValid();
      },
    }),
};

// Common Category 1 schema between Credit Earner and Credit Processor
const sharedCategory1CreditActivitySchema = {
  provider: Yup.string(),
  activityTitle: Yup.string(),
  activityEndDate: Yup.date()
    .nullable(true)
    .test({
      name: 'isValid',
      message: 'Valid end date is required',
      test: (value) => {
        return value === null || momentUtc(value).isValid();
      },
    }),
  creditActivityAttachments: Yup.array().of(
    Yup.object()
      .nullable(true)
      .shape({
        status: Yup.string()
          .nullable(true)
          .oneOf([S3UploadStatus.FAILED, S3UploadStatus.COMPLETE], 'Please wait until all uploads have finished'),
      })
  ),
  numberOfCredits: Yup.string()
    .nullable(true)
    .test({
      name: 'isNumber',
      message: 'Number of credits must be a number',
      test: (value) => (value ? !isNaN(Number(value)) : true),
    }),
};

// Common Category 2 schema between Credit Earner and Credit Processor
const sharedCategory2CreditActivitySchema = {
  activityTitle: Yup.string().when(getOtherFieldNames(Object.keys(category2Columns), 'activityTitle'), {
    is: (...otherValues) => otherValues.some((value) => !!value),
    then: Yup.string().required(),
    otherwise: Yup.string().nullable(true).notRequired(),
  }),
  activityDescription: Yup.string().when(getOtherFieldNames(Object.keys(category2Columns), 'activityDescription'), {
    is: (...otherValues) => otherValues.some((value) => !!value),
    then: Yup.string().required(),
    otherwise: Yup.string().nullable(true).notRequired(),
  }),
  activityEndDate: Yup.date().when(getOtherFieldNames(Object.keys(category2Columns), 'activityEndDate'), {
    is: (...otherValues) => otherValues.some((value) => !!value),
    then: Yup.date()
      .required()
      .nullable(true)
      .test({
        name: 'isValid',
        message: 'Valid end date is required',
        test: (value) => {
          return value === null || momentUtc(value).isValid();
        },
      }),
    otherwise: Yup.date().nullable(true).notRequired(),
  }),
  numberOfCredits: Yup.string().when(getOtherFieldNames(Object.keys(category2Columns), 'numberOfCredits'), {
    is: (...otherValues) => otherValues.some((value) => !!value),
    then: Yup.string()
      .required()
      .test({
        name: 'isNumber',
        message: 'Number of credits must be a number',
        test: (value) => (value ? !isNaN(Number(value)) : true),
      }),
    otherwise: Yup.string().nullable(true).notRequired(),
  }),
};

// These are necessary to avoid cyclic dependency in Yup validation.
// This array should contain tuples of every possible field pair permutation.
const category2ActivityFieldPairs: Array<[string, string]> = [
  ['activityTitle', 'activityDescription'],
  ['activityTitle', 'activityEndDate'],
  ['activityTitle', 'numberOfCredits'],
  ['activityDescription', 'activityEndDate'],
  ['activityDescription', 'numberOfCredits'],
  ['activityEndDate', 'numberOfCredits'],
];

export const processorSchema = Yup.object().shape({
  status: Yup.string()
    .required()
    .test({
      name: 'claimStatusChosen',
      message: 'You must select a valid claim status',
      test: (value) =>
        ([CreditClaimStatus.APPROVED, CreditClaimStatus.REJECTED, CreditClaimStatus.PENDING_CUSTOMER_ACTION] as Array<string>).includes(
          value
        ),
    }),
  category1CreditActivities: Yup.array().of(
    Yup.object().shape({
      ...sharedCategory1CreditActivitySchema,
      status: Yup.string(),
    })
  ),
  category2CreditActivities: Yup.array().of(
    Yup.object().shape(
      {
        ...sharedCategory2CreditActivitySchema,
      },
      category2ActivityFieldPairs
    )
  ),
  praCertificateEffectiveDate: Yup.date().when('status', {
    is: (val) => val === CreditClaimStatus.APPROVED,
    then: Yup.date()
      .typeError('Please enter a valid date')
      .required()
      .test({
        name: 'isBeforeExpiration',
        message: 'Effective date must precede expiration date',
        test: function test(value) {
          return !momentUtc(value).isValid() ? false : momentIsBefore(value, this.parent.praCertificateExpirationDate);
        },
      }),
    otherwise: Yup.date().nullable(true).notRequired(),
  }),
  praCertificateExpirationDate: Yup.date().when('status', {
    is: (val) => val === CreditClaimStatus.APPROVED,
    then: Yup.date()
      .typeError('Please enter a valid date')
      .required()
      .test({
        name: 'isValid',
        message: 'Expiration date must be a valid date',
        test: (value) => momentUtc(value).isValid(),
      }),
    otherwise: Yup.date().nullable(true).notRequired(),
  }),
  abmsActivity: Yup.object().when('praCertificateClaimMethod', {
    is: (method) => method === PraClaimMethod.ABMS,
    then: Yup.object().shape({
      ...abmsActivitySchema,
    }),
    otherwise: Yup.object().nullable(true).notRequired(),
  }),
  reciprocityActivity: Yup.object().when('praCertificateClaimMethod', {
    is: (method) => method === PraClaimMethod.Reciprocity,
    then: Yup.object().shape({
      ...reciprocityActivitySchema,
    }),
    otherwise: Yup.object().nullable(true).notRequired(),
  }),
  residencyActivity: Yup.object().when('praCertificateClaimMethod', {
    is: (method) => method === PraClaimMethod.Residency,
    then: Yup.object().shape({
      ...residencyActivitySchema,
    }),
    otherwise: Yup.object().nullable(true).notRequired(),
  }),
  additionalPraCat1Credits: Yup.string()
    .nullable(true)
    .test({
      name: 'isNumber',
      message: 'Additional PRA Category 1 Credits must be a number',
      test: (value) => (value ? !isNaN(Number(value)) : true),
    }),
});

const nonPreviousCMECreditActivityAttachmentSchema = {
  creditActivityAttachments: Yup.array()
    .label('isValid')
    .compact((attachment) => attachment.status === S3UploadStatus.FAILED)
    .min(1, 'You must have at least one successfully loaded supporting document to proceed'),
};

export const earnerSchema = Yup.object().shape({
  abmsActivity: Yup.object().when('praCertificateClaimMethod', {
    is: (method) => method === PraClaimMethod.ABMS,
    then: Yup.object().shape({
      ...abmsActivitySchema,
      ...nonPreviousCMECreditActivityAttachmentSchema,
    }),
    otherwise: Yup.object().nullable(true).notRequired(),
  }),
  reciprocityActivity: Yup.object().when('praCertificateClaimMethod', {
    is: (method) => method === PraClaimMethod.Reciprocity,
    then: Yup.object().shape({
      ...reciprocityActivitySchema,
      ...nonPreviousCMECreditActivityAttachmentSchema,
    }),
    otherwise: Yup.object().nullable(true).notRequired(),
  }),
  residencyActivity: Yup.object().when('praCertificateClaimMethod', {
    is: (method) => method === PraClaimMethod.Residency,
    then: Yup.object().shape({
      ...residencyActivitySchema,
      ...nonPreviousCMECreditActivityAttachmentSchema,
    }),
    otherwise: Yup.object().nullable(true).notRequired(),
  }),
  category2CreditActivities: Yup.array().of(
    Yup.object().shape(
      {
        ...sharedCategory2CreditActivitySchema,
      },
      category2ActivityFieldPairs
    )
  ),
  additionalPraCat1Credits: Yup.string()
    .nullable(true)
    .test({
      name: 'isNumber',
      message: 'Additional PRA Category 1 Credits must be a number',
      test: (value) => (value ? !isNaN(Number(value)) : true),
    }),
  // For previous CME PRA claims only:
  creditActivityAttachments: Yup.array().when('praCertificateClaimMethod', {
    is: (method) => method === PraClaimMethod.PreviousCME,
    then: Yup.array()
      .label('isValid')
      .compact((attachment) => attachment.status === S3UploadStatus.FAILED)
      .min(1, 'You must have at least one successfully loaded supporting document to proceed'),
  }),
});
