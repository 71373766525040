import { isEmpty } from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';
import { ApplicationTypeCode } from 'src/constants/ApplicationTypeCode';
import { Color } from 'src/theme/primaryTheme';
import styled from 'styled-components';
import { LinkStyle } from '../../constants/LinkStyle';
import { buildRoute, Routes } from '../../constants/Routes';
import { awardTypeSelector, claimDataSelector, claimIsEditableSelector } from '../../selectors/claims';
import Link from '../Link';
import StatusLabel from '../StatusLabel';
import Text from '../Text';
import { TextStyle } from '../Text/index';

const ApplicationMetadata: React.FC = () => {
  const claim = useSelector(claimDataSelector);
  const claimIsEditable = useSelector(claimIsEditableSelector);
  const awardType = useSelector(awardTypeSelector);

  const claimTypes = {
    [ApplicationTypeCode.DirectCredit]: 'Direct Credit Application',
    [ApplicationTypeCode.InternationalCreditConversion]: 'Credit Conversion Application',
    [ApplicationTypeCode.PRA]: "Physician's Recognition Award",
  };

  return claim && !isEmpty(claim) ? (
    <>
      {claim.applicationTypeCode && (
        <MetadataGroup>
          <ClaimType tag="h3" textStyle={TextStyle.ClaimType}>
            {claimTypes[claim.applicationTypeCode]}
          </ClaimType>
          <StatusLabel status={claim.status} />
        </MetadataGroup>
      )}
      {claim.applicationTypeCode === ApplicationTypeCode.PRA && (
        <MetadataGroup>
          <Text tag="h4" color={Color.Gray}>
            {awardType}
          </Text>
          {claimIsEditable && (
            <AwardTypeLink to={buildRoute(Routes.PraAwardType, { id: claim.id })} linkStyle={LinkStyle.ExternalLink}>
              Update application type
            </AwardTypeLink>
          )}
        </MetadataGroup>
      )}
    </>
  ) : null;
};

const MetadataGroup = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;

  &:last-of-type {
    margin-bottom: 0;
  }
`;

const ClaimType = styled(Text)`
  margin-right: 1rem;
`;

const AwardTypeLink = styled(Link)`
  font-size: 1.2rem;
  margin-left: 1rem;
`;

export default ApplicationMetadata;
