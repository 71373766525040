import React from 'react';
import { ApplicationTypeCodeLabels } from '../../constants/ApplicationTypeCodeLabels';
import { ModalType } from '../../constants/ModalType';
import { NotesStyle } from '../../constants/NotesStyle';
import { CreditClaim } from '../../types';
import ActionModal from '../ActionModal';
import ApplicationNotes from '../ApplicationNotes';
import Text, { TextStyle } from '../Text';
import { getEarnerName } from './index';

interface Props {
  claim: CreditClaim;
}

const NotesModal: React.FunctionComponent<Props> = ({ claim }) => {
  const handleConfirm = () => true;

  return (
    <ActionModal
      modalId={`${ModalType.ApplicationNotes}-${claim.id}`}
      title="Application Notes"
      onConfirm={handleConfirm}
      showButtons={false}
    >
      <Text textStyle={TextStyle.Label}>Application</Text>
      <div style={{ marginBottom: '1rem' }} />
      <Text textStyle={TextStyle.Default} bold={true}>
        {getEarnerName(claim)}
      </Text>
      <Text textStyle={TextStyle.Default}>{ApplicationTypeCodeLabels[claim.applicationTypeCode || '']}</Text>
      <ApplicationNotes claimId={claim.id} claimNotes={claim.notes} notesStyle={NotesStyle.Modal} />
    </ActionModal>
  );
};

export default NotesModal;
