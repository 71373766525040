import React, { useEffect, useState } from 'react';
import { Color, ThemeColors } from '../../theme/primaryTheme';
import { apiRequest } from '../../utilities/api';
import Button from '../Button';
import Icon from '../Icon';
import LoadingSpinner from '../LoadingSpinner';

interface Props {
  claimId: number;
  flagged: boolean;
  size?: number;
}

const FlagApplicationButton: React.FunctionComponent<Props> = ({ claimId, flagged: flaggedFromProps, size = 20 }) => {
  const [flagged, setFlagged] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(
    () => {
      setFlagged(flaggedFromProps);
    },
    [claimId] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const handleClick = () => {
    setLoading(true);

    apiRequest.put(`/v1/creditclaims/${claimId}`, { flagged: !flagged }).then(({ data }) => {
      setFlagged(data.flagged);
      setLoading(false);
    });
  };

  if (loading) {
    return <LoadingSpinner size={14} />;
  }

  return (
    <Button onClick={handleClick} buttonStyle="icon-toggle" data-test-id="flagApplication">
      <Icon
        viewBox="0 0 17 19"
        width={size}
        height={size * 1.1}
        fill={flagged ? ThemeColors[Color.Error] : ThemeColors[Color.Secondary]}
        name={flagged ? 'flagFill' : 'flagOutline'}
      />
    </Button>
  );
};

export default FlagApplicationButton;
