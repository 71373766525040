import React from 'react';
import logo from '../../assets/images/EdHub-logo-trademark-gray-white.svg';
import styled from '../../theme';
import { themeBreakpoint, themeColor } from '../../theme/helpers';
import { Color } from '../../theme/primaryTheme';
import { PageWrap } from '../PageStructure/index';

const Footer = () => {
  return (
    <StyledFooter>
      <FooterWrap>
        <StyledLink href="https://edhub.ama-assn.org/">
          <Logo src={logo} alt="American Medical Association Ed Hub" />
        </StyledLink>
        <Copyright>
          <sup>&copy;</sup> {new Date().getFullYear()} American Medical Association. All rights reserved, including those for text and data
          mining, AI training, and similar technologies.
        </Copyright>
        <NavWrap>
          <NavLink rel="nofollow" href="https://www.ama-assn.org/about/terms-use">
            Terms of Use
          </NavLink>
          <NavDivider>|</NavDivider>
          <NavLink rel="nofollow" href="https://www.ama-assn.org/privacy-policy">
            Privacy Policy
          </NavLink>
          <NavDivider>|</NavDivider>
          <NavLink rel="nofollow" href="https://www.ama-assn.org/about/accessibility-statement">
            Accessibility Statement
          </NavLink>
          <NavDivider>|</NavDivider>
          <NavLink rel="nofollow" href="javascript:OneTrust.ToggleInfoDisplay()">
            Cookie Settings
          </NavLink>
        </NavWrap>
      </FooterWrap>
    </StyledFooter>
  );
};

const StyledFooter = styled.footer`
  background: ${themeColor(Color.BackgroundDark)};
  text-align: center;
`;

const FooterWrap = styled(PageWrap)`
  padding-top: 6rem;
  padding-bottom: 7rem;

  @media (max-width: ${themeBreakpoint('medium')}) {
    padding-top: 5rem;
    padding-bottom: 6rem;
  }
`;

const Copyright = styled.div`
  color: ${themeColor(Color.Secondary)};
  font-size: 1.2rem;
  padding-top: 2.2rem;
`;

const StyledLink = styled.a`
  display: inline-block;
`;

const NavWrap = styled.div`
  color: ${themeColor(Color.Secondary)};
  font-size: 1.3rem;
  padding-top: 0.3rem;
`;

const NavDivider = styled.span`
  margin: 0 10px;
  color: ${themeColor(Color.Reverse)};
`;

const NavLink = styled.a`
  display: inline-block;
  color: ${themeColor(Color.Reverse)};

  &:hover {
    text-decoration: underline;
  }
`;

const Logo = styled.img`
  width: 16rem;
`;

export default Footer;
