import React from 'react';
import { useSelector } from 'react-redux';
import { calculatePriceForCollectionOfDcIccActivities } from 'src/constants/ProccesingRates';
import styled from 'styled-components';
import { ActivityType } from '../../../../constants/ActivityType';
import { buildRoute, Routes } from '../../../../constants/Routes';
import { claimDataSelector, claimUserSelector } from '../../../../selectors/claims';
import { calculateValidAndPendingCredits, groupActivitiesByType } from '../../../../utilities/creditActivities';
import EditButton from '../../../EditButton';
import FormSection from '../../../FormSection';
import Text from '../../../Text';
import ActivityTypeGroup from './ActivityTypeGroup';

const CreditActivitiesSummary: React.FC<{ className?: string }> = ({ className }) => {
  const user = useSelector(claimUserSelector);
  const { id, creditActivities, rateCode } = useSelector(claimDataSelector);

  const renderEdit = () => (
    <EditButton id="editActivities" to={buildRoute(Routes.ApplicationActivities, { id })} label="Add More Activities" />
  );

  const activitiesByType = groupActivitiesByType(creditActivities ?? []);

  return (
    <FormSection title="Activities Summary" renderAction={renderEdit}>
      <ActivitiesSummaryTable className={className}>
        <thead>
          <tr>
            <th>{/* empty cell for alignment */}</th>
            <TableHeading colSpan={2}>
              <Text bold={true}>Activity Type</Text>
            </TableHeading>
            <TableHeading>
              <Text bold={true}>Credits</Text>
            </TableHeading>
            <TableHeading>
              <Text bold={true}>Price</Text>
            </TableHeading>
          </tr>
        </thead>
        {Object.keys(activitiesByType).map((activityType: ActivityType) => (
          <ActivityTypeGroup key={activityType} activityType={activityType} activities={activitiesByType[activityType]} />
        ))}
        <tfoot data-testid="totals">
          <tr>
            <th>{/* empty cell for alignment */}</th>
            <TableHeading colSpan={2}>
              <Text bold={true}>TOTAL</Text>
            </TableHeading>
            <TableHeading>
              <Text bold={true}>{calculateValidAndPendingCredits(creditActivities ?? [])}</Text>
            </TableHeading>
            <TableHeading>
              <Text bold={true}>{`$${calculatePriceForCollectionOfDcIccActivities(rateCode, creditActivities, user?.amaMember)}`}</Text>
            </TableHeading>
          </tr>
        </tfoot>
      </ActivitiesSummaryTable>
    </FormSection>
  );
};

const ActivitiesSummaryTable = styled.table`
  border-collapse: collapse;
  width: 100%;

  & td,
  th {
    padding: 1.6rem;
  }
`;

const TableHeading = styled.th`
  text-align: left;
  font-size: 1.8rem;
`;

export default CreditActivitiesSummary;
