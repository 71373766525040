import { AxiosError, AxiosResponse } from 'axios';
import { Form, Formik, FormikBag, FormikProps, getIn } from 'formik';
import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import Toggle from 'react-toggled';
import { updateClaim, UpdateClaimAsync } from '../../ducks/claims';
import { PageFooter, PageSection, PageSectionTitle, PageTitleIcon } from '../../layouts/PageStructure';
import { ExternalCreditEarner } from '../../types';
import { addSources } from '../../utilities/addSources';
import { apiRequest } from '../../utilities/api';
import { nullifyEmptyValues } from '../../utilities/cleanData';
import Alert, { AlertType } from '../Alert';
import Button from '../Button';
import Field from '../Field';
import AddressField from '../Field/AddressField';
import ArrayField from '../Field/ArrayField';
import DatePickerField from '../Field/DatePickerField';
import { FieldColumn, FieldRow } from '../FieldRow';
import FormSection from '../FormSection';
import Icon from '../Icon';
import Text from '../Text';
import { UserFormValues } from './types';
import { validationSchema } from './validationSchema';

const defaultValues: UserFormValues = {
  aimsEntityId: '',
  firstName: '',
  lastName: '',
  degreeType: '',
  meNumber: '',
  npiNumbers: [''],
  dob: null,
  emails: [''],
  address1: '',
  address2: '',
  city: '',
  stateCode: '',
  countryCode: '',
  zip: '',
  phone: '',
  fax: '',
  medSchool: '',
  gradYear: '',
  sources: {},
};

const buildInitialValues = (user?: ExternalCreditEarner): UserFormValues => {
  if (!user) {
    return defaultValues;
  }

  const clonedDefaultValues = { ...defaultValues };

  return Object.keys(defaultValues).reduce((acc, key) => {
    if (Array.isArray(user[key])) {
      acc[key] = !user[key].length ? defaultValues[key] : user[key];
    } else {
      acc[key] = !user[key] ? defaultValues[key] : user[key];
    }

    return acc;
  }, clonedDefaultValues);
};

type redirectToFunction = (data: ExternalCreditEarner) => any;

interface Props {
  history: any;
  user?: ExternalCreditEarner;
  updateClaim: UpdateClaimAsync;
  submitText: string;
  redirectTo: string | redirectToFunction;
  view?: 'update' | 'create';
}

class CreditEarnerForm extends React.Component<Props> {
  public render() {
    const { history, user, submitText, view } = this.props;
    return (
      <Formik
        initialValues={buildInitialValues(user)}
        initialStatus={{ error: undefined }}
        isInitialValid={this.isInitialValid}
        enableReinitialize={true}
        validationSchema={validationSchema}
        onSubmit={this.handleSubmit(history, updateClaim)}
      >
        {({ status, dirty, isSubmitting, handleReset }: FormikProps<UserFormValues>) => {
          return (
            <>
              {status && status.error && (
                <Alert type={AlertType.Error} data-test-id="applicantInformationError">
                  {status.error}
                </Alert>
              )}
              <Form data-test-id="applicantInformationForm">
                <FormSection title="Personal Information">
                  <FieldRow even={true}>
                    <FieldColumn>
                      <Field name="firstName" placeholder="John" label="First Name" required={true} locked={view === 'update'} />
                    </FieldColumn>
                    <FieldColumn>
                      <Field name="lastName" placeholder="Smith" label="Last Name" required={true} locked={view === 'update'} />
                    </FieldColumn>
                  </FieldRow>
                  <FieldRow>
                    <FieldColumn>
                      <DatePickerField name="dob" placeholder="MM/DD/YYYY" label="Date of Birth" />
                    </FieldColumn>
                  </FieldRow>
                </FormSection>
                <FormSection title="Contact Information">
                  <FieldRow>
                    <FieldColumn grow={1}>
                      <ArrayField name="emails" label="Email" addAnotherLabel="Add additional email address">
                        {(fieldName: string) => (
                          <Field name={fieldName} placeholder="example@example.com" controlProps={{ type: 'email' }} />
                        )}
                      </ArrayField>
                    </FieldColumn>
                  </FieldRow>
                  <AddressField label="Mailing Address" showCountry={true} />
                </FormSection>
                <PageSection>
                  <Toggle defaultOn={Boolean(user && user.id)}>
                    {({ on, toggle }) => (
                      <>
                        <PageSectionTitle>
                          <Text tag="h2" bold={true} textStyle="medium">
                            Additional Information
                          </Text>
                          <PageTitleIcon type="button" buttonStyle="toggle" onClick={toggle} data-test-id="sectionToggle">
                            <Icon name={on ? 'angleArrowUp' : 'angleArrowDown'} width={16} height={10} viewBox="0 0 16 10" />
                          </PageTitleIcon>
                        </PageSectionTitle>

                        {on && (
                          <div style={{ marginTop: 32 }} data-test-id="additionalInfo">
                            <FieldRow even={true}>
                              <FieldColumn>
                                <Field name="phone" label="Phone" placeholder="555-555-5555" controlProps={{ type: 'tel' }} />
                              </FieldColumn>
                              <FieldColumn>
                                <Field name="fax" label="Fax" placeholder="555-555-5555" controlProps={{ type: 'tel' }} />
                              </FieldColumn>
                            </FieldRow>
                            <FieldRow even={true}>
                              <FieldColumn>
                                <Field name="medSchool" label="Medical School" placeholder="Enter Medical School" />
                              </FieldColumn>
                              <FieldColumn>
                                <Field name="gradYear" label="Graduation Year" placeholder="YYYY" />
                              </FieldColumn>
                            </FieldRow>
                          </div>
                        )}
                      </>
                    )}
                  </Toggle>
                </PageSection>
                <PageFooter>
                  <Button type="submit" loading={isSubmitting}>
                    {submitText}
                  </Button>

                  {dirty && (
                    <Button onClick={handleReset} type="reset" buttonStyle="plain-text">
                      Cancel
                    </Button>
                  )}
                </PageFooter>
              </Form>
            </>
          );
        }}
      </Formik>
    );
  }

  private handleSubmit = (history: any, updateClaim: UpdateClaimAsync) => {
    const { user, redirectTo } = this.props;
    let endpoint = '/v1/creditearner';
    let method = 'POST';

    if (user && user.id) {
      endpoint = `${endpoint}/${user.id}`;
      method = 'PUT';
    }

    return (values: UserFormValues, formikBag: FormikBag<RouteComponentProps, UserFormValues>) => {
      const { setStatus, setSubmitting } = formikBag;
      const cleanValues = nullifyEmptyValues(values);
      const bodyValues = addSources(cleanValues);

      apiRequest[method.toLowerCase()](endpoint, bodyValues)
        .then(({ data }: AxiosResponse<ExternalCreditEarner>) => {
          setSubmitting(false);
          updateClaim(data.id, data);
          if (redirectTo) {
            if (typeof redirectTo === 'function') {
              history.push(redirectTo(data));
            } else {
              history.push(redirectTo);
            }
          }
        })
        .catch((error: AxiosError) => {
          setSubmitting(false);
          const message = 'Could not submit form. Please try again.';
          const errResponse = getIn(error, 'response.data.error', { message });
          setStatus({
            success: false,
            error: errResponse.message,
          });
          console.error('error', error);
        });
    };
  };

  private isInitialValid = ({ initialValues }: FormikProps<UserFormValues>) => validationSchema.isValidSync(initialValues);
}

const mapDispatchToProps = {
  updateClaim,
};

export default connect(null, mapDispatchToProps)(CreditEarnerForm);
