import moment from 'moment';
import { AbmsActivitySubType } from 'src/constants/AbmsActivitySubType';
import { S3UploadStatus } from 'src/constants/S3UploadStatus';
import { momentUtc } from 'src/utilities/moment';
import { matchQuarterValues, matchZeroValues } from 'src/utilities/regex';
import * as Yup from 'yup';
import { AccreditorOptions } from '../../constants/Accreditor';
import { ActivityStatus } from '../../constants/ActivityStatus';
import { ActivityType } from '../../constants/ActivityType';

const DEFAULT_REQUIRED_TEXT = 'This field is required';

const isDateWithinSixYears = (val: any) => {
  if (!val) {
    return true;
  }
  const sixYearsFromToday = momentUtc().startOf('day').subtract(6, 'years');
  return moment(val).isSameOrAfter(sixYearsFromToday);
};

const isPositiveNumber = (value: any): boolean => {
  if (!value) {
    return true;
  }
  const numberValue = Number(value);
  return !isNaN(numberValue) && numberValue >= 0;
};

const ceAttachmentsRequired = (val: any) => {
  return [
    ActivityType.Abms,
    ActivityType.Acgme,
    ActivityType.ICC,
    ActivityType.Medical,
    ActivityType.Poster,
    ActivityType.Teaching,
  ].includes(val);
};

const medicalFields = {
  school: Yup.string()
    .label('School')
    .when('activityTypeCode', {
      is: (val) => val === ActivityType.Medical,
      then: Yup.string().required(DEFAULT_REQUIRED_TEXT),
      otherwise: Yup.string().notRequired().nullable(),
    }),
  graduationDate: Yup.string()
    .label('Graduation Date')
    .when('activityTypeCode', {
      is: (val) => val === ActivityType.Medical,
      then: Yup.string()
        .test('isWithinSixYears', 'Must be within six years', isDateWithinSixYears)
        .nullable(true)
        .required(DEFAULT_REQUIRED_TEXT),
      otherwise: Yup.string().nullable(true).notRequired(),
    }),
};

let maxCredits,
  isTeachingActivity = false,
  isRequiredField = false;
const teachingFields = {
  creditsRequested: Yup.string()
    .when('activityTypeCode', {
      is: (val) => {
        isTeachingActivity = val === ActivityType.Teaching || false;
        return false;
      },
      then: Yup.string().notRequired().nullable(),
    })
    .when('activityEndDate', {
      is: (val) => {
        isRequiredField = (val && moment(val).isSameOrAfter(moment('2023-01-01'))) || false;
        return false;
      },
      then: Yup.string().notRequired().nullable(),
    })
    .when('activityUnits', {
      is: (val) => {
        if (isTeachingActivity && typeof val === 'string' && Number(val) >= 0) {
          maxCredits = Number(val) * 4;
        }
        return isTeachingActivity && isRequiredField;
      },
      then: Yup.string()
        .required(DEFAULT_REQUIRED_TEXT)
        .max(6, ' ')
        .matches(matchQuarterValues, { message: ' ', excludeEmptyString: true })
        .matches(matchZeroValues, { message: ' ' })
        .test({
          name: 'isWithinRange',
          message: ' ',
          test: (value) => {
            return maxCredits && value <= maxCredits;
          },
        }),
      otherwise: Yup.string().nullable(true).notRequired(),
    }),
};

const posterFields = {
  presentationTitle: Yup.string()
    .label('Presentation Title')
    .when('activityTypeCode', {
      is: (val) => val === ActivityType.Poster,
      then: Yup.string().required(DEFAULT_REQUIRED_TEXT),
      otherwise: Yup.string().notRequired().nullable(),
    }),
};

const abmsFields = {
  board: Yup.string()
    .label('Board')
    .when('activityTypeCode', {
      is: (val) => val === ActivityType.Abms,
      then: Yup.string().required(DEFAULT_REQUIRED_TEXT),
      otherwise: Yup.string().notRequired().nullable(),
    }),
  subspeciality: Yup.string()
    .label('Subspeciality')
    .when('activitySubTypeCode', {
      is: (val) => val === AbmsActivitySubType.FOCUSED_PRACTICE,
      then: Yup.string().nullable(true).required(DEFAULT_REQUIRED_TEXT),
      otherwise: Yup.string().nullable(true).notRequired(),
    }),
  certificationDate: Yup.string()
    .label('Date')
    .when('activityTypeCode', {
      is: (val) => val === ActivityType.Abms,
      then: Yup.string()
        .nullable(true)
        .test('isWithinSixYears', 'Must be within six years', isDateWithinSixYears)
        .required(DEFAULT_REQUIRED_TEXT),
      otherwise: Yup.string().notRequired().nullable(true),
    }),
  activitySubTypeCode: Yup.string()
    .label('Activity sub type')
    .when('activityTypeCode', {
      is: (val) => val === ActivityType.Abms,
      then: Yup.string().required('Please select an option above').nullable(true),
      otherwise: Yup.string().notRequired().nullable(true),
    }),
};

const acgmeFields = {
  activityStartDate: Yup.string()
    .label('Start date')
    .when('activityTypeCode', {
      is: (val) => val === ActivityType.Acgme || val === ActivityType.ICC,
      then: Yup.string().nullable(true).required(DEFAULT_REQUIRED_TEXT),
      otherwise: Yup.string().notRequired().nullable(true),
    }),
};

const iccFields = {
  conferenceTitle: Yup.string()
    .label('Conference title')
    .when('activityTypeCode', {
      is: (val) => val === ActivityType.ICC,
      then: Yup.string().required(DEFAULT_REQUIRED_TEXT),
      otherwise: Yup.string().notRequired().nullable(),
    }),
  conferenceAccreditor: Yup.string()
    .label('Accreditor')
    .when('activityTypeCode', {
      is: (val) => val === ActivityType.ICC,
      then: Yup.string()
        .oneOf(
          AccreditorOptions.map((option) => option.value),
          'Please select a valid accreditor'
        )
        .required(DEFAULT_REQUIRED_TEXT),
      otherwise: Yup.string(),
    }),
  city: Yup.string()
    .nullable(true)
    .label('City')
    .when('activityTypeCode', {
      is: (val) => val === ActivityType.ICC,
      then: Yup.string().required(DEFAULT_REQUIRED_TEXT),
      otherwise: Yup.string().notRequired().nullable(),
    }),
  country: Yup.string()
    .nullable(true)
    .label('Country')
    .when('activityTypeCode', {
      is: (val) => val === ActivityType.ICC,
      then: Yup.string().required(DEFAULT_REQUIRED_TEXT),
      otherwise: Yup.string().notRequired().nullable(),
    }),
};

const sharedSchema = {
  ...medicalFields,
  ...teachingFields,
  ...posterFields,
  ...abmsFields,
  ...acgmeFields,
  ...iccFields,
  activityTypeCode: Yup.string().label('Activity type').oneOf(Object.values(ActivityType), 'Please select an activity type').required(),
  activityEndDate: Yup.string()
    .label('End Date')
    .nullable(true)
    .when('activityTypeCode', {
      is: (val) =>
        val === ActivityType.Article ||
        val === ActivityType.Poster ||
        val === ActivityType.Acgme ||
        val === ActivityType.ICC ||
        val === ActivityType.Teaching,
      then: Yup.string()
        .nullable(true)
        .test('isWithinSixYears', 'Must be within six years', isDateWithinSixYears)
        .required(DEFAULT_REQUIRED_TEXT),
      otherwise: Yup.string().notRequired().nullable(true),
    }),
  doi: Yup.string()
    .label('DOI')
    .nullable(true)
    .when('activityTypeCode', {
      is: (val) => val === ActivityType.Article,
      then: Yup.string().required(DEFAULT_REQUIRED_TEXT),
      otherwise: Yup.string().notRequired().nullable(),
    }),
  activityTitle: Yup.string()
    .label('Title of Activity')
    .when('activityTypeCode', {
      is: (val) => val === ActivityType.Teaching || val === ActivityType.Article,
      then: Yup.string().required(DEFAULT_REQUIRED_TEXT).nullable(true),
      otherwise: Yup.string().notRequired().nullable(true),
    }),
  program: Yup.string()
    .label('Program')
    .when('activityTypeCode', {
      is: (val) => val === ActivityType.Medical || val === ActivityType.Acgme,
      then: Yup.string().required(DEFAULT_REQUIRED_TEXT),
      otherwise: Yup.string().notRequired().nullable(),
    }),
  activityUnits: Yup.string()
    .when('activityTypeCode', {
      is: (val) => val === ActivityType.Acgme || val === ActivityType.ICC,
      then: Yup.string().nullable(true).required(DEFAULT_REQUIRED_TEXT),
      otherwise: Yup.string().nullable(true).notRequired(),
    })
    .when('activityTypeCode', {
      is: (val) => val === ActivityType.Teaching,
      then: Yup.string()
        .nullable(true)
        .required(DEFAULT_REQUIRED_TEXT)
        .max(6, ' ')
        .matches(matchQuarterValues, { message: ' ', excludeEmptyString: true })
        .matches(matchZeroValues, { message: ' ' }),
      otherwise: Yup.string().notRequired().nullable(),
    }),
};

export const processorSchema = Yup.object().shape({
  creditActivities: Yup.array().of(
    Yup.object().shape({
      ...sharedSchema,
      activityTypeCode: Yup.string().label('Activity type').oneOf(Object.values(ActivityType), 'Please select an activity type').required(),
      status: Yup.string()
        .label('Activity Status')
        .oneOf(
          Object.values(ActivityStatus).filter((value) => value !== ActivityStatus.Pending),
          'Please select a valid activity status'
        )
        .required(),
      creditActivityAttachments: Yup.array()
        .label('Proof Documents')
        .of(
          Yup.object()
            .nullable(true)
            .shape({
              status: Yup.string()
                .nullable(true)
                .oneOf([S3UploadStatus.FAILED, S3UploadStatus.COMPLETE], 'Please wait until all uploads have finished'),
            })
        ),
      firstAuthor: Yup.bool(),
      numberOfCredits: Yup.string()
        .nullable(true)
        .test('isPositiveNumber', 'Please enter a valid number of credits', isPositiveNumber)
        .when('activitySubTypeCode', {
          is: (val) => (AbmsActivitySubType.CONTINUOUS_ASSESSMENT, AbmsActivitySubType.FOCUSED_PRACTICE).includes(val),
          then: Yup.string().required('Please enter an amount of credits'),
          otherwise: Yup.string().notRequired().nullable(),
        }),
    })
  ),
});

export const earnerSchema = Yup.object().shape({
  creditActivities: Yup.array().of(
    Yup.object().shape({
      ...sharedSchema,
      creditActivityAttachments: Yup.array()
        .label('Proof Documents')
        .when('activityTypeCode', {
          is: ceAttachmentsRequired,
          then: Yup.array()
            .of(
              Yup.object()
                .nullable(true)
                .shape({
                  status: Yup.string()
                    .nullable(true)
                    .oneOf([S3UploadStatus.FAILED, S3UploadStatus.COMPLETE], 'Please wait until all uploads have finished'),
                })
            )
            .compact((attachment) => attachment.status === S3UploadStatus.FAILED)
            .min(1, 'You must have at least one successfully loaded supporting document to proceed'),
          otherwise: Yup.array().nullable(true).notRequired(),
        }),
      firstAuthor: Yup.bool()
        .label('First Author')
        .when('activityTypeCode', {
          is: (val) => val === ActivityType.Article || val === ActivityType.Poster,
          then: Yup.bool().oneOf([true], 'You must be the first author in order to add this activity'),
          otherwise: Yup.bool().nullable(true).notRequired(),
        }),
      numberOfCredits: Yup.string()
        .nullable(true)
        .test('isPositiveNumber', 'Please enter a valid number of credits', isPositiveNumber)
        .when('activitySubTypeCode', {
          is: (val) => (AbmsActivitySubType.CONTINUOUS_ASSESSMENT, AbmsActivitySubType.FOCUSED_PRACTICE).includes(val),
          then: Yup.string().required('Please enter an amount of credits'),
          otherwise: Yup.string().notRequired().nullable(),
        }),
    })
  ),
});
