import styled from '../../theme';
import { themeBreakpoint } from '../../theme/helpers';

export const ModalHeader = styled.div`
  margin-bottom: 4rem;
`;

export const ModalBody = styled.div`
  margin-bottom: 0rem;

  @media (min-width: ${themeBreakpoint('large')}) {
    margin-bottom: 5rem;
  }
`;

export const ModalButtonGroup = styled.div`
  @media (max-width: ${themeBreakpoint('small')}) {
    * {
      width: 100%;
    }

    * + * {
      margin-top: 1rem;
    }
  }

  @media (min-width: ${themeBreakpoint('small')}) {
    display: flex;
    justify-content: space-between;
  }

  @media (max-width: ${themeBreakpoint('large')}) {
    padding-top: 4rem;
  }
`;

export const ModalClose = styled.button`
  position: absolute;
  top: 3rem;
  right: 3rem;
  cursor: pointer;
  background: transparent;
  border: none;
`;
