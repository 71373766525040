import { createAction, createReducer } from '@reduxjs/toolkit';
import { desktopPromptModalId } from '../components/DesktopPromptModal';

export const showModal = createAction<string>('@modal/SHOW');
export const hideModal = createAction<string>('@modal/HIDE');

export const initialState: Record<string, boolean> = {
  userInfoForm: false,
  optInForm: false,
  [desktopPromptModalId]: false,
};

export default createReducer(initialState, (builder) =>
  builder
    .addCase(showModal, (state, action) => ({
      ...state,
      [action.payload]: true,
    }))
    .addCase(hideModal, (state, action) => ({
      ...state,
      [action.payload]: false,
    }))
);
