import React from 'react';
import { connect } from 'react-redux';
import { matchPath, RouteComponentProps, withRouter } from 'react-router';
import { Redirect, Route, Switch } from 'react-router-dom';
import NewItemButton, { ItemType } from '../../components/NewItemButton';
import UserApplications from '../../components/UserApplications';
import UserProfile from '../../components/UserProfile';
import UserProfileEdit from '../../components/UserProfileEdit';
import UserTranscript from '../../components/UserTranscript';
import { buildRoute, Routes } from '../../constants/Routes';
import { ReduxStore } from '../../ducks';
import { fetchCreditEarner, resetCreditEarner, ResetCreditEarnerActionCreator } from '../../ducks/creditEarners';
import { creditEarnerDataSelector, creditEarnerErrorSelector, creditEarnerLoadingSelector } from '../../selectors/creditEarner';
import { ExternalCreditEarner } from '../../types';
import DefaultPage from '../DefaultPage';
import LoadingPage from '../LoadingPage';
import UserSubtitle from './UserSubtitle';

interface MatchParams {
  id: string;
}

interface Props extends RouteComponentProps<MatchParams> {
  user: ExternalCreditEarner;
  fetchCreditEarner: any;
  resetCreditEarner: ResetCreditEarnerActionCreator;
  loading: boolean;
  error: any;
}

class SingleUser extends React.Component<Props> {
  public componentDidMount(): void {
    const { match, fetchCreditEarner, user } = this.props;
    const { id } = match.params;

    if (!user.id || (id && user.id && user.id.toString() !== id)) {
      fetchCreditEarner(id);
    }
  }

  public render() {
    const { user, error, loading, location } = this.props;
    const isActive = (path: string) => !!matchPath(location.pathname, { path, exact: true });

    if (loading) {
      return <LoadingPage />;
    }

    if (!user || (error && error.statusCode)) {
      return <Redirect to={{ pathname: '/not-found' }} />;
    }

    return (
      <DefaultPage
        title={loading ? 'Loading' : `${user.firstName} ${user.lastName}${user.degreeType ? `, ${user.degreeType}` : ''}`}
        subtitle={<UserSubtitle user={user} />}
        action={<NewItemButton itemType={ItemType.Application} state={{ selectedUser: user }} />}
        tabs={[
          {
            id: 'profile',
            label: 'Personal Info',
            selected: isActive(Routes.UserShow),
            onClick: this.goToUserProfile,
          },
          {
            id: 'applications',
            label: 'Applications',
            selected: isActive(Routes.UserApplications),
            onClick: this.goToUserApplications,
          },
          {
            id: 'transcript',
            label: 'Transcript',
            selected: isActive(Routes.UserTranscript),
            onClick: this.goToUserTranscript,
          },
        ]}
      >
        <Switch>
          <Route exact={true} path={Routes.UserShow} render={this.renderUserProfile} />
          <Route path={Routes.UserEdit} render={this.renderUserProfileEdit} />
          <Route path={Routes.UserApplications} render={this.renderUserApplications} />
          <Route path={Routes.UserTranscript} render={this.renderUserTranscript} />
        </Switch>
      </DefaultPage>
    );
  }

  private renderUserProfile = () => {
    const { user } = this.props;
    return <UserProfile user={user} />;
  };

  private renderUserProfileEdit = () => {
    const { user } = this.props;
    return <UserProfileEdit user={user} />;
  };

  private renderUserApplications = () => {
    const { user } = this.props;
    return <UserApplications user={user} />;
  };

  private renderUserTranscript = () => {
    const { user } = this.props;
    return <UserTranscript userId={user.id} />;
  };

  private goToUserProfile = () => {
    const { history, user } = this.props;
    history.replace(buildRoute(Routes.UserShow, { id: user.id }));
  };

  private goToUserApplications = () => {
    const { history, user } = this.props;
    history.replace(buildRoute(Routes.UserApplications, { id: user.id }));
  };

  private goToUserTranscript = () => {
    const { history, user } = this.props;
    history.replace(buildRoute(Routes.UserTranscript, { id: user.id }));
  };
}

const mapStateToProps = (state: ReduxStore) => ({
  user: creditEarnerDataSelector(state),
  loading: creditEarnerLoadingSelector(state),
  error: creditEarnerErrorSelector(state),
});

const mapDispatchToProps = {
  fetchCreditEarner,
  resetCreditEarner,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SingleUser));
