import { createSelector } from 'reselect';
import { ReduxStore } from '../ducks';

export const transcripts = (state: ReduxStore) => state.transcript;

export const transcriptPageSizeSelector = createSelector(transcripts, (transcript) => transcript.pageSize);

export const transcriptPageSelector = createSelector(transcripts, (transcript) => transcript.page);

export const transcriptTotalSelector = createSelector(transcripts, (transcript) => transcript.total);

export const transcriptDataSelector = createSelector(transcripts, (transcript) => transcript.activities);

export const transcriptErrorSelector = createSelector(transcripts, (transcript) => transcript.error);

export const transcriptResultsSelector = createSelector(transcripts, (transcript) => transcript.results);

export const nonDeletedLearnerActivitySelector = createSelector(
  transcriptDataSelector,
  transcriptResultsSelector,
  (learnerActivities, learnerActivityIds) =>
    learnerActivityIds
      .filter((id: number) => {
        return !learnerActivities[id].deleted;
      })
      .map((id: number) => {
        return learnerActivities[id];
      })
);
