import React from 'react';
import styled from 'styled-components';
import { ThemeColors } from '../../theme/primaryTheme';
import Text from '../Text';
import { TextStyle } from '../Text/index';

const PraPricingGrid: React.FC = () => {
  const priceBreakdown = {
    'Standard PRA': {
      member: '$50',
      nonMember: '$100',
    },
    'PRA with Commendation': {
      member: '$70',
      nonMember: '$140',
    },
    'Standard PRA for Residents': {
      member: '$0',
      nonMember: '$100',
    },
    'Standard PRA with Qualified Medical Society': {
      member: '$40',
      nonMember: '$90',
    },
  };

  return (
    <TableContainer>
      <StyledTable>
        <StyledTableHead>
          <StyledTableRow>
            <StyledTableHeaderCell>
              <Text textStyle={TextStyle.Small} bold={true} color={ThemeColors.primary}>
                Application Type
              </Text>
            </StyledTableHeaderCell>
            <StyledTableHeaderCell>
              <Text textStyle={TextStyle.Small} bold={true} color={ThemeColors.primary}>
                Member
              </Text>
            </StyledTableHeaderCell>
            <StyledTableHeaderCell>
              <Text textStyle={TextStyle.Small} bold={true} color={ThemeColors.primary}>
                Non-member
              </Text>
            </StyledTableHeaderCell>
          </StyledTableRow>
        </StyledTableHead>
        <tbody>
          {Object.entries(priceBreakdown).map(([praType, prices]) => (
            <StyledTableRow key={praType}>
              <StyledTableCell>
                <Text textStyle={TextStyle.SubTitle} color={ThemeColors.primary}>
                  {praType}
                </Text>
              </StyledTableCell>
              <StyledTableCell>
                <Text textStyle={TextStyle.SubTitle} color={ThemeColors.primary}>
                  {prices.member}
                </Text>
              </StyledTableCell>
              <StyledTableCell>
                <Text textStyle={TextStyle.SubTitle} color={ThemeColors.primary}>
                  {prices.nonMember}
                </Text>
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </tbody>
      </StyledTable>
    </TableContainer>
  );
};

const TableContainer = styled.div`
  width: 80rem;
  display: block;
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: 0.5rem;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  overflow: hidden;
`;

const StyledTable = styled.table`
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
`;

const StyledTableHead = styled.thead`
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};
`;

const StyledTableRow = styled.tr`
  background-color: ${({ theme }) => theme.colors.reverse};

  &:nth-child(even) {
    background-color: ${({ theme }) => theme.colors.backgroundLight};
  }

  ${StyledTableHead} > & {
    background-color: ${({ theme }) => theme.colors.reverse};
  }
`;

const StyledTableHeaderCell = styled.th`
  text-align: left;
  vertical-align: middle;
  padding: 0.8rem 1.6rem;
`;

const StyledTableCell = styled.td`
  color: ${({ theme }) => theme.colors.backgroundDark};
  text-align: left;
  vertical-align: middle;
  padding: 0.8rem 1.6rem;
`;

export default PraPricingGrid;
