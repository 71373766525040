import { Form, Formik, FormikBag, FormikProps, FormikValues } from 'formik';
import React from 'react';
import { connect } from 'react-redux';
import {
  createBulkLearnerActivityUpload,
  CreateBulkLearnerActivityUploadAsync,
  fetchBulkLearnerActivityUploads,
  FetchBulkLearnerActivityUploadsAsync,
  OnError,
  OnSuccess,
} from '../../ducks/bulkLearnerActivityUploads';
import styled, { css } from '../../theme';
import Button from '../Button';
import BulkLearnerActivityUploadField from './BulkLearnerActivityUploadField';

interface Props {
  createBulkLearnerActivityUpload: CreateBulkLearnerActivityUploadAsync;
  fetchBulkLearnerActivityUploads: FetchBulkLearnerActivityUploadsAsync;
}

const initialValues = {
  bulkLearnerActivityUploadFile: null,
};

export interface Values extends FormikValues {
  bulkLearnerActivityUploadFile: File;
}

const BulkLearnerActivityUploadForm: React.FunctionComponent<Props> = ({
  createBulkLearnerActivityUpload,
  fetchBulkLearnerActivityUploads,
}) => {
  const handleSubmit = (values: Values, formikBag: FormikBag<Props, FormikValues>) => {
    const { setSubmitting, setFieldError, resetForm } = formikBag;

    const handleSuccess: OnSuccess = () => {
      resetForm();
      fetchBulkLearnerActivityUploads();
    };

    const handleError: OnError = (err) => {
      setFieldError('bulkLearnerActivityUploadFile', err.message);
      setSubmitting(false);
    };

    const config = {
      onSuccess: handleSuccess,
      onError: handleError,
    };

    createBulkLearnerActivityUpload(values.bulkLearnerActivityUploadFile, config);
  };

  return (
    <Formik enableReinitialize={true} initialValues={initialValues} onSubmit={handleSubmit}>
      {({ values }: FormikProps<Values>) => (
        <Form data-test-id="bulkLearnerActivityUploadForm">
          <UploadFormRow>
            <UploadFormColumn grow={1}>
              <BulkLearnerActivityUploadField name="bulkLearnerActivityUploadFile" placeholder="Select or drop a file" />
            </UploadFormColumn>
            <UploadFormColumn>
              <UploadButton name="submitBulkLearnerActivityUploadButton" type="submit" disabled={!values.bulkLearnerActivityUploadFile}>
                Upload File
              </UploadButton>
            </UploadFormColumn>
          </UploadFormRow>
        </Form>
      )}
    </Formik>
  );
};

const UploadFormRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 5.5rem;
  margin-top: 2rem;
`;

const UploadFormColumn = styled.div<{ grow?: number }>`
  height: inherit;

  & + & {
    margin-left: 5rem;
  }

  ${({ grow }) =>
    grow
      ? css`
          flex-grow: ${grow};
        `
      : ''};
`;

const UploadButton = styled(Button)`
  height: inherit;
`;

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  createBulkLearnerActivityUpload,
  fetchBulkLearnerActivityUploads,
};

export default connect(mapStateToProps, mapDispatchToProps)(BulkLearnerActivityUploadForm);
