/**
 * Converts RGB hex values to HSL
 * @param red
 * @param green
 * @param blue
 */
export const rgbToHsl = (red: string, green: string, blue: string) => {
  const r = parseInt(red, 16) / 255;
  const g = parseInt(green, 16) / 255;
  const b = parseInt(blue, 16) / 255;

  const max = Math.max(r, g, b);
  const min = Math.min(r, g, b);

  const L = (max + min) / 2;
  const d = max - min;

  if (d === 0) {
    return [0, 0, L * 100];
  }

  const S = d / (1 - Math.abs(2 * L - 1));

  let H = 0;
  switch (max) {
    case r:
      H = ((g - b) / d) % 6;
      break;
    case g:
      H = (b - r) / d + 2;
      break;
    case b:
      H = (r - g) / d + 4;
      break;
  }

  return [H * 60, S * 100, L * 100];
};

/**
 * Lightens or darkens a color
 * @param hex
 * @param lum: number from -100 to +100 (percent)
 */
export const lightenOrDarken = (hex: string, lum = 0) => {
  const hsl = rgbToHsl(hex.substr(1, 2), hex.substr(3, 2), hex.substr(5, 2));
  return `hsl(${hsl[0]}, ${hsl[1]}%, ${hsl[2] + lum}%)`;
};
