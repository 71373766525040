import React from 'react';
import { applicationTypeCodeOptions } from '../../constants/ApplicationTypeCodeLabels';
import Field from '../Field';
import { FieldColumn, FieldRow } from '../FieldRow';
import FormSection from '../FormSection';
import OptionGroup from '../OptionGroup';

const ApplicationTypeSection: React.FunctionComponent = () => (
  <FormSection title="Application Type">
    <FieldRow>
      <FieldColumn grow={1}>
        <Field
          type="radio"
          name="applicationTypeCode"
          component={OptionGroup}
          fieldBorder={false}
          controlProps={{ options: applicationTypeCodeOptions }}
        />
      </FieldColumn>
    </FieldRow>
  </FormSection>
);

export default ApplicationTypeSection;
