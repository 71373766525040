import styled from '../../theme';
import { themeBreakpoint, themeColor } from '../../theme/helpers';

export const FieldValue = styled.div`
  height: 54px;
  width: 100%;
  padding: 1.5rem;
  font-size: 1.6rem;
  border-radius: 0.5rem;
  background-color: ${themeColor('reverse')};
  border: 1px solid ${themeColor('border')};

  @media (min-width: ${themeBreakpoint('large')}) {
    max-width: 340px;
    margin-left: auto;
  }
`;
