import React from 'react';
import { Route, RouteProps } from 'react-router';
import { loginUrl } from 'src/utilities/authentication';
import { UserTypes } from '../../constants/UserTypes';
import LoadingPage from '../../layouts/LoadingPage';
import Text, { TextStyle } from '../Text';

const { REACT_APP_PUBLIC_URL } = process.env;

interface AmaRouteProps extends RouteProps {
  isAuthenticated: boolean;
  userType: UserTypes;
  isInitialized: boolean;
}

const getRedirect = (userType: UserTypes, location?: any) => {
  if (location) {
    return `${REACT_APP_PUBLIC_URL}${location.pathname}`;
  }

  if (userType === UserTypes.Processor) {
    return `${REACT_APP_PUBLIC_URL}/applications`;
  }

  return `${REACT_APP_PUBLIC_URL}/preferences`;
};

const AmaRoute: React.FunctionComponent<AmaRouteProps> = ({ isAuthenticated, userType, isInitialized, location, path, ...rest }) => {
  if (isAuthenticated && isInitialized) {
    return <Route path={path} {...location} {...rest} />;
  }

  if (isAuthenticated && !isInitialized) {
    return <LoadingPage />;
  }

  const redirectTo = getRedirect(userType, location);
  window.open(loginUrl(redirectTo), '_self');

  // Show the loading page while opening the SSO window
  return (
    <LoadingPage>
      <Text bold={true} textStyle={TextStyle.Medium} tag="h2">
        Loading SSO
      </Text>
    </LoadingPage>
  );
};

export default AmaRoute;
