import React from 'react';
import Tabs from '../../components/Tabs';
import Text, { TextStyle } from '../../components/Text';
import App from '../App';
import { Page, PageAction, PageActionItem, PageBody, PageHead, PageSubtitle, PageTitle, PageWrap } from '../PageStructure';

interface Props {
  title: string;
  subtitle?: any;
  action?: any;
  tabs?: any[];
  children: React.ReactNode;
}

const DefaultPage: React.FunctionComponent<Props> = ({ title, subtitle, action, tabs, children }) => (
  <App>
    <Page>
      <PageHead>
        <PageWrap>
          <PageTitle>
            <Text tag="h1" textStyle={TextStyle.Headline}>
              {title}
            </Text>
            {action && (
              <PageAction>
                <PageActionItem>{action}</PageActionItem>
              </PageAction>
            )}
          </PageTitle>
          {subtitle && (
            <PageSubtitle>
              <Text tag="p" textStyle={TextStyle.Medium}>
                {subtitle}
              </Text>
            </PageSubtitle>
          )}
        </PageWrap>
      </PageHead>
      {tabs && <Tabs tabItems={tabs} />}
      <PageBody>
        <PageWrap>{children}</PageWrap>
      </PageBody>
    </Page>
  </App>
);

export default DefaultPage;
