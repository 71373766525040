import { FieldProps, Form, Formik, FormikBag } from 'formik';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { CreditClaimStatus } from 'src/constants/ApplicationStatus';
import { LinkStyle } from 'src/constants/LinkStyle';
import * as Yup from 'yup';
import { PraCertificateCode } from '../../constants/PraCertificateCode';
import { PraClaimMethod } from '../../constants/PraClaimMethod';
import { PraClaimType } from '../../constants/PraClaimType';
import { PraDuration } from '../../constants/PraDuration';
import { buildRoute, Routes } from '../../constants/Routes';
import { updateClaim } from '../../ducks/claims';
import ApplicationPage from '../../layouts/ApplicationPage';
import { PageFooter } from '../../layouts/PageStructure';
import { claimDataSelector, claimIsCompletedSelector } from '../../selectors/claims';
import { isCreditProcessor } from '../../selectors/user';
import { CreditClaim } from '../../types';
import Alert, { AlertType } from '../Alert';
import { ApplicationStepLabel } from '../ApplicationProgressBar/ApplicationProgressBar';
import Button from '../Button';
import Debug from '../Debug';
import FetchClaim from '../FetchClaim';
import Link from '../Link';
import ClaimMethodSection from './Sections/ClaimMethodSection';
import ClaimTypeSection from './Sections/ClaimTypeSection';
import CmeCreditSection from './Sections/CmeCreditSection';
import RenewalStatusSection from './Sections/RenewalStatusSection';

interface AwardTypeValues {
  praClaimType: PraClaimType;
  praCertificateCode: PraCertificateCode;
  praIsRenewal: boolean;
}

interface PraAwardProperties {
  praCertificateClaimMethod: PraClaimMethod;
  praCertificateDuration: PraDuration;
  praCertificateIsCommendation: boolean;
}

interface ApiAwardValues extends PraAwardProperties {
  praIsRenewal: boolean;
}

const validationSchema = Yup.object().shape({
  praClaimType: Yup.string().required('Please select how you would like to claim a PRA').oneOf(Object.values(PraClaimType)),
});

const getPraClaimType = ({ praCertificateClaimMethod }: { praCertificateClaimMethod?: string }) => {
  switch (praCertificateClaimMethod) {
    case PraClaimMethod.PreviousCME:
      return PraClaimType.CME;
    case PraClaimMethod.ABMS:
      return PraClaimType.ABMS;
    case PraClaimMethod.Reciprocity:
      return PraClaimType.Reciprocity;
    case PraClaimMethod.Residency:
      return PraClaimType.Residency;
    default:
      return;
  }
};

const getPraCertificateCodeFromClaimType = (claimType: string) => {
  switch (claimType) {
    case PraClaimType.ABMS:
      return PraCertificateCode.PRA_STANDARD_3Y_ABMS;
    case PraClaimType.Reciprocity:
      return PraCertificateCode.PRA_STANDARD_3Y_RECIPROCITY;
    case PraClaimType.Residency:
      return PraCertificateCode.PRA_STANDARD_3Y_RESIDENCY;
    default:
      return;
  }
};

const getPraCertificateCode = (claim: CreditClaim): PraCertificateCode | undefined => {
  const praClaimType = getPraClaimType(claim);
  if (!praClaimType) {
    return;
  }

  if (praClaimType === PraClaimType.CME) {
    switch (claim.praCertificateDuration) {
      case 1:
        return claim.praCertificateIsCommendation ? PraCertificateCode.PRA_COMMENDATION_1Y : PraCertificateCode.PRA_STANDARD_1Y;
      case 2:
        return claim.praCertificateIsCommendation ? PraCertificateCode.PRA_COMMENDATION_2Y : PraCertificateCode.PRA_STANDARD_2Y;
      case 3:
        return claim.praCertificateIsCommendation ? PraCertificateCode.PRA_COMMENDATION_3Y : PraCertificateCode.PRA_STANDARD_3Y;
      default:
        return;
    }
  } else {
    switch (claim.praCertificateClaimMethod) {
      case PraClaimMethod.ABMS:
        return PraCertificateCode.PRA_STANDARD_3Y_ABMS;
      case PraClaimMethod.Reciprocity:
        return PraCertificateCode.PRA_STANDARD_3Y_RECIPROCITY;
      case PraClaimMethod.Residency:
        return PraCertificateCode.PRA_STANDARD_3Y_RESIDENCY;
      default:
        return;
    }
  }
};

const getPraValues = (values: AwardTypeValues): PraAwardProperties => {
  switch (values.praCertificateCode) {
    case PraCertificateCode.PRA_STANDARD_3Y:
      return {
        praCertificateClaimMethod: PraClaimMethod.PreviousCME,
        praCertificateDuration: PraDuration.THREE_YEARS,
        praCertificateIsCommendation: false,
      };
    case PraCertificateCode.PRA_STANDARD_2Y:
      return {
        praCertificateClaimMethod: PraClaimMethod.PreviousCME,
        praCertificateDuration: PraDuration.TWO_YEARS,
        praCertificateIsCommendation: false,
      };
    case PraCertificateCode.PRA_STANDARD_1Y:
      return {
        praCertificateClaimMethod: PraClaimMethod.PreviousCME,
        praCertificateDuration: PraDuration.ONE_YEAR,
        praCertificateIsCommendation: false,
      };
    case PraCertificateCode.PRA_COMMENDATION_3Y:
      return {
        praCertificateClaimMethod: PraClaimMethod.PreviousCME,
        praCertificateDuration: PraDuration.THREE_YEARS,
        praCertificateIsCommendation: true,
      };
    case PraCertificateCode.PRA_COMMENDATION_2Y:
      return {
        praCertificateClaimMethod: PraClaimMethod.PreviousCME,
        praCertificateDuration: PraDuration.TWO_YEARS,
        praCertificateIsCommendation: true,
      };
    case PraCertificateCode.PRA_COMMENDATION_1Y:
      return {
        praCertificateClaimMethod: PraClaimMethod.PreviousCME,
        praCertificateDuration: PraDuration.ONE_YEAR,
        praCertificateIsCommendation: true,
      };
    case PraCertificateCode.PRA_STANDARD_3Y_ABMS:
      return {
        praCertificateClaimMethod: PraClaimMethod.ABMS,
        praCertificateDuration: PraDuration.THREE_YEARS,
        praCertificateIsCommendation: false,
      };
    case PraCertificateCode.PRA_STANDARD_3Y_RESIDENCY:
      return {
        praCertificateClaimMethod: PraClaimMethod.Residency,
        praCertificateDuration: PraDuration.THREE_YEARS,
        praCertificateIsCommendation: false,
      };
    case PraCertificateCode.PRA_STANDARD_3Y_RECIPROCITY:
      return {
        praCertificateClaimMethod: PraClaimMethod.Reciprocity,
        praCertificateDuration: PraDuration.THREE_YEARS,
        praCertificateIsCommendation: false,
      };
  }
};

const PraAwardType: React.FunctionComponent = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const claim = useSelector(claimDataSelector);
  const isProcessor = useSelector(isCreditProcessor);
  const claimIsCompleted = useSelector(claimIsCompletedSelector);

  const [error, setError] = useState('');

  const awardTypeIsEditable = isProcessor
    ? !claimIsCompleted
    : [CreditClaimStatus.PENDING_CUSTOMER_ACTION, CreditClaimStatus.UNSUBMITTED].includes(claim.status);

  const initialValues: AwardTypeValues = {
    praClaimType: getPraClaimType(claim),
    praCertificateCode: getPraCertificateCode(claim),
    praIsRenewal: claim.praIsRenewal || false,
  };

  const onChangeClaimType = (event: React.ChangeEvent<any>, formikProps: FieldProps) => {
    const {
      target: { value },
    } = event;
    const {
      form: { setFieldValue },
    } = formikProps;
    setFieldValue('praCertificateCode', getPraCertificateCodeFromClaimType(value));
  };

  const handleSubmit = (values: AwardTypeValues, formikBag: FormikBag<any, AwardTypeValues>) => {
    const { setSubmitting } = formikBag;
    const praValues = getPraValues(values);
    const body: ApiAwardValues = {
      ...praValues,
      praIsRenewal: values.praIsRenewal,
    };

    dispatch(
      updateClaim(claim.id, body, {
        onSuccess: () => {
          setSubmitting(false);
          history.push(buildRoute(Routes.PraActivities, { id: claim.id, claimMethod: body.praCertificateClaimMethod }));
        },
        onError: (error) => {
          setSubmitting(false);
          setError(error.message);
        },
      })
    );
  };

  return (
    <FetchClaim>
      <ApplicationPage
        title="Application Type"
        applicationTypeCode={claim.applicationTypeCode}
        applicationStep={ApplicationStepLabel.AwardType}
      >
        <Formik onSubmit={handleSubmit} initialValues={initialValues} validationSchema={validationSchema}>
          {({ values, isSubmitting }) => {
            const { praCertificateClaimMethod } = getPraValues(values) ?? {};
            return (
              <Form data-testid="praAwardTypeForm">
                {error && (
                  <Alert data-test-id="formError" type={AlertType.Error}>
                    {error}
                  </Alert>
                )}
                <ClaimTypeSection isEditable={awardTypeIsEditable} onChange={onChangeClaimType} />
                {values.praClaimType === PraClaimType.CME && <CmeCreditSection isEditable={awardTypeIsEditable} />}
                {values.praClaimType === PraClaimType.ABMS && (
                  <ClaimMethodSection method={PraCertificateCode.PRA_STANDARD_3Y_ABMS} isEditable={awardTypeIsEditable} />
                )}
                {values.praClaimType === PraClaimType.Residency && (
                  <ClaimMethodSection method={PraCertificateCode.PRA_STANDARD_3Y_RESIDENCY} isEditable={awardTypeIsEditable} />
                )}
                {values.praClaimType === PraClaimType.Reciprocity && (
                  <ClaimMethodSection method={PraCertificateCode.PRA_STANDARD_3Y_RECIPROCITY} isEditable={awardTypeIsEditable} />
                )}
                <RenewalStatusSection claimIsLocked={!awardTypeIsEditable} />
                <PageFooter>
                  {awardTypeIsEditable ? (
                    <Button type="submit" loading={isSubmitting}>
                      Save and Continue
                    </Button>
                  ) : (
                    <Link
                      to={buildRoute(Routes.PraActivities, { id: claim.id, claimMethod: praCertificateClaimMethod })}
                      linkStyle={LinkStyle.Button}
                    >
                      Continue
                    </Link>
                  )}
                </PageFooter>
                <Debug json={values} />
              </Form>
            );
          }}
        </Formik>
      </ApplicationPage>
    </FetchClaim>
  );
};

export default PraAwardType;
