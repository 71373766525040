import { FieldProps, FormikValues, getIn } from 'formik';
import React from 'react';
import { TextStyle } from 'src/components/Text';
import styled from '../../theme';
import { Board, CreditActivity, CreditClaim } from '../../types';
import Field from '../Field';
import LockedInput from '../Field/Controls/LockedInput';
import Select, { Option } from '../Field/Controls/Select/index';
import DatePickerField from '../Field/DatePickerField';
import { FieldColumn, FieldRow } from '../FieldRow';
import FormSection from '../FormSection';
import Label from '../Label';
import Panel, { PanelBody } from '../Panel';
import ProofDocumentUpload from '../ProofDocumentUpload';
import Text from '../Text';

interface Props {
  activity?: CreditActivity;
  claim: CreditClaim;
  values: FormikValues;
  abmsBoards: Board[];
  activitiesAreEditable: boolean;
}

const ActivityTablePanel = styled(Panel)`
  width: 60vw;
  max-width: 1200px;
  margin-bottom: 45px;
`;

const StyledText = styled(Text)`
  margin-bottom: 2.5rem;
`;

const ABMSActivitySection: React.FunctionComponent<Props> = ({ activity, claim, values, abmsBoards, activitiesAreEditable }) => {
  const previouslySelectedBoard = abmsBoards.find((board) => board.name === getIn(values, 'abmsActivity.board'));
  const [subspecialties, setSubspecialties] = React.useState<string[]>(
    previouslySelectedBoard ? previouslySelectedBoard.subspecialties : []
  );

  const abmsBoardOptions: Option[] = abmsBoards
    .sort((a, b) => (a.name > b.name ? 1 : -1))
    .map(({ name }) => ({
      value: name,
      label: name,
    }));

  const subspecialtyOptions = subspecialties.sort().map((subspecialty: string) => ({
    value: subspecialty,
    label: subspecialty,
  }));

  const handleBoardChange = (event: React.ChangeEvent<any>, formikProps: FieldProps) => {
    const {
      target: { value },
    } = event;
    const {
      field: { name },
      form: { setFieldValue, setFieldTouched },
    } = formikProps;
    const baseName = name.split('.')[0];
    setFieldValue(`${baseName}.board`, value);

    const newlySelectedBoard = abmsBoards.find((board) => board.name === value);
    const availableSubspecialties = newlySelectedBoard ? newlySelectedBoard.subspecialties : [];

    setSubspecialties(availableSubspecialties);
    setFieldValue(`${baseName}.subspeciality`, '');
    setFieldTouched(`${baseName}.subspeciality`, false);
  };

  return activity !== undefined ? (
    <FormSection>
      <ActivityTablePanel heading="ABMS Board Certification or Maintenance of Certification (MOC)">
        <PanelBody>
          <StyledText textStyle={TextStyle.Small}>
            A 3 Year Standard certificate may be claimed for completing the ABMS member board certification, recertification, or Maintenance
            of Certification (MOC).
          </StyledText>
          <FieldRow>
            <FieldColumn grow={1}>
              {abmsBoards.length > 0 && (
                <Field
                  name="abmsActivity.board"
                  required={true}
                  label="Board"
                  placeholder="Board name"
                  component={Select}
                  onChange={handleBoardChange}
                  controlProps={{
                    options: abmsBoardOptions,
                    defaultLabel: 'Select board',
                  }}
                  locked={!activitiesAreEditable}
                />
              )}
            </FieldColumn>
          </FieldRow>
          {subspecialtyOptions.length > 0 ? (
            <FieldRow>
              <FieldColumn grow={1}>
                <Field
                  name="abmsActivity.subspeciality"
                  label="Subspecialty (optional)"
                  placeholder="Subspecialty"
                  component={Select}
                  controlProps={{
                    options: subspecialtyOptions,
                    defaultLabel: 'Select subspecialty',
                  }}
                  locked={!activitiesAreEditable}
                />
              </FieldColumn>
            </FieldRow>
          ) : getIn(values, 'abmsActivity.board') !== '' ? (
            <FieldRow>
              <FieldColumn grow={1}>
                <>
                  <Label>Subspecialty (optional)</Label>
                  <LockedInput value="No available subspecialties for the selected board" />
                </>
              </FieldColumn>
            </FieldRow>
          ) : null}
          <FieldRow>
            <FieldColumn>
              <DatePickerField
                name="abmsActivity.certificationDate"
                label="Certification Date"
                required={true}
                locked={!activitiesAreEditable}
              />
            </FieldColumn>
          </FieldRow>
        </PanelBody>
      </ActivityTablePanel>
      <ActivityTablePanel heading="Upload Proof Document">
        <PanelBody>
          <StyledText textStyle={TextStyle.Small}>
            Attach a copy of the board certificate or the specialty board notification letter.
          </StyledText>
          <FieldRow>
            <FieldColumn grow={1}>
              <ProofDocumentUpload
                name="abmsActivity.creditActivityAttachments"
                claimId={claim.id}
                uploadsDisabled={!activitiesAreEditable}
              />
            </FieldColumn>
          </FieldRow>
        </PanelBody>
      </ActivityTablePanel>
    </FormSection>
  ) : null;
};

export default ABMSActivitySection;
