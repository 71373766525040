import React from 'react';
import Toggle from 'react-toggled';
import { Color } from 'src/theme/primaryTheme';
import { ThemeColors } from '../../theme/primaryTheme';
import { Pra } from '../../types';
import { momentIsBefore, momentUtc } from '../../utilities/moment';
import Button from '../Button';
import Icon from '../Icon';
import PraCertificateDownloadButton from '../PraTable/PraCertificateDownloadButton';
import SummaryValue, { SummaryDisplayType } from '../SummaryValue';
import Text, { TextStyle } from '../Text';
import { CardBody, CardDetails, CardFace, CardFooter, CardHeader, CardInternal, Root, TextWithBackground } from './styles';

const ToggleablePraCard: React.FunctionComponent<{ pra: Pra }> = ({ pra }) => {
  const { id, isCommendation, effectiveDate, expirationDate, duration, isRenewal } = pra;
  const awardType = `PRA ${isCommendation ? 'with Commendation' : 'Standard'}`;
  const formattedEffectiveDate = momentUtc(effectiveDate).format('M/D/YY');
  const formattedExpirationDate = momentUtc(expirationDate).format('M/D/YY');
  const isExpired = momentIsBefore(expirationDate, momentUtc());

  const internalData = [
    { label: 'Award Type', value: awardType },
    { label: 'Duration', value: `${duration} Year` },
    { label: 'Issue Date', value: formattedEffectiveDate },
    { label: 'Expiration Date', value: formattedExpirationDate },
    { label: 'New / Renew', value: isRenewal ? 'Renewed' : 'New' },
    { label: 'Status', value: isExpired ? 'Expired' : 'Active' },
  ];

  return (
    <Toggle>
      {({ on, toggle }) => (
        <Root>
          <CardFace>
            <CardHeader onClick={toggle}>
              <CardDetails>
                <Text bold={true} color={Color.GrayBlue} textStyle={TextStyle.Small}>
                  {formattedEffectiveDate}
                </Text>
                <Button buttonStyle="toggle" onClick={toggle}>
                  <Icon
                    name={on ? 'angleArrowUp' : 'angleArrowDown'}
                    fill={ThemeColors[Color.Accent]}
                    width={16}
                    height={10}
                    viewBox="0 0 16 10"
                  />
                </Button>
              </CardDetails>
            </CardHeader>
            <CardBody onClick={toggle}>
              <Text textStyle={TextStyle.Headline}>{awardType}</Text>
            </CardBody>
            <CardFooter>
              <CardDetails>
                <TextWithBackground bold={true} color={Color.GrayBlue} textStyle={TextStyle.Small}>
                  {`Term: ${formattedEffectiveDate} - ${formattedExpirationDate}`}
                </TextWithBackground>
                <PraCertificateDownloadButton
                  data={pra}
                  value={`/v1/pra/${id}/certificate.pdf`}
                  buttonProps={{
                    buttonText: 'Download PDF',
                    iconWidth: 15,
                  }}
                />
              </CardDetails>
            </CardFooter>
          </CardFace>
          {on && (
            <CardInternal>
              {internalData.map(({ label, value }) => (
                <SummaryValue key={label} bold={true} displayType={SummaryDisplayType.Mobile} label={label} value={value} />
              ))}
            </CardInternal>
          )}
        </Root>
      )}
    </Toggle>
  );
};

export default ToggleablePraCard;
