import React from 'react';
import { TextStyle } from 'src/components/Text';
import styled from '../../theme';
import { CreditActivity, CreditClaim } from '../../types';
import Field from '../Field';
import DatePickerField from '../Field/DatePickerField';
import { FieldColumn, FieldRow } from '../FieldRow';
import FormSection from '../FormSection';
import Panel, { PanelBody } from '../Panel';
import ProofDocumentUpload from '../ProofDocumentUpload';
import Text from '../Text';

interface Props {
  activity?: CreditActivity;
  claim: CreditClaim;
  isProcessor: boolean;
  activitiesAreEditable: boolean;
}

const ActivityTablePanel = styled(Panel)`
  width: 60vw;
  max-width: 1200px;
  margin-bottom: 45px;
`;

const StyledText = styled(Text)`
  margin-bottom: 2.5rem;
`;

const ReciprocityActivitySection: React.FunctionComponent<Props> = ({ activity, claim, isProcessor = false, activitiesAreEditable }) => {
  return activity !== undefined ? (
    <FormSection>
      <ActivityTablePanel heading="Reciprocity Agreement">
        <PanelBody>
          <StyledText textStyle={TextStyle.Small}>
            A 3 Year Standard certificate may be claimed with a specialty society that has a reciprocal agreement with the AMA. You may only
            complete this application type if you have received a notice from your specialty organization stating that you are eligible to
            apply for the AMA PRA.
          </StyledText>
          <FieldRow>
            <FieldColumn grow={1}>
              <Field
                name="reciprocityActivity.provider"
                label="Name of Specialty Organization"
                required={true}
                locked={!activitiesAreEditable}
              />
            </FieldColumn>
          </FieldRow>
          <FieldRow>
            <FieldColumn>
              <DatePickerField
                name="reciprocityActivity.activityEndDate"
                label="Date of Letter/Notification"
                required={true}
                locked={!activitiesAreEditable}
              />
            </FieldColumn>
          </FieldRow>
        </PanelBody>
      </ActivityTablePanel>
      <ActivityTablePanel heading="Upload Proof Document">
        <PanelBody>
          <StyledText textStyle={TextStyle.Small}>
            Attach a copy of the letter or notice you received from your specialty organization stating that you are eligible to apply for
            the AMA PRA.
          </StyledText>
          <FieldRow>
            <FieldColumn grow={1}>
              <ProofDocumentUpload
                name="reciprocityActivity.creditActivityAttachments"
                required={!isProcessor}
                claimId={claim.id}
                uploadsDisabled={!activitiesAreEditable}
              />
            </FieldColumn>
          </FieldRow>
        </PanelBody>
      </ActivityTablePanel>
    </FormSection>
  ) : null;
};

export default ReciprocityActivitySection;
