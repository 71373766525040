import { Form, Formik } from 'formik';
import { isEmpty } from 'lodash';
import React, { useEffect } from 'react';
import { fetchAccessCodeEmail } from 'src/ducks/claimAccount';
import styled from 'styled-components';
import * as Yup from 'yup';
import { ModalType } from '../../constants/ModalType';
import ActionModal from '../ActionModal';
import Button from '../Button';
import ErrorMessage from '../ErrorMessage';
import Field from '../Field';
import LoadingSpinner from '../LoadingSpinner';
import Text, { TextStyle } from '../Text';

const maskedEmail = (email: string) => {
  if (!email) return '';
  return email.substring(0, 6).replace(/./g, '*') + email.substring(6);
};

const emailSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email address').required('Required'),
});

const accessSchema = Yup.object().shape({
  accessCode: Yup.string().required('Required'),
});

interface Props {
  accessCodeEmail: Record<string, never> | { targetEmailAddress: string };
  accessCodeEmailError?: { message: string; statusCode: number };
  accessCodeEmailLoading?: boolean;
  dispatch: any;
  userId: number;
}

const StyledEmailForm = styled(Form)`
  margin-bottom: -30px;
`;

const StyledAccessCodeForm = styled(Form)`
  margin-top: 30px;
  margin-bottom: -30px;
`;

const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const StyledInput = styled.div`
  flex: 2;
`;

const StyledButton = styled(Button)`
  flex: 1;
  margin-left: 60px;
`;

const ClaimCreditsModal: React.FunctionComponent<Props> = ({
  accessCodeEmail,
  accessCodeEmailError,
  accessCodeEmailLoading,
  dispatch,
  userId,
}) => {
  const [showEmailForm, setShowEmailForm] = React.useState(false);

  useEffect(() => {
    dispatch(fetchAccessCodeEmail());
  }, [dispatch]);

  const handleSubmitEmail = () => {
    // TODO: handle submit
  };

  const handleSubmitAccessCode = () => {
    // TODO: handle submit
  };

  const EmailForm = () => (
    <Formik
      initialValues={{
        email: '',
      }}
      validationSchema={emailSchema}
      onSubmit={handleSubmitEmail}
    >
      {(props) => {
        return (
          <StyledEmailForm>
            <StyledWrapper>
              <StyledInput>
                <Field type="email" id="email" name="email" placeholder="Enter Email" showErrorMessage={false} />
              </StyledInput>
              <StyledButton type="submit" disabled={!props.dirty || !props.isValid} data-type="submit">
                Enter Email
              </StyledButton>
            </StyledWrapper>
            {props.touched.email && props.errors.email && <ErrorMessage name="email" />}
          </StyledEmailForm>
        );
      }}
    </Formik>
  );

  const AccessCodeForm = () => (
    <Formik
      initialValues={{
        accessCode: '',
      }}
      validationSchema={accessSchema}
      onSubmit={handleSubmitAccessCode}
    >
      {(props) => {
        return (
          <>
            <Form>
              <StyledWrapper>
                <StyledInput>
                  <Field
                    type="disabled"
                    id="disabled"
                    name="disabled"
                    placeholder={maskedEmail(accessCodeEmail?.targetEmailAddress)}
                    showErrorMessage={false}
                    disabled
                  />
                </StyledInput>
                <StyledButton type="button" data-type="submit" onClick={() => setShowEmailForm(true)}>
                  Enter New Email
                </StyledButton>
              </StyledWrapper>
            </Form>
            <StyledAccessCodeForm>
              <Text textStyle={TextStyle.Default} marginBottom marginTop>
                Email sent! Enter the code from the email to view any missing activities.
              </Text>
              <StyledWrapper>
                <StyledInput>
                  <Field type="text" id="accessCode" name="accessCode" placeholder="Enter Code" showErrorMessage={false} />
                </StyledInput>
                <StyledButton type="submit" disabled={!props.dirty || !props.isValid} data-type="submit">
                  Submit
                </StyledButton>
              </StyledWrapper>
              <div>{props.touched.accessCode && props.errors.accessCode && <ErrorMessage name="accessCode" />}</div>
            </StyledAccessCodeForm>
          </>
        );
      }}
    </Formik>
  );

  const ClaimCreditsModal = () => (
    <>
      <Text textStyle={TextStyle.Default} marginBottom>
        If you're missing activities that you believe should be on your transcript, it's possible these credits are associated with a
        different account.
      </Text>
      <Text textStyle={TextStyle.Default} marginBottom>
        To search for another account, enter an email below that could be associated with missing CME activities.
      </Text>
      <>{showEmailForm || !accessCodeEmail?.targetEmailAddress ? <EmailForm /> : <AccessCodeForm />}</>
    </>
  );

  const ErrorModal = () => (
    <div>
      <Text textStyle={TextStyle.Default} marginBottom>
        Sorry, we ran into an issue. Please try again shortly.
      </Text>
      <Text textStyle={TextStyle.Default} marginBottom>
        If the issue persists, please contact the AMA Unified Service Center at <br />
        800-262-3211, Monday – Friday, 7:00 AM – 6:00 PM CST, or send an email to MOSupport@ama-assn.org.
      </Text>
    </div>
  );

  return (
    <ActionModal
      modalId={`${ModalType.ClaimCredits}-${userId}`}
      confirmLabel="Submit"
      disableConfirm={true}
      showButtons={false}
      onCloseModal={() => setShowEmailForm(false)}
    >
      {accessCodeEmailLoading ? <LoadingSpinner /> : !isEmpty(accessCodeEmailError) ? <ErrorModal /> : <ClaimCreditsModal />}
    </ActionModal>
  );
};

export default ClaimCreditsModal;
