import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Toggle from 'react-toggled';
import { ReduxStore } from 'src/ducks';
import { themeBreakpoint } from 'src/theme/helpers';
import Icon from '../../components/Icon';
import Text, { TextStyle } from '../../components/Text';
import { TextStack } from '../../components/TextStack';
import { fetchUser } from '../../ducks/user';
import styled from '../../theme';
import App from '../App';
import OptInPreferencesForm from '../Forms/OptIn';
import UserInfoForm from '../Forms/UserInfo';
import { LearnerPage, PageTitleIcon, PageWrap } from '../PageStructure';
import { FormPanel, HeaderToggle } from './styles';

const Preferences: React.FunctionComponent = () => {
  const { isAuthenticated } = useSelector((state: ReduxStore) => state.auth);
  const { userId } = useSelector((state: ReduxStore) => state.user.data);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isAuthenticated && !userId) {
      dispatch(fetchUser());
    }
  }, [isAuthenticated, userId, dispatch]);

  return (
    <App>
      <LearnerPage>
        <Section>
          <PageWrap>
            <TextStack>
              <Text textStyle={TextStyle.Headline} tag="h1">
                Automated Credit Transmission Preferences
              </Text>
              <Text textStyle={TextStyle.SubHeadline} tag="h2">
                Please indicate your MOC or CME licensure and certification credit transmission preferences below.
              </Text>
            </TextStack>
          </PageWrap>
        </Section>
        <Toggle>
          {({ on, toggle }) => (
            <PageWrap>
              <HeaderToggle>
                <PageTitleIcon buttonStyle="toggle" onClick={toggle}>
                  <Icon name={on ? 'angleArrowDown' : 'angleArrowUp'} width={16} height={10} viewBox="0 0 16 10" />
                </PageTitleIcon>
                <Text textStyle={TextStyle.SectionTitle} tag="h4">
                  User Information
                </Text>
              </HeaderToggle>
              <FormPanel hidden={on}>
                <UserInfoForm />
              </FormPanel>
            </PageWrap>
          )}
        </Toggle>
        <Toggle>
          {({ on, toggle }) => (
            <PageWrap>
              <HeaderToggle>
                <PageTitleIcon buttonStyle="toggle" onClick={toggle}>
                  <Icon name={on ? 'angleArrowDown' : 'angleArrowUp'} width={16} height={10} viewBox="0 0 16 10" />
                </PageTitleIcon>
                <Text textStyle={TextStyle.SectionTitle} tag="h4">
                  MOC/CME Automated Reporting Preferences
                </Text>
              </HeaderToggle>
              <FormPanel hidden={on}>
                <OptInPreferencesForm />
              </FormPanel>
            </PageWrap>
          )}
        </Toggle>
      </LearnerPage>
    </App>
  );
};

const Section = styled.div`
  margin-bottom: 4rem;

  @media (max-width: ${themeBreakpoint('medium')}) {
    margin-bottom: 2.4rem;
  }
`;

export default Preferences;
