import { AxiosResponse } from 'axios';
import { Dispatch } from 'redux';
import { CreditClaimStatus } from '../constants/ApplicationStatus';
import { FormId } from '../constants/FormId';
import { ExternalCreditEarner, User } from '../types';
import { apiRequest } from '../utilities/api';
import { removeAccessToken } from '../utilities/authentication';
import { getOptInInitialValues, getUserInitialValues } from '../utilities/initialValues';
import { logout } from './authentication';
import { setClaimStatusFilters } from './claims';
import { setInitialValues } from './initialValues';
import { UserStore } from './types';

export const FETCH_USER_REQUEST = '@USER/REQUEST';
export const FETCH_USER_SUCCESS = '@USER/SUCCESS';
export const FETCH_USER_ERROR = '@USER/ERROR';

interface ActionFetchUserRequest {
  type: typeof FETCH_USER_REQUEST;
}

interface ActionFetchUserSuccess {
  type: typeof FETCH_USER_SUCCESS;
  data: User;
}

interface ActionFetchUserError {
  type: typeof FETCH_USER_ERROR;
  error: any;
}

export type UserAction = ActionFetchUserRequest | ActionFetchUserSuccess | ActionFetchUserError;

type FetchUserRequestActionCreator = () => ActionFetchUserRequest;
export const fetchUserRequest: FetchUserRequestActionCreator = () => {
  return {
    type: FETCH_USER_REQUEST,
  };
};

type FetchUserSuccessActionCreator = (data: User) => ActionFetchUserSuccess;
export const fetchUserSuccess: FetchUserSuccessActionCreator = (data) => {
  return {
    type: FETCH_USER_SUCCESS,
    data,
  };
};

type FetchUserErrorActionCreator = (error: any) => ActionFetchUserError;
export const fetchUserError: FetchUserErrorActionCreator = (error) => {
  return {
    type: FETCH_USER_ERROR,
    error,
  };
};

export const fetchUser =
  () =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch(fetchUserRequest());

    try {
      const { data: user } = (await apiRequest.get('/v1/user?view=external')) as AxiosResponse<User>;

      if (user.isCreditProcessor) {
        dispatch(setClaimStatusFilters([CreditClaimStatus.SUBMITTED, CreditClaimStatus.PROCESSING, CreditClaimStatus.PAYMENT_FAILED]));
      }

      dispatch(fetchUserSuccess(user));
      dispatch(setInitialValues(FormId.UserInfo, getUserInitialValues(user)));
      dispatch(setInitialValues(FormId.OptIn, getOptInInitialValues(user)));
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          dispatch(fetchUserError(error.response.data));
          dispatch(logout());
          removeAccessToken();
        } else {
          dispatch(
            fetchUserError({
              message: `${error.response.status} status returned`,
            })
          );
        }
      } else if (error.request) {
        dispatch(
          fetchUserError({
            message: 'No response returned',
          })
        );
      } else {
        dispatch(fetchUserError(error));
      }
    }
  };

export const initialState: UserStore = {
  data: {} as User,
  initialized: false,
  isLoading: false,
  hasError: false,
  error: {},
};

const user = (state = initialState, action: UserAction): UserStore => {
  switch (action.type) {
    case FETCH_USER_SUCCESS:
      return {
        ...state,
        data: action.data as ExternalCreditEarner,
        isLoading: false,
        initialized: true,
      };

    case FETCH_USER_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
        error: {},
      };
    case FETCH_USER_ERROR:
      return {
        ...state,
        isLoading: false,
        hasError: true,
        error: action.error,
        initialized: true,
      };

    default:
      return state;
  }
};

export default user;
