import { AxiosResponse } from 'axios';
import _ from 'lodash';
import { Dispatch } from 'redux';
import { Board } from '../types';
import { apiRequest } from '../utilities/api';
import { BoardDataStore } from './types';

export const FETCH_BOARD_DATA_REQUEST = '@boardData/REQUEST';
export const FETCH_BOARD_DATA_SUCCESS = '@boardData/SUCCESS';
export const FETCH_BOARD_DATA_ERROR = '@boardData/ERROR';

interface ActionFetchBoardDataRequest {
  type: typeof FETCH_BOARD_DATA_REQUEST;
  data?: any;
}

interface ActionFetchBoardDataSuccess {
  type: typeof FETCH_BOARD_DATA_SUCCESS;
  data: any; // TODO: BoardData type
}

interface ActionFetchBoardDataError {
  type: typeof FETCH_BOARD_DATA_ERROR;
  error: any; // TODO: Error type
}

type ActionBoardData = ActionFetchBoardDataRequest | ActionFetchBoardDataSuccess | ActionFetchBoardDataError;

export interface ResponseError {
  message: string;
  statusCode?: number;
}

type FetchBoardDataRequestActionCreator = (data?: any) => ActionFetchBoardDataRequest;
const fetchBoardDataRequest: FetchBoardDataRequestActionCreator = (data) => {
  return {
    type: FETCH_BOARD_DATA_REQUEST,
    data,
  };
};

type FetchBoardDataSuccessActionCreator = (data: any) => ActionFetchBoardDataSuccess;
export const fetchBoardDataSuccess: FetchBoardDataSuccessActionCreator = (data) => {
  return {
    type: FETCH_BOARD_DATA_SUCCESS,
    data,
  };
};

type FetchBoardDataErrorActionCreator = (error: ResponseError) => ActionFetchBoardDataError;
const fetchBoardDataError: FetchBoardDataErrorActionCreator = (error) => {
  return {
    type: FETCH_BOARD_DATA_ERROR,
    error,
  };
};

export const getPreferredUniqueId = (board: Board) => {
  const { preferredUniqueIds } = board;

  if (preferredUniqueIds && Array.isArray(preferredUniqueIds)) {
    return preferredUniqueIds[0];
  }

  return null;
};

export const formatBoardData = (data: Board[]): Board[] => {
  if (data) {
    return data.map((board) => {
      const isStateBoard = _.get(board, 'metadata.isStateBoard') === true;
      const preferredUniqueId = getPreferredUniqueId(board);

      return {
        isStateBoard,
        placeholder: isStateBoard ? 'License #' : 'Diplomate ID',
        preferredUniqueId,
        ...board,
      };
    });
  }

  return [];
};

export type FetchBoardsAsync = () => (dispatch: Dispatch) => void;
export const fetchBoardData: FetchBoardsAsync = () => async (dispatch: Dispatch) => {
  dispatch(fetchBoardDataRequest());

  try {
    const { data } = (await apiRequest.get('/v1/organizations')) as AxiosResponse<Board[]>;
    dispatch(fetchBoardDataSuccess(formatBoardData(data)));
  } catch (error) {
    dispatch(fetchBoardDataError(error));
  }
};

export const initialState = {
  data: [],
  isLoading: false,
  hasError: false,
  error: {},
};

const boardData = (state: BoardDataStore = initialState, action: ActionBoardData): BoardDataStore => {
  switch (action.type) {
    case FETCH_BOARD_DATA_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case FETCH_BOARD_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        hasError: false,
        data: action.data,
      };
    case FETCH_BOARD_DATA_ERROR:
      return {
        ...state,
        isLoading: false,
        hasError: true,
        error: action.error,
      };

    default:
      return state;
  }
};

export default boardData;
