import { FormikProps, FormikValues } from 'formik';
import moment, { Moment } from 'moment';
import React, { createRef, useEffect, useState } from 'react';
import { SingleDatePicker } from 'react-dates';
import { momentUtc } from 'src/utilities/moment';
import styled, { css } from '../../../../theme';

const Root = styled.div<{ hasDate: boolean }>`
  position: relative;

  ${({ hasDate }) =>
    hasDate
      ? css`
          &::after {
            content: '▾';
            position: absolute;
            right: 1.5rem;
            top: 50%;
            transform: translateY(-50%);
            pointer-events: none;
          }
        `
      : ''}

  .SingleDatePickerInput__showClearDate {
    padding-right: 0;
  }
`;
interface Props extends FormikProps<FormikValues> {
  name: string;
  placeholder?: string;
  hasError: boolean;
  date: Moment | null;
  isOutsideRange?: (day: any) => boolean;
}

const defaultIsOutsideRange = (day: any) => {
  const today = momentUtc();
  return today.diff(day) < 0;
};

const DatePicker: React.FunctionComponent<Props> = ({
  name,
  setFieldValue,
  setFieldTouched,
  placeholder = 'MM/DD/YYYY',
  date = null,
  isOutsideRange = defaultIsOutsideRange,
}) => {
  const [focused, setFocused] = useState(false);

  const datePickerRef: React.RefObject<HTMLDivElement> = createRef();

  const removeCalendarButtonsFromTabOrder = (): void => {
    const datePicker = datePickerRef.current;
    const datePickerButtons = Array.from(datePicker.querySelectorAll('button'));
    const arrowButtons = Array.from(datePicker.querySelectorAll('div[tabindex]'));

    [...datePickerButtons, ...arrowButtons].forEach((el: HTMLDivElement | HTMLButtonElement) => (el.tabIndex = -1));
  };

  useEffect(
    () => {
      if (focused) {
        removeCalendarButtonsFromTabOrder();
      }
    },
    [focused] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const handleDateChange = (date: Moment | null) => {
    if (moment.isMoment(date)) {
      setFieldValue(name, date.utc());
    } else {
      setFieldValue(name, date);
    }
    setFieldTouched(name, true);
  };

  const handleFocusChange = ({ focused }: { focused: boolean }) => {
    setFocused(focused);
  };

  return (
    <Root id="amaDatePicker" ref={datePickerRef} hasDate={date === null}>
      <SingleDatePicker
        date={date}
        focused={focused}
        id={name}
        onDateChange={handleDateChange}
        onFocusChange={handleFocusChange}
        placeholder={placeholder}
        hideKeyboardShortcutsPanel={true}
        numberOfMonths={1}
        displayFormat="MM/DD/YYYY"
        noBorder={true}
        regular={true}
        isOutsideRange={isOutsideRange}
        block={true}
        showClearDate={true}
      />
    </Root>
  );
};

export default DatePicker;
