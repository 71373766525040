import _ from 'lodash';

type AccessorFunction = (data: any) => string | number | React.ReactElement | Record<string, any>;
type getCellValue = (accessor: string | AccessorFunction, data: any) => string | number | Record<string, any>;

export const getCellValue: getCellValue = (accessor, data) => {
  if (typeof accessor === 'string') {
    return _.get(data, accessor);
  }

  if (typeof accessor === 'function') {
    return accessor(data);
  }

  console.warn('Cell accessor must be a function or a string');
  return '';
};
