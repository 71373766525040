import { FormikHelpers } from 'formik';
import React from 'react';
import { Color } from 'src/theme/primaryTheme';
import { CreditActivity, CreditClaim } from 'src/types';
import styled from '../../theme';
import Alert, { AlertType } from '../Alert';
import Field from '../Field';
import { FieldColumn, FieldRow } from '../FieldRow';
import Panel from '../Panel';
import ProofDocumentUpload from '../ProofDocumentUpload';
import Text, { TextStyle } from '../Text';
import ToggleablePageSection from '../ToggleablePageSection';
import Category1ActivitiesSection from './Category1ActivitiesSection';
import Category2ActivitiesSection from './Category2ActivitiesSection';
import { CreditRequirements } from './index';

interface Props {
  claim: CreditClaim;
  creditRequirements: CreditRequirements;
  totalTranscriptActivityCredits: number;
  activitiesAreEditable: boolean;
  category1Activities: CreditActivity[];
  category2Activities: CreditActivity[];
  setFieldValue: FormikHelpers<any>['setFieldValue'];
  category1HasErrors?: boolean;
  category2HasErrors?: boolean;
}

const AdditionalActivitiesSection: React.FunctionComponent<Props> = ({
  claim,
  creditRequirements,
  totalTranscriptActivityCredits,
  activitiesAreEditable,
  category1Activities,
  category2Activities,
  setFieldValue,
  category1HasErrors = false,
  category2HasErrors = false,
}) => {
  const additionalCreditsAreRequired = totalTranscriptActivityCredits < creditRequirements.requiredOverallCredits;

  const renderPanelHeading = () => (
    <Text bold={true}>
      Add{' '}
      <Text tag="em" bold={true}>
        AMA PRA Category 1 Credits&trade;
      </Text>{' '}
      {additionalCreditsAreRequired && (
        <Text tag="span" bold={false}>
          (Required)
        </Text>
      )}
    </Text>
  );

  return (
    <ToggleablePageSection title="Add additional activities to your PRA application">
      <Panel heading={renderPanelHeading()}>
        {category1HasErrors && (
          <Alert data-test-id="praActivityDetailsError" type={AlertType.TableError}>
            Please correct the fields highlighted below
          </Alert>
        )}
        <FieldRow>
          <AdditionalCreditsFieldColumn>
            <Text tag="h2" bold={true} textStyle={TextStyle.Medium} color={Color.Secondary}>
              Fill in additional{' '}
              <Text tag="em" bold={true}>
                AMA PRA Category 1 Credits&trade;
              </Text>
            </Text>
            <Text textStyle={TextStyle.Small}>
              Number of <Text tag="em">AMA PRA Category 1 Credits&trade;</Text>
            </Text>
            <AdditionalCreditsField
              name="additionalPraCat1Credits"
              type="number"
              label=""
              placeholder="Ex: 20.00"
              locked={!activitiesAreEditable}
            />
          </AdditionalCreditsFieldColumn>
        </FieldRow>
        <UploadSection>
          <Text tag="h2" bold={true} textStyle={TextStyle.Medium} color={Color.Secondary}>
            Upload document(s)*
          </Text>
          <Text textStyle={TextStyle.Small}>
            Add certificates or transcript records to support the additional <Text tag="em">AMA PRA Category 1 Credits&trade;</Text> you are
            applying to your PRA application.
          </Text>
          <ProofDocumentUpload
            claimId={claim.id}
            uploadsDisabled={!activitiesAreEditable}
            name="creditActivityAttachments"
            label=""
            buttonText="Choose File(s)"
            dropZoneText="Drag and drop files or click to select"
          />
        </UploadSection>
      </Panel>
      {category1Activities?.length > 0 && (
        <Category1ActivitiesSection
          activities={category1Activities}
          activitiesAreEditable={activitiesAreEditable}
          setFieldValue={setFieldValue}
        />
      )}
      <Category2ActivitiesSection
        activitiesAreEditable={activitiesAreEditable}
        activities={category2Activities}
        hasErrors={category2HasErrors}
        setFieldValue={setFieldValue}
      />
    </ToggleablePageSection>
  );
};

const AdditionalCreditsFieldColumn = styled(FieldColumn)`
  padding: 2rem;
`;

const AdditionalCreditsField = styled(Field)`
  margin-top: 1rem;
  flex-direction: row-reverse;
  width: 15rem;
`;

const UploadSection = styled.div`
  padding: 2rem;

  ul {
    margin-top: 1rem;
  }
`;

export default AdditionalActivitiesSection;
