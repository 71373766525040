import { getIn } from 'formik';
import moment from 'moment';
import React from 'react';
import { LEGACY_TEACHING_YEAR_LIMIT } from 'src/constants/General';
import { activityContent } from 'src/content/activities';
import { ActivityType } from '../../constants/ActivityType';
import { Credits } from '../../constants/Credits';
import { Color } from '../../theme/primaryTheme';
import { matchQuarterValues } from '../../utilities/regex';
import Field from '../Field';
import DatePickerField from '../Field/DatePickerField';
import { FieldColumn, FieldRow } from '../FieldRow';
import ProofDocumentUpload from '../ProofDocumentUpload';
import Text, { TextStyle } from '../Text';
import { GenericActivityProps } from './index';
import ProcessorFields from './ProcessorFields';
import Requirements from './Requirements';
import { TotalCreditsRow } from './styles';

const TeachingFields: React.FunctionComponent<GenericActivityProps> = ({ baseName, values, isProcessor, claimId }) => {
  const activityUnits = getIn(values, `${baseName}.activityUnits`);
  const creditsRequested = getIn(values, `${baseName}.creditsRequested`);

  const { description, footer, fields } = activityContent[ActivityType.Teaching];

  const validateActivityUnitsIncrement = () =>
    activityUnits && !Number.isNaN(Number(activityUnits)) && !matchQuarterValues.test(activityUnits) ? Color.Error : Color.Label;

  const validateCreditsRequestedIncrement = () =>
    creditsRequested && !Number.isNaN(Number(creditsRequested)) && !matchQuarterValues.test(creditsRequested) ? Color.Error : Color.Label;

  const validateCreditsRequestedMax = () =>
    creditsRequested && Number(activityUnits) * 4 < Number(creditsRequested) ? Color.Error : Color.Label;

  const teachingYear = () => {
    const activityEndDate = moment(getIn(values, `${baseName}.activityEndDate`));
    return activityEndDate.isValid() ? activityEndDate.year() : null;
  };

  return (
    <>
      <Requirements content={description} showAll={true} />
      <FieldRow>
        <FieldColumn grow={1}>
          <Field name={`${baseName}.activityTitle`} required={true} {...fields.activityTitle} />
        </FieldColumn>
      </FieldRow>
      <FieldRow>
        <FieldColumn>
          <DatePickerField name={`${baseName}.activityEndDate`} required={true} {...fields.activityEndDate} />
        </FieldColumn>
        {typeof teachingYear() === 'number' && (
          <FieldColumn width="25%">
            <Field name={`${baseName}.activityUnits`} type="number" required={true} {...fields.activityUnits} />
            {fields.activityUnits && (
              <Text color={validateActivityUnitsIncrement()} textStyle={TextStyle.Small}>
                {fields.activityUnits.helpText}
              </Text>
            )}
          </FieldColumn>
        )}
        {typeof teachingYear() === 'number' && teachingYear() > LEGACY_TEACHING_YEAR_LIMIT && (
          <FieldColumn>
            <Field name={`${baseName}.creditsRequested`} type="number" required={true} {...fields.creditsRequested} />
            {fields.creditsRequested && (
              <>
                <Text color={validateCreditsRequestedIncrement()} textStyle={TextStyle.Small}>
                  {fields.creditsRequested.helpText.increment}
                </Text>
                <Text color={validateCreditsRequestedMax()} textStyle={TextStyle.Small}>
                  {fields.creditsRequested.helpText.max}
                </Text>
              </>
            )}
          </FieldColumn>
        )}
      </FieldRow>
      <FieldRow>
        <FieldColumn grow={1}>
          <ProofDocumentUpload
            name={`${baseName}.creditActivityAttachments`}
            required={!isProcessor}
            claimId={claimId}
            {...fields.creditActivityAttachments}
          />
        </FieldColumn>
      </FieldRow>
      <TotalCreditsRow>
        <FieldColumn>
          <Text tag="span" textStyle={TextStyle.Medium} bold={true} color={Color.Label}>
            {footer.total}
          </Text>
          &ensp;
          <Text tag="span" textStyle={TextStyle.Medium} color={Color.Label} data-test-id="totalCredits">
            {typeof teachingYear() === 'number' &&
              teachingYear() > LEGACY_TEACHING_YEAR_LIMIT &&
              (creditsRequested ? Number(creditsRequested) : '—')}
            {typeof teachingYear() === 'number' &&
              teachingYear() <= LEGACY_TEACHING_YEAR_LIMIT &&
              (activityUnits ? Number(activityUnits) * Credits[ActivityType.Teaching] : '—')}
          </Text>
          <Text tag="div" textStyle={TextStyle.Small}>
            <p dangerouslySetInnerHTML={{ __html: footer.disclaimer }} />
          </Text>
        </FieldColumn>
      </TotalCreditsRow>

      {isProcessor && <ProcessorFields baseName={baseName} values={values} />}
    </>
  );
};

export default TeachingFields;
