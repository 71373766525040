import React from 'react';
import Toggle from 'react-toggled';
import { ButtonClickEvent } from 'src/types';
import { Color, ThemeColors } from '../../theme/primaryTheme';
import { ColorKeys } from '../../theme/theme';
import Icon from '../Icon';
import ActionMenuPanel from './ActionMenuPanel';
import { MenuButtonStyles, MenuItemStyles, Root } from './styles';

interface ItemProps {
  onClick?: (e: ButtonClickEvent) => void;
  className?: string;
  color?: ColorKeys;
  context?: string;
}

export const ActionMenuItem: React.FunctionComponent<ItemProps> = ({ onClick, context, children }) => (
  <MenuItemStyles buttonStyle="text" type="button" onClick={onClick} full={true} context={context} data-test-id="actionMenuItem">
    {children}
  </MenuItemStyles>
);

// TODO: Add "menu mode" with a light and dark option.
interface MenuProps {
  children: React.ReactNode;
}

const ActionMenu: React.FunctionComponent<MenuProps> = ({ children }) => (
  <Toggle>
    {({ getTogglerProps, on, toggle, setOff }) => (
      <Root data-test-id="actionMenu">
        <MenuButtonStyles onClick={toggle} isOpen={on} {...getTogglerProps()} data-test-id="actionMenuButton">
          <Icon viewBox="0 0 24 24" width={24} height={24} fill={ThemeColors[on ? Color.Accent : Color.Secondary]} name="dotsCircle" />
        </MenuButtonStyles>
        <ActionMenuPanel onClick={toggle} onClickOutside={setOff} isOpen={on}>
          {children}
        </ActionMenuPanel>
      </Root>
    )}
  </Toggle>
);

export default ActionMenu;
