import styled, { css } from 'styled-components';
import { themeColor } from '../../../theme/helpers';
import { Color } from '../../../theme/primaryTheme';

const baseFieldStyles = css`
  appearance: none;
  height: 54px;
  width: 100%;
  border: 0;
  padding: 1em;
  font-size: 1.6rem;
  border-radius: 0.5rem;
  background-color: transparent;
  color: ${({ hasError }: any) => (hasError ? themeColor(Color.Error) : 'inherit')};
`;

const smallStyles = css`
  height: 36px;
  padding: 0.5em;
`;

export const StyledInput = styled.input<{ variant?: 'small' | 'default' }>`
  ${baseFieldStyles}
  ${({ variant }) => variant === 'small' && smallStyles};
`;

export const SelectWrap = styled.div`
  position: relative;

  &::after {
    content: '▾';
    position: absolute;
    right: 1.5rem;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
  }
`;

export const StyledSelect = styled.select<{ variant?: 'small' | 'default' }>`
  ${baseFieldStyles};
  ${({ variant }) => variant === 'small' && smallStyles};
  padding-right: 4.5rem; // make room for the down arrow
`;
