import Button from '../../components/Button';
import styled from '../../theme';
import { themeBreakpoint, themeColor, themeSize } from '../../theme/helpers';
import { Color } from '../../theme/primaryTheme';

export const Page = styled.div`
  background-color: ${themeColor(Color.BackgroundLight)};
  display: flex;
  flex-direction: column;
  width: 100%;
`;

/**
 * This component should be responsible for maintaining consistent padding
 * or "gutter" throughout the app
 */
export const PageWrap = styled.div`
  width: 100%;
  padding: 0 ${themeSize('desktopGutter')};
  margin: 0 auto;

  @media (max-width: ${themeBreakpoint('medium')}) {
    padding: 0 ${themeSize('mobileGutter')};
  }
`;

export const PageHead = styled.div`
  padding: 6rem 0 3.6rem;
  background-color: ${themeColor(Color.Reverse)};

  @media (max-width: ${themeBreakpoint('medium')}) {
    padding: 2.4rem 0;
  }
`;

export const PageEyebrow = styled.div`
  margin-bottom: 2rem;
`;

export const PageTitle = styled.div`
  display: flex;
  align-items: center;
`;

export const PageTitleIcon = styled(Button)`
  padding: 0.75em 1em;

  &:hover {
    background-color: ${themeColor(Color.BackgroundLight)};
  }

  &:first-child {
    margin-right: 1em;

    @media (max-width: ${themeBreakpoint('medium')}) {
      margin-right: 0;
    }
  }

  &:last-child {
    margin-right: 0;
  }
`;

export const PageSubtitle = styled.div`
  margin-top: 1.5rem;

  // I don't really want this here, but this is the first and potentially
  // only time we have this situation
  a {
    color: ${themeColor(Color.Accent)};

    &:hover {
      color: ${themeColor(Color.Secondary)};
    }
  }

  & > * + * {
    margin-top: 1rem;
  }
`;

export const PageAction = styled.div`
  display: flex;
  margin-left: auto;
`;

export const PageActionItem = styled.div`
  & + & {
    margin-left: 1rem;
  }
`;

export const PageBody = styled.div`
  padding: 6rem 0 12rem;

  @media (max-width: ${themeBreakpoint('medium')}) {
    padding: 3.5rem 0 7rem;
  }
`;

export const PageSection = styled.section<{ hasTopMargin?: boolean }>`
  margin-top: ${({ hasTopMargin }) => (hasTopMargin ? '3.25rem' : '0')};

  & + & {
    margin-top: 6rem;
  }
`;

export const PageSectionTitle = styled.div`
  padding-bottom: 2rem;
  margin-bottom: 3.25rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  line-height: 1;
  border-bottom: 1.5px solid ${themeColor(Color.Secondary)};

  ${PageTitleIcon} {
    padding: 0.25em 0.5em;
    margin: 0 0 0 auto;
  }

  @media (max-width: ${themeBreakpoint('medium')}) {
    flex-direction: column;
    align-items: flex-start;
    border-bottom: none;
    margin-bottom: 0;
  }
`;

export const PageSectionDescription = styled.div`
  margin-bottom: 3rem;
`;

export const PageFooter = styled.div`
  margin-top: 4.8rem;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`;

export const LearnerPage = styled.div`
  padding-top: 6rem;
  padding-bottom: 12rem;
  background-color: ${themeColor(Color.Reverse)};
  width: 100%;

  @media (max-width: ${themeBreakpoint('small')}) {
    padding-top: 2.5rem;
  }
`;
