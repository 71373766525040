import moment, { Moment, MomentFormatSpecification, MomentInput } from 'moment';

type MomentFunction = (input?: MomentInput, format?: MomentFormatSpecification, strict?: boolean) => Moment;

/**
 * Generates a UTC representation of a given Moment object or the current date/time if no input is passed in
 */
export const momentUtc: MomentFunction = (input = moment(), ...args) => {
  return moment.utc(input, ...args);
};

export const momentIsBefore = (input1: MomentInput, input2: MomentInput): boolean => {
  return momentUtc(input1).isBefore(momentUtc(input2));
};

type FormattedDate = (input: MomentInput, format?: MomentFormatSpecification, strict?: boolean, outputFormat?: string) => string;
export const formattedDate: FormattedDate = (...args) => {
  const [input, format, strict, outputFormat = 'MM/DD/YYYY'] = args;
  return moment.utc(input, format, strict).format(outputFormat);
};
