import { AxiosResponse } from 'axios';
import { Form, Formik, FormikBag, FormikProps, FormikValues } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { updateClaim, UpdateClaimAsync } from '../../ducks/claims';
import { Color, ThemeColors } from '../../theme/primaryTheme';
import { apiRequest } from '../../utilities/api';
import Alert, { AlertType } from '../Alert';
import Field from '../Field';
import Icon from '../Icon';
import { NotesActions, NotesBody, NotesButton, NotesHeader, NotesLabel, NotesText, NotesTextArea, Root } from './styles';

const validationSchema = Yup.object().shape({
  notes: Yup.string(),
});

interface Values extends FormikValues {
  notes: string;
}

interface Props {
  claimId?: number;
  claimNotes?: string;
  updateClaim: UpdateClaimAsync;
  notesStyle?: string;
}

const ApplicationNotes: React.FunctionComponent<Props> = ({ claimId, claimNotes = '', updateClaim, notesStyle }) => {
  const placeholder = 'Click here and start typing to enter notes about this application...';

  const notesRef = useRef<HTMLDivElement>(null);
  const formRef = useRef<any>(null);

  const [editMode, setEditMode] = useState(false);
  const [notes, setNotes] = useState(claimNotes);

  useEffect(() => {
    apiRequest.get<{ notes: string }>(`/v1/creditclaims/${claimId}`).then(({ data }: AxiosResponse) => {
      setNotes(data.notes);
    });
  }, [claimId]);

  const focusTextarea = () => {
    if (notesRef && notesRef.current) {
      notesRef.current.focus();
    }
  };

  const toggleEditMode = () => {
    setEditMode((prevMode) => !prevMode);
    focusTextarea();
  };

  const handleEditClick = (event: any) => {
    event.preventDefault();
    toggleEditMode();
  };

  const handleCancelClick = (event: any) => {
    event.preventDefault();
    if (formRef && formRef.current) {
      formRef.current.resetForm();
    }
    toggleEditMode();
  };

  const handleSubmit = (values: Values, formikBag: FormikBag<Props, Values>) => {
    const { setStatus, setSubmitting } = formikBag;
    const { claimId, claimNotes } = values;

    updateClaim(
      claimId,
      { notes: claimNotes },
      {
        onSuccess: ({ data }) => {
          toggleEditMode();
          setNotes(data.notes);
          setSubmitting(false);
          setStatus({
            showAlert: true,
            success: true,
            message: 'Notes successfully saved.',
          });

          setTimeout(() => {
            setStatus({ showAlert: false });
          }, 4000);
        },
        onError: () => {
          setSubmitting(false);
          setStatus({
            showAlert: true,
            success: false,
            message: 'Could not save notes. Please try again.',
          });
        },
      }
    );
  };

  const isInitialValid = (props: FormikProps<Values>) => {
    return validationSchema.isValidSync(props.initialValues);
  };

  return (
    <Formik
      ref={formRef}
      initialValues={{ claimNotes: notes, claimId }}
      isInitialValid={isInitialValid}
      onSubmit={handleSubmit}
      onReset={handleCancelClick}
      validationSchema={validationSchema}
      enableReinitialize={true}
    >
      {({ values, dirty, isValid, isSubmitting, status = {} }) => {
        const handleTextClick = () => {
          if (!dirty && !values.claimNotes) {
            toggleEditMode();
          }
        };

        return (
          <Form data-test-id="applicationNotesForm">
            <Root isEditMode={editMode} isDirty={dirty || values.claimNotes} notesStyle={notesStyle}>
              <NotesHeader>
                <NotesLabel htmlFor="claimNotes">Notes</NotesLabel>
                <NotesActions>
                  {editMode ? (
                    <>
                      <NotesButton
                        type="submit"
                        color={ThemeColors[Color.Accent]}
                        buttonStyle="toggle"
                        disabled={!isValid || isSubmitting}
                        data-test-id="saveFormButton"
                      >
                        Save
                      </NotesButton>
                      <NotesButton
                        type="button"
                        color={ThemeColors[Color.Secondary]}
                        buttonStyle="toggle"
                        onClick={handleCancelClick}
                        data-test-id="cancelFormButton"
                      >
                        Cancel
                      </NotesButton>
                    </>
                  ) : (
                    <NotesButton
                      type="button"
                      color={ThemeColors[Color.Accent]}
                      buttonStyle="toggle"
                      onClick={handleEditClick}
                      data-test-id="editFormButton"
                    >
                      <Icon
                        viewBox="0 0 10 10"
                        width={10}
                        height={10}
                        fill={ThemeColors[Color.Accent]}
                        name="pencil"
                        style={{ marginRight: '0.325em' }}
                      />
                      Edit
                    </NotesButton>
                  )}
                </NotesActions>
              </NotesHeader>
              <NotesBody ref={notesRef}>
                {editMode ? (
                  <Field name="claimNotes" placeholder={placeholder} component={NotesTextArea} value={values.claimNotes || ''} />
                ) : (
                  <NotesText onClick={handleTextClick} data-test-id="notesText">
                    {values.claimNotes ? values.claimNotes : placeholder}
                  </NotesText>
                )}
              </NotesBody>
              {status && status.showAlert && (
                <Alert
                  type={status.success ? AlertType.Success : AlertType.Error}
                  data-test-id={`form${status.success ? 'Success' : 'Error'}`}
                  style={{ marginTop: '2rem' }}
                >
                  {status.message}
                </Alert>
              )}
            </Root>
          </Form>
        );
      }}
    </Formik>
  );
};

const mapDispatchToProps = {
  updateClaim,
};

export default connect(null, mapDispatchToProps)(ApplicationNotes);
