import React from 'react';
import { useEffect } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';

const ScrollToTop: React.FunctionComponent<RouteComponentProps> = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [props.location.pathname]);

  return <>{props.children}</>;
};

export default withRouter(ScrollToTop);
