export function createDownloadLink(data: string, innerText: string, fileName: string, type = 'application/pdf') {
  const blob = new Blob([data], { type });

  if (window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(blob);
  } else {
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', fileName);
    link.innerText = innerText;
    document.body.appendChild(link);
    link.click();
    // This setTimeout is a hack that is necessary to combat a bug in Safari on
    // iOS. This works around an issue related to blob downloads. Doesn't have
    // any impact on other browsers.
    setTimeout(() => {
      window.URL.revokeObjectURL(url);
      link.remove();
    }, 500);
  }
}
