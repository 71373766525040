import React from 'react';

interface Props {
  json: any;
}

const Debug: React.FunctionComponent<Props> = ({ json }) => {
  return process.env.REACT_APP_DEBUG ? (
    <pre style={{ margin: '2em 0', whiteSpace: 'pre-wrap' }}>{JSON.stringify(json, null, 2)}</pre>
  ) : null;
};

export default Debug;
