import React from 'react';
import styled from '../../theme';
import { Color, ThemeColors } from '../../theme/primaryTheme';
import Icon from '../Icon';

interface SortArrowProps {
  onUpArrowClick: () => void;
  onDownArrowClick: () => void;
}

const SortArrows: React.FunctionComponent<SortArrowProps> = ({ onUpArrowClick, onDownArrowClick }) => {
  return (
    <SortArrowsEl>
      <ArrowButton onClick={onUpArrowClick}>
        <Icon name="caretUp" fill={ThemeColors[Color.Primary]} width={10} height={5} viewBox="0 0 10 5" />
      </ArrowButton>
      <ArrowButton onClick={onDownArrowClick}>
        <Icon name="caretDown" fill={ThemeColors[Color.Primary]} width={10} height={5} viewBox="0 0 10 5" />
      </ArrowButton>
    </SortArrowsEl>
  );
};

const SortArrowsEl = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 3px;
  margin-left: 0.8rem;
`;

const ArrowButton = styled.div`
  line-height: 0.6;

  &:hover {
    cursor: pointer;
  }
`;

export default SortArrows;
