import { connect as formikConnect, FormikContextType } from 'formik';
import _ from 'lodash';
import React from 'react';
import { FieldValue } from '../../../components/FieldValue';
import { FlexTableLeft, FlexTableRight, FlexTableRows } from '../../../components/FlexTable';
import Label from '../../../components/Label';
import fields from './fields';

interface IModalBodyProps {
  formik: FormikContextType<any>;
}

class ModalBody extends React.Component<IModalBodyProps> {
  public render() {
    const {
      formik: { values, initialValues },
    } = this.props;

    return (
      <>
        {Object.keys(fields).map((name, index) => {
          const fieldConfig = fields[name];
          const initialValue = initialValues[name];
          const { label, displayValue } = fieldConfig;

          if ((_.get(values, name) || initialValue) && _.get(values, name) !== initialValue) {
            return (
              <FlexTableRows key={`${name}_${index}`}>
                <FlexTableLeft>
                  <Label bold={true} tag="span">
                    {label}
                  </Label>
                </FlexTableLeft>
                <FlexTableRight>
                  <FieldValue>{displayValue(values[name])}</FieldValue>
                </FlexTableRight>
              </FlexTableRows>
            );
          }

          return null;
        })}
      </>
    );
  }
}

export default formikConnect<any>(ModalBody);
