import React from 'react';
import { useSelector } from 'react-redux';
import { CreditClaimStatus } from '../../../constants/ApplicationStatus';
import { claimDataSelector } from '../../../selectors/claims';
import { isCreditProcessor } from '../../../selectors/user';
import Field from '../../Field';
import { FieldColumn, FieldRow } from '../../FieldRow';
import FormSection from '../../FormSection';
import SummaryValue from '../../SummaryValue';

interface Props {
  value: number;
}

const PraNumCopiesSection: React.FunctionComponent<Props> = ({ value }) => {
  const { status } = useSelector(claimDataSelector);
  const isProcessor = useSelector(isCreditProcessor);
  const isEditable = isProcessor ? status !== CreditClaimStatus.COMPLETED : status === CreditClaimStatus.UNSUBMITTED;

  const renderBody = () => {
    if (isEditable) {
      return (
        <FieldRow data-test-id="directCreditOptions">
          <FieldColumn>
            <Field type="number" name="praNumCopies" placeholder="0" label="" />
          </FieldColumn>
        </FieldRow>
      );
    }

    return <SummaryValue label="" value={value.toString()} />;
  };

  return <FormSection title="Printed Copies">{renderBody()}</FormSection>;
};

export default PraNumCopiesSection;
