export enum Accreditor {
  EACCME = 'EACCME',
  RCPSC = 'RCPSC',
  QCHPAD = 'QCHP-AD',
  EBAC = 'EBAC',
}

export const AccreditorOptions = Object.keys(Accreditor).map((key) => {
  return {
    label: Accreditor[key].toUpperCase(),
    value: Accreditor[key],
  };
});
