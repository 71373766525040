import { CreditActivity, CreditClaim } from '../types';
import { nullifyEmptyValues } from './cleanData';

const excludedKeys = ['price', 'paymentAmount', 'shippingRateCode', 'receiptId'];

export const convertActivityUnits = (claim: Partial<CreditClaim>) => {
  const { creditActivities } = claim;

  if (creditActivities && creditActivities.length !== 0) {
    claim.creditActivities = creditActivities.map((activity: CreditActivity) => {
      let units = activity.activityUnits;

      if (typeof units === 'string') {
        units = Number(units) || 1;
      }

      return {
        ...activity,
        activityUnits: units,
      };
    });
  }

  return claim;
};

export const convertEmptyCreditsToNull = (claim: Partial<CreditClaim>) => {
  if (claim.creditActivities) {
    claim.creditActivities = claim.creditActivities.map((activity) => {
      if (activity.numberOfCredits === '') {
        activity.numberOfCredits = null;
      }
      return activity;
    });
  }

  return claim;
};

export const convertPraNumCopies = (claim: Partial<CreditClaim>) => {
  const praNumCopies = claim.praNumCopies;
  if (typeof praNumCopies === 'string') {
    claim.praNumCopies = parseInt(praNumCopies, 10) || 1;
  }
  return claim;
};

export const prepareClaim = (obj: Partial<CreditClaim>): Partial<CreditClaim> => {
  let claim = Object.keys(obj).reduce((acc, val) => {
    if (!excludedKeys.includes(val)) {
      acc[val] = obj[val];
    }
    return acc;
  }, {});

  claim = convertActivityUnits(claim);
  claim = convertEmptyCreditsToNull(claim);
  claim = convertPraNumCopies(claim);

  return nullifyEmptyValues(claim);
};
