/* eslint-disable @typescript-eslint/no-use-before-define */
import { AxiosError, AxiosResponse } from 'axios';
import { Dispatch } from 'redux';
import { apiRequest } from '../utilities/api';

export interface ClaimAccount {
  targetEmailAddress?: string;
}

export interface ClaimAccountStore {
  isLoading: boolean;
  error?: ResponseError;
  data?: ClaimAccount;
}

/* ACTIONS */
export const FETCH_ACCESS_CODE_EMAIL_REQUEST = '@FETCH_ACCESS_CODE_EMAIL/REQUEST';
export const FETCH_ACCESS_CODE_EMAIL_SUCCESS = '@FETCH_ACCESS_CODE_EMAIL/SUCCESS';
export const FETCH_ACCESS_CODE_EMAIL_ERROR = '@FETCH_ACCESS_CODE_EMAIL/ERROR';

interface ActionFetchAccessCodeEmailRequest {
  type: typeof FETCH_ACCESS_CODE_EMAIL_REQUEST;
}

interface ActionFetchAccessCodeEmailSuccess {
  type: typeof FETCH_ACCESS_CODE_EMAIL_SUCCESS;
  data: ClaimAccount;
}

interface ActionFetchAccessCodeEmailError {
  type: typeof FETCH_ACCESS_CODE_EMAIL_ERROR;
  error: any;
}

type ActionClaim = ActionFetchAccessCodeEmailRequest | ActionFetchAccessCodeEmailSuccess | ActionFetchAccessCodeEmailError;

/* ACTION CREATORS */
export interface ResponseError {
  message: string;
  statusCode?: number;
}

type FetchAccessCodeEmailRequestActionCreator = () => ActionFetchAccessCodeEmailRequest;
export const fetchAccessCodeEmailRequest: FetchAccessCodeEmailRequestActionCreator = () => {
  return { type: FETCH_ACCESS_CODE_EMAIL_REQUEST };
};

export type FetchAccessCodeEmailSuccessActionCreator = (data: ClaimAccount) => ActionFetchAccessCodeEmailSuccess;
export const fetchAccessCodeEmailSuccess: FetchAccessCodeEmailSuccessActionCreator = (data) => {
  return {
    type: FETCH_ACCESS_CODE_EMAIL_SUCCESS,
    data,
  };
};

type FetchAccessCodeEmailErrorActionCreator = (error: ResponseError) => ActionFetchAccessCodeEmailError;
export const fetchAccessCodeEmailError: FetchAccessCodeEmailErrorActionCreator = (error) => {
  return {
    type: FETCH_ACCESS_CODE_EMAIL_ERROR,
    error,
  };
};

/* THUNKS */
export type OnSuccess = (response: AxiosResponse) => void;
export type OnError = (error: ResponseError) => void;

interface Config {
  onSuccess?: OnSuccess;
  onError?: OnError;
}

export const fetchAccessCodeEmail: () => void = () => {
  return async (dispatch: Dispatch) => {
    dispatch(fetchAccessCodeEmailRequest());
    apiRequest
      .get(`/v1/claim-account/has-active-verification-code`)
      .then(handleFetchAccessCodeEmailSuccess(dispatch))
      .catch(handleFetchAccessCodeEmailError(dispatch));
  };
};

type ClaimSuccessHandler = (dispatch: Dispatch, config?: Config) => (response: AxiosResponse<ClaimAccount>) => void;
const handleFetchAccessCodeEmailSuccess: ClaimSuccessHandler = (dispatch) => (response) => {
  dispatch(fetchAccessCodeEmailSuccess(response.data));
};

type ClaimErrorHandler = (dispatch: Dispatch, config?: Config) => (error: AxiosError) => void;
const handleFetchAccessCodeEmailError: ClaimErrorHandler = (dispatch) => (error) => {
  dispatch(fetchAccessCodeEmailError(error.response.data.error));
};

/* REDUCER */
export const initialState: ClaimAccountStore = {
  isLoading: false,
  data: {} as ClaimAccount,
  error: undefined,
};

const claimAccount = (state = initialState, action: ActionClaim): ClaimAccountStore => {
  switch (action.type) {
    case FETCH_ACCESS_CODE_EMAIL_REQUEST:
      return {
        ...state,
        isLoading: true,
        data: initialState.data,
        error: initialState.error,
      };
    case FETCH_ACCESS_CODE_EMAIL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.data,
      };
    case FETCH_ACCESS_CODE_EMAIL_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export default claimAccount;
