import Text from '../../../components/Text';
import styled from '../../../theme';
import { themeBreakpoint, themeColor } from '../../../theme/helpers';
import { Color } from '../../../theme/primaryTheme';

interface WrapProps {
  wide?: boolean;
}

export const FormFooter = styled.div`
  display: flex;
  align-items: center;
  margin-top: 3.6rem;

  & > *:not(:first-child) {
    margin-left: 2.4rem;
  }

  @media (max-width: ${themeBreakpoint('medium')}) {
    flex-direction: column;

    & > *:not(:first-child) {
      margin-top: 1.4rem;
      margin-left: 0;
    }
  }
`;

export const FormWrapper = styled.div`
  padding: 2.4rem 3.2rem;
  background-color: ${themeColor(Color.BackgroundLight)};
  border-radius: 0.5rem;
  @keyframes FadeIn {
    from {
      background-color: ${themeColor(Color.Border)};
    }
    to {
      background-color: ${themeColor(Color.BackgroundLight)};
    }
  }
  &:target {
    box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);
    animation: FadeIn 1s cubic-bezier(0.23, 1, 0.32, 1) forwards;
    border: 1px solid ${themeColor(Color.Border)};
  }
`;

export const FormAction = styled.div`
  margin-top: 1.6rem;
`;

export const StyledLinkCont = styled.div`
  margin-top: 2.8rem;
  text-align: center;
  border-top: 1px solid ${themeColor(Color.Border)};
  padding-top: 1.2rem;
`;

export const FormBody = styled.div`
  > * + * {
    margin-top: 1.6rem;
  }
`;

export const FormDescription = styled(Text)`
  margin: 1rem 0 2.4rem;
`;

export const FormWrap = styled.div`
  max-width: ${(props: WrapProps) => (props.wide ? '1200px' : '564px')};
`;

export const FormGroup = styled.div`
  margin-bottom: 4.5rem;
`;
