import { ActivityType } from './ActivityType';

export const Credits = {
  [ActivityType.Article]: 10,
  [ActivityType.Poster]: 5,
  [ActivityType.Abms]: 60,
  [ActivityType.Acgme]: 20,
  [ActivityType.Medical]: 25,
  [ActivityType.Teaching]: 2,
  [ActivityType.ICC]: 1,
};
