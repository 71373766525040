import { AxiosResponse } from 'axios';
import React from 'react';
import styled, { css } from 'src/theme';
import { themeBreakpoint } from 'src/theme/helpers';
import { Color, ThemeColors } from '../../theme/primaryTheme';
import { BulkLearnerActivityUpload, LearnerActivity, Pra } from '../../types';
import { apiRequest } from '../../utilities/api';
import { createDownloadLink } from '../../utilities/createDownloadLink';
import Alert, { AlertType } from '../Alert';
import Button from '../Button';
import Icon from '../Icon';
import StatusLabel from '../StatusLabel';
import Text from '../Text';

export interface DownloadButtonProps {
  buttonText?: string;
  iconWidth?: number;
}

interface Props {
  value?: string;
  data: AcceptableData;
  label?: string;
  iconName?: 'download' | 'file';
  buttonProps?: DownloadButtonProps;
  hidden?: boolean;
  downloadedFileName: string;
  rollbarErrorText: (data: AcceptableData) => string;
}

type AcceptableData = Pra | LearnerActivity | BulkLearnerActivityUpload;

const FileDownload: React.FunctionComponent<Props> = (props) => {
  const { data, value = '', iconName, buttonProps = {}, hidden, downloadedFileName, rollbarErrorText } = props;
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(false);

  const fetchFile = () => {
    setLoading(true);

    apiRequest
      .get(value, { responseType: 'blob' })
      .then(({ data }: AxiosResponse) => {
        setLoading(false);
        createDownloadLink(data, 'Download PDF', downloadedFileName);
      })
      .catch((err) => {
        Rollbar.error(rollbarErrorText(data), err);
        setError(true);
        setLoading(false);
      });
  };

  if (error) {
    return (
      <Alert type={AlertType.Error} data-test-id="fileDownloadError">
        Download Failed
      </Alert>
    );
  }

  if (!value) {
    return <StatusLabel status="certificate_unavailable" />;
  }

  const { buttonText, iconWidth } = buttonProps;
  const width = iconWidth || 22;
  const height = width * 1.214285714;
  return (
    <FileDownloadButton
      onClick={fetchFile}
      buttonStyle={iconName ? 'download-button' : 'plain-text'}
      loading={loading}
      data-test-id="fileDownloadButton"
      hidden={hidden}
    >
      {iconName && (
        <FileDownloadButtonIcon name={iconName} width={width} height={height} viewBox="0 0 14 17" fill={ThemeColors[Color.Accent]} />
      )}
      {buttonText && (
        <Text color={Color.Accent} bold={true}>
          {buttonText}
        </Text>
      )}
    </FileDownloadButton>
  );
};

const FileDownloadButton = styled(Button)<{ hidden?: boolean }>`
  display: flex;
  ${({ hidden }) =>
    hidden
      ? css`
          visibility: hidden;
        `
      : ''}

  @media (max-width: ${themeBreakpoint('medium')}) {
    align-items: center;
    flex-direction: row-reverse;

    & > * + * {
      margin-right: 1rem;
    }
  }
`;

const FileDownloadButtonIcon = styled(Icon)`
  display: inline-block;
`;

export default FileDownload;
