import React from 'react';
import DatePickerField from '../Field/DatePickerField';
import { FieldColumn, FieldRow } from '../FieldRow';
import FormSection from '../FormSection';

const DateReceivedSection: React.FunctionComponent = () => {
  return (
    <FormSection title="Date Received">
      <FieldRow>
        <FieldColumn>
          <DatePickerField name="dateReceived" label="Date" />
        </FieldColumn>
      </FieldRow>
    </FormSection>
  );
};

export default DateReceivedSection;
