import React, { ButtonHTMLAttributes } from 'react';
import { ColorKeys } from '../../theme/theme';
import LoadingSpinner from '../LoadingSpinner';
import { StyledButton } from './styles';

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  buttonStyle?: string;
  color?: ColorKeys;
  full?: boolean;
  loading?: boolean;
}

const Button: React.FunctionComponent<ButtonProps> = ({
  buttonStyle,
  children,
  full = false,
  loading = false,
  disabled = false,
  type = 'button',
  ...rest
}) => (
  <StyledButton type={type} buttonStyle={buttonStyle} full={full} disabled={disabled || loading} {...rest}>
    {loading ? <LoadingSpinner display="block" size={16} /> : children}
  </StyledButton>
);

export default Button;
