import React from 'react';
import { LinkStyle } from 'src/constants/LinkStyle';
import styled from 'src/theme';
import Icon from '../Icon';
import Link from '../Link';

const PrintButton: React.FunctionComponent = () => {
  // https://developer.mozilla.org/en-US/docs/Web/API/Window/print
  if (typeof window.print !== 'function') {
    return null;
  }

  const triggerPrintDialog = () => {
    window.print();
  };

  return (
    <HiddenWhenPrintedLink to="#" onClick={triggerPrintDialog} linkStyle={LinkStyle.AccentText}>
      <PaddedIcon name="print" width={12} height={12} viewBox="0 0 60 60" />
      Print This Page
    </HiddenWhenPrintedLink>
  );
};

const PaddedIcon = styled(Icon)`
  margin-right: 0.325em;
`;

const HiddenWhenPrintedLink = styled(Link)`
  @media print {
    display: none;
  }
`;

export default PrintButton;
