import { createSelector } from 'reselect';
import { ReduxStore } from '../ducks';

/**
 * ====================
 * FEATURE FLAGS DATA
 * ====================
 */
export const featureFlags = (state: ReduxStore) => state.featureFlags;
export const featureFlagsLoadingSelector = createSelector(featureFlags, (featureFlags) => featureFlags.loading);
export const claimMissingCreditsSelector = createSelector(featureFlags, (featureFlags) => featureFlags.claimMissingCredits);
