import React from 'react';
import { PageWrap } from '../../layouts/PageStructure';
import styled from '../../theme';
import { themeColor } from '../../theme/helpers';
import { Color } from '../../theme/primaryTheme';
import { baseStyles } from '../Button/styles';

const Root = styled.div`
  background-color: ${themeColor(Color.Reverse)};
  border-top: solid thin ${themeColor(Color.Border)};
  border-bottom: solid thin ${themeColor(Color.Border)};

  ${PageWrap} {
    align-items: baseline;
    display: flex;
    flex-direction: row;
  }
`;

const TabItem = styled.button<{ selected?: boolean }>`
  ${baseStyles};
  text-align: center;
  padding: 2rem;
  position: relative;
  outline: none;

  &::after {
    content: '';
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    height: 4px;
    background-color: ${({ selected }) => (selected ? themeColor(Color.Accent) : 'transparent')};
  }

  & + & {
    margin-left: 2rem;
  }
`;

interface Props {
  tabItems: any;
  className?: string;
}

const Tabs: React.FunctionComponent<Props> = ({ tabItems, className }) => {
  return (
    <Root className={className}>
      <PageWrap>
        {tabItems.map(({ id, label, selected, onClick }: any) => (
          <TabItem key={id} selected={selected} onClick={onClick}>
            {label}
          </TabItem>
        ))}
      </PageWrap>
    </Root>
  );
};

export default Tabs;
