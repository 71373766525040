import axios, { AxiosError, AxiosResponse } from 'axios';
import { Dispatch } from 'redux';
import { ExternalCreditEarner } from '../types';
import { addSources } from '../utilities/addSources';
import { apiRequest } from '../utilities/api';
import { CreditEarnerStore } from './types';

const FETCH_CREDIT_EARNER_REQUEST = '@CREDIT_EARNER/REQUEST';
const FETCH_CREDIT_EARNER_SUCCESS = '@CREDIT_EARNER/SUCCESS';
const FETCH_CREDIT_EARNER_ERROR = '@CREDIT_EARNER/ERROR';
const RESET_CREDIT_EARNER = '@CREDIT_EARNER/RESET';

interface ActionFetchCreditEarnerRequest {
  type: typeof FETCH_CREDIT_EARNER_REQUEST;
}

interface ActionFetchCreditEarnerSuccess {
  type: typeof FETCH_CREDIT_EARNER_SUCCESS;
  data: any; // TODO: CreditEarner type
}

interface ActionFetchCreditEarnerError {
  type: typeof FETCH_CREDIT_EARNER_ERROR;
  error: any; // TODO: Error type
}

interface ActionResetCreditEarner {
  type: typeof RESET_CREDIT_EARNER;
}

type ActionCreditEarner =
  | ActionFetchCreditEarnerRequest
  | ActionFetchCreditEarnerSuccess
  | ActionFetchCreditEarnerError
  | ActionResetCreditEarner;

export interface ResponseError {
  message: string;
  statusCode?: number;
}

type FetchCreditEarnerRequestActionCreator = () => ActionFetchCreditEarnerRequest;
const fetchCreditEarnerRequest: FetchCreditEarnerRequestActionCreator = () => {
  return { type: FETCH_CREDIT_EARNER_REQUEST };
};

type FetchCreditEarnerSuccessActionCreator = (data: any) => ActionFetchCreditEarnerSuccess;
const fetchCreditEarnerSuccess: FetchCreditEarnerSuccessActionCreator = (data) => {
  return {
    type: FETCH_CREDIT_EARNER_SUCCESS,
    data,
  };
};

type FetchCreditEarnerErrorActionCreator = (error: ResponseError) => ActionFetchCreditEarnerError;
const fetchCreditEarnerError: FetchCreditEarnerErrorActionCreator = (error) => {
  return {
    type: FETCH_CREDIT_EARNER_ERROR,
    error,
  };
};

type SuccessHandler = (dispatch: Dispatch, config?: Config) => (response: AxiosResponse) => void;
const handleSuccess: SuccessHandler =
  (dispatch, config = {}) =>
  (response) => {
    const { data } = response;
    const { onSuccess } = config;
    dispatch(fetchCreditEarnerSuccess(data));

    if (onSuccess && typeof onSuccess === 'function') {
      onSuccess(response);
    }
  };

type ErrorHandler = (dispatch: Dispatch, config?: Config) => (error: AxiosError) => void;
const handleError: ErrorHandler =
  (dispatch, config = {}) =>
  (error) => {
    const { onError } = config;
    let data = {
      message: 'Submission failed.',
    };

    console.error(error);

    if (error.response) {
      data = error.response.data;
      dispatch(fetchCreditEarnerError(error.response.data.error));
    } else if (error.request) {
      data = {
        message: 'Could not make request',
      };
      dispatch(fetchCreditEarnerError(data));
    } else {
      data = {
        message: 'Submission failed',
      };
      dispatch(fetchCreditEarnerError(data));
    }

    if (onError && typeof onError === 'function') {
      onError(data);
    }
  };

type FetchCreditEarnerAsync = (
  userId: number | string,
  params?: {
    sync: boolean;
  }
) => (dispatch: Dispatch) => void;
export const fetchCreditEarner: FetchCreditEarnerAsync = (userId, params) => async (dispatch: Dispatch) => {
  dispatch(fetchCreditEarnerRequest());

  try {
    const { data } = await apiRequest.get(`/v1/creditearner/${userId}?view=external`, { params });
    dispatch(fetchCreditEarnerSuccess(data));
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const {
        response: { status: statusCode },
        message,
      } = error;
      dispatch(fetchCreditEarnerError({ message, statusCode }));
    } else {
      dispatch(fetchCreditEarnerError({ message: 'Oops, there was an error rendering the page. Please refresh to try again.' }));
    }
  }
};

export type OnSuccess = (response: AxiosResponse) => void;
export type OnError = (error: ResponseError) => void;

interface Config {
  onSuccess?: OnSuccess;
  onError?: OnError;
}

export type UpdateCreditEarnerActionCreator = (
  userId: number,
  data: Partial<ExternalCreditEarner>,
  config?: Config
) => (dispatch: Dispatch) => void;

export const updateCreditEarner: UpdateCreditEarnerActionCreator = (userId, data, config = {}) => {
  return async (dispatch: Dispatch) => {
    dispatch(fetchCreditEarnerRequest());

    apiRequest
      .put(`/v1/creditearner/${userId}`, addSources(data))
      .then(handleSuccess(dispatch, config))
      .catch(handleError(dispatch, config));
  };
};

export type CreateCreditEarnerActionCreator = (data: Partial<ExternalCreditEarner>, config?: Config) => (dispatch: Dispatch) => void;

export const createCreditEarner: CreateCreditEarnerActionCreator = (data, config = {}) => {
  return async (dispatch: Dispatch) => {
    dispatch(fetchCreditEarnerRequest());

    apiRequest.post('/v1/creditearner', data).then(handleSuccess(dispatch, config)).catch(handleError(dispatch, config));
  };
};

export type ResetCreditEarnerActionCreator = () => ActionResetCreditEarner;
export const resetCreditEarner: ResetCreditEarnerActionCreator = () => {
  return { type: RESET_CREDIT_EARNER };
};

export const initialState = {
  isLoading: false,
  data: {} as ExternalCreditEarner,
  error: undefined,
  meta: {},
  entities: {},
  result: [],
};

const creditEarner = (state: CreditEarnerStore = initialState, action: ActionCreditEarner): CreditEarnerStore => {
  switch (action.type) {
    case FETCH_CREDIT_EARNER_REQUEST:
      return {
        ...state,
        data: initialState.data,
        isLoading: true,
      };
    case FETCH_CREDIT_EARNER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.data,
      };
    case FETCH_CREDIT_EARNER_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    case RESET_CREDIT_EARNER:
      return {
        ...state,
        data: initialState.data,
      };

    default:
      return state;
  }
};

export default creditEarner;
