import React from 'react';
import { useDispatch } from 'react-redux';
import { CreditClaimStatus } from '../../constants/ApplicationStatus';
import { ModalType } from '../../constants/ModalType';
import { buildRoute, Routes } from '../../constants/Routes';
import { updateClaim } from '../../ducks/claims';
import { hideModal, showModal } from '../../ducks/modal';
import { CreditClaim } from '../../types';
import ActionMenu, { ActionMenuItem } from '../ActionMenu';
import NotesModal from './NotesModal';
import PaymentFailedModal from './PaymentFailedModal';

interface Props {
  claim: CreditClaim;
  history?: any;
}

const ClaimActionMenu: React.FunctionComponent<Props> = ({ claim, history }) => {
  const dispatch = useDispatch();

  const goToUserProfile = () => {
    if (claim.creditEarner) {
      history.push(buildRoute(Routes.UserShow, { id: claim.creditEarner.id }));
    }
  };

  const showPaymentFailedModal = () => {
    dispatch(showModal(`${ModalType.PaymentFailed}-${claim.id}`));
  };

  const showNotesModal = () => {
    dispatch(showModal(`${ModalType.ApplicationNotes}-${claim.id}`));
  };

  const updateClaimStatus = (checked: boolean) => {
    if (checked) {
      dispatch(
        updateClaim(
          claim.id,
          { status: CreditClaimStatus.PAYMENT_FAILED },
          {
            onSuccess: () => {
              dispatch(hideModal(`${ModalType.PaymentFailed}-${claim.id}`));
            },
          }
        )
      );
    }
  };

  return (
    <>
      <ActionMenu>
        <ActionMenuItem onClick={goToUserProfile}>Applicant Profile</ActionMenuItem>
        {claim.status === CreditClaimStatus.COMPLETED && (
          <ActionMenuItem onClick={showPaymentFailedModal}>Mark as Payment Failed</ActionMenuItem>
        )}
        <ActionMenuItem onClick={showNotesModal}>View Notes</ActionMenuItem>
      </ActionMenu>

      {claim.status === CreditClaimStatus.COMPLETED && <PaymentFailedModal claim={claim} handleConfirm={updateClaimStatus} />}
      <NotesModal claim={claim} />
    </>
  );
};

export default ClaimActionMenu;
