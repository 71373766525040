import React from 'react';
import { upperFirst } from 'lodash';
import { ApplicationType } from 'src/constants/ApplicationType';
import { ApplicationTypeCode } from '../../constants/ApplicationTypeCode';
import FormSection from '../FormSection';
import Text from '../Text';

const ProcessingRateSection: React.FunctionComponent<{ applicationTypeCode?: ApplicationTypeCode }> = ({ applicationTypeCode }) => {
  switch (applicationTypeCode) {
    case ApplicationTypeCode.InternationalCreditConversion:
    case ApplicationTypeCode.DirectCredit:
    case ApplicationTypeCode.PRA:
      const formattedApplicationType =
        applicationTypeCode === ApplicationTypeCode.PRA
          ? ApplicationType[applicationTypeCode]
          : upperFirst((ApplicationType[applicationTypeCode] ?? '').toLowerCase());
      const costDescription =
        applicationTypeCode === ApplicationTypeCode.PRA
          ? ' Applications for standard PRAs cost $50 for AMA members and $100 for non-members. Applications for PRAs with commendation are $70 for AMA members and $140 for non-members.'
          : ' Applications cost $30 for AMA members, $75 for non-members.';

      return (
        <FormSection title="Processing Rate">
          <Text data-test-id="processingRateDescription">
            {formattedApplicationType} applications are processed within four business weeks from the date received.
            {costDescription}
          </Text>
        </FormSection>
      );

    default:
      return null;
  }
};

export default ProcessingRateSection;
