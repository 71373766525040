import React from 'react';
import { useDispatch } from 'react-redux';
import { LinkStyle } from 'src/constants/LinkStyle';
import { setSelectedResult } from 'src/ducks/userSearch';
import { emptyCellCharacter } from '../../constants/General';
import { buildRoute, Routes } from '../../constants/Routes';
import { ExternalCreditEarner } from '../../types';
import { momentUtc } from '../../utilities/moment';
import Link from '../Link';
import ExternalLink from '../Link/ExternalLink';
import Table from '../Table';
import Text from '../Text';

export enum UsersTableColumn {
  Name = 'name',
  Email = 'email',
  Dob = 'dob',
  Location = 'location',
  MeNumber = 'meNumber',
  NpiNumbers = 'npiNumbers',
  NewApp = 'newApp',
}

interface Props {
  users: ExternalCreditEarner[];
}

export const UsersTable: React.FunctionComponent<Props> = ({ users }) => {
  const dispatch = useDispatch();

  const columns = [
    {
      header: 'Name',
      id: UsersTableColumn.Name,
      width: '18%',
      accessor: ({ firstName = '', lastName = '' }: ExternalCreditEarner) => {
        return `${firstName} ${lastName}`;
      },
      cellComponent: ({ data, value }: any) => (
        <Link to={buildRoute(Routes.UserShow, { id: data.id })}>
          <Text bold={true} tag="span">
            {value}
          </Text>
        </Link>
      ),
    },
    {
      header: 'Email',
      id: UsersTableColumn.Email,
      width: '18%',
      accessor: ({ emails = [] }: ExternalCreditEarner) => {
        return emails.length ? emails[0] : emptyCellCharacter;
      },
      cellComponent: ({ value }: any) =>
        value !== emptyCellCharacter ? <ExternalLink href={`mailto:${value}`}>{value}</ExternalLink> : <>{value}</>,
    },
    {
      header: 'Date of Birth',
      id: UsersTableColumn.Dob,
      accessor: ({ dob }: ExternalCreditEarner) => {
        if (!dob) {
          return emptyCellCharacter;
        }

        const date = momentUtc(dob);
        const year = date.format('YYYY');

        return date.format(year === '1904' ? 'MMMM Do' : 'MM/DD/YYYY');
      },
    },
    {
      header: 'Location',
      id: UsersTableColumn.Location,
      accessor: ({ city = emptyCellCharacter, stateCode = '' }: ExternalCreditEarner) => {
        return `${city}${stateCode ? `, ${stateCode}` : ''}`;
      },
    },
    {
      header: 'ME#',
      id: UsersTableColumn.MeNumber,
      accessor: 'meNumber',
    },
    {
      header: 'NPI#',
      id: UsersTableColumn.NpiNumbers,
      accessor: ({ npiNumbers = [] }: ExternalCreditEarner) => {
        return npiNumbers.length ? npiNumbers[0] : emptyCellCharacter;
      },
    },
    {
      header: '',
      id: UsersTableColumn.NewApp,
      width: '130px',
      accessor: (user: ExternalCreditEarner) => user,
      cellComponent: ({ value: user }: { value: ExternalCreditEarner }) => (
        <Link
          data-test-id="newAppFromUser"
          linkStyle={LinkStyle.ButtonOutline}
          onClick={() => {
            dispatch(setSelectedResult(user));
          }}
          to={
            (user as ExternalCreditEarner).id ? buildRoute(Routes.ApplicantInformationWithId, { id: user.id }) : Routes.ApplicantInformation
          }
        >
          New App
        </Link>
      ),
    },
  ];

  return <Table columns={columns} data={users} />;
};

export default UsersTable;
