import React from 'react';
import styled from 'styled-components';
import { themeBreakpoint, themeColor } from '../../theme/helpers';
import { Color } from '../../theme/primaryTheme';
import { baseStyles } from '../Button/styles';

export interface Props {
  currentPage: number;
  pageCount: number;
  onPageChanged: (page: number) => void;
}

const PaginationContainer = styled.div`
  color: ${themeColor(Color.Accent)};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 3rem;
`;

const PaginationButton = styled.button<{ disabled: boolean }>`
  ${baseStyles};
  padding: 0;
  color: ${({ disabled }) => (disabled ? themeColor(Color.Label) : themeColor(Color.Accent))};

  &:disabled {
    background-color: transparent;

    &:hover {
      background-color: transparent;
    }
  }

  @media (max-width: ${themeBreakpoint('medium')}) {
    padding: 0.8rem 1.2rem 0.6rem;
    font-size: 3rem;
  }
`;

const PaginationButtonLabel = styled.span`
  display: inline-block;
  margin: 0 0.5rem;

  @media (max-width: ${themeBreakpoint('medium')}) {
    display: none;
  }
`;

const PaginationPage = styled.button<{ selected: boolean }>`
  ${baseStyles};
  background-color: ${({ selected }) => (selected ? themeColor(Color.Accent) : 'transparent')};
  color: ${({ selected }) => (selected ? themeColor(Color.Reverse) : themeColor(Color.Accent))};
  padding: 0.8rem 1.2rem 0.6rem;
  border-radius: 2px;
  transition: none;

  @media (max-width: ${themeBreakpoint('medium')}) {
    font-size: 1.4rem;
    padding: 0.8rem;
  }
`;

const Ellipsis = styled.div`
  ${baseStyles};
  background-color: transparent;
  color: ${themeColor(Color.Accent)};
  cursor: default;

  @media (max-width: ${themeBreakpoint('medium')}) {
    padding: 0 0.5rem;
  }
`;

const PaginationPageList = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;

  @media (max-width: ${themeBreakpoint('medium')}) {
    display: none;
  }
`;

const MobilePaginationPageList = styled.div`
  display: none;
  justify-content: space-between;
  align-items: baseline;

  @media (max-width: ${themeBreakpoint('medium')}) {
    display: flex;
  }
`;

const buildPageRange = ({ currentPage, pageCount, onPageChanged, delta = 2 }: Props & { delta?: number }) => {
  const left = currentPage - delta;
  const right = currentPage + delta + 1;
  const range = [];
  const pages = [];
  let l;

  for (let i = 1; i <= pageCount; i++) {
    if (i === 1 || i === pageCount || (i >= left && i < right)) {
      range.push(i);
    }
  }

  for (const i of range) {
    if (l && i - l !== 1) {
      pages.push(<Ellipsis key={`ellipsis-${i.toString()}`}>&hellip;</Ellipsis>);
    }

    const handleClick = () => {
      if (typeof onPageChanged === 'function') {
        onPageChanged(i);
      }
    };

    pages.push(
      <PaginationPage key={i.toString()} selected={i === currentPage} onClick={handleClick}>
        {i}
      </PaginationPage>
    );
    l = i;
  }

  return pages;
};

export const Pagination: React.FunctionComponent<Props> = ({ currentPage, pageCount, onPageChanged }) => {
  const decrementPage = () => {
    if (typeof onPageChanged === 'function' && currentPage > 1) {
      onPageChanged(currentPage - 1);
    }
  };

  const incrementPage = () => {
    if (typeof onPageChanged === 'function' && currentPage !== pageCount) {
      onPageChanged(currentPage + 1);
    }
  };

  const pages = buildPageRange({ currentPage, pageCount, onPageChanged });
  const mobilePages = buildPageRange({ currentPage, pageCount, onPageChanged, delta: 1 });

  return (
    <PaginationContainer>
      <PaginationButton disabled={currentPage === 1} onClick={decrementPage}>
        &lt;
        <PaginationButtonLabel>Previous</PaginationButtonLabel>
      </PaginationButton>
      <PaginationPageList>{pages}</PaginationPageList>
      <MobilePaginationPageList>{mobilePages}</MobilePaginationPageList>
      <PaginationButton disabled={currentPage === pageCount} onClick={incrementPage}>
        <PaginationButtonLabel>Next</PaginationButtonLabel>
        &gt;
      </PaginationButton>
    </PaginationContainer>
  );
};

export default Pagination;
