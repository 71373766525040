import _ from 'lodash';

export const getChangedValues = (values: any, initialValues: any) => {
  // Get an array of the board types ['state', 'specialty']
  const boardIds = Object.keys(values);

  // Iterate over that array
  return boardIds.reduce((accumulator, boardId: string) => {
    if (!_.isEqual(values[boardId], initialValues[boardId])) {
      accumulator.push(values[boardId]);
    }

    // return the accumulator
    return accumulator;
  }, []);
};
