import { AnchorHTMLAttributes } from 'react';
import { LinkStyle } from '../../constants/LinkStyle';
import { css } from '../../theme';
import { Color } from '../../theme/primaryTheme';
import { baseStyles, buttonStyles } from '../Button/styles';
import { themeBreakpoint, themeColor } from './../../theme/helpers';

export interface LinkProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
  testId?: string;
  linkStyle?: LinkStyle;
  bold?: boolean;
  border?: boolean;
  truncate?: boolean;
}

export const linkStyles = {
  [LinkStyle.DualLinks]: css`
    margin: 1rem;
  `,
  [LinkStyle.TextButton]: css`
    font-weight: 700;
    border-bottom: 0;
    color: ${themeColor(Color.Secondary)};
    padding: 0;
    position: relative;
    transition: color 0.25s ease;
    display: flex;
    align-items: center;
  `,
  [LinkStyle.Button]: css`
    ${baseStyles};
    ${buttonStyles.default};

    &:hover {
      color: ${themeColor(Color.Reverse)};
      border-color: transparent;
    }
  `,
  [LinkStyle.ButtonOutline]: css`
    ${baseStyles}
    ${buttonStyles.outlined}
    padding: 10px 20px;
  `,
  [LinkStyle.AccentText]: css`
    font-weight: 700;
    color: ${themeColor(Color.Accent)};

    &:hover {
      color: ${themeColor(Color.Primary)};
    }
  `,
  [LinkStyle.NavLink]: css`
    color: ${themeColor(Color.Accent)};
    display: block;
    height: 2em;
    line-height: 2em;
    font-weight: bold;

    @media (max-width: ${themeBreakpoint('medium')}) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 7.5rem;
      border-bottom: 1px solid ${themeColor(Color.Border)};
    }
  `,
  [LinkStyle.ExternalLink]: css`
    color: ${themeColor(Color.Accent)};
    text-decoration: underline;

    &:hover {
      color: ${themeColor(Color.Secondary)};
    }
  `,
};

export const rootStyles = css`
  text-decoration: none;
  padding-bottom: 0.125rem;
  transition: color 0.25s cubic-bezier(0.215, 0.61, 0.355, 1), border-color 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);

  &:hover {
    color: ${themeColor(Color.Accent)};
    border-color: ${themeColor(Color.Accent)};
    cursor: pointer;
  }
`;
