import React from 'react';
import styled, { css } from '../../theme';
import { themeBreakpoint, themeColor } from '../../theme/helpers';
import { Color } from '../../theme/primaryTheme';
import ThemeInterface, { ColorKeys } from '../../theme/theme';

export interface TextProps {
  textStyle?: string;
  hidden?: boolean;
  className?: string;
  tag?: 'div' | 'p' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'td' | 'th' | 'span' | 'small' | 'em' | 'label' | 'strong';
  color?: ColorKeys | string;
  theme?: ThemeInterface;
  bold?: boolean;
  truncate?: boolean;
  marginTop?: boolean;
  marginBottom?: boolean;
}

export const TextStyle = {
  Eyebrow: 'eyebrow',
  Headline: 'headline',
  SubHeadline: 'subHeadline',
  ModalTitle: 'modalTitle',
  ModalBody: 'modalBody',
  FlowTitle: 'flowTitle',
  ClaimType: 'claimType',
  SectionTitle: 'sectionTitle',
  CardTitle: 'cardTitle',
  SubTitle: 'subTitle',
  FieldHelper: 'fieldHelper',
  Link: 'link',
  Medium: 'medium',
  Label: 'label',
  Value: 'value',
  Placeholder: 'placeholder',
  Tiny: 'tiny',
  Small: 'small',
  Default: 'default',
};

const textStyles = {
  eyebrow: css`
    font-size: 1.6rem;
    color: ${themeColor(Color.Gray)};
  `,
  headline: css`
    font-family: 'Publico', serif;
    font-size: 4rem;
    line-height: 1.2;
    letter-spacing: -0.5px;

    @media (max-width: ${themeBreakpoint('medium')}) {
      font-size: 1.8rem;
      line-height: 2.2rem;
    }
  `,
  subHeadline: css`
    font-size: 2.8rem;
    font-weight: 700;
    line-height: 1.2;
    padding-top: 0.5rem;

    @media (max-width: ${themeBreakpoint('medium')}) {
      font-size: 1.6rem;
      line-height: 2.1rem;
    }
  `,
  secondaryHeadline: css`
    font-size: 4.8rem;
    line-height: 1.2;
  `,
  modalTitle: css`
    font-size: 2.5rem;
    font-weight: 700;

    @media (max-width: ${themeBreakpoint('medium')}) {
      font-size: 1.6rem;
      line-height: 2.1rem;
    }
  `,
  modalBody: css`
    @media (max-width: ${themeBreakpoint('medium')}) {
      font-size: 1.4rem;
      line-height: 2.1rem;
    }
  `,
  flowTitle: css`
    font-size: 2.4rem;
    line-height: 3.6rem;
    font-weight: 700;
  `,
  claimType: css`
    font-size: 2rem;
    line-height: 3rem;
  `,
  sectionTitle: css`
    font-size: 2.4rem;
    font-weight: 700;

    @media (max-width: ${themeBreakpoint('medium')}) {
      font-size: 1.8rem;
      line-height: 2.1rem;
    }
  `,
  cardTitle: css`
    font-size: 2.2rem;
    line-height: 1.8;

    @media (max-width: ${themeBreakpoint('medium')}) {
      font-size: 1.4rem;
      line-height: 2rem;
    }
  `,
  subTitle: css`
    font-size: 1.6rem;
  `,
  fieldHelper: css`
    font-size: 1.6rem;
    padding-top: 1rem;

    a {
      color: ${themeColor(Color.Accent)};

      &:hover {
        color: ${themeColor(Color.Secondary)};
      }
    }
  `,
  link: css`
    text-decoration: none;
    border-bottom: 1px solid ${themeColor(Color.Secondary)};
    padding: 0 0 0.5rem 0;

    &:hover {
      color: ${themeColor(Color.Accent)};
      border-bottom: 1px solid currentColor;
    }
  `,
  medium: css`
    font-size: 2rem;
  `,
  label: css`
    display: inline-block;
    margin-bottom: 0.5rem;
    font-size: 1.6rem;
    color: ${themeColor(Color.Label)};

    @media (max-width: ${themeBreakpoint('medium')}) {
      color: ${themeColor(Color.GrayBlue)};
      display: block;
      font-size: 1.2rem;
      line-height: 1.5rem;
      text-transform: uppercase;
    }
  `,
  value: css`
    @media (max-width: ${themeBreakpoint('medium')}) {
      color: ${themeColor(Color.DarkNavy)};
      font-size: 1.4rem;
      line-height: 2rem;
    }
  `,
  placeholder: css`
    font-size: 1.6rem;
    color: ${themeColor(Color.Secondary)};
  `,
  tiny: css`
    font-size: 1rem;
  `,
  small: css`
    font-size: 1.4rem;
    line-height: 1.4;
  `,
  default: css``,
};

const getTextColor = ({ theme, color }: TextProps) => {
  if (theme && theme.colors) {
    if (color && theme.colors.hasOwnProperty(color)) {
      return css`
        color: ${theme.colors[color]};
      `;
    }
  }

  return '';
};

const StyledText = styled.p<TextProps>`
  ${({ textStyle = 'default' }) => textStyles[textStyle]}
  font-weight: ${({ bold }) => (bold ? '700' : '400')};
  margin-top: ${({ marginTop }) => (marginTop ? '2rem' : '0')};
  margin-bottom: ${({ marginBottom }) => (marginBottom ? '2rem' : '0')};
  ${getTextColor}
  ${({ truncate = false }) =>
    truncate &&
    css`
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    `}
`;

const Text: React.FunctionComponent<TextProps> = ({
  textStyle,
  tag,
  hidden,
  children,
  color,
  bold,
  marginTop,
  marginBottom,
  className,
  ...rest
}) => (
  <StyledText
    color={color}
    textStyle={textStyle}
    as={tag as any}
    hidden={hidden}
    bold={bold}
    marginBottom={marginBottom}
    marginTop={marginTop}
    className={className}
    {...rest}
  >
    {children}
  </StyledText>
);

export default Text;
