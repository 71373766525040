import * as styledComponents from 'styled-components';
import { ThemedStyledComponentsModule } from 'styled-components';
import GlobalStyle from './globalStyle';
import primaryTheme from './primaryTheme';
import ThemeInterface from './theme';

const {
  default: styled,
  css,
  createGlobalStyle,
  keyframes,
  ThemeProvider,
} = styledComponents as ThemedStyledComponentsModule<ThemeInterface>;

export { css, createGlobalStyle, keyframes, ThemeProvider, GlobalStyle, primaryTheme };
export default styled;
