import { CreditClaimStatus } from '../constants/ApplicationStatus';
import { ApplicationTypeCode } from '../constants/ApplicationTypeCode';

export enum PageTypes {
  AddActivities = 'AddActivities',
  OrderDetails = 'OrderDetails',
  NewApplication = 'NewApplication',
}

export const pageContent = {
  AddActivities: {
    [ApplicationTypeCode.DirectCredit]: {
      title: 'Add Activities',
      subtitle: 'Select an activity type and enter the relevant details.',
    },
    [ApplicationTypeCode.InternationalCreditConversion]: {
      title: 'Activity Details',
      subtitle: 'Enter the applicants conference information.',
    },
  },
  OrderDetails: {
    title: {
      [CreditClaimStatus.APPROVED]: 'Application Approved',
      [CreditClaimStatus.SUBMITTED]: 'Application Submitted',
      [CreditClaimStatus.REJECTED]: 'Application Rejected',
      [CreditClaimStatus.COMPLETED]: 'Application Complete',
      [CreditClaimStatus.PENDING_CUSTOMER_ACTION]: 'Application Needs Follow-Up',
      default: '', // no title by default
    },
    description: {
      [CreditClaimStatus.SUBMITTED]:
        'You’ll receive an email with a link to your award certificate once your claim has been successfully processed.',
      [CreditClaimStatus.UNSUBMITTED]: '', // no desc by default
      default: '',
    },

    transcriptFailure: 'Some activities failed to generate transcripts. Please consult an administrator.',
    claimRejected: 'This application has been rejected because all the activities are invalid.',
    paymentDetailsHeading: 'Payment Details',
    mustAddActivities: 'You must add activities before submitting your application.',
    allInvalid: 'All activities are invalid. Application cannot be submitted.',

    noFollowUp: 'Applications with activities of status "Follow-up" cannot be submitted.',

    nextButton: 'Next: Payment',
    submitButton: 'Submit Application',
    completeButton: 'Complete Application',
    cardCharge:
      'Your card will not be charged until your application has been approved. You will only be charged for activities you qualify for.',
  },
};
