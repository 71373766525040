import { findIndex } from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { CreditClaimStatus } from 'src/constants/ApplicationStatus';
import styled, { css } from 'styled-components';
import { ApplicationTypeCode } from '../../constants/ApplicationTypeCode';
import { buildRoute, Routes } from '../../constants/Routes';
import { claimDataSelector } from '../../selectors/claims';
import { ThemeColors } from '../../theme/primaryTheme';
import Icon from '../Icon';
import Text, { TextStyle } from '../Text';

export enum ApplicationStepLabel {
  ReviewProfile = 'Review Profile',
  AwardType = 'Award Type',
  AddActivities = 'Add Activities',
  OrderDetails = 'Order Details',
  ApplicationComplete = 'Application Complete',
}

type ApplicationStep = {
  label: ApplicationStepLabel;
  route?: string;
};

interface Props {
  applicationStep: ApplicationStepLabel;
}

const ApplicationProgressBar: React.FC<Props> = ({ applicationStep }) => {
  const claim = useSelector(claimDataSelector);
  const { id, applicationTypeCode, praCertificateClaimMethod } = claim;

  // Order matters in each array of ApplicationSteps
  const applicationStepsByApplicationTypeCode: { [key in ApplicationTypeCode]: ApplicationStep[] } = {
    [ApplicationTypeCode.DirectCredit]: [
      { label: ApplicationStepLabel.ReviewProfile },
      { label: ApplicationStepLabel.AddActivities, route: buildRoute(Routes.ApplicationActivities, { id }) },
      { label: ApplicationStepLabel.OrderDetails, route: buildRoute(Routes.ApplicationShow, { id }) },
      { label: ApplicationStepLabel.ApplicationComplete, route: buildRoute(Routes.ApplicationComplete, { id }) },
    ],
    [ApplicationTypeCode.InternationalCreditConversion]: [
      { label: ApplicationStepLabel.ReviewProfile },
      { label: ApplicationStepLabel.AddActivities, route: buildRoute(Routes.ApplicationActivities, { id }) },
      { label: ApplicationStepLabel.OrderDetails, route: buildRoute(Routes.ApplicationShow, { id }) },
      { label: ApplicationStepLabel.ApplicationComplete, route: buildRoute(Routes.ApplicationComplete, { id }) },
    ],
    [ApplicationTypeCode.PRA]: [
      { label: ApplicationStepLabel.ReviewProfile },
      { label: ApplicationStepLabel.AwardType, route: buildRoute(Routes.PraAwardType, { id }) },
      {
        label: ApplicationStepLabel.AddActivities,
        route: buildRoute(Routes.PraActivities, { id, claimMethod: praCertificateClaimMethod }),
      },
      { label: ApplicationStepLabel.OrderDetails, route: buildRoute(Routes.ApplicationShow, { id }) },
      { label: ApplicationStepLabel.ApplicationComplete, route: buildRoute(Routes.ApplicationComplete, { id }) },
    ],
  };

  // We default to Direct Credit steps here since we won't know the application type on the
  // first page of the flow
  const steps = applicationStepsByApplicationTypeCode[applicationTypeCode ?? ApplicationTypeCode.DirectCredit];

  const calculateStepStatus = (step: ApplicationStep) => {
    if (
      [CreditClaimStatus.SUBMITTED, CreditClaimStatus.COMPLETED].includes(claim.status) ||
      steps.indexOf(step) < findIndex(steps, ({ label }) => label === applicationStep) ||
      applicationStep === ApplicationStepLabel.ApplicationComplete
    ) {
      return 'complete';
    } else if (steps.indexOf(step) > findIndex(steps, ({ label }) => label === applicationStep)) {
      return 'incomplete';
    } else {
      return 'current';
    }
  };

  const canNavigateToStep = (step: ApplicationStep) => step.route !== undefined && calculateStepStatus(step) === 'complete';

  const completedStepIndicator = <Icon name="checkmark" fill={ThemeColors.reverse} width={13} height={10} viewBox="0 0 13 10" />;

  return steps ? (
    <ApplicationSteps data-testid="applicationSteps">
      {steps.map((step, idx) => {
        const stepStatus = calculateStepStatus(step);
        return (
          <React.Fragment key={step.label}>
            {canNavigateToStep(step) ? (
              <Link to={step.route} data-testid={`${step.label}-link`}>
                <Step>
                  <StepIndicator stepStatus={stepStatus}>{completedStepIndicator}</StepIndicator>
                  <StepLabel textStyle={TextStyle.ModalBody}>{step.label}</StepLabel>
                </Step>
              </Link>
            ) : (
              <Step>
                <StepIndicator stepStatus={stepStatus}>
                  {stepStatus === 'complete' || applicationStep === ApplicationStepLabel.ApplicationComplete
                    ? completedStepIndicator
                    : idx + 1}
                </StepIndicator>
                <StepLabel textStyle={TextStyle.ModalBody}>{step.label}</StepLabel>
              </Step>
            )}
            {idx !== steps.length - 1 && <StepSeparator />}
          </React.Fragment>
        );
      })}
    </ApplicationSteps>
  ) : null;
};

const ApplicationSteps = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 3rem;
`;

const Step = styled.div`
  display: flex;
  align-items: center;
`;

const StepLabel = styled(Text)`
  margin-left: 1rem;
`;

const stepIndicatorStyles = {
  complete: css`
    background-color: ${({ theme }) => theme.colors.confirmation};
    border: 1px solid ${({ theme }) => theme.colors.confirmation};
  `,
  current: css`
    background-color: ${({ theme }) => theme.colors.accent};
    color: ${({ theme }) => theme.colors.reverse};
    border: 1px solid ${({ theme }) => theme.colors.accent};
  `,
  incomplete: css`
    background-color: transparent;
    color: ${({ theme }) => theme.colors.secondary};
    border: 1px solid ${({ theme }) => theme.colors.secondary};
  `,
};

const StepIndicator = styled.div<{ stepStatus: 'complete' | 'current' | 'incomplete' }>`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;

  ${({ stepStatus }) => stepIndicatorStyles[stepStatus]}
`;

const StepSeparator = styled.div`
  flex: 1;
  background-color: ${({ theme }) => theme.colors.secondary};
  height: 1px;
  margin: 0 1rem;
`;

export default ApplicationProgressBar;
