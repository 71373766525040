export enum AbmsActivitySubType {
  CONTINUOUS_ASSESSMENT = 'continuous_assessment',
  FOCUSED_PRACTICE = 'focused_practice',
  SECURE_EXAM = 'secure_exam',
}

const AbmsActivitySubTypeLabels = {
  [AbmsActivitySubType.CONTINUOUS_ASSESSMENT]: 'Continuing certification assessment',
  [AbmsActivitySubType.FOCUSED_PRACTICE]: 'Focused practice designation',
  [AbmsActivitySubType.SECURE_EXAM]: 'Initial certification and continuing certification achieved by taking the secure exam (60 credits)',
};

export const directCreditAbmsActivitySubTypeOptions = (
  [AbmsActivitySubType.SECURE_EXAM, AbmsActivitySubType.CONTINUOUS_ASSESSMENT, AbmsActivitySubType.FOCUSED_PRACTICE] as const
).map((type) => {
  return {
    label: AbmsActivitySubTypeLabels[type],
    value: type,
  };
});

export const renderAbmsActivitySubTypeLabel = (abmsActivitySubType: AbmsActivitySubType): string => {
  return AbmsActivitySubTypeLabels[abmsActivitySubType];
};
