import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { ReduxStore } from '../../ducks';
import { isCreditProcessor } from '../../selectors/user';
import { getCellValue } from '../../utilities/tableHelpers';
import Cell from './Cell';
import { ColumnConfig } from './index';
import { TableCell, TableRow } from './styles';

interface Props extends RouteComponentProps {
  data: any;
  columns: any;
  isProcessor: boolean;
}

// TODO: remove withRouter dependency
const Row: React.FunctionComponent<Props> = ({ data, columns, history, isProcessor }) => {
  return (
    <TableRow loading={data.loading} error={data.error}>
      {columns.map((config: ColumnConfig, index: number) => {
        const CellComponent = config.cellComponent ? config.cellComponent : Cell;
        const value = getCellValue(config.accessor, data);
        const cellProps = {
          data,
          columns,
          key: index,
          value,
          history,
          isProcessor,
        };

        if (config.renderCell && typeof config.renderCell === 'function') {
          return config.renderCell(cellProps);
        }

        const handleClick = () => {
          if (typeof config.onCellClick === 'function') {
            config.onCellClick(cellProps);
          }
        };

        return (
          <TableCell
            style={{
              width: config.width,
              textAlign: config.align,
            }}
            data-test-id={config.id}
            hover={config.onCellClick && typeof config.onCellClick === 'function'}
            key={cellProps.key}
            onClick={handleClick}
          >
            <CellComponent {...cellProps} />
          </TableCell>
        );
      })}
    </TableRow>
  );
};

const mapStateToProps = (state: ReduxStore) => {
  return {
    isProcessor: isCreditProcessor(state),
  };
};

export default withRouter(connect(mapStateToProps)(Row));
