import React from 'react';
import { ColumnConfig } from './index';
import { TableCell } from './styles';

const TableHeaderCell = ({ column, ...rest }: { column: ColumnConfig }) => {
  const handleClick = () => {
    if (typeof column.onHeaderClick === 'function') {
      column.onHeaderClick(column);
    }
  };

  return (
    <TableCell as="th" onClick={handleClick} {...rest}>
      {column.header}
    </TableCell>
  );
};

export default TableHeaderCell;
