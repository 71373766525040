import React from 'react';
import Toggle from 'react-toggled';
import { Color } from 'src/theme/primaryTheme';
import { ThemeColors } from '../../theme/primaryTheme';
import { LearnerActivity } from '../../types';
import { momentUtc } from '../../utilities/moment';
import Button from '../Button';
import Icon from '../Icon/index';
import SummaryValue, { SummaryDisplayType } from '../SummaryValue/index';
import Text, { TextStyle } from '../Text';
import CertificateDownloadButton from '../TranscriptTable/CertificateDownloadButton';
import { CardBody, CardDetails, CardFace, CardFooter, CardHeader, CardInternal, Root, TextWithBackground } from './styles';

const ToggleableLearnerActivityCard: React.FunctionComponent<{ learnerActivity: LearnerActivity }> = ({ learnerActivity }) => {
  const { activityEndDate, courseTitle, accreditor, praCreditsClaimed, activityType, accredidationType, certificateUrl } = learnerActivity;
  const formattedActivityEndDate = momentUtc(activityEndDate).format('MM/DD/YY');
  const fullFormattedActivityEndDate = momentUtc(activityEndDate).format('MMMM D, YYYY');
  const formattedAccreditationType = accredidationType
    .replace(/AMA PRA Category (\d) Credit\(?s\)? \(TM\)/i, 'AMA PRA Category $1 Credit(s) (TM)')
    .replace(/\s+\(TM\)/, '™');

  const internalData = [
    { label: 'Accredited Provider', value: accreditor },
    { label: 'Activity Name', value: courseTitle },
    { label: 'Credit Type', value: formattedAccreditationType },
    { label: 'Activity Type', value: activityType },
    { label: 'Credits', value: praCreditsClaimed },
    { label: 'Date', value: fullFormattedActivityEndDate },
  ];

  return (
    <Toggle>
      {({ on, toggle }) => (
        <Root>
          <CardFace>
            <CardHeader onClick={toggle}>
              <CardDetails>
                <Text bold={true} color={Color.GrayBlue} textStyle={TextStyle.Small}>
                  {formattedActivityEndDate}
                </Text>
                <Button buttonStyle="toggle" onClick={toggle}>
                  <Icon
                    name={on ? 'angleArrowUp' : 'angleArrowDown'}
                    fill={ThemeColors[Color.Accent]}
                    width={16}
                    height={10}
                    viewBox="0 0 16 10"
                  />
                </Button>
              </CardDetails>
            </CardHeader>
            <CardBody onClick={toggle}>
              <Text textStyle={TextStyle.CardTitle}>{accreditor}</Text>
              <Text bold={true} truncate={true} textStyle={TextStyle.CardTitle}>
                {courseTitle}
              </Text>
            </CardBody>
            <CardFooter>
              <CardDetails>
                <TextWithBackground bold={true} color={Color.GrayBlue} textStyle={TextStyle.Small}>
                  {`${praCreditsClaimed} Credits`}
                </TextWithBackground>
                <CertificateDownloadButton
                  data={learnerActivity}
                  value={certificateUrl}
                  buttonProps={{
                    buttonText: 'Download PDF',
                    iconWidth: 15,
                  }}
                />
              </CardDetails>
            </CardFooter>
          </CardFace>
          {on && (
            <CardInternal>
              {internalData.map(({ label, value }) =>
                label === 'Credit Type' ? (
                  <SummaryValue key={label} bold={true} tag="em" displayType={SummaryDisplayType.Mobile} label={label} value={value} />
                ) : (
                  <SummaryValue key={label} bold={true} displayType={SummaryDisplayType.Mobile} label={label} value={value} />
                )
              )}
            </CardInternal>
          )}
        </Root>
      )}
    </Toggle>
  );
};

export default ToggleableLearnerActivityCard;
