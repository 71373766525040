import React, { ChangeEventHandler } from 'react';
import CheckableInput from '../../../CheckableInput';
import { TextProps } from '../../../Text';

export interface Props {
  name: string;
  className?: string;
  title?: string;
  description?: string | React.ReactNode;
  onChange: ChangeEventHandler<HTMLInputElement>;
  value?: boolean;
  checked?: boolean;
  textProps?: TextProps;
  disabled?: boolean;
  hasError?: boolean;
}

const CheckBox: React.FunctionComponent<Props> = ({
  onChange,
  value,
  checked,
  disabled = false,
  hasError = false,
  title,
  description,
  name,
  textProps,
  className,
}) => {
  return (
    <CheckableInput
      type="checkbox"
      value={value ? name : ''}
      checked={checked || value || false}
      title={title}
      description={description}
      disabled={disabled}
      hasError={hasError}
      name={name}
      onChange={onChange}
      textProps={textProps}
      className={className}
    />
  );
};

CheckBox.displayName = 'Checkbox';

export default CheckBox;
