import React from 'react';
import { Pra } from '../../types';
import { FileDownload } from '../FileDownload';
import { DownloadButtonProps } from '../FileDownload/FileDownload';

const rollbarErrorText = (data: Pra) => `Error response generating PDF for PRA ID ${data.id}`;

const PraCertificateDownloadButton: React.FunctionComponent<{ data: Pra; value?: string; buttonProps?: DownloadButtonProps }> = (props) => (
  <FileDownload {...props} iconName="download" downloadedFileName="PRA_Certificate.pdf" rollbarErrorText={rollbarErrorText} />
);

export default PraCertificateDownloadButton;
