/**
 * The `code` property is not necessarily the ISO code. These are codes from Advantage.
 */
export const countryOptions = [
  {
    label: 'United States of America',
    value: 'United States of America',
    code: 'USA', // Advantage uses a blank string for USA, but we need to use a value for the form elements to work.
  },
  {
    label: 'Afghanistan',
    value: 'Afghanistan',
    code: 'AFG',
  },
  {
    label: 'Åland Islands',
    value: 'Åland Islands',
    code: 'ALA',
  },
  {
    label: 'Albania',
    value: 'Albania',
    code: 'ALB',
  },
  {
    label: 'Algeria',
    value: 'Algeria',
    code: 'ALG',
  },
  {
    label: 'American Samoa',
    value: 'American Samoa',
    code: 'American Samoa',
  },
  {
    label: 'Andorra',
    value: 'Andorra',
    code: 'AND',
  },
  {
    label: 'Angola',
    value: 'Angola',
    code: 'ANG',
  },
  {
    label: 'Anguilla',
    value: 'Anguilla',
    code: 'ANH',
  },
  {
    label: 'Antarctica',
    value: 'Antarctica',
    code: 'ATA',
  },
  {
    label: 'Antigua and Barbuda',
    value: 'Antigua and Barbuda',
    code: 'AAB',
  },
  {
    label: 'Argentina',
    value: 'Argentina',
    code: 'ARG',
  },
  {
    label: 'Armenia',
    value: 'Armenia',
    code: 'ARM',
  },
  {
    label: 'Aruba',
    value: 'Aruba',
    code: 'ARU',
  },
  {
    label: 'Australia',
    value: 'Australia',
    code: 'AUS',
  },
  {
    label: 'Austria',
    value: 'Austria',
    code: 'AST',
  },
  {
    label: 'Azerbaijan',
    value: 'Azerbaijan',
    code: 'AZR',
  },
  {
    label: 'Bahamas',
    value: 'Bahamas',
    code: 'BHM',
  },
  {
    label: 'Bahrain',
    value: 'Bahrain',
    code: 'BAH',
  },
  {
    label: 'Bangladesh',
    value: 'Bangladesh',
    code: 'BAN',
  },
  {
    label: 'Barbados',
    value: 'Barbados',
    code: 'BAR',
  },
  {
    label: 'Belarus',
    value: 'Belarus',
    code: 'BEL',
  },
  {
    label: 'Belgium',
    value: 'Belgium',
    code: 'BLG',
  },
  {
    label: 'Belize',
    value: 'Belize',
    code: 'BLZ',
  },
  {
    label: 'Benin',
    value: 'Benin',
    code: 'BEN',
  },
  {
    label: 'Bermuda',
    value: 'Bermuda',
    code: 'BER',
  },
  {
    label: 'Bhutan',
    value: 'Bhutan',
    code: 'BHU',
  },
  {
    label: 'Bolivia (Plurinational State of)',
    value: 'Bolivia (Plurinational State of)',
    code: 'BOL',
  },
  {
    label: 'Bonaire, Sint Eustatius and Saba',
    value: 'Bonaire, Sint Eustatius and Saba',
    code: 'Bonaire, Sint Eustatius and Saba',
  },
  {
    label: 'Bosnia and Herzegovina',
    value: 'Bosnia and Herzegovina',
    code: 'Bosnia and Herzegovina',
  },
  {
    label: 'Botswana',
    value: 'Botswana',
    code: 'BOT',
  },
  {
    label: 'Bouvet Island',
    value: 'Bouvet Island',
    code: 'BOU',
  },
  {
    label: 'Brazil',
    value: 'Brazil',
    code: 'BRA',
  },
  {
    label: 'British Indian Ocean Territory',
    value: 'British Indian Ocean Territory',
    code: 'British Indian Ocean Territory',
  },
  {
    label: 'Brunei Darussalam',
    value: 'Brunei Darussalam',
    code: 'BRU',
  },
  {
    label: 'Bulgaria',
    value: 'Bulgaria',
    code: 'BUL',
  },
  {
    label: 'Burkina Faso',
    value: 'Burkina Faso',
    code: 'BFA',
  },
  {
    label: 'Burundi',
    value: 'Burundi',
    code: 'BRN',
  },
  {
    label: 'Cabo Verde',
    value: 'Cabo Verde',
    code: 'CVE',
  },
  {
    label: 'Cambodia',
    value: 'Cambodia',
    code: 'KHM',
  },
  {
    label: 'Cameroon',
    value: 'Cameroon',
    code: 'CAM',
  },
  {
    label: 'Canada',
    value: 'Canada',
    code: 'CAN',
  },
  {
    label: 'Cayman Islands',
    value: 'Cayman Islands',
    code: 'CAY',
  },
  {
    label: 'Central African Republic',
    value: 'Central African Republic',
    code: 'CAR',
  },
  {
    label: 'Chad',
    value: 'Chad',
    code: 'CHA',
  },
  {
    label: 'Chile',
    value: 'Chile',
    code: 'CHI',
  },
  {
    label: 'China',
    value: 'China',
    code: 'CNA',
  },
  {
    label: 'Christmas Island',
    value: 'Christmas Island',
    code: 'Christmas Island',
  },
  {
    label: 'Cocos (Keeling) Islands',
    value: 'Cocos (Keeling) Islands',
    code: 'CCK',
  },
  {
    label: 'Colombia',
    value: 'Colombia',
    code: 'COL',
  },
  {
    label: 'Comoros',
    value: 'Comoros',
    code: 'COM',
  },
  {
    label: 'Congo, Democratic Republic of the',
    value: 'Congo, Democratic Republic of the',
    code: 'CON',
  },
  {
    label: 'Cook Islands',
    value: 'Cook Islands',
    code: 'CKI',
  },
  {
    label: 'Costa Rica',
    value: 'Costa Rica',
    code: 'CRI',
  },
  {
    label: "Côte d'Ivoire",
    value: "Côte d'Ivoire",
    code: 'IVO',
  },
  {
    label: 'Croatia',
    value: 'Croatia',
    code: 'CRO',
  },
  {
    label: 'Cuba',
    value: 'Cuba',
    code: 'CUB',
  },
  {
    label: 'Curaçao',
    value: 'Curaçao',
    code: 'CUR',
  },
  {
    label: 'Cyprus',
    value: 'Cyprus',
    code: 'CYP',
  },
  {
    label: 'Czechia',
    value: 'Czechia',
    code: 'CZR',
  },
  {
    label: 'Denmark',
    value: 'Denmark',
    code: 'DEN',
  },
  {
    label: 'Djibouti',
    value: 'Djibouti',
    code: 'DJI',
  },
  {
    label: 'Dominica',
    value: 'Dominica',
    code: 'DMI',
  },
  {
    label: 'Dominican Republic',
    value: 'Dominican Republic',
    code: 'DOM',
  },
  {
    label: 'Ecuador',
    value: 'Ecuador',
    code: 'ECU',
  },
  {
    label: 'Egypt',
    value: 'Egypt',
    code: 'EGY',
  },
  {
    label: 'El Salvador',
    value: 'El Salvador',
    code: 'ELS',
  },
  {
    label: 'Equatorial Guinea',
    value: 'Equatorial Guinea',
    code: 'EGU',
  },
  {
    label: 'Eritrea',
    value: 'Eritrea',
    code: 'ERI',
  },
  {
    label: 'Estonia',
    value: 'Estonia',
    code: 'EST',
  },
  {
    label: 'Eswatini',
    value: 'Eswatini',
    code: 'Eswatini',
  },
  {
    label: 'Ethiopia',
    value: 'Ethiopia',
    code: 'ETH',
  },
  {
    label: 'Falkland Islands (Malvinas)',
    value: 'Falkland Islands (Malvinas)',
    code: 'FAL',
  },
  {
    label: 'Faroe Islands',
    value: 'Faroe Islands',
    code: 'FAR',
  },
  {
    label: 'Fiji',
    value: 'Fiji',
    code: 'FIJ',
  },
  {
    label: 'Finland',
    value: 'Finland',
    code: 'FIN',
  },
  {
    label: 'France',
    value: 'France',
    code: 'FRA',
  },
  {
    label: 'French Guiana',
    value: 'French Guiana',
    code: 'FGU',
  },
  {
    label: 'French Polynesia',
    value: 'French Polynesia',
    code: 'FPO',
  },
  {
    label: 'French Southern Territories',
    value: 'French Southern Territories',
    code: 'ATF',
  },
  {
    label: 'Gabon',
    value: 'Gabon',
    code: 'GAB',
  },
  {
    label: 'Gambia',
    value: 'Gambia',
    code: 'GAM',
  },
  {
    label: 'Georgia',
    value: 'Georgia',
    code: 'GEO',
  },
  {
    label: 'Germany',
    value: 'Germany',
    code: 'GER',
  },
  {
    label: 'Ghana',
    value: 'Ghana',
    code: 'GHA',
  },
  {
    label: 'Gibraltar',
    value: 'Gibraltar',
    code: 'GIB',
  },
  {
    label: 'Greece',
    value: 'Greece',
    code: 'GRC',
  },
  {
    label: 'Greenland',
    value: 'Greenland',
    code: 'GRN',
  },
  {
    label: 'Grenada',
    value: 'Grenada',
    code: 'GRA',
  },
  {
    label: 'Guadeloupe',
    value: 'Guadeloupe',
    code: 'GDP',
  },
  {
    label: 'Guam',
    value: 'Guam',
    code: 'Guam',
  },
  {
    label: 'Guatemala',
    value: 'Guatemala',
    code: 'GUA',
  },
  {
    label: 'Guernsey',
    value: 'Guernsey',
    code: 'Guernsey',
  },
  {
    label: 'Guinea',
    value: 'Guinea',
    code: 'GUI',
  },
  {
    label: 'Guinea-Bissau',
    value: 'Guinea-Bissau',
    code: 'GBI',
  },
  {
    label: 'Guyana',
    value: 'Guyana',
    code: 'GUY',
  },
  {
    label: 'Haiti',
    value: 'Haiti',
    code: 'HAI',
  },
  {
    label: 'Heard Island and McDonald Islands',
    value: 'Heard Island and McDonald Islands',
    code: 'HMD',
  },
  {
    label: 'Holy See',
    value: 'Holy See',
    code: 'Holy See',
  },
  {
    label: 'Honduras',
    value: 'Honduras',
    code: 'HON',
  },
  {
    label: 'Hong Kong',
    value: 'Hong Kong',
    code: 'HK',
  },
  {
    label: 'Hungary',
    value: 'Hungary',
    code: 'HUN',
  },
  {
    label: 'Iceland',
    value: 'Iceland',
    code: 'ICE',
  },
  {
    label: 'India',
    value: 'India',
    code: 'IND',
  },
  {
    label: 'Indonesia',
    value: 'Indonesia',
    code: 'IDN',
  },
  {
    label: 'Iran (Islamic Republic of)',
    value: 'Iran (Islamic Republic of)',
    code: 'IRA',
  },
  {
    label: 'Iraq',
    value: 'Iraq',
    code: 'IRQ',
  },
  {
    label: 'Ireland',
    value: 'Ireland',
    code: 'IRE',
  },
  {
    label: 'Isle of Man',
    value: 'Isle of Man',
    code: 'Isle of Man',
  },
  {
    label: 'Israel',
    value: 'Israel',
    code: 'ISR',
  },
  {
    label: 'Italy',
    value: 'Italy',
    code: 'ITA',
  },
  {
    label: 'Jamaica',
    value: 'Jamaica',
    code: 'JAM',
  },
  {
    label: 'Japan',
    value: 'Japan',
    code: 'JAP',
  },
  {
    label: 'Jersey',
    value: 'Jersey',
    code: 'Jersey',
  },
  {
    label: 'Jordan',
    value: 'Jordan',
    code: 'JOR',
  },
  {
    label: 'Kazakhstan',
    value: 'Kazakhstan',
    code: 'KAZ',
  },
  {
    label: 'Kenya',
    value: 'Kenya',
    code: 'KEN',
  },
  {
    label: 'Kiribati',
    value: 'Kiribati',
    code: 'KIR',
  },
  {
    label: "Korea (Democratic People's Republic of)",
    value: "Korea (Democratic People's Republic of)",
    code: 'KOR',
  },
  {
    label: 'Kuwait',
    value: 'Kuwait',
    code: 'KUW',
  },
  {
    label: 'Kyrgyzstan',
    value: 'Kyrgyzstan',
    code: 'KYR',
  },
  {
    label: "Lao People's Democratic Republic",
    value: "Lao People's Democratic Republic",
    code: "Lao People's Democratic Republic",
  },
  {
    label: 'Latvia',
    value: 'Latvia',
    code: 'LAT',
  },
  {
    label: 'Lebanon',
    value: 'Lebanon',
    code: 'LEB',
  },
  {
    label: 'Lesotho',
    value: 'Lesotho',
    code: 'LES',
  },
  {
    label: 'Liberia',
    value: 'Liberia',
    code: 'LIB',
  },
  {
    label: 'Libya',
    value: 'Libya',
    code: 'Libya',
  },
  {
    label: 'Liechtenstein',
    value: 'Liechtenstein',
    code: 'LIE',
  },
  {
    label: 'Lithuania',
    value: 'Lithuania',
    code: 'LIT',
  },
  {
    label: 'Luxembourg',
    value: 'Luxembourg',
    code: 'LUX',
  },
  {
    label: 'Macao',
    value: 'Macao',
    code: 'MAC',
  },
  {
    label: 'Madagascar',
    value: 'Madagascar',
    code: 'MAD',
  },
  {
    label: 'Malawi',
    value: 'Malawi',
    code: 'MAL',
  },
  {
    label: 'Malaysia',
    value: 'Malaysia',
    code: 'MLY',
  },
  {
    label: 'Maldives',
    value: 'Maldives',
    code: 'MAV',
  },
  {
    label: 'Mali',
    value: 'Mali',
    code: 'MLI',
  },
  {
    label: 'Malta',
    value: 'Malta',
    code: 'MLT',
  },
  {
    label: 'Marshall Islands',
    value: 'Marshall Islands',
    code: 'MIS',
  },
  {
    label: 'Martinique',
    value: 'Martinique',
    code: 'MAR',
  },
  {
    label: 'Mauritania',
    value: 'Mauritania',
    code: 'MAU',
  },
  {
    label: 'Mauritius',
    value: 'Mauritius',
    code: 'MRS',
  },
  {
    label: 'Mayotte',
    value: 'Mayotte',
    code: 'MAO',
  },
  {
    label: 'Mexico',
    value: 'Mexico',
    code: 'MEX',
  },
  {
    label: 'Micronesia (Federated States of)',
    value: 'Micronesia (Federated States of)',
    code: 'MIC',
  },
  {
    label: 'Moldova, Republic of',
    value: 'Moldova, Republic of',
    code: 'MOL',
  },
  {
    label: 'Monaco',
    value: 'Monaco',
    code: 'MON',
  },
  {
    label: 'Mongolia',
    value: 'Mongolia',
    code: 'MGA',
  },
  {
    label: 'Montenegro',
    value: 'Montenegro',
    code: 'MNT',
  },
  {
    label: 'Montserrat',
    value: 'Montserrat',
    code: 'MOT',
  },
  {
    label: 'Morocco',
    value: 'Morocco',
    code: 'MOR',
  },
  {
    label: 'Mozambique',
    value: 'Mozambique',
    code: 'MOZ',
  },
  {
    label: 'Myanmar',
    value: 'Myanmar',
    code: 'Myanmar',
  },
  {
    label: 'Namibia',
    value: 'Namibia',
    code: 'NAM',
  },
  {
    label: 'Nauru',
    value: 'Nauru',
    code: 'NAU',
  },
  {
    label: 'Nepal',
    value: 'Nepal',
    code: 'NEP',
  },
  {
    label: 'Netherlands',
    value: 'Netherlands',
    code: 'NET',
  },
  {
    label: 'New Caledonia',
    value: 'New Caledonia',
    code: 'NCA',
  },
  {
    label: 'New Zealand',
    value: 'New Zealand',
    code: 'NZE',
  },
  {
    label: 'Nicaragua',
    value: 'Nicaragua',
    code: 'NIC',
  },
  {
    label: 'Niger',
    value: 'Niger',
    code: 'NGR',
  },
  {
    label: 'Nigeria',
    value: 'Nigeria',
    code: 'NIG',
  },
  {
    label: 'Niue',
    value: 'Niue',
    code: 'NIU',
  },
  {
    label: 'Norfolk Island',
    value: 'Norfolk Island',
    code: 'NFK',
  },
  {
    label: 'North Macedonia',
    value: 'North Macedonia',
    code: 'MNA',
  },
  {
    label: 'Northern Mariana Islands',
    value: 'Northern Mariana Islands',
    code: 'Northern Mariana Islands',
  },
  {
    label: 'Norway',
    value: 'Norway',
    code: 'NOR',
  },
  {
    label: 'Oman',
    value: 'Oman',
    code: 'OMA',
  },
  {
    label: 'Pakistan',
    value: 'Pakistan',
    code: 'PAK',
  },
  {
    label: 'Palau',
    value: 'Palau',
    code: 'PAL',
  },
  {
    label: 'Palestine, State of',
    value: 'Palestine, State of',
    code: 'Palestine, State of',
  },
  {
    label: 'Panama',
    value: 'Panama',
    code: 'PAN',
  },
  {
    label: 'Papua New Guinea',
    value: 'Papua New Guinea',
    code: 'PNG',
  },
  {
    label: 'Paraguay',
    value: 'Paraguay',
    code: 'PAR',
  },
  {
    label: 'Peru',
    value: 'Peru',
    code: 'PER',
  },
  {
    label: 'Philippines',
    value: 'Philippines',
    code: 'PHI',
  },
  {
    label: 'Pitcairn',
    value: 'Pitcairn',
    code: 'Pitcairn',
  },
  {
    label: 'Poland',
    value: 'Poland',
    code: 'POL',
  },
  {
    label: 'Portugal',
    value: 'Portugal',
    code: 'POR',
  },
  {
    label: 'Puerto Rico',
    value: 'Puerto Rico',
    code: 'Puerto Rico',
  },
  {
    label: 'Qatar',
    value: 'Qatar',
    code: 'QAT',
  },
  {
    label: 'Réunion',
    value: 'Réunion',
    code: 'REU',
  },
  {
    label: 'Romania',
    value: 'Romania',
    code: 'ROM',
  },
  {
    label: 'Russian Federation',
    value: 'Russian Federation',
    code: 'RUS',
  },
  {
    label: 'Rwanda',
    value: 'Rwanda',
    code: 'RWA',
  },
  {
    label: 'Saint Barthélemy',
    value: 'Saint Barthélemy',
    code: 'Saint Barthélemy',
  },
  {
    label: 'Saint Helena, Ascension and Tristan da Cunha',
    value: 'Saint Helena, Ascension and Tristan da Cunha',
    code: 'SHE',
  },
  {
    label: 'Saint Kitts and Nevis',
    value: 'Saint Kitts and Nevis',
    code: 'SKN',
  },
  {
    label: 'Saint Lucia',
    value: 'Saint Lucia',
    code: 'SLU',
  },
  {
    label: 'Saint Martin (French part)',
    value: 'Saint Martin (French part)',
    code: 'Saint Martin (French part)',
  },
  {
    label: 'Saint Pierre and Miquelon',
    value: 'Saint Pierre and Miquelon',
    code: 'SPM',
  },
  {
    label: 'Saint Vincent and the Grenadines',
    value: 'Saint Vincent and the Grenadines',
    code: 'SVG',
  },
  {
    label: 'Samoa',
    value: 'Samoa',
    code: 'SAM',
  },
  {
    label: 'San Marino',
    value: 'San Marino',
    code: 'SMO',
  },
  {
    label: 'Sao Tome and Principe',
    value: 'Sao Tome and Principe',
    code: 'PRI',
  },
  {
    label: 'Saudi Arabia',
    value: 'Saudi Arabia',
    code: 'SAU',
  },
  {
    label: 'Senegal',
    value: 'Senegal',
    code: 'SEN',
  },
  {
    label: 'Serbia',
    value: 'Serbia',
    code: 'SER',
  },
  {
    label: 'Seychelles',
    value: 'Seychelles',
    code: 'SEY',
  },
  {
    label: 'Sierra Leone',
    value: 'Sierra Leone',
    code: 'SIE',
  },
  {
    label: 'Singapore',
    value: 'Singapore',
    code: 'SIN',
  },
  {
    label: 'Sint Maarten (Dutch part)',
    value: 'Sint Maarten (Dutch part)',
    code: 'SXM',
  },
  {
    label: 'Slovakia',
    value: 'Slovakia',
    code: 'SLK',
  },
  {
    label: 'Slovenia',
    value: 'Slovenia',
    code: 'SLV',
  },
  {
    label: 'Solomon Islands',
    value: 'Solomon Islands',
    code: 'SLI',
  },
  {
    label: 'Somalia',
    value: 'Somalia',
    code: 'SOM',
  },
  {
    label: 'South Africa',
    value: 'South Africa',
    code: 'SAF',
  },
  {
    label: 'South Georgia and the South Sandwich Islands',
    value: 'South Georgia and the South Sandwich Islands',
    code: 'SGS',
  },
  {
    label: 'Spain',
    value: 'Spain',
    code: 'SPA',
  },
  {
    label: 'Sri Lanka',
    value: 'Sri Lanka',
    code: 'SRI',
  },
  {
    label: 'Sudan',
    value: 'Sudan',
    code: 'SUD',
  },
  {
    label: 'Surilabel',
    value: 'Surilabel',
    code: 'Surilabel',
  },
  {
    label: 'Svalbard and Jan Mayen',
    value: 'Svalbard and Jan Mayen',
    code: 'JAN',
  },
  {
    label: 'Sweden',
    value: 'Sweden',
    code: 'SWE',
  },
  {
    label: 'Switzerland',
    value: 'Switzerland',
    code: 'SWI',
  },
  {
    label: 'Syrian Arab Republic',
    value: 'Syrian Arab Republic',
    code: 'SYR',
  },
  {
    label: 'Taiwan, Province of China',
    value: 'Taiwan, Province of China',
    code: 'TAI',
  },
  {
    label: 'Tajikistan',
    value: 'Tajikistan',
    code: 'TAJ',
  },
  {
    label: 'Tanzania, United Republic of',
    value: 'Tanzania, United Republic of',
    code: 'TAN',
  },
  {
    label: 'Thailand',
    value: 'Thailand',
    code: 'THA',
  },
  {
    label: 'Timor-Leste',
    value: 'Timor-Leste',
    code: 'TLE',
  },
  {
    label: 'Togo',
    value: 'Togo',
    code: 'TOG',
  },
  {
    label: 'Tokelau',
    value: 'Tokelau',
    code: 'TOK',
  },
  {
    label: 'Tonga',
    value: 'Tonga',
    code: 'TNG',
  },
  {
    label: 'Trinidad and Tobago',
    value: 'Trinidad and Tobago',
    code: 'TRI',
  },
  {
    label: 'Tunisia',
    value: 'Tunisia',
    code: 'TUN',
  },
  {
    label: 'Turkey',
    value: 'Turkey',
    code: 'TUR',
  },
  {
    label: 'Turkmenistan',
    value: 'Turkmenistan',
    code: 'TKN',
  },
  {
    label: 'Turks and Caicos Islands',
    value: 'Turks and Caicos Islands',
    code: 'TCI',
  },
  {
    label: 'Tuvalu',
    value: 'Tuvalu',
    code: 'TUV',
  },
  {
    label: 'Uganda',
    value: 'Uganda',
    code: 'UGA',
  },
  {
    label: 'Ukraine',
    value: 'Ukraine',
    code: 'UKR',
  },
  {
    label: 'United Arab Emirates',
    value: 'United Arab Emirates',
    code: 'UAE',
  },
  {
    label: 'United Kingdom of Great Britain and Northern Ireland',
    value: 'United Kingdom of Great Britain and Northern Ireland',
    code: 'UK',
  },
  {
    label: 'United States Minor Outlying Islands',
    value: 'United States Minor Outlying Islands',
    code: 'UMI',
  },
  {
    label: 'Uruguay',
    value: 'Uruguay',
    code: 'URU',
  },
  {
    label: 'Uzbekistan',
    value: 'Uzbekistan',
    code: 'UZB',
  },
  {
    label: 'Vanuatu',
    value: 'Vanuatu',
    code: 'VAN',
  },
  {
    label: 'Venezuela (Bolivarian Republic of)',
    value: 'Venezuela (Bolivarian Republic of)',
    code: 'VEN',
  },
  {
    label: 'Viet Nam',
    value: 'Viet Nam',
    code: 'VIE',
  },
  {
    label: 'Virgin Islands (British)',
    value: 'Virgin Islands (British)',
    code: 'Virgin Islands (British)',
  },
  {
    label: 'Virgin Islands (U.S.)',
    value: 'Virgin Islands (U.S.)',
    code: 'Virgin Islands (U.S.)',
  },
  {
    label: 'Wallis and Futuna',
    value: 'Wallis and Futuna',
    code: 'WLF',
  },
  {
    label: 'Western Sahara',
    value: 'Western Sahara',
    code: 'ESH',
  },
  {
    label: 'Yemen',
    value: 'Yemen',
    code: 'YEM',
  },
  {
    label: 'Zambia',
    value: 'Zambia',
    code: 'ZAM',
  },
  {
    label: 'Zimbabwe',
    value: 'Zimbabwe',
    code: 'ZIM',
  },
];
