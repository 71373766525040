import { connect as formikConnect, FormikContextType } from 'formik';
import _ from 'lodash';
import React from 'react';
import styled from 'src/theme';
import { FieldValue } from '../../../components/FieldValue';
import { FlexTableGroup, FlexTableHeading, FlexTableLeft, FlexTableRight, FlexTableRows } from '../../../components/FlexTable';
import Label from '../../../components/Label';
import Text from '../../../components/Text';
import { themeBreakpoint } from '../../../theme/helpers';
import { Board } from '../../../types';
import { getChangedValues } from '../../../utilities/formHelpers';
import { labels } from './labels';

const ModalGroup = styled.div`
  @media (min-width: ${themeBreakpoint('large')}) {
    & + & {
      margin-top: 3rem;
    }
  }
`;

const StyledFlexTableRows = styled(FlexTableRows)`
  @media (max-width: ${themeBreakpoint('large')}) {
    :last-child {
      border-bottom: none;
    }
  }
`;

const StyledFlexTableHeading = styled(FlexTableHeading)`
  display: none;

  @media (min-width: ${themeBreakpoint('large')}) {
    margin-bottom: '1rem';
  }
`;

const TableWrapper = styled.div`
  width: 180px;

  @media (max-width: ${themeBreakpoint('large')}) {
    width: 100%;
    display: flex;
    margin-bottom: 2rem;
  }
`;

const CreditLabel = styled(Text)`
  @media (max-width: ${themeBreakpoint('large')}) {
    display: none;
  }
`;

const CreditQuestion = styled(Text)`
  flex: 1 9;
  margin-left: 0.2rem;

  @media (min-width: ${themeBreakpoint('large')}) {
    display: none;
  }
`;

const ModalBody = ({ formik }: { formik: FormikContextType<any> }) => {
  const { values, initialValues } = formik;
  const changedValues = getChangedValues(values, initialValues);
  const stateBoards = changedValues.filter((board: Board) => board.isStateBoard);
  const specialtyBoards = changedValues.filter((board: Board) => !board.isStateBoard);

  const sections = [
    {
      id: 'state',
      ...labels.state,
      boards: stateBoards,
    },
    {
      id: 'specialty',
      ...labels.specialty,
      boards: specialtyBoards,
    },
  ];

  /**
   * Determines which ID to show for a user to confirm.
   * If the board prefers aimsSuppliedId, try showing that before
   * falling back to userSuppliedId. Vice versa, if the board
   * prefers userSuppliedId, show that before falling back to
   * aimsSuppliedId.
   * @param board
   * @return string id to show, or blank if no ids exist
   */
  const boardIdValueToConfirm = (board: Board) => {
    if (board.preferredUniqueId === 'aimsSuppliedId') {
      return !_.isEmpty(board.aimsSuppliedId) ? board.aimsSuppliedId : board.userSuppliedId;
    } else {
      return !_.isEmpty(board.userSuppliedId) ? board.userSuppliedId : board.aimsSuppliedId;
    }
  };

  return (
    <>
      {sections.map((section: any) => {
        if (section.boards && section.boards.length > 0) {
          return (
            <ModalGroup key={`confirm_${section.id}`}>
              <StyledFlexTableHeading>
                <FlexTableLeft>
                  <Text textStyle="small" color="secondary" bold={true}>
                    {section.title}
                  </Text>
                </FlexTableLeft>
                <FlexTableRight>
                  <CreditLabel textStyle="small" color="secondary" bold={true}>
                    Send CME?
                  </CreditLabel>
                </FlexTableRight>
              </StyledFlexTableHeading>
              {section.boards.map((board: Board) => {
                return (
                  <StyledFlexTableRows key={board.id}>
                    <FlexTableLeft>
                      <Label bold={true} tag="span">
                        {board.name}
                      </Label>
                    </FlexTableLeft>
                    <FlexTableRight>
                      <FlexTableGroup alignItems="center">
                        <TableWrapper>
                          <CreditQuestion textStyle="small" color="secondary" bold={true}>
                            Send Credit Data?
                          </CreditQuestion>
                          <Label bold={true} tag="span" color={board.optInToSendToOrganization ? 'accent' : 'secondary'}>
                            {board.optInToSendToOrganization ? 'Yes' : 'No'}
                          </Label>
                        </TableWrapper>
                        {boardIdValueToConfirm(board) ? <FieldValue>{boardIdValueToConfirm(board)}</FieldValue> : 'None'}
                      </FlexTableGroup>
                    </FlexTableRight>
                  </StyledFlexTableRows>
                );
              })}
            </ModalGroup>
          );
        }
        return null;
      })}
    </>
  );
};

export default formikConnect<any>(ModalBody);
