import React from 'react';
import { activityContent } from 'src/content/activities';
import { ActivityType } from '../../constants/ActivityType';
import { Credits } from '../../constants/Credits';
import { Color } from '../../theme/primaryTheme';
import Field from '../Field';
import DatePickerField from '../Field/DatePickerField';
import { FieldColumn, FieldRow } from '../FieldRow';
import ProofDocumentUpload from '../ProofDocumentUpload';
import Text, { TextStyle } from '../Text';
import { GenericActivityProps } from './index';
import ProcessorFields from './ProcessorFields';
import Requirements from './Requirements';
import { TotalCreditsRow } from './styles';

const MedDegreeFields: React.FunctionComponent<GenericActivityProps> = ({ baseName, values, isProcessor, claimId }) => {
  const { description, fields, footer } = activityContent[ActivityType.Medical];

  return (
    <>
      <Requirements content={description} />
      <FieldRow>
        <FieldColumn grow={1}>
          <Field name={`${baseName}.school`} required={true} {...fields.school} />
        </FieldColumn>
      </FieldRow>
      <FieldRow>
        <FieldColumn grow={1}>
          <Field name={`${baseName}.program`} required={true} {...fields.program} />
        </FieldColumn>
      </FieldRow>
      <FieldRow>
        <FieldColumn>
          <DatePickerField name={`${baseName}.graduationDate`} required={true} {...fields.graduationDate} />
        </FieldColumn>
      </FieldRow>
      <FieldRow>
        <FieldColumn grow={1}>
          <ProofDocumentUpload
            name={`${baseName}.creditActivityAttachments`}
            required={!isProcessor}
            claimId={claimId}
            {...fields.creditActivityAttachments}
          />
        </FieldColumn>
      </FieldRow>
      <TotalCreditsRow>
        <FieldColumn>
          <Text tag="span" textStyle={TextStyle.Medium} bold={true} color={Color.Label}>
            {footer.total}
          </Text>
          &ensp;
          <Text tag="span" textStyle={TextStyle.Medium} color={Color.Label}>
            {Credits[ActivityType.Medical]}
          </Text>
        </FieldColumn>
      </TotalCreditsRow>

      {isProcessor && <ProcessorFields baseName={baseName} values={values} />}
    </>
  );
};

export default MedDegreeFields;
