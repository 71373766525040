import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { ModalType } from 'src/constants/ModalType';
import { fetchFeatureFlags } from 'src/ducks/featureFlags';
import { showModal } from 'src/ducks/modal';
import { accessCodeEmailDataSelector, accessCodeEmailErrorSelector, accessCodeEmailLoadingSelector } from 'src/selectors/claimAccount';
import styled from 'src/theme';
import { themeBreakpoint } from 'src/theme/helpers';
import { ReduxStore } from '../../ducks';
import { changeTranscriptPage, ChangeTranscriptPageActionCreator } from '../../ducks/transcript';
import { PageSection, PageSectionTitle } from '../../layouts/PageStructure';
import { claimMissingCreditsSelector } from '../../selectors/featureFlags';
import { transcriptPageSelector, transcriptPageSizeSelector, transcriptTotalSelector } from '../../selectors/transcripts';
import { isCreditProcessor, selectUserId } from '../../selectors/user';
import FetchBoards from '../FetchBoards/FetchBoards';
import { FetchPras } from '../FetchPras';
import { FetchTranscript } from '../FetchTranscript';
import CheckBox from '../Field/Controls/CheckBox';
import Pagination from '../Pagination/index';
import PraTable from '../PraTable';
import Text, { TextStyle } from '../Text';
import { LearnerActivityList, PraList } from '../TranscriptLists';
import TranscriptTable from '../TranscriptTable';
import ClaimCreditsModal from './ClaimCreditsModal';

interface Props {
  accessCodeEmail?: { targetEmailAddress: string };
  accessCodeEmailError?: { message: string; statusCode: number };
  accessCodeEmailLoading?: boolean;
  changeTranscriptPage?: ChangeTranscriptPageActionCreator;
  isCreditProcessor?: boolean;
  page?: number;
  pageSize?: number;
  total?: number;
  userId?: number;
  dispatch?: any;
  claimMissingCredits?: boolean;
}

interface State {
  showPraHistory: boolean;
}

const PraHistoryCheckbox = styled(CheckBox)`
  @media (max-width: ${themeBreakpoint('medium')}) {
    margin-top: 0.8rem;
  }
`;

const DesktopOnly = styled.div`
  display: block;

  @media (max-width: ${themeBreakpoint('medium')}) {
    display: none;
  }
`;

const MobileOnly = styled.div`
  display: none;

  @media (max-width: ${themeBreakpoint('medium')}) {
    display: block;
  }
`;

const StyledLink = styled.a`
  color: ${(props) => props.theme.colors.accent};
  text-decoration: underline;
`;

const UserTranscript: React.FunctionComponent<Props> = ({
  changeTranscriptPage,
  accessCodeEmail,
  accessCodeEmailError,
  accessCodeEmailLoading,
  isCreditProcessor,
  page,
  pageSize,
  total,
  userId,
  claimMissingCredits,
}) => {
  const [{ showPraHistory }, setState] = useState<State>({ showPraHistory: true });
  const onChangeShowPraHistory = () => setState({ showPraHistory: !showPraHistory });
  const totalPages = total && pageSize ? Math.ceil(total / pageSize) : 0;
  const dispatch = useDispatch();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const query = searchParams.get('claimAccountModal');

  useEffect(() => {
    if (claimMissingCredits && query === '1') {
      showClaimCreditsModal();
    }
    dispatch(fetchFeatureFlags());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  const showClaimCreditsModal = () => {
    dispatch(showModal(`${ModalType.ClaimCredits}-${userId}`));
  };

  const handlePageChange = (newPage: number) => {
    if (newPage >= 1 && newPage <= totalPages && changeTranscriptPage) {
      changeTranscriptPage(newPage);
    }

    return page;
  };

  return (
    <>
      <PageSection>
        <PageSectionTitle>
          <Text tag="h3" bold={true} textStyle={TextStyle.SectionTitle}>
            Physician's Recognition Award
          </Text>
          <PraHistoryCheckbox name="showPraHistory" onChange={onChangeShowPraHistory} checked={showPraHistory} title="Show Award History" />
        </PageSectionTitle>
        <FetchPras userId={userId}>
          <DesktopOnly>
            <PraTable showPraHistory={showPraHistory} />
          </DesktopOnly>
          <MobileOnly>
            <PraList showPraHistory={showPraHistory} />
          </MobileOnly>
        </FetchPras>
      </PageSection>
      <PageSection>
        <PageSectionTitle>
          <Text tag="h3" bold={true} textStyle={TextStyle.SectionTitle}>
            Activities
          </Text>
          {!isCreditProcessor && claimMissingCredits && (
            <Text tag="p" bold={false} textStyle={TextStyle.Default}>
              Missing activities? 
              <StyledLink data-test-id="missingActivitiesLink" onClick={() => showClaimCreditsModal()}>
                Click
              </StyledLink>
               to search for your other activities and claim those credits.
            </Text>
          )}
        </PageSectionTitle>

        <FetchTranscript userId={userId} excludeAwardedLearnerActivities={false}>
          <FetchBoards>
            <DesktopOnly>
              <TranscriptTable />
            </DesktopOnly>
            <MobileOnly>
              <LearnerActivityList />
            </MobileOnly>
          </FetchBoards>
        </FetchTranscript>
        {total && total > 1 ? <Pagination currentPage={page || 1} pageCount={totalPages} onPageChanged={handlePageChange} /> : null}
        <ClaimCreditsModal
          accessCodeEmail={accessCodeEmail}
          accessCodeEmailError={accessCodeEmailError}
          accessCodeEmailLoading={accessCodeEmailLoading}
          dispatch={dispatch}
          userId={userId}
        />
      </PageSection>
    </>
  );
};

const mapStateToProps = (state: ReduxStore, { userId }: Props) => {
  return {
    userId: userId || selectUserId(state),
    pageSize: transcriptPageSizeSelector(state),
    page: transcriptPageSelector(state),
    total: transcriptTotalSelector(state),
    accessCodeEmail: accessCodeEmailDataSelector(state),
    accessCodeEmailError: accessCodeEmailErrorSelector(state),
    accessCodeEmailLoading: accessCodeEmailLoadingSelector(state),
    isCreditProcessor: isCreditProcessor(state),
    claimMissingCredits: claimMissingCreditsSelector(state),
  };
};

const mapDispatchToProps = {
  changeTranscriptPage,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserTranscript);
