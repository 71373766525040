import styled from '../../theme';
import { themeColor, themeSize } from '../../theme/helpers';
import { Color } from '../../theme/primaryTheme';
import Text from '../Text';

export const CardInternal = styled.div`
  background-color: transparent;
  padding: 1.2rem 2.4rem;
`;

export const Root = styled.div`
  /* Break out of padding set by PageWrap */
  margin: 0 -${themeSize('mobileGutter')};

  /* 'as any' is a workaround for an issue that exists between the current versions of TypeScript and styled-components */
  &:last-of-type > ${CardInternal as any} {
    border-bottom: 1px solid ${themeColor(Color.GrayBlue)};
  }
`;

export const CardFace = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${themeColor(Color.Reverse)};
  height: 15rem;
  padding: 1.2rem 2.4rem;
  border-bottom: 1px solid ${themeColor(Color.Border)};
`;

export const CardDetails = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const TextWithBackground = styled(Text)`
  background-color: ${themeColor(Color.BorderLight)};
  padding: 0.2rem 0.8rem;
`;

export const CardHeader = styled.div`
  margin-bottom: 1rem;
`;

export const CardBody = styled.div`
  flex: 1;
`;

export const CardFooter = styled.div``;
