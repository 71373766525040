import { getIn } from 'formik';
import React from 'react';
import { ActivityStatus } from '../../constants/ActivityStatus';
import { Color } from '../../theme/primaryTheme';
import Field from '../Field';
import TextArea from '../Field/Controls/TextArea';
import { FieldColumn, FieldRow } from '../FieldRow';
import OptionGroup from '../OptionGroup';
import Text, { TextStyle } from '../Text';
import { GenericActivityProps } from './index';

const ProcessorFields: React.FunctionComponent<Partial<GenericActivityProps>> = ({ baseName, values }) => {
  return (
    <>
      <Text tag="span" textStyle={TextStyle.Medium} bold={true} color={Color.Label}>
        Activity Status
      </Text>
      <Text textStyle={TextStyle.Small}>
        Indicate whether this activity counts for credit or not. Selecting "Follow-up" will automatically add a flag visible on the queue.
      </Text>
      <div style={{ marginTop: '1rem' }}>
        <FieldRow>
          <FieldColumn grow={1}>
            <Field
              type="radio"
              component={OptionGroup}
              fieldBorder={false}
              name={`${baseName}.status`}
              controlProps={{
                options: [
                  {
                    label: 'Valid',
                    value: ActivityStatus.Valid,
                  },
                  {
                    label: 'Invalid',
                    value: ActivityStatus.Invalid,
                  },
                  {
                    label: 'Follow-up',
                    value: ActivityStatus.FollowUp,
                  },
                ],
              }}
            />
          </FieldColumn>
        </FieldRow>
        {getIn(values, `${baseName}.status`) === ActivityStatus.FollowUp && (
          <FieldRow>
            <FieldColumn grow={1}>
              <Field
                name={`${baseName}.notes`}
                placeholder="Write a brief note explaining why this activity needs more information to be processed..."
                label="Add Notes"
                component={TextArea}
                value={getIn(values, `${baseName}.notes`) || ''}
              />
            </FieldColumn>
          </FieldRow>
        )}
      </div>
    </>
  );
};

export default ProcessorFields;
