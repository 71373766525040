import { Form, Formik, FormikBag, FormikProps, FormikValues } from 'formik';
import React from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { BaseSchema } from 'yup';
import Button from '../../../components/Button';
import ExternalLink from '../../../components/Link/ExternalLink';
import Modal from '../../../components/Modal';
import Text from '../../../components/Text';
import { FormId } from '../../../constants/FormId';
import { LinkStyle } from '../../../constants/LinkStyle';
import { showModal } from '../../../ducks/modal';
import { themeBreakpoint } from '../../../theme/helpers';
import { FormFooter } from './styles';

interface IConfirmationFormProps {
  onConfirm: (values: FormikValues, formik: FormikBag<any, FormikValues>) => void;
  modalId: string;
  modalTitle: string;
  formId: FormId;
  initialValues: FormikValues;
  children: React.ReactNode;
  validationSchema: BaseSchema<any>;
  modalBodyComponent: React.ComponentType;
  showContact?: boolean;
}

const ConfirmationForm: React.FunctionComponent<IConfirmationFormProps> = ({
  onConfirm,
  initialValues,
  modalId,
  modalTitle,
  formId,
  children,
  validationSchema,
  modalBodyComponent: ModalBody,
  showContact = false,
}) => {
  const dispatch = useDispatch();

  const handleConfirm = () => {
    dispatch(showModal(modalId));
  };

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleConfirm} enableReinitialize={true}>
      {(props: FormikProps<FormikValues>) => {
        return (
          <Form id={formId}>
            <Modal title={modalTitle} modalId={modalId} onConfirm={onConfirm}>
              <ModalBody />
            </Modal>

            {children}

            <FormFooter>
              <Button type="submit" disabled={!props.dirty}>
                Save Changes
              </Button>
              {props.dirty && (
                <Button type="reset" onClick={props.handleReset} buttonStyle="outlined">
                  Cancel
                </Button>
              )}
              {showContact && (
                <ContactSupport textStyle="small" tag="small" data-test-id="support-cta">
                  <StyledText>Something wrong?</StyledText>
                  <ExternalLink linkStyle={LinkStyle.AccentText} href="mailto:mosupport@ama-assn.org">
                    Contact support for help.
                  </ExternalLink>
                </ContactSupport>
              )}
            </FormFooter>
          </Form>
        );
      }}
    </Formik>
  );
};

const ContactSupport = styled(Text)`
  display: flex;
  flex-direction: row;

  @media (max-width: ${themeBreakpoint('medium')}) {
    margin-left: 0;
    margin-top: 2rem;
    display: block;
    text-align: center;
  }
`;

const StyledText = styled(Text)`
  margin-right: 0.5rem;
`;

export default ConfirmationForm;
