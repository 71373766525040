import React from 'react';
import LockIcon from '../../Icon/LockIcon';
import { ControlMeta, LockedField } from '../styles';

interface Props {
  value: any;
}

const LockedInput: React.FunctionComponent<Props> = ({ value, ...rest }) => {
  return (
    <LockedField {...rest}>
      {value}
      <ControlMeta>
        <LockIcon size={12} />
      </ControlMeta>
    </LockedField>
  );
};

export default LockedInput;
