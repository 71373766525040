import { FormikHelpers } from 'formik';
import React from 'react';
import styled from '../../theme';
import { themeColor } from '../../theme/helpers';
import { Color } from '../../theme/primaryTheme';
import { CreditActivity } from '../../types';
import { calculateCheckedCredits } from '../../utilities/creditActivities';
import { momentUtc } from '../../utilities/moment';
import CheckBox from '../Field/Controls/CheckBox';
import { Grid, GridItem } from '../Grid';
import { DEFAULT_PANEL_PADDING } from '../Panel';
import SortArrows from '../SortArrows';
import Text, { TextStyle } from '../Text';
import { sortedActivities } from './index';
import { transcriptColumns, transcriptGridItemsFlexValues, transcriptGridTemplateColumns } from './transcriptColumns';

interface ActivityRowProps {
  activitiesAreEditable: boolean;
  activities: CreditActivity[];
  activity: CreditActivity;
  index: number;
  removeItem: (index: number) => any;
  isProcessor?: boolean;
  setFieldValue: FormikHelpers<any>['setFieldValue'];
}

interface ActivityTableProps {
  activitiesAreEditable: boolean;
  activities: CreditActivity[];
  addItem: (obj: any) => void;
  removeItem: (index: number) => any;
  isProcessor?: boolean;
  setFieldValue: FormikHelpers<any>['setFieldValue'];
}

const StyledActivityRow = styled.div`
  padding: 12px 16px 11px 20px;
`;

const ActivityRow: React.FunctionComponent<ActivityRowProps> = ({ activitiesAreEditable, activity, index, setFieldValue }) => {
  const handleClick = () => {
    if (activitiesAreEditable) {
      setFieldValue(`transcriptCreditActivities[${index}].checked`, !activity.checked);
    }
  };

  return (
    <StyledActivityRow className="activity-row">
      <Grid key={activity.id} gridTemplateColumns={transcriptGridTemplateColumns} ieGridItemsFlexValues={transcriptGridItemsFlexValues}>
        {Object.keys(transcriptColumns).map((name) => {
          const inputName = `transcriptCreditActivities[${index}].${name}`;
          switch (name) {
            case 'checked':
              return (
                <GridItem key={inputName}>
                  <CheckBox disabled={!activitiesAreEditable} name={name} checked={activity.checked} title={''} onChange={handleClick} />
                </GridItem>
              );

            case 'activityEndDate':
              const date = momentUtc(activity[name]).format('MM/DD/YY');
              return <GridItem key={inputName}>{date}</GridItem>;

            default:
              return <GridItem key={inputName}>{activity[name]}</GridItem>;
          }
        })}
      </Grid>
    </StyledActivityRow>
  );
};

const Header = styled.div`
  padding: ${DEFAULT_PANEL_PADDING};
  padding-bottom: 8px;
`;

const FlexGridItem = styled(GridItem)`
  display: flex;
  align-items: center;
`;

const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: ${DEFAULT_PANEL_PADDING};
`;

const CreditTotal = styled(Text)`
  margin-right: 3rem;
`;

const Body = styled.div`
  border-top: 1px solid ${themeColor(Color.Border)};
  border-bottom: 1px solid ${themeColor(Color.Border)};

  ${StyledActivityRow}:nth-child(even) {
    background-color: #f6f8fa;
  }
`;

const PraTranscriptActivityTable: React.FunctionComponent<ActivityTableProps> = ({
  activitiesAreEditable,
  activities,
  removeItem,
  isProcessor,
  setFieldValue,
}) => {
  const handleDateArrowClick = (chronological: boolean) => () =>
    setFieldValue('transcriptCreditActivities', sortedActivities(activities, chronological));

  return (
    <div data-testid="praTranscriptTable">
      <Header>
        <Grid gridTemplateColumns={transcriptGridTemplateColumns} ieGridItemsFlexValues={transcriptGridItemsFlexValues}>
          {Object.keys(transcriptColumns).map((name) => {
            const column = transcriptColumns[name];

            if (name === 'activityEndDate') {
              return (
                <FlexGridItem key={name}>
                  <Text bold={true} textStyle={TextStyle.Small}>
                    {column.title}
                  </Text>
                  <SortArrows onUpArrowClick={handleDateArrowClick(false)} onDownArrowClick={handleDateArrowClick(true)} />
                </FlexGridItem>
              );
            }

            return (
              <GridItem key={name}>
                <Text bold={true} textStyle={TextStyle.Small}>
                  {column.title}
                </Text>
              </GridItem>
            );
          })}
        </Grid>
      </Header>
      <Body>
        {activities.map((activity, index: number) => (
          <ActivityRow
            activitiesAreEditable={activitiesAreEditable}
            activities={activities}
            removeItem={removeItem}
            key={index}
            index={index}
            activity={activity}
            isProcessor={isProcessor}
            setFieldValue={setFieldValue}
          />
        ))}
      </Body>
      <Footer>
        <CreditTotal bold={true} tag="span" color={Color.Secondary}>
          <>
            Total{' '}
            <Text tag="em" bold={true}>
              AMA PRA Category 1 Credits&trade;
            </Text>
            : {activities && calculateCheckedCredits(activities)}
          </>
        </CreditTotal>
      </Footer>
    </div>
  );
};

export default PraTranscriptActivityTable;
