import { Field, FieldProps } from 'formik';
import React from 'react';
import CheckBox from '../../Field/Controls/CheckBox';
import { FieldColumn, FieldRow } from '../../FieldRow';
import FormSection from '../../FormSection';

const RenewalStatusSection: React.FunctionComponent<{ claimIsLocked: boolean }> = ({ claimIsLocked }) => {
  return (
    <>
      <FormSection title="Would you like to renew your previous Physician's Recognition Award?">
        <FieldRow data-testid="directCreditOptions">
          <FieldColumn grow={1}>
            <Field name="praIsRenewal">
              {({ field }: FieldProps) => <CheckBox {...field} title="Yes, please renew my previous PRA" disabled={claimIsLocked} />}
            </Field>
          </FieldColumn>
        </FieldRow>
      </FormSection>
    </>
  );
};

export default RenewalStatusSection;
