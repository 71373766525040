import React from 'react';
import { TextStyle } from 'src/components/Text';
import styled from '../../theme';
import { CreditActivity, CreditClaim } from '../../types';
import Field from '../Field';
import DatePickerField from '../Field/DatePickerField';
import { FieldColumn, FieldRow } from '../FieldRow';
import FormSection from '../FormSection';
import OptionGroup from '../OptionGroup';
import Panel, { PanelBody } from '../Panel';
import ProofDocumentUpload from '../ProofDocumentUpload';
import Text from '../Text';

interface Props {
  activity?: CreditActivity;
  claim: CreditClaim;
  isProcessor: boolean;
  activitiesAreEditable: boolean;
}

const ActivityTablePanel = styled(Panel)`
  width: 60vw;
  max-width: 1200px;
  margin-bottom: 45px;
`;

const StyledText = styled(Text)`
  margin-bottom: 2.5rem;
`;

const ResidencyActivitySection: React.FunctionComponent<Props> = ({ activity, claim, isProcessor = false, activitiesAreEditable }) => {
  return activity !== undefined ? (
    <FormSection>
      <ActivityTablePanel heading="Completing Residency or Fellowship">
        <PanelBody>
          <StyledText textStyle={TextStyle.Small}>
            ACGME accredited residency or fellowship programs completed within the past 6 years are eligible for a Standard PRA. The
            duration of the PRA certificate depends on how many years of residency or fellowship were completed.
          </StyledText>
          <FieldRow>
            <FieldColumn grow={1}>
              <Field
                name="residencyActivity.program"
                label="Name of Residency or Fellowship Program"
                required={true}
                locked={!activitiesAreEditable}
              />
            </FieldColumn>
          </FieldRow>

          <FieldRow>
            <FieldColumn grow={1}>
              <Field
                type="radio"
                label="Years In Program *"
                component={OptionGroup}
                fieldBorder={false}
                name="praCertificateDuration"
                controlProps={{
                  options: [
                    {
                      label: '1 Year',
                      value: '1',
                    },
                    {
                      label: '2 Years',
                      value: '2',
                    },
                    {
                      label: '3 Years',
                      value: '3',
                    },
                  ],
                }}
                locked={!activitiesAreEditable}
              />
            </FieldColumn>
          </FieldRow>

          <FieldRow>
            <FieldColumn>
              <DatePickerField
                name="residencyActivity.activityStartDate"
                label="Start of Program"
                required={true}
                locked={!activitiesAreEditable}
              />
            </FieldColumn>
            <FieldColumn>
              <DatePickerField
                name="residencyActivity.activityEndDate"
                label="End of Program"
                required={true}
                locked={!activitiesAreEditable}
              />
            </FieldColumn>
          </FieldRow>
        </PanelBody>
      </ActivityTablePanel>
      <ActivityTablePanel heading="Upload Proof Document">
        <PanelBody>
          <StyledText textStyle={TextStyle.Small}>
            Attach letter from the Program Director or the Certificate of Completion of the Residency Program verifying the dates of
            participation and completion of the program.
          </StyledText>
          <FieldRow>
            <FieldColumn grow={1}>
              <ProofDocumentUpload
                name="residencyActivity.creditActivityAttachments"
                required={!isProcessor}
                claimId={claim.id}
                uploadsDisabled={!activitiesAreEditable}
              />
            </FieldColumn>
          </FieldRow>
        </PanelBody>
      </ActivityTablePanel>
    </FormSection>
  ) : null;
};

export default ResidencyActivitySection;
