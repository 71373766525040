import React from 'react';
import { LearnerActivity } from '../../types';
import { FileDownload } from '../FileDownload';
import { DownloadButtonProps } from '../FileDownload/FileDownload';

const rollbarErrorText = (data: LearnerActivity) => `Error response generating PDF for activity ID ${data.id}`;

const CertificateDownloadButton: React.FunctionComponent<{
  data: LearnerActivity;
  value?: string;
  buttonProps?: DownloadButtonProps;
}> = (props) => <FileDownload {...props} iconName="download" downloadedFileName="Certificate.pdf" rollbarErrorText={rollbarErrorText} />;

export default CertificateDownloadButton;
