import React from 'react';
import { CreditRequirements as CreditSummary } from '.';
import { emptyCellCharacter } from '../../constants/General';
import styled from '../../theme';
import { themeColor } from '../../theme/helpers';
import { Color } from '../../theme/primaryTheme';
import Panel, { PanelBody } from '../Panel';
import Text, { TextStyle } from '../Text';

interface Props {
  creditRequirements?: CreditSummary;
  totalCategory1ActivityCredits: number;
  totalCategory2ActivityCredits: number;
}

const PraCreditSummary: React.FunctionComponent<Props> = ({
  creditRequirements,
  totalCategory1ActivityCredits,
  totalCategory2ActivityCredits,
}) => {
  const category1CreditRequirementsAreMet = totalCategory1ActivityCredits >= creditRequirements?.requiredCategory1Credits;
  const overallCreditRequirementsAreMet =
    totalCategory1ActivityCredits + totalCategory2ActivityCredits >= creditRequirements?.requiredOverallCredits;

  return (
    <PraCreditSummaryPanel>
      <PraCreditSummaryPanelBody>
        <CreditTally>
          <Text tag="h2" textStyle={TextStyle.CardTitle}>
            <CreditTallyNumerator>{totalCategory1ActivityCredits}</CreditTallyNumerator>
            {` / ${creditRequirements?.requiredCategory1Credits ?? emptyCellCharacter}`}
          </Text>
          <CreditTallyLabel tag="h3" textStyle="small" bold={true} color={Color.Gray}>
            <Text tag="em" bold={true}>
              AMA PRA Category 1 Credits&trade;
            </Text>{' '}
            Required
          </CreditTallyLabel>
        </CreditTally>
        <VerticalDivider />
        <CreditTally>
          <Text tag="h2" textStyle={TextStyle.CardTitle}>
            <CreditTallyNumerator>{`${totalCategory1ActivityCredits + totalCategory2ActivityCredits}`}</CreditTallyNumerator>
            {` / ${creditRequirements?.requiredOverallCredits ?? emptyCellCharacter}`}
          </Text>
          <CreditTallyLabel tag="h3" textStyle="small" bold={true} color={Color.Gray}>
            Total Credits Required
          </CreditTallyLabel>
        </CreditTally>
      </PraCreditSummaryPanelBody>
      {creditRequirements && (!category1CreditRequirementsAreMet || !overallCreditRequirementsAreMet) && (
        <PraCreditRequirementsNotice tag="p" color={Color.Error}>
          * You have not met your requirements for a PRA. Add more credits to continue.
        </PraCreditRequirementsNotice>
      )}
    </PraCreditSummaryPanel>
  );
};

const PraCreditSummaryPanel = styled(Panel)`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: none;
  border-bottom: 7px solid ${themeColor(Color.Accent)};
  border-radius: inherit;
`;

const PraCreditSummaryPanelBody = styled(PanelBody)`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 9.5rem;
`;

const CreditTally = styled.section`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

const VerticalDivider = styled.hr`
  background-color: ${themeColor(Color.Secondary)};
  width: 1px;
  height: 100%;
  border: none;
  margin: 0;
`;

const CreditTallyNumerator = styled.span`
  color: ${themeColor(Color.Label)};
`;

// Necessary for proper display on IE 11
const CreditTallyLabel = styled(Text)`
  width: 100%;
`;

const PraCreditRequirementsNotice = styled(Text)`
  width: 100%;
  text-align: center;
  padding: 1rem 0;
  border-top: 1px solid ${themeColor(Color.Border)};
`;

export default PraCreditSummary;
