export const labels = {
  state: {
    title: 'State Boards',
    description:
      '<p>Currently, qualifying AMA CME and JAMA Network™ CME credits can be automatically transmitted to the following state boards. To initiate the automatic transfer, please slide the button to “yes” under the "Send Credit Data?" column located below.</p><p>The AMA Ed Hub plans to add additional state boards in the future. To be informed of these updates, please ensure your e-mail is up to date to receive our announcements.</p>',
    placeholder: 'License #',
    sortKeys: ['externalEntityId'],
  },
  specialty: {
    title: 'Specialty Boards',
    description:
      '<p>Currently, all JAMA Network™ CME and qualifying AMA CME credits can be automatically transmitted to the following specialty boards when you select yes for "Send CME Data?"</p>',
    placeholder: 'Diplomate ID',
    sortKeys: ['name'],
  },
};
