import React from 'react';
import { connect } from 'react-redux';
import { ReduxStore } from '../../ducks';
import { fetchBoardData } from '../../ducks/boardData';
import { boardsLoadingSelector } from '../../selectors/boards';
import LoadingSpinner from '../LoadingSpinner';

interface Props {
  loading: boolean;
  fetchBoardData: typeof fetchBoardData;
}

const FetchBoards: React.FunctionComponent<Props> = ({ loading, fetchBoardData, children }) => {
  React.useEffect(() => {
    fetchBoardData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return loading ? <LoadingSpinner /> : <>{children}</>;
};

const mapStateToProps = (state: ReduxStore) => ({
  loading: boardsLoadingSelector(state),
});

const mapDispatchToProps = {
  fetchBoardData,
};

export default connect(mapStateToProps, mapDispatchToProps)(FetchBoards);
