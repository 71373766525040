import React from 'react';
import { useState } from 'react';
import styled from '../../theme';
import { Color } from '../../theme/primaryTheme';
import { ColorKeys } from '../../theme/theme';
import Icon from '../Icon';
import Text from '../Text';
import Button from './index';

interface Props {
  label: string;
  onConfirm: () => void;
}

const StyledConfirm = styled.div`
  button {
    margin-left: 0.75em;
  }
`;

const Root = styled.div`
  font-size: 1.4rem;
  line-height: 1.2;
`;

const ConfirmButton: React.FunctionComponent<Props> = ({ label, onConfirm }) => {
  const [clicked, setClicked] = useState(false);
  const cancelClick = () => setClicked(false);
  const handleClick = () => setClicked(true);

  return (
    <Root data-test-id="ConfirmButton">
      {clicked ? (
        <StyledConfirm>
          <Text tag="span">Are you sure?</Text>
          <Button data-test-id="ConfirmButtonRemove" color={Color.Error as ColorKeys} buttonStyle="toggle" onClick={onConfirm}>
            Remove
          </Button>
          <Button data-test-id="ConfirmButtonCancel" buttonStyle="toggle" color={Color.Secondary as ColorKeys} onClick={cancelClick}>
            Cancel
          </Button>
        </StyledConfirm>
      ) : (
        <Button buttonStyle="toggle" onClick={handleClick} color={Color.Error as ColorKeys} data-test-id="ConfirmButtonInitial">
          <Icon name="trashcan" width={10} height={12} viewBox="0 0 10 12" style={{ marginRight: '0.5em', verticalAlign: 'middle' }} />
          <Text tag="span">{label}</Text>
        </Button>
      )}
    </Root>
  );
};

export default ConfirmButton;
