import React from 'react';
import { connect } from 'react-redux';
import { ReduxStore } from '../../ducks';
import { prasErrorSelector, prasSelector } from '../../selectors/pras';
import { Pra } from '../../types';
import { momentIsBefore, momentUtc } from '../../utilities/moment';
import Alert, { AlertType } from '../Alert';
import { ToggleablePraCard } from '../ToggleableTranscriptCard';
import NoTranscriptDataMessage from './NoTranscriptDataMessage';

interface Props {
  error?: string;
  pras: Pra[];
  showPraHistory: boolean;
}

const PraList: React.FunctionComponent<Props> = ({ pras = [], error = '', showPraHistory }) => {
  const isExpired = (pra: Pra) => momentIsBefore(pra.expirationDate, momentUtc());
  const visiblePras = showPraHistory ? pras : pras.filter((pra) => !isExpired(pra));
  if (!visiblePras.length) {
    return <NoTranscriptDataMessage dataType="pra" />;
  }

  return (
    <>
      {error && (
        <Alert data-test-id="transcriptTableError" type={AlertType.Error}>
          {error}
        </Alert>
      )}
      {visiblePras.map((pra) => (
        <ToggleablePraCard pra={pra} key={pra.id} />
      ))}
    </>
  );
};

const mapStateToProps = (state: ReduxStore) => ({
  pras: prasSelector(state),
  error: prasErrorSelector(state),
});

export default connect(mapStateToProps)(PraList);
