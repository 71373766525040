import React from 'react';
import { countryOptions } from '../../layouts/Forms/countryOptions';
import { AdvantagePostalAddress, AdvantagePostalAddressSummary, AmaAddress } from '../../types';
import { FieldColumn, FieldRow } from '../FieldRow';
import SummaryValue from '../SummaryValue';

interface Props {
  address?: AmaAddress;
  advantageAddress?: AdvantagePostalAddress;
  advantageAddressSummary?: AdvantagePostalAddressSummary;
  label?: string;
}

const AddressSummary: React.FunctionComponent<Props> = ({ label = 'Address', address, advantageAddress, advantageAddressSummary }) => {
  let addressData: AmaAddress = {
    address1: '',
    address2: '',
    city: '',
    stateCode: '',
    postalCode: '',
    countryCode: '',
  };

  if (address) {
    addressData = {
      ...addressData,
      ...address,
    };
  }

  if (advantageAddress) {
    addressData = {
      address1: advantageAddress.Line1 || '',
      address2: advantageAddress.Line2 || '',
      city: advantageAddress.City || '',
      stateCode: advantageAddress.StateCode || '',
      postalCode: advantageAddress.PostalCode || '',
      countryCode: advantageAddress.CountryCode || '',
    };
  }

  if (advantageAddressSummary) {
    addressData = {
      address1: advantageAddressSummary.line1 || '',
      address2: advantageAddressSummary.line2 || '',
      city: advantageAddressSummary.city || '',
      stateCode: advantageAddressSummary.stateCode || '',
      postalCode: advantageAddressSummary.zip || '',
      countryCode: advantageAddressSummary.countryCode || '',
    };
  }

  const { address1, address2, city, stateCode, postalCode, countryCode } = addressData;

  const columnWidth = countryCode ? '25%' : '33.333%';

  return (
    <>
      <FieldRow>
        <FieldColumn grow={1}>
          <SummaryValue label={label} value={address1} />
          {address2 && <SummaryValue value={address2} />}
        </FieldColumn>
      </FieldRow>
      <FieldRow>
        <FieldColumn width={columnWidth}>
          <SummaryValue label="City" value={city} />
        </FieldColumn>
        <FieldColumn width={columnWidth}>
          <SummaryValue label="State" value={stateCode} />
        </FieldColumn>
        <FieldColumn width={columnWidth}>
          <SummaryValue label="ZIP" value={postalCode} />
        </FieldColumn>
        {countryCode && (
          <FieldColumn width={columnWidth}>
            <SummaryValue label="Country" value={countryOptions.find((country) => country.code === countryCode).value} />
          </FieldColumn>
        )}
      </FieldRow>
    </>
  );
};

export default AddressSummary;
