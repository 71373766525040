import styled, { css } from '../../theme';
import { themeBreakpoint, themeColor } from '../../theme/helpers';
import { Color } from '../../theme/primaryTheme';
import { ButtonProps } from './index';

const base = css`
  background-color: ${themeColor(Color.Accent)};
  border-radius: 0.5rem;
  color: ${themeColor(Color.Reverse)};
  position: relative;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 5px;
    box-shadow: 0 2px 20px rgba(36, 54, 71, 0.8);
    opacity: 0;
    transition: all 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
    transform-origin: 50% 100%;
  }

  &:hover {
    background-color: ${themeColor(Color.Primary)};

    &:after {
      opacity: 1;
      transform: scale(0.9);
    }
  }

  @media (max-width: ${themeBreakpoint('medium')}) {
    text-align: center;
    width: 100%;
  }
`;

export const baseStyles = css`
  display: inline-block;
  font-family: 'Akkurat', sans-serif;
  appearance: none;
  background-color: transparent;
  border: none;
  box-shadow: none;
  cursor: pointer;
  font-size: 1.6rem;
  font-weight: 700;
  padding: 1em 1.2em;
  transition: all 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);

  &:disabled {
    background-color: ${themeColor(Color.Secondary)};
    opacity: 0.5;
    cursor: not-allowed;

    &:hover {
      background-color: ${themeColor(Color.Secondary)};
    }

    &:after {
      display: none;
    }
  }
`;

export const buttonStyles = {
  outlined: css`
    border-radius: 0.5rem;
    box-shadow: inset 0 0 0 0.2rem ${themeColor(Color.Accent)};
    color: ${themeColor(Color.Accent)};
    transition: background-color 0.25s ease, color 0.25s ease;
    line-height: inherit;
    padding: 1.1em;

    &:hover {
      background: ${themeColor(Color.Accent)};
      color: ${themeColor(Color.Reverse)};
    }

    @media (max-width: ${themeBreakpoint('medium')}) {
      width: 100%;
      padding: 1.4rem;
    }
  `,
  'plain-text': css`
    font-size: 1em;
    font-weight: 400;
    border: 0;
    padding: 0;
    background-color: transparent;
    color: ${themeColor(Color.Accent)};

    &:hover {
      color: ${themeColor(Color.Primary)};
    }
  `,
  text: css`
    font-size: 1em;
    font-weight: 700;
    border: 0;
    padding: 0;
    background-color: transparent;
    color: ${themeColor(Color.Accent)};
    position: relative;

    &:after {
      content: '';
      display: block;
      position: absolute;
      bottom: -0.25em;
      border-bottom: 1px solid currentColor;
      width: 100%;
    }

    &:hover {
      color: ${themeColor(Color.Primary)};
    }
  `,
  'icon-toggle': css`
    width: 100%;
    display: flex;
    align-items: baseline;
    font-size: 1em;
    border: 0;
    padding: 0;
    background-color: transparent;
    text-align: left;

    &:hover {
      color: ${themeColor(Color.Accent)};
    }
  `,
  toggle: css`
    font-size: 1em;
    border: 0;
    padding: 0;
    background-color: transparent;
    text-align: left;

    &:hover {
      color: ${themeColor(Color.Accent)};
    }
  `,
  'download-button': css`
    padding: 10px;
    border-radius: 5px;
    color: ${themeColor(Color.Primary)};

    &:hover {
      background-color: ${themeColor(Color.BorderLight)};
    }

    @media (max-width: ${themeBreakpoint('medium')}) {
      display: flex;
      align-items: center;
      padding: 0;
    }
  `,
  default: css`
    ${base}
  `,
};

export const StyledButton = styled.button<ButtonProps>`
  ${baseStyles};
  ${({ buttonStyle = 'default' }) => buttonStyles[buttonStyle]};
  ${(props) =>
    props.full
      ? css`
          display: block;
          width: 100%;
        `
      : ''}
  ${(props) =>
    props.color
      ? css`
          color: ${themeColor(props.color)};

          &:hover {
            color: ${themeColor(Color.Accent)};
          }
        `
      : ''}
`;
