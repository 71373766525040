import React from 'react';
import PrintButton from 'src/components/PrintButton/PrintButton';
import { ApplicationProgressBar, ApplicationStepLabel } from '../../components/ApplicationProgressBar';
import ApplicationMetadata from '../../components/SingleClaim/ApplicationMetadata';
import Text, { TextStyle } from '../../components/Text';
import { ApplicationTypeCode } from '../../constants/ApplicationTypeCode';
import styled from '../../theme';
import App from '../App';
import { Page, PageAction, PageActionItem, PageBody, PageHead, PageSubtitle, PageTitle, PageWrap } from '../PageStructure';

interface Props {
  title: string;
  applicationStep: ApplicationStepLabel;
  children: React.ReactNode;
  subtitle?: string | (() => React.ReactNode);
  applicationTypeCode?: ApplicationTypeCode;
}

const ApplicationPage: React.FunctionComponent<Props> = ({ title, applicationStep, children, subtitle, applicationTypeCode }) => {
  const pageTitles = {
    [ApplicationTypeCode.DirectCredit]: 'Direct Credit Application',
    [ApplicationTypeCode.InternationalCreditConversion]: 'Credit Conversion Application',
    [ApplicationTypeCode.PRA]: 'PRA Application',
  };

  return (
    <App>
      <Page>
        <PageHead>
          <PageWrap>
            <PageTitle>
              <Text tag="h1" textStyle={TextStyle.Headline}>
                {pageTitles[applicationTypeCode] ?? 'New Application'}
              </Text>
            </PageTitle>
            <ApplicationProgressBar applicationStep={applicationStep} />
          </PageWrap>
        </PageHead>
        <PageBody>
          <PageWrap>
            <PageTitle>
              {title && (
                <Text tag="h2" bold={true} textStyle={TextStyle.FlowTitle}>
                  {title}
                </Text>
              )}
              {applicationStep === ApplicationStepLabel.OrderDetails && (
                <PageAction>
                  <PageActionItem>
                    <PrintButton />
                  </PageActionItem>
                </PageAction>
              )}
            </PageTitle>
            {subtitle && (
              <ApplicationPageSubtitle>
                {typeof subtitle === 'function' ? (
                  <>{subtitle()}</>
                ) : (
                  <Text tag="p" textStyle={TextStyle.SubTitle}>
                    {subtitle}
                  </Text>
                )}
              </ApplicationPageSubtitle>
            )}
            {(title || subtitle) && <Spacer />}
            <ApplicationMetadata />
            <ApplicationPageContent>{children}</ApplicationPageContent>
          </PageWrap>
        </PageBody>
      </Page>
    </App>
  );
};

const Spacer = styled.div`
  margin-bottom: 3em;
`;

const ApplicationPageSubtitle = styled(PageSubtitle)`
  margin-top: 0.5rem;
`;

const ApplicationPageContent = styled.div`
  margin-top: 5rem;
`;

export default ApplicationPage;
