import { FormikValues, getIn } from 'formik';
import React from 'react';
import { ActivityFieldLabels } from '../../constants/ActivityFieldLabels';
import { ActivityType } from '../../constants/ActivityType';
import { emptyCellCharacter } from '../../constants/General';
import { Color } from '../../theme/primaryTheme';
import { CreditActivity } from '../../types';
import { formattedDate, momentUtc } from '../../utilities/moment';
import { TotalCreditsRow } from '../ActivityFields/styles';
import { FieldColumn, FieldRow } from '../FieldRow';
import Label from '../Label';
import ProofDocumentList from '../ProofDocumentUpload/ProofDocumentList';
import StatusLabel from '../StatusLabel';
import SummaryValue from '../SummaryValue';
import Text, { TextStyle } from '../Text';

const displayKeys = {
  [ActivityType.Teaching]: ['activityTitle', 'activityEndDate', 'activityUnits', 'creditActivityAttachments', 'notes'],
  [ActivityType.Medical]: ['school', 'program', 'graduationDate', 'creditActivityAttachments', 'notes'],
  [ActivityType.Poster]: ['presentationTitle', 'firstAuthor', 'activityEndDate', 'creditActivityAttachments', 'notes'],
  [ActivityType.Article]: ['doi', 'activityTitle', 'firstAuthor', 'activityEndDate', 'creditActivityAttachments', 'notes'],
  [ActivityType.Abms]: ['board', 'subspeciality', 'certificationDate', 'creditActivityAttachments', 'notes'],
  [ActivityType.Acgme]: ['program', 'activityUnits', 'activityStartDate', 'activityEndDate', 'creditActivityAttachments', 'notes'],
  [ActivityType.ICC]: [
    'conferenceTitle',
    'conferenceAccreditor',
    'activityStartDate',
    'activityEndDate',
    'city',
    'country',
    'creditActivityAttachments',
    'activityUnits',
    'notes',
  ],
};

const getValue = (activity: CreditActivity, name: string) => {
  switch (name) {
    case 'activityEndDate':
    case 'activityStartDate':
    case 'certificationDate':
    case 'graduationDate':
      const date = momentUtc(activity[name]);
      return date.isValid() ? formattedDate(date) : emptyCellCharacter;
    case 'firstAuthor':
      return activity[name] ? 'Yes' : 'No';
    case 'creditActivityAttachments':
      return activity[name];
    case 'notes':
      return activity[name] || '';
    default:
      return activity[name] || emptyCellCharacter;
  }
};

interface Props {
  baseName: string;
  selectedType: ActivityType;
  values: FormikValues;
}

const ActivityInfoSummary: React.FunctionComponent<Props> = ({ baseName, selectedType, values }) => {
  const activity = getIn(values, baseName);
  const fields = displayKeys[selectedType] || [];
  const labels = ActivityFieldLabels[selectedType];
  const attachments = getValue(activity, 'creditActivityAttachments') || [];
  const excludeFields = ['notes', 'creditActivityAttachments'];

  return (
    <div data-test-id="activityInfoSummary" data-testid="activityInfoSummary">
      {fields.map((name: string, index: number) => {
        const value = getValue(activity, name);
        return (
          !excludeFields.includes(name) && (
            <FieldRow key={`${name}-summary-${index}`}>
              <FieldColumn>
                <SummaryValue label={labels[name] || name} value={value} />
              </FieldColumn>
            </FieldRow>
          )
        );
      })}
      {!!attachments.length && (
        <FieldRow>
          <FieldColumn>
            <Label tag="p">Attachments</Label>
            <ProofDocumentList attachments={attachments} />
          </FieldColumn>
        </FieldRow>
      )}
      <TotalCreditsRow>
        <FieldColumn>
          <Text tag="span" textStyle={TextStyle.Medium} bold={true} color={Color.Label}>
            Total Credits:
          </Text>
          &ensp;
          <Text tag="span" textStyle={TextStyle.Medium} color={Color.Label}>
            {activity.numberOfCredits}
          </Text>
          <Text textStyle={TextStyle.Small}>This activity is worth {activity.numberOfCredits} credits.</Text>
        </FieldColumn>
      </TotalCreditsRow>
      <FieldRow>
        <FieldColumn>
          <Label tag="p">Activity Status</Label>
          <div>
            <StatusLabel status={activity.status} />
          </div>
        </FieldColumn>
      </FieldRow>
    </div>
  );
};

export default ActivityInfoSummary;
