export const zipRegex = /^\d{5}(-\d{4})?$/;

// http://phoneregex.com
export const phoneRegex =
  /^(?:(?:\+?1\s*(?:[.-]\s*)?)?(?:\(\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*\)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\s*(?:[.-]\s*)?)?([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/;

// Regex matches any zero values such as 0, 00, 0.0, 0.00, etc.
export const matchZeroValues = /^(?!0+(\.0+)?$)(\d+(\.\d+)?|(\.\d+))$/;

// Regex matches one or more digits, optionally followed by decimal points in increments of .25.
export const matchQuarterValues = /^(?!0\d)\d*(\.(?:25|5|50|75|0*))?$/;
