import { createSelector } from 'reselect';
import { CreditClaimStatus } from 'src/constants/ApplicationStatus';
import { ActivityAccredidationType } from '../constants/ActivityType';
import { ReduxStore } from '../ducks';
import { CreditActivity, CreditActivityAttachment, CreditClaim } from '../types';
import { getAwardTypeString } from '../utilities/getAwardTypeString';

export const claims = (state: ReduxStore) => state.claims;
export const claimLoadingSelector = createSelector(claims, (claim) => claim.isLoading);
export const claimDataSelector = createSelector(claims, (claim) => claim.data);
export const claimErrorSelector = createSelector(claims, (claim) => claim.error);
export const claimUserSelector = createSelector(claimDataSelector, (claim) => claim.creditEarner);
export const claimUserMembershipSelector = createSelector(claimUserSelector, (user) => (user && user.amaMember) || false);
export const claimCertificateEmail = createSelector(claimDataSelector, (claim: CreditClaim) => (claim && claim.certificatesEmail) || '');
export const claimHasOrder = createSelector(claimDataSelector, (claim: CreditClaim) => (claim && claim.creditClaimOrder !== null) || false);
export const claimIsEditableSelector = createSelector(
  claimDataSelector,
  (claim: CreditClaim) => ![CreditClaimStatus.COMPLETED, CreditClaimStatus.REJECTED].includes(claim.status)
);
export const claimIsCompletedSelector = createSelector(claimDataSelector, (claim: CreditClaim) =>
  [CreditClaimStatus.COMPLETED].includes(claim.status)
);
export const claimEntitiesSelector = createSelector(claims, (claim) => claim.entities);
export const claimResultsSelector = createSelector(claims, (claims) => claims.result);
export const claimsSelector = createSelector(claimEntitiesSelector, (entities) => entities.claim);
export const claimSelector = (id: number) => createSelector(claimsSelector, (claim) => claim[id]);
export const claimMetaSelector = createSelector(claims, (claim) => claim.meta);
export const claimsTotalSelector = createSelector(claimMetaSelector, (meta) => meta.total);
export const claimsStatusFiltersSelector = createSelector(claims, (claim) => claim.statusFilters);
export const claimsPageSelector = createSelector(claimMetaSelector, (meta) => meta.page);
export const claimsSortOrderSelector = createSelector(claimMetaSelector, (meta) => meta.sortOrder);
export const claimsTotalPagesSelector = createSelector(claimMetaSelector, (meta) => {
  if (meta.total && meta.pageSize) {
    return Math.ceil(meta.total / meta.pageSize);
  }

  return undefined;
});

export const praIsCommendation = createSelector(claimDataSelector, (data) => data.praCertificateIsCommendation || false);

export const praCertificateDuration = createSelector(claimDataSelector, (data) => data.praCertificateDuration);

const praClaimMethod = createSelector(claimDataSelector, (data) => data.praCertificateClaimMethod);

export const praIsRenewal = createSelector(claimDataSelector, (data) => data.praIsRenewal);

export const praCertificateEffectiveDateSelector = createSelector(claimDataSelector, (data) => data.praCertificateEffectiveDate);

export const praCertificateExpirationDateSelector = createSelector(claimDataSelector, (data) => data.praCertificateExpirationDate);

export const praIsHalfCategory1Selector = createSelector(claimDataSelector, (data) => data.praIsHalfCategory1);

export const activitiesSelector = createSelector(claimDataSelector, (data) => data.creditActivities);

export const transcriptActivitiesSelector = createSelector(
  activitiesSelector,
  (activities) => activities && activities.filter((activity: CreditActivity) => !!activity.learnerActivityId)
);

export const category1ActivitiesSelector = createSelector(
  activitiesSelector,
  (activities) =>
    activities && activities.filter((activity: CreditActivity) => activity.accredidationType === ActivityAccredidationType.Category1)
);

export const totalCategory1ActivityCreditsSelector = createSelector(
  [claimDataSelector, transcriptActivitiesSelector, category1ActivitiesSelector],
  ({ additionalPraCat1Credits = 0 }, transcriptActivities: CreditActivity[], category1Activities: CreditActivity[]) =>
    transcriptActivities
      ?.concat(category1Activities)
      .reduce((sum, { numberOfCredits }) => sum + Number(numberOfCredits), additionalPraCat1Credits)
);

export const category2ActivitiesSelector = createSelector(
  activitiesSelector,
  (activities) =>
    activities && activities.filter((activity: CreditActivity) => activity.accredidationType === ActivityAccredidationType.Category2)
);

export const totalCategory2ActivityCreditsSelector = createSelector(category2ActivitiesSelector, (activities) =>
  activities?.reduce((sum, { numberOfCredits }) => sum + Number(numberOfCredits), 0)
);

export const praProofDocsSelector = createSelector(activitiesSelector, (data) => {
  let merged: CreditActivityAttachment[] = [];
  for (const activity of data) {
    merged = merged.concat(activity.creditActivityAttachments);
  }
  return merged;
});

export const awardTypeSelector = createSelector(praIsCommendation, praCertificateDuration, praClaimMethod, getAwardTypeString);
