import React from 'react';
import styled from 'src/theme';
import { themeBreakpoint, themeColor } from 'src/theme/helpers';
import { Color } from 'src/theme/primaryTheme';
import Text from '../Text';

const MobileDisclaimer: React.FunctionComponent = () => <MobileOnlyText textStyle="small">Desktop browser recommended</MobileOnlyText>;

const MobileOnlyText = styled(Text)`
  display: none;

  @media (max-width: ${themeBreakpoint('medium')}) {
    display: block;
    color: ${themeColor(Color.Primary)};
  }
`;

export default MobileDisclaimer;
