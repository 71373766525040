import React from 'react';
import { useDispatch } from 'react-redux';
import { ModalType } from 'src/constants/ModalType';
import { hideModal } from 'src/ducks/modal';
import { User } from 'src/types';
import ActionModal from '../ActionModal';
import Text, { TextStyle } from '../Text';

interface Props {
  viewingUser: User;
  onConfirm: () => void;
  onCancel: () => void;
}

const ResumePraApplicationModal: React.FunctionComponent<Props> = ({ viewingUser, onConfirm, onCancel }) => {
  const dispatch = useDispatch();
  const handleCancel = () => {
    dispatch(hideModal(ModalType.ResumePraApplication));
    onCancel();
  };
  const handleConfirm = () => {
    dispatch(hideModal(ModalType.ResumePraApplication));
    onConfirm();
  };
  return (
    <ActionModal
      modalId={ModalType.ResumePraApplication}
      title="PRA Application in Progress"
      onConfirm={handleConfirm}
      showButtons={true}
      onCancel={handleCancel}
      cancelLabel={viewingUser.isCreditProcessor ? 'Go to Applications' : 'Go back'}
      confirmLabel="Complete PRA Application"
    >
      {viewingUser.isCreditProcessor ? (
        <Text textStyle={TextStyle.Default}>
          There is a PRA application in progress for this user. You won't be able to initiate a new application unless you complete it.
        </Text>
      ) : (
        <>
          <Text textStyle={TextStyle.Default}>
            You have an outstanding PRA application. You won't be able to initiate a new application unless you complete it.
          </Text>
        </>
      )}
    </ActionModal>
  );
};

export default ResumePraApplicationModal;
