import React from 'react';
import Row from './Row';
import { SortOrder } from './SortableTableHeaderCell';
import { Root, TableBody, TableHeader, TableHeaderRow } from './styles';
import TableHeaderCell from './TableHeaderCell';

type ColumnAccessor = (data: any) => string | number | Record<string, any> | React.ReactElement;

export interface RenderCellProps {
  config?: ColumnConfig;
  value?: string | number | Record<string, any>;
  data?: any;
  columns?: any;
}
type RenderCellFunction = (props: RenderCellProps) => React.ComponentType;
export type SortOrderValues = 'ASC' | 'DESC';
export interface ColumnConfig {
  id: string;
  header: string;
  accessor: ColumnAccessor | string;
  renderCell?: RenderCellFunction;
  cellComponent?: React.ComponentType<any>;
  setSortOrder?: (sortOrder: SortOrder) => SortOrder;
  getSortOrder?: (sortOrder: SortOrder) => SortOrderValues;
  size?: any;
  width?: string;
  align?: 'left' | 'right' | 'center';
  headerCellComponent?: React.ComponentType<any>;
  onHeaderClick?: (column: ColumnConfig) => void;
  onCellClick?: (data?: any) => void;
}

export interface Props {
  columns: ColumnConfig[];
  data: any[];
  id?: string;
  className?: string;
}

const Table: React.FunctionComponent<Props> = ({ columns, data = [], id = 'defaultTable', className = '' }) => {
  return (
    <Root data-test-id={id} id={id} className={className}>
      <TableHeader>
        <TableHeaderRow>
          {columns.map((column: any, index: number) => {
            const HeaderComponent = column.headerCellComponent ? column.headerCellComponent : TableHeaderCell;
            return <HeaderComponent data-test-id={`header-${column.id}`} column={column} key={column.id || `th-${index}`} />;
          })}
        </TableHeaderRow>
      </TableHeader>
      <TableBody>
        {data.map((row: any, index: number) => (
          <Row key={row.id || index} columns={columns} data={row} />
        ))}
      </TableBody>
    </Root>
  );
};

export default Table;
