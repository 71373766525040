import { FormikValues, getIn } from 'formik';
import React, { useState } from 'react';
import { countryOptions } from 'src/layouts/Forms/countryOptions';
import { stateOptions } from '../../layouts/Forms/stateOptions';
import { FieldColumn, FieldRow } from '../FieldRow';
import Select from './Controls/Select';
import Field, { FieldOnChange } from './index';

interface Props {
  fieldNames?: Partial<Fields>;
  label?: string;
  disabled?: boolean;
  showCountry?: boolean;
  values?: FormikValues;
}

interface Fields {
  address1: string;
  address2: string;
  city: string;
  stateCode: string;
  postalCode: string;
  countryCode?: string;
}

const AddressField: React.FunctionComponent<Props> = ({
  fieldNames = {},
  label = 'Address',
  disabled = false,
  showCountry = false,
  values = {},
}) => {
  const names = {
    address1: 'address1',
    address2: 'address2',
    city: 'city',
    stateCode: 'stateCode',
    postalCode: 'zip',
    countryCode: 'countryCode',
    ...fieldNames,
  };
  const [showUsStates, setShowUsStates] = useState(getIn(values, names.countryCode) === 'USA');
  const handleCountryChange: FieldOnChange = (event) => {
    if (event.currentTarget.value === 'USA') {
      setShowUsStates(true);
    } else {
      setShowUsStates(false);
    }
  };

  return (
    <>
      <FieldRow>
        <FieldColumn grow={1}>
          <Field name={names.address1} label={label} placeholder="Street" disabled={disabled} />
          <Field placeholder="Apartment, suite, unit, etc." name={names.address2} disabled={disabled} />
        </FieldColumn>
      </FieldRow>
      <FieldRow>
        <FieldColumn width="50%">
          <Field name={names.city} label="City" placeholder="Anytown" disabled={disabled} />
        </FieldColumn>
        {!showCountry && (
          <FieldColumn width="25%">
            <Field
              name={names.stateCode}
              label="State"
              disabled={disabled}
              component={Select}
              controlProps={{
                options: stateOptions,
                defaultLabel: 'State',
              }}
            />
          </FieldColumn>
        )}
        <FieldColumn width="25%">
          <Field name={names.postalCode} label="ZIP" placeholder="12345" disabled={disabled} />
        </FieldColumn>
      </FieldRow>
      {showCountry && (
        <FieldRow>
          <FieldColumn width="50%">
            <Field
              name={names.countryCode}
              label="Country"
              disabled={disabled}
              component={Select}
              onChange={handleCountryChange}
              controlProps={{
                options: countryOptions.map((country) => ({ label: country.label, value: country.code })),
                defaultLabel: 'Country',
              }}
            />
          </FieldColumn>
          <FieldColumn width="25%">
            {showUsStates ? (
              <Field
                name={names.stateCode}
                label="State"
                disabled={disabled}
                component={Select}
                controlProps={{
                  options: stateOptions,
                  defaultLabel: 'State',
                }}
              />
            ) : (
              <Field name={names.stateCode} label="State" placeholder="" disabled={disabled} />
            )}
          </FieldColumn>
        </FieldRow>
      )}
    </>
  );
};

export default AddressField;
