import 'react-app-polyfill/ie11'; // WARNING: Must be at VERY top of file for the app to run on IE11
import 'react-app-polyfill/stable'; // WARNING: Must be at VERY top of file for the app to run on IE11
import 'airbnb-browser-shims';
import React from 'react';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { AnyAction, CombinedState, Store } from 'redux';
import './assets/styles/react-dates-overrides.css';
import AppRoutes from './components/AppRoutes';
import configureStore from './configureStore';
import { ReduxStore } from './ducks';
import { login } from './ducks/authentication';
import { GlobalStyle, primaryTheme, ThemeProvider } from './theme/index';
import { authenticate, getToken } from './utilities/authentication';
// import registerServiceWorker from './registerServiceWorker';

const store = configureStore();

/**
 * Before we do anything else, check if there's a token query parameter
 * and authenticate accordingly.
 */
if (getToken()) {
  authenticate();
  store.dispatch(login());
}

ReactDOM.render(
  <>
    <ThemeProvider theme={primaryTheme}>
      <Provider store={store}>
        <GlobalStyle />
        <AppRoutes />
      </Provider>
    </ThemeProvider>
  </>,
  document.getElementById('root') as HTMLElement
);

// registerServiceWorker();

type ActualWindow = Window &
  typeof globalThis & {
    Cypress?: Record<string, unknown>;
    store?: Store<CombinedState<ReduxStore>, AnyAction> & { dispatch: unknown };
  };

// expose store when run in Cypress
if ((window as ActualWindow).Cypress) {
  (window as ActualWindow).store = store;
}
