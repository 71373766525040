import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { resetClaim } from 'src/ducks/claims';
import { fetchCreditEarner, resetCreditEarner } from 'src/ducks/creditEarners';
import { userSearchSelectedResult } from 'src/selectors/userSearch';
import { Routes } from '../../constants/Routes';
import ApplicationPage from '../../layouts/ApplicationPage';
import { creditEarnerDataSelector, creditEarnerLoadingSelector } from '../../selectors/creditEarner';
import { ExternalCreditEarner } from '../../types';
import { ApplicationStepLabel } from '../ApplicationProgressBar/ApplicationProgressBar';
import CreditEarnerForm from '../CreditEarnerForm';
import LoadingSpinner from '../LoadingSpinner';

const ApplicantInformation: React.FunctionComponent = () => {
  const { id } = useParams<{ id?: string }>();
  const dispatch = useDispatch();
  const history = useHistory();
  const creditEarner = useSelector(creditEarnerDataSelector);
  const isLoading = useSelector(creditEarnerLoadingSelector);
  const selectedResult = useSelector(userSearchSelectedResult);

  useEffect(() => {
    if (id) {
      dispatch(fetchCreditEarner(id, { sync: true }));
    } else {
      dispatch(resetCreditEarner());
    }
    dispatch(resetClaim());
  }, [dispatch, id]);

  const getRedirect = (responseData: ExternalCreditEarner) => {
    return {
      pathname: Routes.ApplicationNew,
      state: { claimUser: responseData },
      search: `?userId=${responseData.id}`,
    };
  };

  return (
    <ApplicationPage
      title="Profile Info"
      subtitle="Review this user's personal information."
      applicationStep={ApplicationStepLabel.ReviewProfile}
    >
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <CreditEarnerForm
          history={history}
          user={id ? creditEarner : selectedResult}
          submitText="Save and Continue"
          redirectTo={getRedirect}
        />
      )}
    </ApplicationPage>
  );
};

export default ApplicantInformation;
