import { ErrorMessage, Field as FormikField, FieldProps as FormikFieldProps, getIn } from 'formik';
import moment from 'moment';
import React from 'react';
import { formattedDate } from '../../utilities/moment';
import { FieldLabel } from '../Label';
import DatePicker from './Controls/DatePicker';
import LockedInput from './Controls/LockedInput';
import { ErrorText, StyledField } from './styles';

interface ControlProps extends React.InputHTMLAttributes<HTMLInputElement> {
  variant?: 'small' | 'default';
  boldLabel?: boolean;
}

interface Props {
  name: string;
  label?: string;
  required?: boolean;
  locked?: boolean;
  showErrorMessage?: boolean;
  placeholder?: string;
  isOutsideRange?: (day: any) => boolean;
  controlProps?: ControlProps;
}

const DatePickerField = ({
  name,
  label,
  required = false,
  isOutsideRange,
  showErrorMessage = true,
  locked: lockedFromProps = false,
  controlProps,
  ...rest
}: Props) => {
  return (
    <FormikField name={name}>
      {({ field, form }: FormikFieldProps) => {
        const hasError = getIn(form.touched, field.name) && getIn(form.errors, field.name);

        const dateValue = field.value ? moment(field.value) : null;
        const locked = lockedFromProps || getIn(form.values, `sources.${name}`) === 'AIMS';
        const bold = controlProps && controlProps.boldLabel;

        return (
          <>
            {label && <FieldLabel hasError={hasError} required={required} locked={locked} text={label} bold={bold} />}
            <StyledField border={true} hasError={hasError} hasFocus={false}>
              {locked ? (
                <LockedInput value={formattedDate(field.value)} data-testid={`${name}-locked`} {...controlProps} />
              ) : (
                <DatePicker date={dateValue} isOutsideRange={isOutsideRange} {...field} {...form} {...rest} hasError={hasError} />
              )}
            </StyledField>

            {showErrorMessage && (
              <ErrorMessage name={name}>
                {(errorMessage) => (
                  <ErrorText data-test-id={`${name}-error`} {...rest}>
                    {errorMessage}
                  </ErrorText>
                )}
              </ErrorMessage>
            )}
          </>
        );
      }}
    </FormikField>
  );
};

export default DatePickerField;
