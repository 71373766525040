import React from 'react';
import { connect } from 'react-redux';
import Toggle from 'react-toggled';
import styled from 'src/theme';
import { themeBreakpoint, themeColor } from 'src/theme/helpers';
import { User } from 'src/types';
import avatarIcon from '../../assets/images/avatar.svg';
import { LinkStyle } from '../../constants/LinkStyle';
import { ReduxStore } from '../../ducks';
import { fetchUser } from '../../ducks/user';
import { authTokenSelector, isAuthenticated } from '../../selectors/auth';
import { userDataSelector, userInitializedSelector, userLoadingSelector } from '../../selectors/user';
import { Color } from '../../theme/primaryTheme';
import { handleLogout } from '../../utilities/authentication';
import { Dropdown, DropdownButton, DropdownPanel } from '../Dropdown';
import LoadingSpinner from '../LoadingSpinner';
import LoginButton from '../LoginButton';

interface UserMenuProps {
  fullName: string;
  isAuthenticated: boolean;
  isInitialized: boolean;
  user: User;
  fetchUser: any;
  isLoading: boolean;
}

class UserMenu extends React.Component<UserMenuProps> {
  public componentDidMount(): void {
    const { fetchUser, user, isAuthenticated } = this.props;
    if (isAuthenticated && !user.id) {
      fetchUser();
    }
  }

  public render() {
    const { fullName, isLoading, isAuthenticated, isInitialized = false } = this.props;

    if (isAuthenticated && (isLoading || !isInitialized)) {
      return (
        <Root>
          <SpinnerContainer>
            <LoadingSpinner size={16} />
          </SpinnerContainer>
        </Root>
      );
    }

    if (!isAuthenticated) {
      return (
        <Root>
          <LoginButton linkStyle={LinkStyle.TextButton}>
            <UserButton>
              <UserMenuContainer>
                <img src={avatarIcon} alt={fullName} />
                <UserMenuName>Sign In</UserMenuName>
              </UserMenuContainer>
            </UserButton>
          </LoginButton>
        </Root>
      );
    }

    return (
      <Root>
        <Toggle>
          {({ on, toggle, setOff, getTogglerProps }) => (
            <Dropdown styling="userMenu">
              <StyledDropdownButton onClick={toggle} isOpen={on} colorKey={Color.Accent} {...getTogglerProps()}>
                <UserMenuContainer>
                  <img src={avatarIcon} alt={fullName} />
                  <UserMenuName>{fullName}</UserMenuName>
                </UserMenuContainer>
              </StyledDropdownButton>
              <DropdownPanel onClickOutside={setOff} isOpen={on}>
                <UserMenuNav>
                  <UserMenuNavItem onClick={() => handleLogout()}>Logout</UserMenuNavItem>
                </UserMenuNav>
              </DropdownPanel>
            </Dropdown>
          )}
        </Toggle>
      </Root>
    );
  }
}

const getFullName = (data: any): string => {
  const { firstName, lastName } = data;

  return `${firstName || ''} ${lastName || ''}`;
};

const Root = styled.div`
  display: flex;
  align-items: center;
`;

const SpinnerContainer = styled.div`
  display: flex;
  padding: 2.4rem;
  text-align: center;

  @media (max-width: ${themeBreakpoint('medium')}) {
    padding: 0 1rem;
  }
`;

const UserButton = styled.div`
  appearance: none;
  background-color: transparent;
  border: none;
  box-shadow: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  text-align: left;
  padding: 2.4rem;
  border-radius: 0.5rem;
  transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);

  &:hover {
    background-color: ${themeColor(Color.BackgroundLight)};
  }

  @media (max-width: ${themeBreakpoint('medium')}) {
    padding: 0;
  }
`;

const UserMenuContainer = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: ${themeBreakpoint('medium')}) {
    flex-direction: row-reverse;
  }
`;

const UserMenuName = styled.div`
  padding-left: 1.2rem;
  font-weight: 700;
  font-size: 1.4rem;
  line-height: 1;

  @media (max-width: ${themeBreakpoint('medium')}) {
    padding-left: 0;
    padding-right: 1.2rem;
  }
`;

const StyledDropdownButton = styled(DropdownButton)`
  @media (max-width: ${themeBreakpoint('medium')}) {
    padding: 0.5rem;
  }
`;

const UserMenuNav = styled.nav`
  background: ${themeColor('primary')};
`;

const UserMenuNavItem = styled.button`
  text-align: left;
  width: 100%;
  appearance: none;
  border: none;
  box-shadow: none;
  cursor: pointer;
  font-weight: 700;
  font-size: 1.4rem;
  padding: 2.4rem;
  color: #fff;
  background-color: ${themeColor('primary')};
  transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);

  &:hover {
    color: ${themeColor('accent')};
  }
`;

const mapDispatchToProps = {
  fetchUser,
};

const mapStateToProps = (state: ReduxStore) => {
  const data = userDataSelector(state);

  return {
    fullName: getFullName(data),
    isLoading: userLoadingSelector(state),
    isAuthenticated: isAuthenticated(state),
    isInitialized: userInitializedSelector(state),
    user: userDataSelector(state),
    token: authTokenSelector(state),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserMenu);
