import * as yup from 'yup';
import { stateOptions } from '../../layouts/Forms/stateOptions';
import { phoneRegex, zipRegex } from '../../utilities/regex';

const stateValues = stateOptions.map((state) => state.value);

export const validationSchema = yup.object().shape(
  {
    firstName: yup.string().label('First Name').required(),
    lastName: yup.string().label('Last Name').required(),
    emails: yup.array().of(yup.string().label('Email').required('Email is required').email('Must be valid email').label('Email')),
    dob: yup.string().nullable(true).label('Date of Birth').typeError('Please enter a valid date'),
    state: yup.string().label('State').oneOf(stateValues),
    zip: yup.string().nullable(true).label('ZIP').matches(zipRegex, {
      message: 'Must be valid ZIP code',
      excludeEmptyString: true,
    }),
    address1: yup.string().label('Address'),
    city: yup.string().label('City'),
    phone: yup.string().label('Phone').matches(phoneRegex, {
      message: 'Must be valid phone number',
      excludeEmptyString: true,
    }),
    fax: yup.string().label('Fax').matches(phoneRegex, {
      message: 'Must be a valid phone number',
      excludeEmptyString: true,
    }),
    medSchool: yup.string().label('Medical School'),
    gradYear: yup.string().label('Graduation Date'),
  },
  [['npiNumbers', 'emails']]
);
