import { connect as formikConnect, Field as FormikField, FieldProps as FormikFieldProps, FormikContextType, getIn } from 'formik';
import React from 'react';
import { FileRejection, useDropzone } from 'react-dropzone';
import styled from '../../theme';
import { themeColor } from '../../theme/helpers';
import { Color, ThemeColors } from '../../theme/primaryTheme';
import { ColorKeys } from '../../theme/theme';
import { ButtonClickEvent } from '../../types';
import Button from '../Button';
import { StyledField } from '../Field/styles';
import Icon from '../Icon/index';
import Text from '../Text';
import { Values } from './BulkLearnerActivityUploadForm';

interface Props {
  name: string;
  placeholder?: string;
}

interface FormikConnectProps {
  formik: FormikContextType<Values>;
}

const BulkLearnerActivityUploadField: React.FunctionComponent<Props & FormikConnectProps> = ({ name, placeholder, formik }) => {
  const { setFieldValue, setFieldError, values } = formik;
  const acceptableFormats = ['text/csv'];
  const file = getIn(values, name);

  const handleFileChange = (files: File[]) => setFieldValue(name, files[0]);
  const handleIncorrectFileFormat = (fileRejections: FileRejection[]) =>
    setFieldError(name, `${fileRejections[0].file.name} is not a CSV file`);
  const handleRemoveClick = (event: ButtonClickEvent) => {
    setFieldValue(name, null);
    event.stopPropagation();
  };

  const { getInputProps, getRootProps } = useDropzone({
    accept: acceptableFormats,
    onDropAccepted: handleFileChange,
    onDropRejected: handleIncorrectFileFormat,
  });

  return (
    <FormikField name={name}>
      {({ form }: FormikFieldProps) => {
        const fieldError = getIn(form.errors, name);
        return (
          <>
            <DropzoneRoot {...getRootProps()}>
              <FileField border={true} hasError={!!fieldError}>
                {file ? (
                  <FileItem>
                    <FileName data-test-id="fileName">{file.name}</FileName>
                    <RemoveButton title="Remove file" buttonStyle="toggle" onClick={handleRemoveClick} color={Color.Border as ColorKeys}>
                      <Icon name="minusCircle" width={12} height={12} viewBox="0 0 12 12" />
                    </RemoveButton>
                  </FileItem>
                ) : (
                  <>
                    <input {...getInputProps({ name, multiple: false })} />
                    {placeholder && <Text textStyle="placeholder">{placeholder}</Text>}
                  </>
                )}
              </FileField>
              <UploadIcon name="upload" fill={ThemeColors[Color.Accent]} width={30} height={30} viewBox="20 20 60 60" />
            </DropzoneRoot>
            {fieldError && (
              <FieldError>
                {`This file was not accepted for the following reasons: ${fieldError}. Please correct and try again.`}
              </FieldError>
            )}
          </>
        );
      }}
    </FormikField>
  );
};

const FileName = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const RemoveButton = styled(Button)`
  padding: 0.5rem;
`;

const FileItem = styled.li`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const DropzoneRoot = styled.div`
  display: flex;
  height: inherit;
  align-items: center;

  &:hover {
    cursor: pointer;
  }
`;

const FileField = styled(StyledField)`
  flex: 1;
  display: flex;
  align-items: center;
  height: inherit;
  padding: 0 2rem;
`;

const UploadIcon = styled(Icon)`
  margin: 0 1rem;
`;

const FieldError = styled.div`
  padding: 1rem;
  color: ${themeColor(Color.Error)};
`;

export default formikConnect<Props, Values>(BulkLearnerActivityUploadField);
