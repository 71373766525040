import { getIn } from 'formik';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { CreditClaimStatus } from '../../constants/ApplicationStatus';
import { ApplicationType } from '../../constants/ApplicationType';
import { ApplicationTypeCode } from '../../constants/ApplicationTypeCode';
import { LinkStyle } from '../../constants/LinkStyle';
import { PraClaimMethod } from '../../constants/PraClaimMethod';
import { buildRoute, Routes } from '../../constants/Routes';
import { patchClaimStatus, updateClaimStatus } from '../../ducks/claims';
import ApplicationPage from '../../layouts/ApplicationPage';
import {
  claimDataSelector,
  claimUserSelector,
  totalCategory1ActivityCreditsSelector,
  totalCategory2ActivityCreditsSelector,
} from '../../selectors/claims';
import { isCreditProcessor } from '../../selectors/user';
import { CreditActivity, CreditClaim } from '../../types';
import Alert, { AlertType } from '../Alert';
import { ApplicationStepLabel } from '../ApplicationProgressBar/ApplicationProgressBar';
import Debug from '../Debug';
import FetchClaim from '../FetchClaim';
import Link from '../Link';
import ExternalLink from '../Link/ExternalLink';
import { calculateCreditRequirementsForAwardType } from '../PraActivityDetails/creditCalculations';
import PraCreditSummary from '../PraActivityDetails/PraCreditSummary';
import { CreditActivitiesSummary } from '../SingleClaim/Sections/CreditActivitiesSummary';
import { StickyContainer } from '../StickyContainer';
import Text from '../Text';

const ApplicationFinished: React.FunctionComponent = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { orderToken } = useParams<{ orderToken: string }>();
  const claim = useSelector(claimDataSelector);
  const isProcessor = useSelector(isCreditProcessor);
  const claimUser = useSelector(claimUserSelector);
  const totalCategory1ActivityCredits = useSelector(totalCategory1ActivityCreditsSelector);
  const totalCategory2ActivityCredits = useSelector(totalCategory2ActivityCreditsSelector);

  const [error, setError] = React.useState('');

  const methodOfPayment = getIn(claim, 'creditClaimOrder.methodOfPayment', '');

  const newFinishedStatus = (status: CreditClaimStatus, orderToken?: string) => {
    if (
      [CreditClaimStatus.APPROVED, CreditClaimStatus.UNSUBMITTED, CreditClaimStatus.PAYMENT_FAILED].includes(status) &&
      (orderToken || methodOfPayment)
    ) {
      return isProcessor ? CreditClaimStatus.COMPLETED : CreditClaimStatus.SUBMITTED;
    }
    return undefined;
  };
  const newProcessingStatus = (status: CreditClaimStatus) =>
    isProcessor && status === CreditClaimStatus.SUBMITTED ? CreditClaimStatus.PROCESSING : undefined;

  /**
   * When loading the claim:
   *  If there is an OrderToken parameter in the URL or the claim has a non-empty methodOfPayment
   *    AND the claim has Approved, Unsubmitted, or Payment Failed status
   *  Then
   *    If a Credit Processor is loading the claim
   *      Then the claim is set to COMPLETED
   *    Else (the Credit Earner is loading the claim)
   *      Then the claim status is set to SUBMITTED
   *  Else
   *    If a Credit Processor is loading the claim and the status is SUBMITTED
   *      Then the claim status is set to PROCESSING
   *
   *  If there was any change to the status based on the above logic
   *  Then
   *    The updated claim status is sent to the API
   *    The page redirects back to itself and strips away the OrderToken from the URL
   */
  React.useEffect(
    () => {
      const { id, status } = claim;

      if (id) {
        const newStatus = newFinishedStatus(status, orderToken) || newProcessingStatus(status);

        if (newStatus) {
          // Remove Advantage orderToken from URL on success
          const onSuccess = () => {
            orderToken && history.replace(buildRoute(Routes.ApplicationComplete, { id }));
          };
          const onError = () => {
            setError(`There was an issue updating your claim to be ${newStatus}.`);
          };

          if (newStatus === CreditClaimStatus.SUBMITTED) {
            //TODO: as all other statuses are handled in EdHub Reporting, this if block can be replaced with this call to patchClaimStatus
            dispatch(patchClaimStatus({ claimId: id, newStatus, onSuccess, onError }));
          } else {
            dispatch(updateClaimStatus({ claimId: id, newStatus, onSuccess, onError }));
          }
        }
      }
    },
    [claim.id] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const getPageTitle = ({ status, applicationTypeCode }: CreditClaim) => {
    if ([CreditClaimStatus.SUBMITTED, CreditClaimStatus.COMPLETED].includes(status)) {
      return `Congratulations! Your ${ApplicationType[applicationTypeCode]} application ${
        status === CreditClaimStatus.SUBMITTED ? 'has been submitted' : 'is complete'
      }.`;
    } else {
      return '';
    }
  };

  const getPageSubtitle = ({ status, applicationTypeCode }: CreditClaim) => {
    if (status === CreditClaimStatus.SUBMITTED) {
      return 'Come back to this page to see the status of your application. Applications will be processed within 20 days of submission.';
    } else if (status === CreditClaimStatus.COMPLETED) {
      return applicationTypeCode === ApplicationTypeCode.PRA
        ? 'You can see a digital copy of your award by clicking "View My Transcript" and downloading the award on that page.'
        : 'You can view the credits on your transcript by clicking "View My Transcript".';
    } else {
      return '';
    }
  };

  const hasFailedLearnerActivityTransfers =
    claim.applicationTypeCode !== ApplicationTypeCode.PRA &&
    claim.creditActivities &&
    claim.creditActivities.filter((activity: CreditActivity) => !activity.learnerActivityId).length > 0;

  const supportEmailAddress = claim.applicationTypeCode === ApplicationTypeCode.PRA ? 'pra@ama-assn.org' : 'mosupport@ama-assn.org';

  return (
    <FetchClaim>
      <ApplicationPage
        title={getPageTitle(claim)}
        subtitle={getPageSubtitle(claim)}
        applicationTypeCode={claim.applicationTypeCode}
        applicationStep={ApplicationStepLabel.ApplicationComplete}
      >
        {error && (
          <Alert data-test-id="orderDetailsError" type={AlertType.Error}>
            <Text>{error}</Text>
            <Text>{`Please contact the AMA Unified Service Center at 800 262-3211, Monday – Friday, 7:00 AM – 6:00 PM CST, or send an email to ${supportEmailAddress}.`}</Text>
          </Alert>
        )}
        {claim.status === CreditClaimStatus.COMPLETED && hasFailedLearnerActivityTransfers && (
          <Alert data-test-id="failedTransferWarning" type={AlertType.Warning}>
            Some activities failed to generate transcripts. Please consult an administrator.
          </Alert>
        )}
        {[ApplicationTypeCode.DirectCredit, ApplicationTypeCode.InternationalCreditConversion].includes(claim.applicationTypeCode) && (
          <StyledCreditActivitiesSummary />
        )}
        {claim.applicationTypeCode === ApplicationTypeCode.PRA && claim.praCertificateClaimMethod === PraClaimMethod.PreviousCME && (
          <StyledStickyContainer>
            <PraCreditSummary
              creditRequirements={calculateCreditRequirementsForAwardType(claim)}
              totalCategory1ActivityCredits={totalCategory1ActivityCredits}
              totalCategory2ActivityCredits={totalCategory2ActivityCredits}
            />
          </StyledStickyContainer>
        )}
        {isProcessor ? (
          <Link to={buildRoute(Routes.UserTranscript, { id: claimUser?.id })} linkStyle={LinkStyle.Button}>
            View Transcript
          </Link>
        ) : (
          <Link to={Routes.Transcript} linkStyle={LinkStyle.Button}>
            View My Transcript
          </Link>
        )}
        <ApplicationSupportText tag="p">
          For any questions about your application, please contact the AMA at{' '}
          <ExternalLink linkStyle={LinkStyle.ExternalLink} href={`mailto:${supportEmailAddress}`}>
            {supportEmailAddress}
          </ExternalLink>{' '}
        </ApplicationSupportText>
        <Debug json={{ status: claim.status }} />
      </ApplicationPage>
    </FetchClaim>
  );
};

const StyledCreditActivitiesSummary = styled(CreditActivitiesSummary)`
  margin-bottom: 5rem;
`;

const StyledStickyContainer = styled(StickyContainer)`
  margin-bottom: 7rem;
`;

const ApplicationSupportText = styled(Text)`
  margin-top: 3rem;
`;

export default ApplicationFinished;
