import { get } from 'lodash';

export const themeColor = (colorKey: string): ((props: Record<string, any>) => string) => {
  return (props): string => {
    return get(props, `theme.colors.${colorKey}`, '');
  };
};

export const themeBreakpoint = (bpKey: string): ((props: Record<string, any>) => string) => {
  return (props): string => {
    return get(props, `theme.breakpoints.${bpKey}`, '');
  };
};

export const themeSize = (sizeKey: string): ((props: Record<string, any>) => string) => {
  return (props): string => {
    return get(props, `theme.sizes.${sizeKey}`, '');
  };
};
