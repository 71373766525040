import { ArrayHelpers, FieldArray, FormikValues, getIn } from 'formik';
import React from 'react';
import { useSelector } from 'react-redux';
import { claimIsCompletedSelector, claimIsEditableSelector } from 'src/selectors/claims';
import { creditActivityIsEditable } from 'src/utilities/creditActivities';
import { ActivityStatus } from '../../constants/ActivityStatus';
import { ActivityType } from '../../constants/ActivityType';
import { isCreditProcessor } from '../../selectors/user';
import { CreditActivity } from '../../types';
import ActivityCard from '../ActivityCard';
import ActivityFields from '../ActivityFields';
import ActivityInfoSummary from '../ActivityInfoSummary';
import { FieldColumn, FieldRow } from '../FieldRow';
import Label from '../Label';
import StatusLabel from '../StatusLabel';
import SummaryValue from '../SummaryValue';
import { initialActivityValue } from './index';

interface Props {
  values: FormikValues;
  name: string;
}

const IccActivities: React.FunctionComponent<Props> = ({ values, name }) => {
  const isProcessor = useSelector(isCreditProcessor);
  const claimIsCompleted = useSelector(claimIsCompletedSelector);
  const claimIsEditable = useSelector(claimIsEditableSelector);

  return (
    <FieldArray name={name}>
      {(arrayHelpers: ArrayHelpers) => {
        const addItem = () => {
          arrayHelpers.push({
            ...initialActivityValue,
            activityTypeCode: ActivityType.ICC,
          });
        };
        const { creditActivities = [] } = values;

        if (!creditActivities.length) {
          addItem();
        }

        return (
          <div data-test-id="activities">
            {creditActivities.map((activity: CreditActivity, index: number) => {
              const activityIsEditable = creditActivityIsEditable({
                activity,
                claimIsCompleted,
                claimIsEditable,
                isProcessor,
              });

              return (
                <ActivityCard locked={!activityIsEditable} title="Activity Details" key={`activity-${index}`} allowRemoval={false}>
                  {!activityIsEditable ? (
                    <ActivityInfoSummary
                      baseName={`${name}[${index}]`}
                      values={values}
                      selectedType={getIn(values, `${name}.[${index}].activityTypeCode`)}
                    />
                  ) : (
                    <ActivityFields selectedType={ActivityType.ICC} baseName={`${name}[${index}]`} values={values} />
                  )}

                  {!isProcessor && activity.status === ActivityStatus.FollowUp && (
                    <>
                      <FieldRow>
                        <FieldColumn>
                          <Label tag="p">Activity Status</Label>
                          <div>
                            <StatusLabel status={activity.status} />
                          </div>
                        </FieldColumn>
                      </FieldRow>
                      {activity.notes && (
                        <FieldRow>
                          <FieldColumn>
                            <SummaryValue label="Notes" value={activity.notes} />
                          </FieldColumn>
                        </FieldRow>
                      )}
                    </>
                  )}
                </ActivityCard>
              );
            })}
          </div>
        );
      }}
    </FieldArray>
  );
};

export default IccActivities;
