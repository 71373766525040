import styled, { css } from '../../theme';
import { themeBreakpoint, themeColor } from '../../theme/helpers';
import { Color } from '../../theme/primaryTheme';

export enum AlertType {
  Error = 'error',
  TableError = 'tableError',
  Success = 'success',
  Default = 'default',
  Warning = 'warning',
}
export interface AlertProps {
  type: AlertType;
}

const alertStyles = {
  error: css`
    background-color: ${themeColor(Color.Error)};
    color: ${themeColor(Color.Reverse)};
  `,
  tableError: css`
    color: ${themeColor(Color.Error)};
    margin-bottom: 0;
    box-shadow: none;
  `,
  success: css`
    background-color: ${themeColor(Color.Confirmation)};
    color: ${themeColor(Color.Reverse)};
  `,
  warning: css`
    background-color: ${themeColor(Color.Reverse)};
    border-left: 6px solid ${themeColor(Color.Warning)};
  `,
  default: css`
    border: 1px solid ${themeColor(Color.Border)};
    background-color: ${themeColor(Color.Reverse)};
  `,
};

const Alert = styled.div<AlertProps>`
  margin-bottom: 4rem;
  padding: 1rem 2rem;
  border-radius: 5px;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.05);
  white-space: pre-wrap;
  ${({ type }) => (type ? alertStyles[type] : alertStyles[AlertType.Default])};

  & > * + * {
    margin-top: 2rem;
  }

  @media (max-width: ${themeBreakpoint('medium')}) {
    padding: 0 1rem;
    margin-bottom: 0;
  }
`;

export default Alert;
