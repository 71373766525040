import { AxiosError, AxiosResponse } from 'axios';
import { normalize, schema } from 'normalizr';
import { Dispatch } from 'redux';
import { LearnerActivity, PagedResponse } from '../types';
import { apiRequest } from '../utilities/api';
import { TranscriptStore } from './types';

const FETCH_TRANSCRIPT_REQUEST = '@TRANSCRIPT/REQUEST';
const FETCH_TRANSCRIPT_SUCCESS = '@TRANSCRIPT/SUCCESS';
const FETCH_TRANSCRIPT_ERROR = '@TRANSCRIPT/ERROR';

interface FetchTranscriptRequestAction {
  type: typeof FETCH_TRANSCRIPT_REQUEST;
  userId: number;
}

interface FetchTranscriptSuccessAction {
  type: typeof FETCH_TRANSCRIPT_SUCCESS;
  data: TranscriptStore;
  userId: number;
}

interface FetchTranscriptErrorAction {
  type: typeof FETCH_TRANSCRIPT_ERROR;
  error: any;
}

type FetchTranscriptAction = FetchTranscriptRequestAction | FetchTranscriptSuccessAction | FetchTranscriptErrorAction;

const CHANGE_TRANSCRIPT_PAGE = '@TRANSCRIPT/PAGE_CHANGE';

interface ChangeTranscriptPageAction {
  type: typeof CHANGE_TRANSCRIPT_PAGE;
  page: number;
}

const DELETE_ACTIVITY_REQUEST = '@ACTIVITY/REQUEST';
const DELETE_ACTIVITY_SUCCESS = '@ACTIVITY/SUCCESS';
const DELETE_ACTIVITY_ERROR = '@ACTIVITY/ERROR';

interface DeleteActivityRequestAction {
  type: typeof DELETE_ACTIVITY_REQUEST;
  activityId: number;
}

interface DeleteActivitySuccessAction {
  type: typeof DELETE_ACTIVITY_SUCCESS;
  activityId: number;
}

interface DeleteActivityErrorAction {
  type: typeof DELETE_ACTIVITY_ERROR;
  activityId: number;
}

type DeleteActivityAction = DeleteActivityRequestAction | DeleteActivitySuccessAction | DeleteActivityErrorAction;

type DeleteActivityRequestActionCreator = (activityId: number) => DeleteActivityRequestAction;
export const deleteActivityRequest: DeleteActivityRequestActionCreator = (activityId) => {
  return {
    type: DELETE_ACTIVITY_REQUEST,
    activityId,
  };
};

type DeleteActivitySuccessActionCreator = (activityId: number) => DeleteActivitySuccessAction;
export const deleteActivitySuccess: DeleteActivitySuccessActionCreator = (activityId) => {
  return {
    type: DELETE_ACTIVITY_SUCCESS,
    activityId,
  };
};

type DeleteActivityErrorActionCreator = (activityId: number, error: any) => DeleteActivityErrorAction;
export const deleteActivityError: DeleteActivityErrorActionCreator = (activityId, error) => {
  return {
    type: DELETE_ACTIVITY_ERROR,
    activityId,
    error,
  };
};

/**
 * Request action creator
 */

type FetchTranscriptRequestActionCreator = (userId: number) => FetchTranscriptRequestAction;

export const fetchTranscriptRequest: FetchTranscriptRequestActionCreator = (userId) => {
  return {
    type: FETCH_TRANSCRIPT_REQUEST,
    userId,
  };
};

/**
 * Success action creator
 */
type FetchTranscriptSuccessActionCreator = (userId: number, data: any) => FetchTranscriptSuccessAction;

export const fetchTranscriptSuccess: FetchTranscriptSuccessActionCreator = (userId, data) => {
  return {
    type: FETCH_TRANSCRIPT_SUCCESS,
    userId,
    data,
  };
};

/**
 * Error action creator
 */
type FetchTranscriptErrorActionCreator = (userId: number, error: any) => FetchTranscriptErrorAction;
export const fetchTranscriptError: FetchTranscriptErrorActionCreator = (userId, error) => {
  return {
    type: FETCH_TRANSCRIPT_ERROR,
    userId,
    error,
  };
};

const activitySchema = new schema.Entity('activities');
const transcriptSchema = [activitySchema];

export type FetchTranscriptAsync = (
  userId: number,
  excludeAwardedLearnerActivities: boolean,
  praCertificateDuration?: number | string,
  page?: number,
  pageSize?: number
) => (dispatch: Dispatch) => void;
export const fetchTranscript: FetchTranscriptAsync = (userId, excludeAwardedLearnerActivities, praCertificateDuration, page, pageSize) => {
  return (dispatch: Dispatch) => {
    dispatch(fetchTranscriptRequest(userId));

    const url = '/v1/learner/activities/transcripts';

    apiRequest
      .get(url, {
        params: {
          userId,
          page,
          pageSize,
          excludeAwardedLearnerActivities,
          praCertificateDuration,
        },
      })
      .then(({ data }: AxiosResponse<PagedResponse<LearnerActivity>>) => {
        const nonDeletedActivities = data.results.filter((activity) => !Boolean(activity.deleted));
        const { entities, result } = normalize(nonDeletedActivities, transcriptSchema);
        const response = {
          total: data.total,
          activities: entities.activities,
          results: result,
        };
        dispatch(fetchTranscriptSuccess(userId, response));
      })
      .catch((error: AxiosError) => {
        dispatch(fetchTranscriptError(userId, error));
      });
  };
};

export const deleteActivityById = (activityId: number) => {
  return (dispatch: Dispatch) => {
    dispatch(deleteActivityRequest(activityId));

    apiRequest
      .del(`/v1/learner/activities/${activityId}`)
      .then(() => {
        dispatch(deleteActivitySuccess(activityId));
      })
      .catch((err: AxiosError) => {
        dispatch(deleteActivityError(activityId, err));
      });
  };
};

export type ChangeTranscriptPageActionCreator = (page: number) => ChangeTranscriptPageAction;
export const changeTranscriptPage: ChangeTranscriptPageActionCreator = (page) => {
  return {
    type: CHANGE_TRANSCRIPT_PAGE,
    page,
  };
};

export const initialState: TranscriptStore = {
  isLoading: false,
  results: [],
  activities: {},
  page: 1,
  pageSize: 25,
  total: 0,
  error: '',
};

const transcript = (state = initialState, action: FetchTranscriptAction | ChangeTranscriptPageAction | DeleteActivityAction) => {
  switch (action.type) {
    case DELETE_ACTIVITY_REQUEST:
      return {
        ...state,
        error: '',
        activities: {
          ...state.activities,
          [action.activityId]: {
            ...state.activities[action.activityId],
            loading: true,
            error: false,
          },
        },
      };
    case DELETE_ACTIVITY_ERROR:
      return {
        ...state,
        error: 'Could not delete activity. Please try again later.',
        activities: {
          ...state.activities,
          [action.activityId]: {
            ...state.activities[action.activityId],
            error: true,
            loading: false,
          },
        },
      };
    case DELETE_ACTIVITY_SUCCESS:
      return {
        ...state,
        activities: {
          ...state.activities,
          [action.activityId]: {
            ...state.activities[action.activityId],
            loading: false,
            deleted: true,
          },
        },
      };
    case FETCH_TRANSCRIPT_REQUEST:
      return {
        ...state,
        error: '',
        loading: true,
      };
    case FETCH_TRANSCRIPT_SUCCESS:
      return {
        ...state,
        ...action.data,
        error: '',
        loading: false,
      };
    case FETCH_TRANSCRIPT_ERROR:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case CHANGE_TRANSCRIPT_PAGE:
      return {
        ...state,
        page: action.page,
        error: '',
      };
    default:
      return state;
  }
};

export default transcript;
