import { ActivityType } from 'src/constants/ActivityType';

export const transcriptColumns = {
  checked: {
    title: '',
    initialValue: '',
  },
  accreditor: {
    title: 'Accredited Provider',
    initialValue: null,
    render: () => {
      return '';
    },
  },
  activityTitle: {
    title: 'Activity',
    initialValue: null,
    render: () => {
      return '';
    },
  },
  activityEndDate: {
    title: 'Date',
    initialValue: null,
    render: () => {
      return '';
    },
  },
  numberOfCredits: {
    title: 'Credits',
    initialValue: null,
    render: () => {
      return '';
    },
  },
};

const activityTypeCode = 'activityTypeCode';

export const transcriptGridTemplateColumns = '40px 1fr 1fr 120px 60px';
export const transcriptGridItemsFlexValues = ['40px', '25%', '30%', '120px', '60px'];
export const transcriptDefaultValues = Object.keys(transcriptColumns).reduce((acc, name) => {
  acc[name] = transcriptColumns[name].initialValue;
  acc[activityTypeCode] = ActivityType.SelfReported;
  return acc;
}, {});
