import React from 'react';
import { useSelector } from 'react-redux';
import { TextStyle } from 'src/components/Text';
import { CreditClaimStatus } from 'src/constants/ApplicationStatus';
import { claimIsCompletedSelector } from '../../selectors/claims';
import { isCreditProcessor } from '../../selectors/user';
import styled from '../../theme';
import { Color } from '../../theme/primaryTheme';
import Field from '../Field';
import DatePickerField from '../Field/DatePickerField';
import { FieldColumn, FieldRow } from '../FieldRow';
import FormSection from '../FormSection';
import OptionGroup from '../OptionGroup';
import Panel, { PanelBody } from '../Panel';
import Text from '../Text';

interface Props {
  praApprovalStatus?: CreditClaimStatus;
}

const PraFinalizeApplicationSection: React.FunctionComponent<Props> = ({ praApprovalStatus }) => {
  const userIsProcessor = useSelector(isCreditProcessor);
  const claimIsCompleted = useSelector(claimIsCompletedSelector);

  const renderDescription = () =>
    userIsProcessor ? (
      'Review the application details and confirm that the information is accurate.'
    ) : (
      <Text tag="p">
        By continuing, I agree that I have reviewed my application and that at least 50% of submitted{' '}
        <Text tag="em">AMA PRA Category 1 Credits&trade;</Text> are specific to my specialty.
      </Text>
    );

  // Allows all dates to be valid inputs for react-dates SingleDatePicker
  const isOutsideRange = () => false;

  return (
    <FormSection title={`Verify that ${userIsProcessor ? 'the' : 'your'} application is complete`} description={renderDescription()}>
      {userIsProcessor && !claimIsCompleted && (
        <Panel heading="Application Approval">
          <PanelBody data-testid="praApproval">
            <PraApprovalStatusLabel textStyle={TextStyle.Label} bold={true} color={Color.Label}>
              Application Approval Status
            </PraApprovalStatusLabel>
            <FieldRow>
              <FieldColumn grow={1}>
                <Field
                  type="radio"
                  component={OptionGroup}
                  fieldBorder={false}
                  name="status"
                  controlProps={{
                    options: [
                      {
                        label: 'Approved',
                        value: CreditClaimStatus.APPROVED,
                      },
                      {
                        label: 'Rejected',
                        value: CreditClaimStatus.REJECTED,
                      },
                      {
                        label: 'Follow-up',
                        value: CreditClaimStatus.PENDING_CUSTOMER_ACTION,
                      },
                    ],
                  }}
                />
              </FieldColumn>
            </FieldRow>
            {praApprovalStatus === CreditClaimStatus.APPROVED && (
              <FieldRow>
                <FieldColumn>
                  <DatePickerField
                    name="praCertificateEffectiveDate"
                    isOutsideRange={isOutsideRange}
                    label="Effective Date"
                    required={true}
                    controlProps={{ boldLabel: true }}
                  />
                </FieldColumn>
                <FieldColumn>
                  <DatePickerField
                    name="praCertificateExpirationDate"
                    isOutsideRange={isOutsideRange}
                    label="Expiration Date"
                    required={true}
                    controlProps={{ boldLabel: true }}
                  />
                </FieldColumn>
              </FieldRow>
            )}
          </PanelBody>
        </Panel>
      )}
    </FormSection>
  );
};

const PraApprovalStatusLabel = styled(Text)`
  margin-bottom: 1rem;
`;

export default PraFinalizeApplicationSection;
