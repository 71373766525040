import qs from 'qs';
import 'url-search-params-polyfill';

const API_LOGIN = 'auth/sso/login';
const API_LOGOUT = 'auth/sso/logout';
const TOKEN_KEY = 'access_token';
const { REACT_APP_API_URL, REACT_APP_PUBLIC_URL } = process.env;

export const API_APP_ID = 'schub';

function setAccessToken(val: string) {
  localStorage.setItem(TOKEN_KEY, val);
}

function getAccessToken() {
  return localStorage.getItem(TOKEN_KEY);
}

// We will need the removeAccessToken function for logout:

function removeAccessToken() {
  if (localStorage[TOKEN_KEY]) {
    localStorage.removeItem(TOKEN_KEY);
  }
}

function getToken() {
  const queryString = window.location.search.slice(1);
  const queryArray = queryString.split('&');
  const tokenParam = queryArray.find((param) => param.includes(TOKEN_KEY)) || '';
  const token = tokenParam.split('=')[1];

  return token ? token.toString() : '';
}

function authenticate() {
  const token = getToken();

  setAccessToken(token);

  // Remove the access token query param
  // Prevent a window refresh when the browser supports it.
  if (typeof window.history.pushState === 'function') {
    const url = new URL(window.location.toString());
    url.searchParams.delete(TOKEN_KEY);
    window.history.pushState({}, '', url.toString());
  } else {
    window.location.search = '';
  }
}

function loginUrl(redirectTo: string) {
  let redirectParam = redirectTo;

  const params = new URLSearchParams(window.location.search.slice(1));

  if (params.get('redirectAfterLogin')) {
    redirectParam = params.get('redirectAfterLogin');
  }

  return `${REACT_APP_API_URL}/v1/${API_LOGIN}?appId=${API_APP_ID}&redirect=${redirectParam}`;
}

function handleLogout(logoutAtSSO = true, redirectAfterLogout = `${REACT_APP_PUBLIC_URL}/unauthenticate`) {
  const queryString = qs.stringify({
    access_token: getAccessToken(),
    redirectAfterLogout,
    logoutAtSSO,
  });

  removeAccessToken();

  const url = `${REACT_APP_API_URL}/v1/${API_LOGOUT}?${queryString}`;
  window.open(url, '_self');
}

export { API_LOGOUT, TOKEN_KEY, authenticate, getAccessToken, getToken, removeAccessToken, loginUrl, handleLogout };
