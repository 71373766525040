import { AxiosResponse } from 'axios';
import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import qs from 'qs';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Redirect, useLocation } from 'react-router-dom';
import { LinkStyle } from 'src/constants/LinkStyle';
import { ModalType } from 'src/constants/ModalType';
import { showModal } from 'src/ducks/modal';
import { userDataSelector } from 'src/selectors/user';
import styled, { primaryTheme } from 'src/theme';
import { CreditClaimStatus } from '../../constants/ApplicationStatus';
import { ApplicationTypeCode } from '../../constants/ApplicationTypeCode';
import { RateCode } from '../../constants/CreditClaimRateCode';
import { buildRoute, Routes } from '../../constants/Routes';
import { createClaim, fetchInProgressPraClaim, resetClaim } from '../../ducks/claims';
import ApplicationPage from '../../layouts/ApplicationPage';
import { PageFooter, PageSection, PageSectionTitle } from '../../layouts/PageStructure';
import { ClaimFormValues } from '../../types';
import { momentUtc } from '../../utilities/moment';
import Alert, { AlertType } from '../Alert';
import { ApplicationStepLabel } from '../ApplicationProgressBar/ApplicationProgressBar';
import Button from '../Button';
import { DesktopPromptModal, desktopPromptModalId } from '../DesktopPromptModal';
import ExternalLink from '../Link/ExternalLink';
import PraPricingGrid from '../PraPricingGrid/PraPricingGrid';
import ResumePraApplicationModal from '../ResumePraApplicationModal';
import Text, { TextStyle } from '../Text';
import ApplicationTypeSection from './ApplicationTypeSection';
import DateReceivedSection from './DateReceivedSection';
import ProcessingRateSection from './ProcessingRateSection';
import UserInfoTable from './UserInfoTable';
import { validationSchema } from './validationSchema';

const Membership = styled.div`
  margin-bottom: 2.5rem;
`;

const NewApplication: React.FunctionComponent = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const currentUser = useSelector(userDataSelector);

  const { search } = useLocation();
  const { userId: userIdParam, applicationTypeCode: applicationTypeCodeParam = '' } = qs.parse(search.substr(1));

  const claimUserId = parseInt(userIdParam as string, 10) || currentUser.id;
  const applicationTypeCodeSantitizedParam = String(applicationTypeCodeParam).toUpperCase() as ApplicationTypeCode;
  const applicationTypeCode = Object.values(ApplicationTypeCode).includes(applicationTypeCodeSantitizedParam)
    ? applicationTypeCodeSantitizedParam
    : ('' as ApplicationTypeCode);

  const [error, setError] = useState('');
  const [existingPraClaimId, setExistingPraClaimId] = useState(null);

  const { innerWidth } = window;
  React.useEffect(() => {
    // Display modal at widths below the 'medium' breakpoint
    if (innerWidth < parseInt(primaryTheme.breakpoints.medium.slice(0, -2), 10)) {
      dispatch(showModal(desktopPromptModalId));
    }

    dispatch(resetClaim());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (!claimUserId) {
    return <Redirect to={Routes.UserIndex} />;
  }

  const initialValues = currentUser.isCreditProcessor
    ? {
        dateReceived: momentUtc(),
        applicationTypeCode,
        rateCode: '' as RateCode,
      }
    : {
        dateReceived: momentUtc(),
        applicationTypeCode,
      };

  const continueExistingPraClaim = () => {
    if (existingPraClaimId) {
      history.push(buildRoute(Routes.PraAwardType, { id: existingPraClaimId }));
    }
  };

  const redirectToMyApplications = () => {
    if (currentUser.isCreditProcessor) {
      history.replace(buildRoute(Routes.UserApplications, { id: claimUserId }));
    } else {
      history.push(buildRoute(Routes.ApplicationIndex));
    }
  };

  const handleSubmit = (values: ClaimFormValues, { setSubmitting, setValues }: FormikHelpers<ClaimFormValues>) => {
    const rateCode = values.rateCode ? values.rateCode : RateCode.Standard;
    const isPra = values.applicationTypeCode === ApplicationTypeCode.PRA;
    const redirectTo = isPra ? Routes.PraAwardType : Routes.ApplicationActivities;

    if (isPra && !currentUser.isCreditProcessor && !currentUser.meNumber) {
      setError(
        'Only Physicians may apply for the PRA. If you are getting this error and are a physician, please contact the AMA Unified Service Center at 800 262-3211, Monday – Friday, 7:00 AM – 6:00 PM CST, or send an email to MOSupport@ama-assn.org'
      );
      setSubmitting(false);
      window.scrollTo(0, 0);
      return;
    }

    const newClaimData = {
      userId: currentUser.isCreditProcessor ? claimUserId : currentUser.id,
      status: CreditClaimStatus.UNSUBMITTED,
      creditActivities: [],
      ...values,
      rateCode,
    };

    const claimCreateCallbacks = {
      onSuccess: ({ data }: AxiosResponse) => {
        setSubmitting(false);
        history.push(buildRoute(redirectTo, { id: data.id }));
      },
      onError: (error: { message: string }) => {
        console.error('Error creating claim.', error, values);
        setError(`Could not create claim. ${error.message}`);
        setSubmitting(false);
        setValues(values);
      },
    };

    if (isPra) {
      // if there is an in-progress PRA. Show modal to continue
      dispatch(
        fetchInProgressPraClaim({
          userId: claimUserId,
          onSuccess: (claim) => {
            if (claim) {
              setExistingPraClaimId(claim.id);
              setSubmitting(false);
              dispatch(showModal(ModalType.ResumePraApplication));
            } else {
              dispatch(createClaim(newClaimData, claimCreateCallbacks));
            }
          },
        })
      );
    } else {
      dispatch(createClaim(newClaimData, claimCreateCallbacks));
    }
  };

  return (
    <ApplicationPage
      title={currentUser.isCreditProcessor ? 'Processing Information' : ''}
      subtitle={
        currentUser.isCreditProcessor
          ? 'Select the following categories for this application; your selections here will affect the application’s placement in the application queue.'
          : () => (
              <Text tag="p" textStyle={TextStyle.SubTitle}>
                Review and confirm your contact information below. The name displayed below will appear on all your CME certificates and
                awards. If you need to make changes to your name, please contact us at:{' '}
                <ExternalLink data-test-id="contactLink" href="mailto:mosupport@ama-assn.org">
                  MOSupport@ama-assn.org
                </ExternalLink>
                .
              </Text>
            )
      }
      applicationStep={ApplicationStepLabel.ReviewProfile}
    >
      <DesktopPromptModal />
      {!currentUser.isCreditProcessor && <UserInfoTable user={currentUser} />}
      <Membership>
        {currentUser.amaMember ? (
          <Text tag="h2" bold={true} textStyle={TextStyle.Medium}>
            Well done! As an AMA Member you will save on PRA application fees.
          </Text>
        ) : (
          <>
            <Text tag="h2" bold={true} textStyle={TextStyle.Medium}>
              Save with AMA Membership
            </Text>
            <Text textStyle={TextStyle.Small}>
              If you become an AMA member today, you can receive our special AMA member pricing.{' '}
              <ExternalLink linkStyle={LinkStyle.ExternalLink} href="https://www.ama-assn.org/amaone/membership">
                Join the AMA now
              </ExternalLink>
              .
            </Text>
          </>
        )}
      </Membership>
      <Formik
        initialValues={initialValues}
        initialStatus={{ error: '' }}
        onSubmit={handleSubmit}
        validationSchema={validationSchema(currentUser)}
      >
        {({ values, isSubmitting }: FormikProps<ClaimFormValues>) => {
          return (
            <Form data-test-id="newApplicationForm">
              {error && (
                <Alert data-test-id="formError" type={AlertType.Error}>
                  {error}
                </Alert>
              )}
              {currentUser.isCreditProcessor && <DateReceivedSection />}
              {(currentUser.isCreditProcessor || !applicationTypeCode) && <ApplicationTypeSection />}
              {currentUser.isCreditProcessor && <ProcessingRateSection applicationTypeCode={values.applicationTypeCode} />}
              {!currentUser.isCreditProcessor && values.applicationTypeCode === ApplicationTypeCode.PRA && (
                <PageSection>
                  <PageSectionTitle>
                    <Text tag="h2" bold={true} textStyle={TextStyle.Medium}>
                      Pricing
                    </Text>
                  </PageSectionTitle>
                  <PraPricingGrid />
                </PageSection>
              )}
              <PageFooter>
                <Button type="submit" loading={isSubmitting}>
                  Save and Continue
                </Button>
              </PageFooter>
              <ResumePraApplicationModal
                viewingUser={currentUser}
                onConfirm={continueExistingPraClaim}
                onCancel={redirectToMyApplications}
              ></ResumePraApplicationModal>
            </Form>
          );
        }}
      </Formik>
    </ApplicationPage>
  );
};

export default NewApplication;
