import { User } from 'src/types';
import * as Yup from 'yup';
import { ApplicationTypeCode } from '../../constants/ApplicationTypeCode';

export const creditEarnerValidationRules = {
  applicationTypeCode: Yup.string().label('Application type').required().oneOf(Object.values(ApplicationTypeCode)),
};

export const creditProcessorValidationRuiles = {
  ...creditEarnerValidationRules,
  dateReceived: Yup.date().label('Date').typeError('Please enter a valid date.').required(),
  rateCode: Yup.string().label('Processing rate').notRequired().nullable(),
};

export const validationSchema = (user: User) =>
  Yup.object().shape(user.isCreditProcessor ? creditProcessorValidationRuiles : creditEarnerValidationRules);
