import React from 'react';
import Toggle from 'react-toggled';
import styled from 'src/theme';
import { themeColor } from 'src/theme/helpers';
import { Color } from 'src/theme/primaryTheme';
import { CreditActivityAttachment } from '../../types';
import Button from '../Button';
import Icon from '../Icon';
import ProofDocumentItem from './ProofDocumentItem';

interface Props {
  attachments: CreditActivityAttachment[];
  onRemoveItem?: (attachment: CreditActivityAttachment) => void;
}

const ProofDocumentList: React.FunctionComponent<Props> = ({ attachments = [], onRemoveItem }) => {
  const numVisibleFiles = 5;
  const showToggler: boolean = attachments.filter(Boolean).length > numVisibleFiles;

  return (
    <Toggle>
      {({ on, getTogglerProps, toggle }) => {
        let visibleCount = 0;

        return (
          <>
            <StyledList data-test-id="uploaded-files">
              {attachments.map((attachment: CreditActivityAttachment) => {
                visibleCount = attachment && visibleCount + 1;

                if (attachment && visibleCount <= (on ? 1000 : numVisibleFiles)) {
                  return (
                    <ProofDocumentItem
                      key={`attachment-${attachment.id}`}
                      status={attachment.status}
                      fileName={attachment.fileName}
                      signedUrlForDownload={attachment.signedUrlForDownload}
                      loading={attachment.loading}
                      onRemove={
                        typeof onRemoveItem === 'function'
                          ? () => {
                              onRemoveItem(attachment);
                            }
                          : null
                      }
                    />
                  );
                }

                return null;
              })}
            </StyledList>

            {showToggler && (
              <Toggler onClick={toggle} {...getTogglerProps()} type="button" buttonStyle="plain-text" data-test-id="file-toggler">
                View {on ? 'fewer' : 'all'} attachments
                <Icon
                  name={on ? 'angleArrowUp' : 'angleArrowDown'}
                  width={12}
                  height={7.5}
                  viewBox="0 0 16 10"
                  style={{ marginLeft: '0.2em' }}
                />
              </Toggler>
            )}
          </>
        );
      }}
    </Toggle>
  );
};

const StyledList = styled.ul`
  margin-bottom: 0.8rem;
  font-size: 1.4rem;
  color: ${themeColor(Color.Accent)};
`;

const Toggler = styled(Button)`
  font-size: 1.4rem;
  font-weight: 700;
  outline: none;
`;

export default ProofDocumentList;
