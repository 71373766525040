import styled, { css } from '../../theme';
import { themeBreakpoint } from '../../theme/helpers';

interface StyledInputProps {
  inputStyle?: any;
  even?: boolean;
}

interface ColumnProps {
  grow?: number;
  width?: string;
}

export const FieldColumn = styled.div<ColumnProps>`
  & + & {
    margin-top: 1em;
  }

  @media (min-width: ${themeBreakpoint('medium')}) {
    ${({ grow }) =>
      grow
        ? css`
            flex-grow: ${grow};
          `
        : ''};

    ${({ width }) =>
      width
        ? css`
            width: ${width};
          `
        : ''}

    & + & {
      margin-top: 0;
      margin-left: 2rem;
    }
  }
`;

export const FieldRow = styled.div<StyledInputProps>`
  @media (min-width: ${themeBreakpoint('medium')}) {
    display: flex;
    align-items: baseline;
  }

  & + & {
    margin-top: 3.2rem;
  }

  ${({ even }) =>
    even
      ? css`
          ${FieldColumn} {
            flex-basis: 50%;
          }
        `
      : ''}
`;
