import React, { useEffect, useRef, useState } from 'react';
import { themeBreakpoint } from 'src/theme/helpers';
import { css } from 'styled-components';
import styled from '../../theme';

interface Props {
  children: React.ReactNode;
  stickingHeight?: number;
  className?: string;
}

const StickyContainer: React.FunctionComponent<Props> = ({
  children,
  stickingHeight = 152, // the height in pixels of the Header component on desktop
  className,
}) => {
  const [containerIsStuck, setContainerIsStuck] = useState(false);
  const stickyContainerRef = useRef(null);

  useEffect(() => {
    const stickyContainer: HTMLDivElement = stickyContainerRef.current;

    const handleScroll = () => {
      const stickyContainerRect = stickyContainer.getBoundingClientRect();

      if (!containerIsStuck && stickyContainerRect.top <= stickingHeight) {
        setContainerIsStuck(true);
      } else if (containerIsStuck && stickyContainerRect.top > stickingHeight) {
        setContainerIsStuck(false);
      }
    };

    if (stickyContainer) {
      window.addEventListener('scroll', handleScroll, { passive: true });
    }

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [stickingHeight, containerIsStuck]);

  return (
    <Root ref={stickyContainerRef} containerIsStuck={containerIsStuck} stickingHeight={stickingHeight} className={className}>
      {children}
    </Root>
  );
};

const Root = styled.div<{ stickingHeight: number; containerIsStuck: boolean }>`
  position: sticky;
  top: ${({ theme }) => theme.sizes.desktopHeaderHeight};
  z-index: 1;
  transition: all 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
  width: 100%;
  border-radius: 0.5rem;

  @media (max-width: ${themeBreakpoint('medium')}) {
    top: 0;
  }

  ${({ containerIsStuck }) =>
    /* Expand the container to the full view width and allow it
    to break out of the site gutter */
    containerIsStuck
      ? css`
          width: 100vw;
          margin-left: -${({ theme }) => theme.sizes.desktopGutter};
          border-radius: 0;
        `
      : ''}
`;

export default StickyContainer;
