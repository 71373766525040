import React from 'react';
import styled from 'styled-components';
import { ThemeColors } from '../../theme/primaryTheme';
import Icon, { IconWrap } from '../Icon';
import Text from '../Text';

interface Props {
  rate: string;
  rejected: boolean;
}

const upperFirst = (str: string) => str.charAt(0).toUpperCase() + str.slice(1);

const getThemeTextColor = (rate: string, rejected: boolean): string => {
  if (rejected) {
    return 'inherit';
  }
  if (rate === 'Express') {
    return ThemeColors.confirmationDark;
  }
  return ThemeColors.primary;
};
const getThemeBGColor = (rate: string, rejected: boolean): string => {
  if (rejected) {
    return 'inherit';
  }
  if (rate === 'Express') {
    return ThemeColors.confirmationLight;
  }

  return 'transparent';
};

const StyledProcessingRateLabel = styled.span<{
  rate: string;
  rejected: boolean;
}>`
  display: inline-flex;
  align-items: center;
  padding: 3px 8px;
  color: ${({ rate, rejected }) => getThemeTextColor(rate, rejected)};
  background-color: ${({ rate, rejected }) => getThemeBGColor(rate, rejected)};
  border: 1px solid ${({ rate, rejected }) => getThemeBGColor(rate, rejected)};
  border-radius: 2px;

  ${IconWrap} {
    margin-left: 5px;
  }
`;

export const ProcessingRateLabel: React.FunctionComponent<Props> = ({ rate, rejected }) => {
  return (
    <StyledProcessingRateLabel rate={rate} rejected={rejected}>
      <Text textStyle="small" tag="span">
        {upperFirst(rate)}
      </Text>
      {rate === 'Express' && <Icon width={16} height={16} viewBox="0 0 18 18" name="clock" />}
    </StyledProcessingRateLabel>
  );
};
