import moment from 'moment';
import Select from '../../../components/Field/Controls/Select';

export interface Option {
  value: string | number;
  label: string;
}

const months = moment.months();

const monthOptions: Option[] = [
  {
    value: '',
    label: 'Select Month',
  },
  ...months.map((month, index) => {
    return {
      value: index + 1,
      label: month,
    };
  }),
];

const monthNameFromIndex = (index: number) => {
  return months[index - 1];
};

const defaultField = {
  type: 'text',
  displayValue: (value: string) => value,
  normalizeValue: (value: string) => value,
};

const fields = {
  birthMonth: {
    ...defaultField,
    type: 'select',
    component: Select,
    label: 'Month of birth',
    required: true,
    controlProps: {
      options: monthOptions,
    },
    displayValue: (value: string) => {
      const intVal = parseInt(value, 10);
      return monthNameFromIndex(intVal);
    },
    normalizeValue: (value: string) => {
      return parseInt(value, 10);
    },
  },
  birthDayOfMonth: {
    ...defaultField,
    label: 'Day of Birth',
    placeholder: 'Day of birth',
    required: true,
    controlProps: {
      maxLength: 2,
    },
    normalizeValue: (value: string) => {
      return parseInt(value, 10);
    },
  },
};

export default fields;
