import styled, { css } from '../../theme';
import { themeColor } from '../../theme/helpers';
import { Color } from '../../theme/primaryTheme';

export const thFontSize = 13;
export const tdFontSize = 14;
export const cellPadding = 12;
export const rowBorderSpacing = 3;
export const rowBorderRadius = 5;

export const Root = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 ${rowBorderSpacing}px;
`;

export const TableHeaderRow = styled.tr``;

export const TableBody = styled.tbody``;

const cellStyles = css`
  padding: ${cellPadding * 2}px ${cellPadding}px;
  vertical-align: middle;
  font-size: ${tdFontSize}px;
  line-height: 1.2;
`;

export const TableCell = styled.td<{ hover?: boolean }>`
  ${({ hover }) =>
    hover &&
    css`
      cursor: pointer;
    `}
  ${cellStyles}
`;

export const TableHeader = styled.thead`
  ${TableCell} {
    text-align: left;
    font-size: ${thFontSize}px;
    font-weight: bold;
    padding: ${cellPadding}px;
    line-height: 1.2;
    vertical-align: middle;
  }
`;

export const TableRow = styled.tr<{ loading?: boolean; error?: boolean }>`
  background-color: ${themeColor(Color.Reverse)};

  ${({ loading }) =>
    loading &&
    css`
      opacity: 0.5;
    `}

  ${({ error }) =>
    error &&
    css`
      outline: 1px solid ${themeColor(Color.Error)};
      background-color: rgba(252, 94, 71, 0.2);
    `}

  :first-child {
    border-top-left-radius: ${rowBorderRadius}px;
    border-bottom-left-radius: ${rowBorderRadius}px;
  }
  :last-child {
    border-top-right-radius: ${rowBorderRadius}px;
    border-bottom-right-radius: ${rowBorderRadius}px;
  }
`;
