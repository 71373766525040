import { connect as formikConnect } from 'formik';
import React from 'react';
import styled from '../../theme';
import Button from '../Button';
import Text from '../Text';
import { ModalBody, ModalButtonGroup, ModalHeader } from './styles';

const ConfirmView = styled.div``;

const Confirm: React.SFC<any> = ({ title, onConfirm, isSubmitting, onCancel, children, formik }) => {
  const handleConfirm = () => {
    onConfirm(formik.values, formik);
  };

  return (
    <ConfirmView data-id="modalForm">
      {title && (
        <ModalHeader>
          <Text textStyle="modalTitle">{title}</Text>
        </ModalHeader>
      )}
      <ModalBody>{children}</ModalBody>
      <ModalButtonGroup>
        <Button onClick={handleConfirm} disabled={isSubmitting} data-type="submit">
          Confirm
        </Button>
        <Button onClick={onCancel} disabled={isSubmitting} buttonStyle="outlined" data-type="cancel">
          Go Back
        </Button>
      </ModalButtonGroup>
    </ConfirmView>
  );
};

export default formikConnect(Confirm);
