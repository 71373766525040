import React from 'react';
import { AccreditorOptions } from '../../constants/Accreditor';
import { ActivityFieldLabels } from '../../constants/ActivityFieldLabels';
import { ActivityType } from '../../constants/ActivityType';
import { countryOptions } from '../../layouts/Forms/countryOptions';
import Field from '../Field';
import Select from '../Field/Controls/Select';
import DatePickerField from '../Field/DatePickerField';
import { FieldColumn, FieldRow } from '../FieldRow';
import OptionGroup from '../OptionGroup';
import ProofDocumentUpload from '../ProofDocumentUpload';
import Text, { TextStyle } from '../Text';
import { GenericActivityProps } from './index';
import ProcessorFields from './ProcessorFields';

const fieldLabels = ActivityFieldLabels[ActivityType.ICC];

const IccFields: React.FunctionComponent<GenericActivityProps> = ({ baseName, values, isProcessor, claimId }) => {
  return (
    <>
      <FieldRow>
        <FieldColumn grow={1}>
          <Field name={`${baseName}.conferenceTitle`} placeholder="Conference title" label={fieldLabels.conferenceTitle} required={true} />
        </FieldColumn>
      </FieldRow>
      <FieldRow>
        <FieldColumn>
          <Text tag="div" textStyle={TextStyle.Label}>
            {fieldLabels.conferenceAccreditor}*
          </Text>
          <Field
            type="radio"
            name={`${baseName}.conferenceAccreditor`}
            component={OptionGroup}
            fieldBorder={false}
            controlProps={{ options: AccreditorOptions }}
          />
        </FieldColumn>
      </FieldRow>
      <FieldRow even={true}>
        <FieldColumn>
          <DatePickerField name={`${baseName}.activityStartDate`} label={fieldLabels.activityStartDate} required={true} />
        </FieldColumn>
        <FieldColumn>
          <DatePickerField name={`${baseName}.activityEndDate`} label={fieldLabels.activityEndDate} required={true} />
        </FieldColumn>
      </FieldRow>
      <FieldRow>
        <FieldColumn>
          <Field name={`${baseName}.city`} placeholder="City" label={fieldLabels.city} required={true} />
        </FieldColumn>
      </FieldRow>
      <FieldRow>
        <FieldColumn>
          <Field
            name={`${baseName}.country`}
            label={fieldLabels.country}
            required={true}
            component={Select}
            controlProps={{
              options: countryOptions,
              defaultLabel: 'Select Country',
            }}
          />
        </FieldColumn>
      </FieldRow>

      <FieldRow>
        <FieldColumn>
          <Field name={`${baseName}.activityUnits`} placeholder="#" label={fieldLabels.activityUnits} required={true} />
        </FieldColumn>
      </FieldRow>

      <FieldRow>
        <FieldColumn grow={1}>
          <ProofDocumentUpload name={`${baseName}.creditActivityAttachments`} required={!isProcessor} claimId={claimId} />
        </FieldColumn>
      </FieldRow>

      {isProcessor && (
        <FieldRow>
          <FieldColumn grow={1}>
            <ProcessorFields baseName={baseName} values={values} />
          </FieldColumn>
        </FieldRow>
      )}
    </>
  );
};

export default IccFields;
