import React from 'react';
import { PageWrap } from 'src/layouts/PageStructure';
import { themeBreakpoint, themeColor } from '../../theme/helpers';
import styled from '../../theme/index';
import { Color } from '../../theme/primaryTheme';
import NavMenu from '../NavMenu';
import UserMenu from '../UserMenu';

const UtilityBar = () => (
  <StyledUtilityBar>
    <UtilityBarWrap>
      <NavMenu />
      <UserMenu />
    </UtilityBarWrap>
  </StyledUtilityBar>
);

const StyledUtilityBar = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 8.7rem; /* prevent popping when user loads in */
  border-bottom: 1px solid ${themeColor(Color.BorderLight)};
  background-color: ${themeColor(Color.Reverse)};

  @media (max-width: ${themeBreakpoint('medium')}) {
    position: static;
    align-items: center;
    width: 100%;
    height: auto;
    min-height: 5rem;
    border-bottom: 1px solid ${themeColor(Color.Border)};
  }
`;

const UtilityBarWrap = styled(PageWrap)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 0;
`;

export default UtilityBar;
