import React from 'react';
import ReactModal from 'react-modal';
import { connect } from 'react-redux';
import { Transition } from 'react-spring';
import { Dispatch } from 'redux';
import closeIcon from '../../assets/images/close.svg';
import { hideModal } from '../../ducks/modal';
import Confirm from './Confirm';
import Error from './Error';
import { ModalClose } from './styles';
import Success from './Success';

/*
TODO: I don't think this is the best way to handle this, but I couldn't get
the DOM to have this element in a testing environment. Rather than spend
a ton of time trying to make a test work, I added this escape hatch
 */
const rootNode = document.getElementById('root');
if (rootNode) {
  // We need setAppElement for accessibility:
  // http://reactcommunity.org/react-modal/accessibility/#app-element
  ReactModal.setAppElement(rootNode);
}

const styleOverrides = {
  overlay: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  content: {
    width: '90vw',
    maxWidth: '80rem',
    maxHeight: '90vh',
    border: 'none',
    borderRadius: 'none',
    top: 'auto',
    left: 'auto',
    right: 'auto',
    bottom: 'auto',
    padding: '7rem 5rem',
    boxShadow: '0 2px 10px 0 rgba(0,0,0,0.25)',
  },
};

export interface ModalProps {
  isOpen: boolean;
  onConfirm: () => void;
  onCancel: () => void;
  title?: string;
  view?: 'default' | 'loading' | 'success' | 'error';
  readonly modalId?: string;
  dispatch?: Dispatch;
}

export const Modal: React.FunctionComponent<ModalProps> = (props) => {
  const { isOpen, dispatch, modalId, view, children } = props;

  const handleCloseModal = () => {
    if (dispatch !== undefined && modalId !== undefined) {
      dispatch(hideModal(modalId));
    }
  };

  const renderBody = () => {
    switch (view) {
      case 'success':
        return <Success onButtonClick={handleCloseModal} />;
      case 'error':
        return <Error onButtonClick={handleCloseModal} />;
      default:
        return (
          <Confirm isSubmitting={view === 'loading'} {...props}>
            {children}
          </Confirm>
        );
    }
  };

  return (
    <Transition
      items={isOpen}
      from={{ opacity: 0, transform: 'translate3d(0, 30px, 0)' }}
      enter={{ opacity: 1, transform: 'translate3d(0, 0, 0)' }}
      leave={{ opacity: 0, transform: 'translate3d(0, 30px, 0)' }}
    >
      {(show) =>
        show &&
        ((style) => (
          <ReactModal
            isOpen={isOpen}
            htmlOpenClassName="is-scroll-locked"
            bodyOpenClassName="is-scroll-locked"
            style={{
              content: { ...styleOverrides.content, ...style },
              overlay: styleOverrides.overlay,
            }}
            onRequestClose={handleCloseModal}
          >
            {renderBody()}
            <ModalClose type="button" onClick={handleCloseModal}>
              <img src={closeIcon} alt="dismiss modal" />
            </ModalClose>
          </ReactModal>
        ))
      }
    </Transition>
  );
};

const mapStateToProps = (state: any, ownProps: any) => {
  const { modalId } = ownProps;
  const { modal, form } = state;
  const { view } = form;

  return {
    view,
    isOpen: modal[modalId] || false,
  };
};

export default connect(mapStateToProps)(Modal);
