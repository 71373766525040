import styled from '../../theme';
import { themeColor } from '../../theme/helpers';
import { Color } from '../../theme/primaryTheme';
import Button from '../Button';
import { FieldRow } from '../FieldRow';

export const TotalCreditsRow = styled(FieldRow)`
  padding-top: 2.5rem;
  border-top: 1px solid ${themeColor(Color.Border)};

  &:not(:last-child) {
    margin-bottom: 3.2rem;
    padding-bottom: 2.5rem;
    border-bottom: 1px solid ${themeColor(Color.Border)};
  }
`;

export const AppTypeInfo = styled.div<{ open?: boolean }>`
  margin: 2.5rem 0;
`;

export const Toggler = styled(Button)`
  margin-top: 1rem;
  font-size: 1.4rem;
  font-weight: 700;
  outline: none;
`;

export const DescriptionBlock = styled.div`
  & ul {
    padding-inline-start: 40px;
    list-style-type: disc;
  }
`;
