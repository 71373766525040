import React from 'react';
import styled from '../../theme';
import Footer from '../Footer';
import Header from '../Header';

const App: React.FunctionComponent = ({ children }) => (
  <StyledApp>
    <Header />
    <StyledAppMain>{children}</StyledAppMain>
    <Footer />
  </StyledApp>
);

const StyledApp = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const StyledAppMain = styled.main`
  display: flex;
  width: 100%;
  margin: auto;
  flex-grow: 1;
  z-index: 0;
`;

export default App;
