import { createAction, createAsyncThunk, createReducer } from '@reduxjs/toolkit';
import { FeatureFlag } from '../types';
import { apiRequest } from '../utilities/api';

export type FeatureFlagStore = {
  loading: boolean;
  featureFlags: FeatureFlag[];
  claimMissingCredits?: boolean;
};

/* REDUCER */
const initialState: FeatureFlagStore = {
  loading: false,
  featureFlags: [],
  claimMissingCredits: false,
};

const CLAIM_MISSING_CREDITS_FLAG = 'claimMissingCredits';

export const fetchFeatureFlags = createAsyncThunk<FeatureFlag[], void, { rejectValue: string }>(
  '@FEATURES/FETCH_FLAGS',
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const { data: featureFlags } = await apiRequest.get<FeatureFlag[]>('/v1/feature-flags/app/learnerPortal');
      const claimMissingCreditsFlag = featureFlags.find((f) => f.enabled && f.feature === CLAIM_MISSING_CREDITS_FLAG);
      dispatch(claimMissingCredits(claimMissingCreditsFlag?.enabled || false));
      return featureFlags;
    } catch (error) {
      Rollbar.error(`Failed to fetch feature flags. ${error}`);
      return rejectWithValue((error as Error).message);
    }
  }
);

export const claimMissingCredits = createAction<boolean>('CLAIM_MISSING_CREDITS');

const featureFlags = createReducer(initialState, (builder) =>
  builder
    .addCase(fetchFeatureFlags.pending, (state) => ({
      ...state,
      claimMissingCredits: false,
    }))
    .addCase(fetchFeatureFlags.fulfilled, (state, { payload }) => ({
      ...state,
      featureFlags: payload,
      loading: false,
    }))
    .addCase(fetchFeatureFlags.rejected, (state) => ({
      ...state,
      claimMissingCredits: false,
      loading: false,
    }))
    .addCase(claimMissingCredits, (state, { payload }) => ({
      ...state,
      claimMissingCredits: payload,
    }))
);

export default featureFlags;
