import moment from 'moment';
import React from 'react';
import { ActivityAccredidationType, ActivityType } from 'src/constants/ActivityType';
import Field from '../Field';
import DatePickerField from '../Field/DatePickerField';

export const category2Columns = {
  activityTitle: {
    title: 'Activity Title',
    placeholder: 'Enter activity title',
    initialValue: '',
    controlProps: {
      variant: 'small',
    },
  },
  activityDescription: {
    title: 'Subject or Content',
    placeholder: 'Enter subject or content',
    initialValue: '',
    controlProps: {
      variant: 'small',
    },
  },
  activityEndDate: {
    title: 'Activity Date',
    initialValue: null,
    controlProps: {
      variant: 'small',
    },
    render: (name: string, column: any, fieldIsEditable: boolean) => {
      if (fieldIsEditable) {
        return <DatePickerField name={name} locked={!fieldIsEditable} variant="small" showErrorMessage={false} {...column} />;
      }
      return (
        <Field
          name={name}
          locked={true}
          showErrorMessage={false}
          {...column}
          value={column.value ? moment(column.value).format('YYYY-mm-dd') : ''}
        />
      );
    },
  },
  numberOfCredits: {
    title: 'Credits Earned',
    type: 'number',
    placeholder: '0',
    initialValue: '',
    controlProps: {
      variant: 'small',
    },
  },
};

const activityTypeCode = 'activityTypeCode';
const accredidationType = 'accredidationType';

export const category2GridTemplateColumns = '1fr 1fr 150px 60px 20px';
export const category2GridItemsFlexValues = ['34%', '29%', '140px', '60px', '20px'];
export const category2DefaultValues = Object.keys(category2Columns).reduce((acc, name) => {
  acc[name] = category2Columns[name].initialValue;
  acc[activityTypeCode] = ActivityType.SelfReported;
  acc[accredidationType] = ActivityAccredidationType.Category2;
  return acc;
}, {});
