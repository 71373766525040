import styled, { css } from '../../theme';
import { themeColor } from '../../theme/helpers';
import { Color } from '../../theme/primaryTheme';

export const Header = styled.div`
  display: flex;
  background-color: ${themeColor(Color.CardHeader)};
  padding: 1.8rem 2.4rem;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px 4px 0 0;
`;

export const Body = styled.div`
  padding: 2.4rem;
`;

export const Root = styled.section<{ locked?: boolean }>`
  border: 1px solid ${themeColor(Color.Border)};
  border-radius: 5px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
  background-color: ${({ locked }) => (locked ? themeColor(Color.BackgroundLight) : themeColor(Color.Reverse))};
  & + & {
    margin-top: 2rem;
  }
  ${Header} {
    color: ${({ locked }) => (locked ? themeColor(Color.Gray) : themeColor(Color.Primary))};
  }

  ${Body} {
    ${({ locked }) =>
      locked
        ? css`
            opacity: 0.75;
          `
        : ''}
  }
`;
