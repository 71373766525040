import _ from 'lodash';
import * as types from '../types';
import { Organization } from '../types';
import { momentUtc } from './moment';

export const getUserInitialValues = (data: any): types.UserValues => {
  const { dob } = data;
  const momentDob = momentUtc(dob, 'YYYY-MM-DDTHH:mm:ss.SSS');
  const birthMonth = momentDob.isValid() ? momentDob.format('M') : '';
  const birthDayOfMonth = momentDob.isValid() ? momentDob.format('D') : '';

  return {
    birthMonth,
    birthDayOfMonth,
  };
};

const normalizeOrganization = (organization?: Organization) => {
  const preferredUniqueId = _.get(organization, 'preferredUniqueIds[0]', 'aimsSuppliedId');

  if (!organization) {
    return {};
  }

  return {
    id: organization.id,
    name: organization.name,
    isStateBoard: organization.metadata.isStateBoard,
    visibleToUser: organization.visibleToUser,
    optInByDefault: organization.optInByDefault,
    userDefinableAttributes: organization.userDefinableAttributes,
    preferredUniqueId,
  };
};

const normalizeUserOrganization = (userOrganization: types.UserOrganization): types.OrganizationFieldData => {
  const { organization } = userOrganization;

  return {
    ...normalizeOrganization(organization),
    firstNameAlias: userOrganization.firstNameAlias || '',
    lastNameAlias: userOrganization.lastNameAlias || '',
    useAlias: userOrganization.useAlias,
    optInToSendToOrganization: userOrganization.optInToSendToOrganization,
    userSuppliedId: userOrganization.userSuppliedId || '',
    aimsSuppliedId: userOrganization.aimsSuppliedId || '',
    disabled: Boolean(
      userOrganization.aimsSuppliedId && organization.preferredUniqueIds && organization.preferredUniqueIds[0] === 'aimsSuppliedId'
    ),
  };
};

export const flattenBoard = (accumulator: any, org: types.OrganizationFieldData) => {
  if (org && org.id) {
    accumulator[org.id] = org;
  }

  return accumulator;
};

export const getOptInInitialValues = (data: any) => {
  const { userOrganizations = [] } = data;
  const normalizedBoards = userOrganizations.map(normalizeUserOrganization);

  return {
    ...normalizedBoards.reduce(flattenBoard, {}),
  };
};
