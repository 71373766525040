import React from 'react';
import styled from '../../../../theme';
import { themeColor } from '../../../../theme/helpers';
import { Color } from '../../../../theme/primaryTheme';

const StyledTextArea = styled.textarea`
  border: 0;
  border-radius: 0.5rem;
  width: 100%;
  padding: 1rem;
  background-color: ${themeColor(Color.Reverse)};
  resize: vertical;
  overflow: auto;
  vertical-align: top;
`;

interface Props extends React.InputHTMLAttributes<HTMLTextAreaElement> {
  name: string;
}

const TextArea: React.FunctionComponent<Props> = ({ name, ...rest }) => <StyledTextArea name={name} {...rest} />;

export default TextArea;
