import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxStore } from '../../ducks';
import { fetchTranscript } from '../../ducks/transcript';
import LoadingSpinner from '../LoadingSpinner';

interface Props {
  userId?: number;
  page?: number;
  pageSize?: number;
  excludeAwardedLearnerActivities: boolean;
  praCertificateDuration?: number | string;
}

const FetchTranscript: React.FunctionComponent<Props> = ({
  userId,
  excludeAwardedLearnerActivities,
  praCertificateDuration,
  page,
  pageSize,
  children,
}) => {
  const dispatch = useDispatch();
  const { isLoading, page: pageFromState, pageSize: pageSizeFromState } = useSelector((state: ReduxStore) => state.transcript);

  // Values for `page` and `pageSize` passed in via props take precedence over values in state
  // to override default pagination behavior when necessary.
  const pageParam = page ?? pageFromState;
  const pageSizeParam = pageSize ?? pageSizeFromState;

  useEffect(() => {
    if (userId) {
      dispatch(fetchTranscript(userId, excludeAwardedLearnerActivities, praCertificateDuration, pageParam, pageSizeParam));
    }
  }, [dispatch, userId, excludeAwardedLearnerActivities, praCertificateDuration, pageParam, pageSizeParam]);
  return isLoading ? <LoadingSpinner /> : <>{children}</>;
};

export default FetchTranscript;
