import { combineReducers } from 'redux';
import authentication from './authentication';
import boardData from './boardData';
import bulkLearnerActivityUploads from './bulkLearnerActivityUploads';
import claimAccount from './claimAccount';
import claims from './claims';
import creditEarners from './creditEarners';
import featureFlags from './featureFlags';
import form from './form';
import initialValues from './initialValues';
import modal from './modal';
import pras from './pras';
import transcript from './transcript';
import user from './user';
import userSearch from './userSearch';

const rootReducer = combineReducers({
  user,
  boardData,
  initialValues,
  modal,
  auth: authentication,
  claims,
  claimAccount,
  featureFlags,
  form,
  creditEarners,
  userSearch,
  transcript,
  pras,
  bulkLearnerActivityUploads,
});

export type ReduxStore = ReturnType<typeof rootReducer>;

export default rootReducer;
