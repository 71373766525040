import { Field, FieldProps } from 'formik';
import React from 'react';
import { PraCertificateCode } from '../../../constants/PraCertificateCode';
import { PageSectionDescription } from '../../../layouts/PageStructure';
import styled from '../../../theme';
import { themeColor } from '../../../theme/helpers';
import { Color } from '../../../theme/primaryTheme';
import CheckableInputPra from '../../CheckableInputPra';
import FormSection from '../../FormSection';
import { Grid, GridItem } from '../../Grid';
import Panel, { DEFAULT_PANEL_PADDING } from '../../Panel';
import Text from '../../Text';
import { TextStack } from '../../TextStack';

const category1CreditLabel = <Text tag="em">AMA PRA Category 1 Credits&trade;</Text>;

const awardTypes = {
  standard: {
    heading: 'PRA Standard',
    options: {
      [PraCertificateCode.PRA_STANDARD_3Y]: {
        label: '3 Year Standard',
        description: <Text>150 credits (at least 60 must be {category1CreditLabel})</Text>,
        info: <Text>3-year award requires credit be earned within 36 months prior to submission.</Text>,
      },
      [PraCertificateCode.PRA_STANDARD_2Y]: {
        label: '2 Year Standard',
        description: <Text>100 credits (at least 40 must be {category1CreditLabel})</Text>,
        info: <Text>2-year award requires credit be earned within 24 months prior to submission.</Text>,
      },
      [PraCertificateCode.PRA_STANDARD_1Y]: {
        label: '1 Year Standard',
        description: <Text>50 credits (at least 20 must be {category1CreditLabel})</Text>,
        info: <Text>1-year award requires credit be earned within 12 months prior to submission.</Text>,
      },
    },
  },
  withCommendation: {
    heading: 'PRA with Commendation',
    options: {
      [PraCertificateCode.PRA_COMMENDATION_3Y]: {
        label: '3 Year with Commendation',
        description: <Text>270 credits (at least 180 must be {category1CreditLabel})</Text>,
        info: <Text>3-year award requires credit be earned within 36 months prior to submission.</Text>,
      },
      [PraCertificateCode.PRA_COMMENDATION_2Y]: {
        label: '2 Year with Commendation',
        description: <Text>180 credits (at least 120 must be {category1CreditLabel})</Text>,
        info: <Text>2-year award requires credit be earned within 24 months prior to submission.</Text>,
      },
      [PraCertificateCode.PRA_COMMENDATION_1Y]: {
        label: '1 Year with Commendation',
        description: <Text>90 credits (at least 60 must be {category1CreditLabel})</Text>,
        info: <Text>1-year award requires credit be earned within 12 months prior to submission.</Text>,
      },
    },
  },
};

const CmeCreditSection: React.FunctionComponent<{ isEditable: boolean }> = ({ isEditable }) => {
  const itemStyles = {
    padding: DEFAULT_PANEL_PADDING,
  };

  const renderHeader = () => {
    return (
      <Grid gridGap={0} ieGridItemsMargin={0} ieGridItemsFlexValues={[1, 1]}>
        {Object.keys(awardTypes).map((group) => {
          const { heading } = awardTypes[group];
          return (
            <GridItem key={group} style={itemStyles}>
              {heading}
            </GridItem>
          );
        })}
      </Grid>
    );
  };

  return (
    <FormSection title="Select your award application">
      <PageSectionDescription>
        <TextStack>
          <Text>Please select the award type and duration of your AMA PRA application.</Text>
        </TextStack>
      </PageSectionDescription>
      <Panel heading={renderHeader()} headingPadding={0}>
        <Grid gridGap={0} ieGridItemsMargin={0}>
          {Object.keys(awardTypes).map((group) => {
            const { options } = awardTypes[group];
            return (
              <AwardGroup key={`${group}Options`}>
                {Object.keys(options).map((awardType) => {
                  const { label, description, info, gridArea } = options[awardType];
                  return (
                    <GridItemOption key={awardType} gridArea={gridArea}>
                      <Field name="praCertificateCode">
                        {({ field }: FieldProps) => (
                          <CheckableInputPra field={field} label={label} id={awardType} locked={!isEditable}>
                            <div style={{ marginTop: 2 }} />
                            <Text textStyle="small" color={Color.Gray} tag="span">
                              {description}
                              {info}
                            </Text>
                          </CheckableInputPra>
                        )}
                      </Field>
                    </GridItemOption>
                  );
                })}
              </AwardGroup>
            );
          })}
        </Grid>
      </Panel>
    </FormSection>
  );
};

const GridItemOption = styled(GridItem)`
  & + & {
    border-top: 1px solid ${themeColor(Color.Border)};
  }
`;

const AwardGroup = styled.div`
  & + & {
    border-left: 1px solid ${themeColor(Color.Border)};
  }

  /* Targets IE 11 */
  @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
    flex: 1;
  }
`;

export default CmeCreditSection;
