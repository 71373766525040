import { ActivityType } from './ActivityType';

const sharedLabels = {
  creditActivityAttachments: 'Proof Documents',
  notes: 'Notes',
};

export const ActivityFieldLabels = {
  [ActivityType.Medical]: {
    ...sharedLabels,
    school: 'School',
    program: 'Program Name',
    graduationDate: 'Graduation Date',
  },
  [ActivityType.Teaching]: {
    ...sharedLabels,
    activityTitle: 'Title of Activity',
    activityEndDate: 'Date',
    activityUnits: 'Hours',
  },
  [ActivityType.Poster]: {
    ...sharedLabels,
    presentationTitle: 'Presentation Title',
    firstAuthor: 'Applicant is first author',
    activityEndDate: 'End Date',
  },
  [ActivityType.Article]: {
    ...sharedLabels,
    doi: 'DOI',
    firstAuthor: 'Applicant is first listed or last listed author',
    activityEndDate: 'Date',
  },
  [ActivityType.Abms]: {
    ...sharedLabels,
    board: 'Board',
    subspeciality: 'Subspeciality',
    certificationDate: 'Date',
  },
  [ActivityType.Acgme]: {
    ...sharedLabels,
    program: 'Name of Residency or Fellowship Program',
    activityUnits: 'Years in Program',
    activityStartDate: 'Start of Program',
    activityEndDate: 'End of Program',
  },
  [ActivityType.ICC]: {
    ...sharedLabels,
    conferenceTitle: 'Conference Title',
    conferenceAccreditor: 'Accreditor',
    activityStartDate: 'Start Date',
    activityEndDate: 'End Date',
    city: 'City',
    country: 'Country',
    activityUnits: 'Number of Credits',
  },
};
