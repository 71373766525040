import React from 'react';
import { SelectWrap, StyledSelect } from '../styles';

export interface Props extends React.SelectHTMLAttributes<HTMLSelectElement> {
  name: string;
  options: Option[];
  onChange?: React.ChangeEventHandler;
  defaultLabel?: string;
}

export interface Option {
  value: string | number | boolean;
  label: string;
}

const Select: React.FunctionComponent<Props> = ({ options, name, defaultLabel, ...rest }) => (
  <SelectWrap>
    <StyledSelect as="select" name={name} {...rest}>
      {defaultLabel && <option value="">{defaultLabel}</option>}
      {options.map((option: Option) => (
        <option key={option.value.toString()} value={option.value.toString()} data-test-id={`select-value-${option.value}`}>
          {option.label}
        </option>
      ))}
    </StyledSelect>
  </SelectWrap>
);

export default Select;
