import React from 'react';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { buildRoute, Routes } from '../../constants/Routes';
import { fetchCreditEarner } from '../../ducks/creditEarners';
import { ExternalCreditEarner } from '../../types';
import { Columns, ColumnsMain, ColumnsSide } from '../Columns';
import CreditEarnerForm from '../CreditEarnerForm';

interface Props extends RouteComponentProps {
  user: ExternalCreditEarner;
  fetchCreditEarner: any;
}

const UserProfileEdit: React.FunctionComponent<Props> = ({ user, fetchCreditEarner, history }) => {
  useEffect(() => {
    return () => {
      // Fetch earner on unmount so any changes made
      // in the `<CreditEarnerForm />` are reflected.
      fetchCreditEarner(user.id);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Columns>
      <ColumnsMain>
        <CreditEarnerForm
          submitText="Save Changes"
          history={history}
          user={user}
          redirectTo={buildRoute(Routes.UserShow, { id: user.id })}
          view="update"
        />
      </ColumnsMain>
      <ColumnsSide />
    </Columns>
  );
};

const mapDispatchToProps = {
  fetchCreditEarner,
};

export default withRouter(connect(null, mapDispatchToProps)(UserProfileEdit));
