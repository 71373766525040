import styled, { css } from '../../theme';
import { themeColor } from '../../theme/helpers';
import { Color } from '../../theme/primaryTheme';
import Button from '../Button';

export interface MenuProps {
  isOpen: boolean;
}

export const Root = styled.div`
  position: relative;
  display: inline-block;
  line-height: 1;
`;

export const MenuButtonStyles = styled.button<MenuProps>`
  appearance: none;
  background-color: transparent;
  border: none;
  box-shadow: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0;
  transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);

  &[aria-expanded='true'] {
    pointer-events: none;
  }

  :focus {
    outline: none;
  }
`;

const openStyles = css`
  opacity: 1;
  transform: translate3d(-50%, 0, 0);
  visibility: visible;
  pointer-events: auto;
`;

const arrowSize = 12;

export const MenuPanelStyles = styled.div<MenuProps>`
  position: absolute;
  top: 100%;
  left: 50%;
  min-width: 18rem;
  margin-top: 2rem;
  background-color: ${themeColor(Color.Reverse)};
  opacity: 0;
  transform: translate3d(-50%, -1rem, 0);
  visibility: hidden;
  pointer-events: none;
  transition: all 0.125s cubic-bezier(0.455, 0.03, 0.515, 0.955);
  will-change: transform, opacity;
  z-index: 2;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.15);

  ${({ isOpen }) => (isOpen ? openStyles : '')};

  ::before {
    position: absolute;
    top: -${arrowSize - 2}px;
    left: 50%;
    content: '';
    border-right: ${arrowSize}px solid transparent;
    border-bottom: ${arrowSize}px solid ${themeColor(Color.Reverse)};
    border-left: ${arrowSize}px solid transparent;
    transform: translateX(-50%);
  }
`;

export const MenuItemStyles = styled(Button)<{ context?: string }>`
  padding: 1.5rem 2rem;
  color: ${themeColor(Color.Primary)};
  text-align: left;

  &::after {
    content: none;
  }

  &:hover {
    color: ${themeColor(Color.Accent)};
  }

  &:focus {
    outline: none;
  }

  ${({ context }) =>
    context === 'danger'
      ? css`
          color: ${themeColor(Color.Error)};
          background-color: rgba(252, 94, 71, 0.2);

          &:hover {
            color: ${themeColor(Color.Reverse)};
            background-color: ${themeColor(Color.Error)};
          }
        `
      : ''}
`;
