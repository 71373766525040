import { createAsyncThunk, createReducer } from '@reduxjs/toolkit';
import { Pra } from '../types';
import { apiRequest } from '../utilities/api';

/**
 * This is the PRA transcript, not claims with application type "PRA"
 */
export const fetchPras = createAsyncThunk<Pra[], number, { rejectValue: string }>('@PRAS/FETCH', async (userId, { rejectWithValue }) => {
  try {
    const { data } = await apiRequest.get<Pra[]>('/v1/pra/transcripts', { params: { userId } });
    return data;
  } catch (error) {
    rejectWithValue(error.response.message);
  }
});

export const initialState: {
  isLoading: boolean;
  pras: Pra[];
  error: string;
} = {
  isLoading: false,
  pras: [],
  error: '',
};

export default createReducer(initialState, (builder) =>
  builder
    .addCase(fetchPras.pending, (state) => ({
      ...state,
      error: '',
      isLoading: true,
    }))
    .addCase(fetchPras.fulfilled, (state, { payload }) => ({
      ...state,
      pras: payload,
      error: '',
      isLoading: false,
    }))
    .addCase(fetchPras.rejected, (state, { payload }) => ({
      ...state,
      error: payload,
      isLoading: false,
    }))
);
