import {
  connect as connectFormik,
  ErrorMessage,
  Field as FormikField,
  FieldProps as FormikFieldProps,
  FormikContextType,
  getIn,
} from 'formik';
import React from 'react';
import { useDropzone } from 'react-dropzone';
import { useDispatch } from 'react-redux';
import { S3UploadStatus } from 'src/constants/S3UploadStatus';
import { removeCreditActivityAttachment, uploadCreditActivityAttachments } from 'src/ducks/claims';
import styled from 'src/theme';
import { themeColor } from 'src/theme/helpers';
import { Color } from 'src/theme/primaryTheme';
import { isArray } from 'util';
import { CreditActivityAttachment } from '../../types';
import Button from '../Button';
import { ErrorText } from '../Field/styles';
import { FieldLabel } from '../Label';
import Text, { TextStyle } from '../Text';
import ProofDocumentList from './ProofDocumentList';

interface Props {
  name: string;
  label?: string;
  required?: boolean;
  helpText?: string;
  claimId?: number;
  dropZoneText?: string;
  buttonText?: string;
  uploadsDisabled?: boolean;
}

interface FormikConnectProps {
  formik?: FormikContextType<any>;
}

export const ProofDocumentUpload: React.FunctionComponent<Props & FormikConnectProps> = ({
  name,
  label = 'Proof Document(s)',
  dropZoneText = 'Drag and drop or click to select .pdf, .jpg, or .png files',
  buttonText = 'Choose File',
  required,
  helpText,
  formik,
  claimId = 0,
  uploadsDisabled = false,
  ...rest
}) => {
  const dispatch = useDispatch();
  const { values } = formik;
  const setFieldValue = formik.setFieldValue;
  const acceptableFormats = ['image/jpeg', 'image/png', 'application/pdf', 'application/zip'];
  const attachments: CreditActivityAttachment[] = getIn(values, name, []);

  const onRemoveItem = (attachment: CreditActivityAttachment) => {
    dispatch(
      removeCreditActivityAttachment({
        attachment,
        onSuccess: () => {
          setFieldValue(
            name,
            attachments.filter((a) => a && a.id !== attachment.id)
          );
        },
      })
    );
  };

  const { fileRejections, getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: acceptableFormats,
    onDropAccepted: (files: File[]) => {
      dispatch(
        uploadCreditActivityAttachments({
          claimId,
          files,
          startingFieldIndex: attachments.length,
          beforeUpload: (attachment: CreditActivityAttachment, fieldIndex: number) => {
            setFieldValue(`${name}[${fieldIndex}]`, {
              ...attachment,
              loading: true,
            });
          },
          onSuccess: (attachment: CreditActivityAttachment, fieldIndex: number) => {
            setFieldValue(`${name}[${fieldIndex}]`, {
              ...attachment,
              loading: false,
            });
          },
          onError: (attachment: CreditActivityAttachment, fieldIndex: number) => {
            setFieldValue(`${name}[${fieldIndex}]`, {
              ...attachment,
              status: S3UploadStatus.FAILED,
              loading: false,
            });
          },
        })
      );
    },
  });

  const validFiles: CreditActivityAttachment[] = attachments.filter(
    ({ status }: CreditActivityAttachment) => status === S3UploadStatus.COMPLETE
  );
  const completeCount: number = validFiles.length;

  return (
    <FormikField name={name}>
      {({ form }: FormikFieldProps) => {
        const hasError = getIn(form.touched, name) && getIn(form.errors, name);
        return (
          <>
            <section>
              {label && <FieldLabel hasError={hasError} required={required} text={label} />}
              {helpText && <Text textStyle={TextStyle.Small}>{helpText}</Text>}
              {uploadsDisabled ? (
                <StyledDropzone hasError={false}>
                  <StyledDropzoneText>File uploads are disabled for this claim application.</StyledDropzoneText>
                </StyledDropzone>
              ) : (
                <StyledDropzone hasError={hasError} {...getRootProps({ isDragActive })}>
                  <input {...getInputProps({ name })} />
                  <StyledDropzoneText>{dropZoneText}</StyledDropzoneText>
                  <Button>{buttonText}</Button>
                </StyledDropzone>
              )}
              {attachments.length > 0 && (
                <>
                  <Text textStyle={TextStyle.Label}>Attachments ({completeCount})</Text>
                  <ProofDocumentList attachments={attachments} onRemoveItem={onRemoveItem} />
                </>
              )}
              {fileRejections.length > 0 && (
                <div style={{ marginTop: '2rem' }} data-test-id="rejected-files">
                  <Text textStyle={TextStyle.Small}>
                    The following file{fileRejections.length > 1 && 's'} cannot be uploaded because the format is incorrect:{' '}
                    <strong>{fileRejections.map((fileRejection) => fileRejection.file.name).join(', ')}</strong>
                  </Text>
                </div>
              )}
            </section>
            <ErrorMessage name={name}>
              {(errorMessage = '') => (
                <ErrorText data-test-id={`${name}-error`} {...rest}>
                  {isArray(errorMessage) ? errorMessage.join(', ') : errorMessage}
                </ErrorText>
              )}
            </ErrorMessage>
          </>
        );
      }}
    </FormikField>
  );
};

const getColor = (props: any) => {
  let color = Color.Secondary;
  if (props.isDragActive) {
    color = Color.Accent;
  } else if (props.hasError) {
    color = Color.Error;
  }

  return color;
};

const StyledDropzone = styled.div<{ hasError: boolean }>`
  text-align: center;
  padding: 4rem;
  border: 2px dashed ${(props) => themeColor(getColor(props))};
  background-color: ${themeColor(Color.BackgroundLight)};
  margin: 1rem 0;
  border-radius: 5px;
  transition: border 0.25s ease-in-out;

  &:last-child {
    margin-bottom: 0;
  }

  button {
    pointer-events: none;
  }
`;

const StyledDropzoneText = styled.div`
  margin-bottom: 2rem;
`;

export default connectFormik<Props>(ProofDocumentUpload);
