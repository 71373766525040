import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { themeColor } from 'src/theme/helpers';
import { Color, ThemeColors } from 'src/theme/primaryTheme';
import styled from 'styled-components';
import { emptyCellCharacter } from '../../constants/General';
import { ModalType } from '../../constants/ModalType';
import { hideModal, showModal } from '../../ducks/modal';
import { deleteActivityById } from '../../ducks/transcript';
import { boardsDataSelector } from '../../selectors/boards';
import { nonDeletedLearnerActivitySelector, transcriptErrorSelector } from '../../selectors/transcripts';
import { isCreditProcessor } from '../../selectors/user';
import { LearnerActivity, Submission } from '../../types';
import { momentUtc } from '../../utilities/moment';
import ActionMenu, { ActionMenuItem } from '../ActionMenu';
import Alert, { AlertType } from '../Alert';
import Icon from '../Icon/index';
import Table, { ColumnConfig } from '../Table';
import Text from '../Text';
import NoTranscriptDataMessage from '../TranscriptLists/NoTranscriptDataMessage';
import CertificateDownloadButton from './CertificateDownloadButton';
import DeleteActivityModal from './DeleteActivityModal';

const TranscriptTable: React.FunctionComponent = () => {
  const dispatch = useDispatch();
  const transcript = useSelector(nonDeletedLearnerActivitySelector);
  const boards = useSelector(boardsDataSelector);
  const error = useSelector(transcriptErrorSelector);
  const isProcessor = useSelector(isCreditProcessor);

  if (!transcript.length) {
    return <NoTranscriptDataMessage dataType="learnerActivity" />;
  }

  const columns: ColumnConfig[] = [
    {
      header: 'Date(s)',
      id: 'date',
      accessor: (data: any) => (data.activityEndDate ? momentUtc(data.activityEndDate).format('MM/DD/YY') : emptyCellCharacter),
    },
    {
      header: 'Activity',
      id: 'activity',
      width: '30%',
      accessor: 'courseTitle',
      cellComponent: ({ value }: any) => (
        <Text bold={true} tag="span">
          {value}
        </Text>
      ),
    },
    {
      header: 'Accredited Provider',
      id: 'accreditor',
      accessor: 'accreditor',
    },
    {
      header: 'Activity Type',
      id: 'activityType',
      accessor: 'activityType',
    },
    {
      header: 'Credit Type',
      id: 'creditType',
      accessor: 'accredidationType',
      cellComponent: ({ value }: any) => <Text tag="em">{value}</Text>,
    },
    {
      header: 'Board Transmission',
      id: 'boardTransmission',
      align: 'left',
      accessor: 'submissions',
      cellComponent: ({ value }: { value: Submission[] }) => {
        if (!value || value.length === 0) {
          return <>{emptyCellCharacter}</>;
        }

        return (
          <>
            {value.map((submission) => {
              const submissionBoard = boards.find(
                (board) => board.abbreviation === submission.domain || board.metadata.abbreviation === submission.domain
              );
              const boardName = submissionBoard ? submissionBoard.name : submission.domain;

              return (
                <BoardTransmissionStatus key={submission.domain}>
                  <IndicatorAndBoardName>
                    <StatusIndicator
                      width="18px"
                      height="18px"
                      viewBox="0 0 18 18"
                      fill={submission.submitted ? ThemeColors.success : ThemeColors.error}
                      name={submission.submitted ? 'successIndicator' : 'failureIndicator'}
                    />
                    <Text>{boardName}</Text>
                  </IndicatorAndBoardName>
                  {isProcessor || submission.submitted ? null : <FixItLink to="/preferences">Fix It</FixItLink>}
                </BoardTransmissionStatus>
              );
            })}
          </>
        );
      },
    },
    {
      header: 'Credits',
      id: 'credits',
      align: 'center',
      accessor: 'praCreditsClaimed',
    },
    {
      header: '',
      id: 'certificateUrl',
      align: 'center',
      accessor: 'certificateUrl',
      cellComponent: CertificateDownloadButton,
    },
  ];

  if (isProcessor && !columns.find((col: ColumnConfig) => col.id === 'actions')) {
    columns.push({
      header: '',
      id: 'actions',
      accessor: '',
      width: '60px',
      cellComponent: ({ data }: { data: LearnerActivity }) => {
        const { id } = data;
        const modalId = `${ModalType.DeleteActivity}-${id}`;

        const handleClick = () => {
          dispatch(showModal(modalId));
        };

        const deleteActivity = (id: number) => () => {
          dispatch(deleteActivityById(id));
          dispatch(hideModal(modalId));
        };

        return (
          <>
            <ActionMenu>
              <ActionMenuItem onClick={handleClick} context="danger">
                Delete Activity
              </ActionMenuItem>
            </ActionMenu>
            <DeleteActivityModal modalId={modalId} handleConfirm={deleteActivity(id)} />
          </>
        );
      },
    });
  }

  return (
    <>
      {error && (
        <Alert data-test-id="transcriptTableError" type={AlertType.Error}>
          {error}
        </Alert>
      )}
      <Table id="transcriptTable" columns={columns} data={transcript} />
    </>
  );
};

const BoardTransmissionStatus = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;

  &:last-of-type {
    margin-bottom: 0;
  }
`;

const IndicatorAndBoardName = styled.div`
  display: flex;
  align-items: center;
`;

const StatusIndicator = styled(Icon)`
  min-width: 1.8rem;
  margin-right: 0.8rem;
`;

const FixItLink = styled(Link)`
  color: ${themeColor(Color.Accent)};
  font-weight: 700;
  text-decoration: underline;
  min-width: 4rem;
  margin-left: 0.4rem;
`;

export default TranscriptTable;
